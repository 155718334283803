import {Module} from "vuex"

// import {fromDishToDishBackend} from "@/store/modules/admin/utils/FromDishToDishBackend"
import {
  Dish,
  DishFromBackEnd,
  Emoji,
  EmojiForSelection,
  Ingredient,
  IngredientParamsForDish
} from "@/utils/data/interfaces"
import ingredientUnitsShortName from "@/modules/admin/models/records/IngredientUnitsShortName";
import ingredientsCategoryName from "@/modules/admin/models/records/IngredientsCategoryName";
import {SystemMessages, TypeMessages} from "@/modules/order/models/data/enums"
import {elMessage} from "@/modules/order/models/elMessage"

import {get, post, put} from "@/utils/fetchQueries"

export const dishGuidebook: Module<any, any> = {
  state: {
    dishBeforeEditing: {} as Dish,
    dishForEditing: {} as Dish,
    typeOfEditing: 'post',
    ingredientsForDisplayingInForm: [] as Array<string>,
    deltaDishWeight: 0,
    //ingredients
    mapIngredientsFromEditingDish: new Map<number, Ingredient>(),
    ingredientsForDishEditing: [] as Ingredient[],

    //emojis
    mapEmojisFromEditingDish: new Map<number, number>(),
    emojisForDishEditing: [] as EmojiForSelection[],

  },
  getters: {
    getDishBeforeEditing(state) {
      return state.dishBeforeEditing
    },
    getDeltaDishWeight(state) {
      return  state.deltaDishWeight
    },
    getDishForEditing(state) {
      return state.dishForEditing
    },
    getTypeOfEditing(state) {
      return state.typeOfEditing
    },
    getMapEmojisFromEditingDish(state) {
      return state.mapEmojisFromEditingDish
    },
    getIngredientsForDisplayingInForm(state) {
      return state.ingredientsForDisplayingInForm
    },
    //ingredients
    getIngredientsForDishEditing: (state) => (filterNameValue?: string) => {
      return state.ingredientsForDishEditing
        .filter((ingredient: Ingredient) => !filterNameValue || ingredient.name.toLowerCase().includes(filterNameValue.toLowerCase()))
        .map((ingredient: Ingredient) => {
          return {
            ...ingredient,
            unit: ingredientUnitsShortName[ingredient.unit],
            category: ingredientsCategoryName[ingredient.category],
            isIngredientInDish: state.mapIngredientsFromEditingDish.has(ingredient.id),
            amount: state.mapIngredientsFromEditingDish.get(ingredient.id)?.amount,
          }
        })
    },
    getIngredientsForBack(state) {
      const ingredientsForBack = [] as Array<IngredientParamsForDish>
      state.mapIngredientsFromEditingDish.forEach((value: Ingredient, key: number) => {
        ingredientsForBack.push({
          ingredientId: key,
          amount: value.amount as number
        })
      })
      return ingredientsForBack
    },
    getBeforeEditingIngredients(state) {
      return state.dishBeforeEditing.ingredients?.map((ingredient: Ingredient) => {
        return {
          ...ingredient,
          unit: ingredientUnitsShortName[ingredient.unit]
        }
      })
    },
    getIngredientsFromEditingDishForCompare(state) {
      return [...state.mapIngredientsFromEditingDish.values()].map((ingredient: Ingredient) => {
        return {
          id: ingredient.id,
          amount: ingredient.amount,
        }
      }).sort()
    },
    getBeforeEditingIngredientsForCompare(state) {
      return state.dishBeforeEditing.ingredients?.map((ingredient: Ingredient) => {
        return {
          id: ingredient.id,
          amount: ingredient.amount,
        }
      }).sort()
    },

    getHasAnyIngredientsInDish(state) {
      return !![...state.mapIngredientsFromEditingDish.values()].length
    },


    //emojis
    getEmojisFromEditingDish(state) {
      state.emojisForDishEditing.forEach((emoji: EmojiForSelection) => {
        emoji.isSelected = state.mapEmojisFromEditingDish.has(emoji.id)
      })
      return state.emojisForDishEditing
    },
    getEmojisForBack(state) {
      const emojiIds = [] as Array<number>
      for (let key of state.mapEmojisFromEditingDish.keys()) {
        emojiIds.push(key)
      }
      return emojiIds
    },
  },
  mutations: {
    setDeltaDishWeight(state, delta) {
      state.deltaDishWeight = delta
    },

    setDishBeforeEditing(state, {dish, emojis, image}) {
      state.dishBeforeEditing = dish ?? {}
      state.dishBeforeEditing.emojis = emojis ?? []
      state.dishBeforeEditing.image = image ?? ''
    },
    setDishForEditing(state, data) {
      state.dishForEditing = data
    },
    setTypeForEditing(state, data) {
      state.typeOfEditing = data
    },

    //ingredients
    setAllIngredientsForDishEditing(state, data) {
      state.ingredientsForDishEditing = data
    },
    setMapIngredientsFromEditingDish(state, data) {
      state.mapIngredientsFromEditingDish = data
    },

    changeIngredients(state, ingredient: Ingredient) {
      if (ingredient.amount || ingredient.amount === 0) {
        const tempIngredient = state.ingredientsForDishEditing.find((item: Ingredient) => item.id === ingredient.id)
        state.mapIngredientsFromEditingDish.set(ingredient.id, {
          id: tempIngredient.id,
          amount: ingredient.amount,
          category: tempIngredient.category,
          name: tempIngredient.name,
          unit: tempIngredient.unit
        })
        return
      }
      state.mapIngredientsFromEditingDish.delete(ingredient.id)
    },

    addIngredientsToDish(state) {
      const newIngredients = Array<string>()
      const tempIngredients = [...state.mapIngredientsFromEditingDish.values()].sort((ingredientFirst: Ingredient, ingredientSecond: Ingredient) => (ingredientSecond.amount ?? 0) - (ingredientFirst.amount ?? 0))
      if (!tempIngredients.length) {
        state.ingredientsForDisplayingInForm = []
        return
      }
      tempIngredients.forEach((ingredient: Ingredient) => {

        if (!ingredient.amount) {
          newIngredients.push(` ${ingredient.name}`)
          return
        }
        newIngredients.push(` ${ingredient.name} ${ingredient.amount} ${ingredientUnitsShortName[ingredient.unit]}`)

      })
      state.ingredientsForDisplayingInForm = newIngredients
    },
    resetIngredients(state) {
      state.mapIngredientsFromEditingDish = new Map<number, Ingredient>()
    },
    //emojis
    setAllEmojiForDishEditing(state, data) {
      state.emojisForDishEditing = data
    },
    setMapEmojisForDishEditing(state, data) {
      state.mapEmojisFromEditingDish = data
    },
    changeEmojis(state, data) {
      if (!state.mapEmojisFromEditingDish.has(data.id)) {
        state.mapEmojisFromEditingDish.set(data.id, data.name)
        return
      }
      state.mapEmojisFromEditingDish.delete(data.id)
    },
    setIngredientsForDisplayingInForm(state, data) {
      state.ingredientsForDisplayingInForm = data
    }
  },
  actions: {

    //client
    addRemoveEmoji({commit}, emoji) {
      commit("changeEmojis", emoji)
    },
    addRemoveIngredients({commit}, ingredient) {
      commit("changeIngredients", ingredient)
    },
    addAllSelectIngredientsToDish({commit}) {
      commit("addIngredientsToDish")
    },
    clearSelectedIngredients({commit}) {
      commit("resetIngredients")
    },
    //backend
    async getAllEmojiForDishEditing({commit}) {
      const res = await get('/api/emoji')
      if (res.ok) {
        const data = (await res.json()).data
        commit("setAllEmojiForDishEditing", data)
      }
    },
    async getAllIngredientsForDishEditing({commit}) {
      const res = await get(`/api/ingredient`)
      if (!res.ok) {
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
      } else {
        const ingredientsFromBack: Ingredient[] = (await res.json()).data.sort((ingredient1: Ingredient, ingredient2: Ingredient) => (ingredient1.name === ingredient2.name) ? 0 : ingredient1.name > ingredient2.name ? 1 : -1)

        commit("setAllIngredientsForDishEditing", ingredientsFromBack.filter(ingredient => {
          ingredient.isIngredientInDish = false
          ingredient.amount = undefined
          return !ingredient.isDeleted
        }))
      }
    },

    async getDish({commit}, idDish) {
      if (!idDish) {

        commit("setDishForEditing", {})
        commit("setDishBeforeEditing", {})
        commit("setDeltaDishWeight", 0)
        commit("setTypeForEditing", "post")

        const clearMapIngredients: Map<number, number> = new Map()
        const clearMapEmojis: Map<number, string> = new Map()

        commit("setMapIngredientsFromEditingDish", clearMapIngredients)
        commit("setMapEmojisForDishEditing", clearMapEmojis)
        commit("setIngredientsForDisplayingInForm", [])

        return
      }
      const res = await get(`/api/dish?dishIds=${idDish}`)
      if (!res.ok) {
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
      } else {
        const dish = (await res.json()).data[0] as DishFromBackEnd
        const fullSizeImage = (await (await get(`/api/dish/image/${idDish}`)).json()).data.fullSizeImage

        const mapIngredients: Map<number, Ingredient> = new Map()
        let allWeightIngredients = 0
        dish.ingredients.forEach((ingredient: Ingredient) => {
          mapIngredients.set(ingredient.id, ingredient)
          allWeightIngredients += ingredient.amount ?? 0
        })
        commit("setDeltaDishWeight", allWeightIngredients - dish.weight)
        const ingredientsView = dish.ingredients.map((ingredient) => {
          return ` ${ingredient.name} ${ingredient.amount!} ${ingredientUnitsShortName[ingredient.unit]}`
        })

        const mapEmojis: Map<number, string> = new Map()
        dish.emojis?.forEach((emoji: Emoji) => {
          mapEmojis.set(emoji.id, emoji.name)
        })

        commit("setDishBeforeEditing", {
            dish: JSON.parse(JSON.stringify(dish)),
            emojis: [...mapEmojis],
            image: fullSizeImage ? fullSizeImage : dish.image
          }
        )
        commit("setDishForEditing", {
          ...dish,
          image: fullSizeImage ? fullSizeImage : dish.image
        })
        commit("setTypeForEditing", "update")
        commit("setMapIngredientsFromEditingDish", mapIngredients)
        commit("setMapEmojisForDishEditing", mapEmojis)
        commit("setIngredientsForDisplayingInForm", ingredientsView)
      }
    },

    //add and update dish on back
    async setDish({commit}, setDish) {
      const res = await post(`/api/dish/`, setDish)
      if (res.ok) {
        elMessage(SystemMessages.DISH_ADDED, TypeMessages.SUCCESS)
      } else {
        res.json().then(res => res.status && res.status === 405
          ? elMessage(SystemMessages.DISH_ALREADY_EXISTS, TypeMessages.ERROR)
          : elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
        )
      }
    },

    async updateDish({commit}, changedDish) {
      const res = await put(`/api/dish/${+changedDish.id!}`, changedDish)
      if (res.ok) {
        elMessage(SystemMessages.DISH_UPDATED, TypeMessages.SUCCESS)
      } else {
        res.json().then(res => res.status && res.status === 405
          ? elMessage(SystemMessages.DISH_IN_USE, TypeMessages.ERROR)
          : elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
        )
      }
    },
  },
}
export default dishGuidebook
