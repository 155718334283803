
import { defineComponent, computed, ref } from "vue"
import { useStore } from "vuex"
import useFeedbackRules from './rules'
import type { FormInstance } from 'element-plus'
import { Feedback } from '@/store/modules/common/feedback'


export default defineComponent({
    name: "Feedback",

    setup() {
        const store = useStore()
        const isNightSelect = computed(() => store.getters.getIsSelectedNight)

        const ruleFormRef = ref<FormInstance>()
        const formSize = ref("default")

        const feedback = ref<Feedback>({ title: '', description: '' })

        const feedbackOpen = ref(false)
        const closeFeedback = () => {
            feedbackOpen.value = false
            feedback.value = { title: '', description: '' }
            ruleFormRef.value?.resetFields()
        }
        const openFeedback = () => feedbackOpen.value = true

        const sendClicked = async (form: FormInstance | undefined, feedback: Feedback) => {
            if (!form) return
            await form.validate(async (valid, fields) => {
                if (valid) {
                    await store.dispatch('sendFeedback', feedback)
                    closeFeedback()
                }
            })
        }

        return {
            ruleFormRef,
            formSize,
            ...useFeedbackRules(),
            feedback,
            openFeedback,
            closeFeedback,
            feedbackOpen,
            sendClicked,
            isNightSelect
        }
    }
})
