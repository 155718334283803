export namespace SaveFiltering {

    export type FilterMap = {[key: string]: Array<string>}
    export type FilteringObject = {[key: string]: string | any}

    // is object satisfy mapForFiltering requirments
    // e.g. {a: 'name', b: 'name1'} satisfy {a: ['name','another']}
    export const isObjectFieldsSatisfyMapRequirments = (objectForTest: FilteringObject, map: FilterMap): boolean => {

        for (let key in map) {
            if (map[key].length > 0 && (!objectForTest[key] || !map[key].includes(objectForTest[key]))) return false
        }

        return true
    }
}
