
import { computed, defineComponent, ref, watch } from "vue"
import { useRouter } from "vue-router"
import { handbooks } from './selectsVariants'
import { useStore } from "vuex";

//TODO: обобщить таблицы GuideIngridients, ArchiveDeletedDish, GuideIngridients, ArchiveDeletedIngredient
interface mainHandbooks {
  label: string
  variants: Array<handbooksVariants>
}

interface handbooksVariants {
  label: string
  route: string
  name: string
}

export default defineComponent({
  name: "Handbooks",

  setup() {
    const store = useStore()
    const router = useRouter()
    const selectedHandbook = ref()
    const selectedVariation = ref()

    const identifyHandbooks = () => {
      handbooks.forEach((item: mainHandbooks) => {
        item.variants.forEach((variant: handbooksVariants) => {
          if (router.currentRoute.value.path.includes(variant.route)) {
            selectedHandbook.value = item
            selectedVariation.value = variant.label
          }
        })
      })
    }
    identifyHandbooks()

    watch(selectedHandbook, newHandbook => {
      selectedVariation.value = newHandbook.variants[0]
    })

    watch(selectedVariation, newVariation => {
      router.push({ name: selectedVariation.value.name })

    })
    watch(() => router.currentRoute.value.path, () => {
      identifyHandbooks()
    })

    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    return {
      handbooks,
      selectedHandbook,
      selectedVariation,
      isNightSelect,
    }
  },
})
