

import { computed, defineComponent, ref, watch } from "vue"
import { notificationItems } from "@/modules/order/models/data/constants"
import { useStore } from "vuex"
import { Notifications } from "@/modules/order/models/dishInterfaces"
import TimePicker from "@/modules/order/components/common/TimePicker.vue"
import moment from "moment"
import ToggleCheckbox from "@/common/components/ToggleCheckbox.vue"
import CustomButton from "@/common/components/buttons/CustomButton.vue"
import { compareObjects } from "@/utils/helpers/compareObjects"

export default defineComponent({
  name: "NotificationSettings",
  components: { TimePicker, ToggleCheckbox, CustomButton },
  setup() {
    const store = useStore()
    const userId = store.getters.getUserId

    const convertMomentDates = (date: string) => moment(date, "HH:mm").toISOString()

    const notificationPush = ref(store.getters.getPushSettings)
    const notificationMail = ref(store.getters.getMailSettings)
    const singleTimePicker = ref([false, false, false, true])
    const doubleTimePicker = ref([false, false, true, false])
    const orderMorningReminder = ref(convertMomentDates(store.getters.getSettings.orderMorningReminder as string))
    const orderMiddayReminder = ref(convertMomentDates(store.getters.getSettings.orderMiddayReminder as string))
    const canceledOrderReminder = ref(convertMomentDates(store.getters.getSettings.canceledOrderReminder as string))

    if (!!userId) {
      store.dispatch("getNotifications", userId)
    }

    watch(() => store.getters.getSettings, () => {
      const settings: Notifications = store.getters.getSettings
      notificationPush.value = store.getters.getPushSettings
      notificationMail.value = store.getters.getMailSettings
      if (settings.canceledOrderReminder) canceledOrderReminder.value = convertMomentDates(settings.canceledOrderReminder as string)
      if (settings.orderMiddayReminder) orderMiddayReminder.value = convertMomentDates(settings.orderMiddayReminder as string)
      if (settings.orderMorningReminder) orderMorningReminder.value = convertMomentDates(settings.orderMorningReminder as string)
    })



    //Buttons handling
    const saveButtonDisabled = computed(() =>
      compareObjects(store.getters.getUnsavedPushSettings, notificationPush.value)
      && compareObjects(store.getters.getUnsavedMailSettings, notificationMail.value))

    const resetButtonDisabled = computed(() =>
      !notificationPush.value.find((value: boolean) => value)
      && !notificationMail.value.find((value: boolean) => value)
    )
    const sendSettings = () => {
      const settings = {
        menuMail: notificationMail.value[0],
        menuPush: notificationPush.value[0],
        spareDishMail: notificationMail.value[1],
        spareDishPush: notificationPush.value[1],
        orderMail: notificationMail.value[2],
        orderPush: notificationPush.value[2],
        canceledOrderMail: notificationMail.value[3],
        canceledOrderPush: notificationPush.value[3],
        orderMorningReminder: moment(orderMorningReminder.value).format("HH:mm"),
        orderMiddayReminder: moment(orderMiddayReminder.value).format("HH:mm"),
        canceledOrderReminder: moment(canceledOrderReminder.value).format("HH:mm")
      }
      store.dispatch("setNotifications", { userId, settings })
    }

    const clear = () => store.dispatch("setInitNotifications")
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    return {
      notificationItems,
      notificationPush,
      notificationMail,
      singleTimePicker,
      doubleTimePicker,
      orderMorningReminder,
      orderMiddayReminder,
      canceledOrderReminder,
      saveButtonDisabled,
      resetButtonDisabled,
      sendSettings,
      clear,
      isNightSelect
    }
  },
})
