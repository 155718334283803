
import { computed, defineComponent, PropType, ref } from "vue"
import { useStore } from "vuex"

//interfaces
//composables
import ToggleCheckbox from "@/common/components/ToggleCheckbox.vue";
import { User } from "@/utils/data/interfaces";

export default defineComponent({
  name: "remindCard",
  components: { ToggleCheckbox },
  props: {
    placeholderValue: {
      type: String as PropType<string>,
      required: true
    },
    searchColumnWidthValue: {
      type: String as PropType<string>,
      required: true
    },
    toggleColumnWidthValue: {
      type: String as PropType<string>,
      required: true
    },
  },
  emits: ["closeDialog"],
  setup(props, { emit }) {
    const store = useStore()
    const filterValueForNonPaidUsers = ref('')
    const defaultTablePaddings = ref(50)
    const tableWidth = computed(() => (parseInt(props.toggleColumnWidthValue) + parseInt(props.searchColumnWidthValue) + defaultTablePaddings.value).toString())
    const disableRemindButton = computed(() => !store.getters.getHasIdsUsers)
    const usersForRemind = computed(() => store.getters.getNonPaidUsersForRemindCard(filterValueForNonPaidUsers.value))
    const addRemoveUserForRemind = (user: User) => {
      store.dispatch("addRemoveUserForRemind", user)
    }
    const remindAllUsers = () => {
      store.dispatch("remindUsers")
      closeDialog()
    }
    const remindSelectUsers = () => {
      store.dispatch("remindUsers")
      closeDialog()
    }
    const closeDialog = () => emit("closeDialog")
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    return {
      isNightSelect,
      tableWidth,
      filterValueForNonPaidUsers,
      usersForRemind,
      disableRemindButton,
      addRemoveUserForRemind,
      remindAllUsers,
      remindSelectUsers,
      closeDialog
    }
  },
})
