import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12a18844"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "period-selector" }
const _hoisted_3 = { class: "period-item" }
const _hoisted_4 = { class: "period-item" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["title", { night: _ctx.isNightSelect }])
    }, "Формирование отчета", 2),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", {
          class: _normalizeClass(["label", { night: _ctx.isNightSelect }]),
          for: "date-from"
        }, "c", 2),
        _createVNode(_component_el_date_picker, {
          class: _normalizeClass({ night: _ctx.isNightSelect }),
          type: "date",
          name: "date-from",
          "popper-class": "date-selector-with-shortcuts",
          modelValue: _ctx.dateFrom,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateFrom) = $event)),
          clearable: false,
          editable: false,
          "disabled-date": _ctx.disableDatesFrom,
          format: _ctx.FULLDATE_FORMAT,
          "value-format": "YYYY-MM-DDTHH:mm:ss",
          onChange: _ctx.periodFromChanged
        }, null, 8, ["class", "modelValue", "disabled-date", "format", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", {
          class: _normalizeClass(["label", { night: _ctx.isNightSelect }]),
          for: "date-to"
        }, "по", 2),
        _createVNode(_component_el_date_picker, {
          class: _normalizeClass({ night: _ctx.isNightSelect }),
          type: "date",
          name: "date-to",
          modelValue: _ctx.dateTo,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dateTo) = $event)),
          clearable: false,
          editable: false,
          "disabled-date": _ctx.disableDatesTo,
          format: _ctx.FULLDATE_FORMAT,
          "value-format": "YYYY-MM-DDTHH:mm:ss",
          onChange: _ctx.periodToChanged
        }, null, 8, ["class", "modelValue", "disabled-date", "format", "onChange"])
      ]),
      _createVNode(_component_custom_button, {
        "type-button": "success",
        class: "create-report-button",
        onClick: _ctx.generateReport,
        disabled: _ctx.noOrders
      }, {
        default: _withCtx(() => [
          _createTextVNode("СОЗДАТЬ ОТЧЕТ ")
        ]),
        _: 1
      }, 8, ["onClick", "disabled"])
    ]),
    (_ctx.noOrders)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.SystemMessages.NO_ORDERS), 1))
      : _createCommentVNode("", true)
  ]))
}