import { get } from "@/utils/fetchQueries"
import moment from "moment"
import { FULLDATE_FORMAT } from "@/utils/data/moment-formats"

export const downloadOrderHistory = (
  userId: number,
  dateFrom: string,
  dateTo: string
) => get(`/api/order/${userId}`, { dateFrom, dateTo })

export const downloadFirstOrder = (userId: number) => {
  const dateFrom = moment("01.01.1970", FULLDATE_FORMAT).toISOString()
  const dateTo = moment().add(15, "days").toISOString()
  return get(`/api/order/${userId}`, { dateFrom, dateTo })
}
