import store from '@/store';
import { Receipt, ReceiptPicture } from '@/modules/admin/purchasing-products/models/listInterfaces';

export const saveReceipts = async () => {
  const editingList = JSON.parse(localStorage.getItem('editingList')!);
  const savingReceipts: Array<{
    receipt: Receipt;
    pictures: ReceiptPicture[];
  }> = (Array.from(store.getters.getReceiptsTableData?.values()) as Array<Receipt>).map(
    (receipt: Receipt) => {
      if (!receipt.pictureIds) receipt.pictureIds = [];
      receipt.purchasingListId = editingList.id;
      receipt.executorId = store.getters.getUserId;
      return {
        receipt: receipt,
        pictures: store.getters.getPicturesFromStore(receipt.mapId) ?? [],
      };
    }
  );
  await store.dispatch('saveReceiptsAndPictures', { savingReceipts, listId: editingList.id });
};
