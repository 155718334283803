<template>
  <div class="logo-picture"
       :class="{ 'hide': visible }">
    <div class="content">
      <img
           alt="Логотип компании"
           :src="dayNightLogo" />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue"
import { useStore } from "vuex"
import { LogoUrls } from "@/modules/order/models/data/enums"

export default defineComponent({
  name: "LogoRelex",
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    const dayNightLogo = computed(() => isNightSelect.value ? LogoUrls.LOGO_NIGHT : LogoUrls.LOGO_DAY)
    return {
      dayNightLogo,
      isNightSelect,
    }
  }
}
)
</script>

<style lang="scss" scoped>
.logo-picture {
  display: flex;
  align-items: center;
  z-index: 6;
  transition: $default-trasition;

  @include breakpoint-tablet() {
    scale: 0.85;
  }
}

.logo-picture.hide {
  opacity: 0;
}

.content {
  align-items: center;
}
</style>