import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dialog_window = _resolveComponent("dialog-window")!

  return (_openBlock(), _createBlock(_component_dialog_window, {
    "show-close": true,
    cancelButtonText: "Отмена",
    okButtonText: "Удалить",
    title: "Вы действительно хотите удалить ингредиент?",
    width: "500px",
    onCancelClicked: _ctx.CloseModalWindow,
    onCloseDialog: _ctx.CloseModalWindow,
    onOkClicked: _ctx.dropIngredient
  }, null, 8, ["onCancelClicked", "onCloseDialog", "onOkClicked"]))
}