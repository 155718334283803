import permissionsMenu from "@/modules/portal/models/records/permissions-menu-links";
import {MenuLink} from "@/modules/portal/models/interfaces/menu-links-interface";
import {Roles} from "@/modules/order/models/data/enums";


export default function checkRoleForCurrentLink(link: string,) {

  const menu = Object.values(permissionsMenu) as Array<MenuLink>

  const itemMenuLink = menu.find((item: MenuLink) => link.includes(item.link))

  return itemMenuLink?.role ? itemMenuLink.role : Roles.USER

}