import { get, post, put, deleteUrl } from "@/utils/fetchQueries"
import {
  GuideBookTableRow,
  Manufacturer,
  Unit,
  PurchasingPlace,
} from "@/modules/admin/purchasing-products/models/guideBookInterfaces"
import { PurchasingGuideBooks } from "@/modules/admin/purchasing-products/models/enums"

//Manufacturer handling
export const downloadManufacturers = async () => await get("/api/manufacturer")

const saveManufacturer = async (row: GuideBookTableRow) => {
  return await post("/api/manufacturer", {
    name: row.name,
    productCategory: row.column2Value,
  })
}

const updateManufacturer = async (row: GuideBookTableRow) => {
  const updatingManufacturer: Manufacturer = {
    id: row.id,
    name: row.name,
    productCategory: row.column2Value,
  }
  return await put(
    `/api/manufacturer/${updatingManufacturer.id}`,
    updatingManufacturer
  )
}

const deleteManufacturer = async (row: GuideBookTableRow) =>
  await deleteUrl(`/api/manufacturer/${row.id}`, {})

//Unit handling
export const downloadUnits = async () => await get("/api/unit")

const saveUnit = async (row: GuideBookTableRow) => {
  return await post("/api/unit", {
    name: row.name,
    shortName: row.column2Value,
  })
}

const updateUnit = async (row: GuideBookTableRow) => {
  const updatingUnit: Unit = {
    id: row.id,
    name: row.name,
    shortName: row.column2Value,
  }
  return await put(`/api/unit/${updatingUnit.id}`, updatingUnit)
}

const deleteUnit = async (row: GuideBookTableRow) =>
  await deleteUrl(`/api/unit/${row.id}`, {})

//Purchasing places handling
export const downloadPurchasingPlaces = async () =>
  await get("/api/purchasing-place")

const savePurchasingPlace = async (row: GuideBookTableRow) => {
  return await post("/api/purchasing-place", {
    name: row.name,
    parentPlaceId: parseInt(row.column2Value),
  })
}

const updatePurchasingPlace = async (row: GuideBookTableRow) => {
  const updatingPurchasingPlace: PurchasingPlace = {
    id: row.id,
    name: row.name,
    parentPlaceId: parseInt(row.column2Value),
  }
  return await put(
    `/api/purchasing-place/${updatingPurchasingPlace.id}`,
    updatingPurchasingPlace
  )
}

const deletePurchasingPlace = async (row: GuideBookTableRow) =>
  await deleteUrl(`/api/purchasing-place/${row.id}`, {})

//Table row contents Manufacturer, Unit or PurchasingPlace according row.guideBookType
export const saveGuideBookTableRow = async (row: GuideBookTableRow) => {
  if (row.guideBookType === PurchasingGuideBooks.PURCHASING_PLACES)
    return await savePurchasingPlace(row)
  if (row.guideBookType === PurchasingGuideBooks.MANUFACTURERS)
    return await saveManufacturer(row)
  else return await saveUnit(row)
}

export const updateGuideBookTableRow = async (row: GuideBookTableRow) => {
  if (row.guideBookType === PurchasingGuideBooks.PURCHASING_PLACES)
    return await updatePurchasingPlace(row)
  if (row.guideBookType === PurchasingGuideBooks.MANUFACTURERS)
    return await updateManufacturer(row)
  else return await updateUnit(row)
}

export const deleteGuideBookTableRow = async (row: GuideBookTableRow) => {
  if (row.guideBookType === PurchasingGuideBooks.PURCHASING_PLACES)
    return await deletePurchasingPlace(row)
  if (row.guideBookType === PurchasingGuideBooks.MANUFACTURERS)
    return await deleteManufacturer(row)
  else return await deleteUnit(row)
}
