import { get, post, put, deleteUrl } from "@/utils/fetchQueries"
import {
  PurchasingList,
  PurchasingListRow,
} from "@/modules/admin/purchasing-products/models/listInterfaces"

//Each purchasing list contents list rows
//Purchasing lists handling
export const downloadLists = (dateFrom: string, dateTo: string) =>
  get(`/api/purchasing-list?dateFrom=${dateFrom}&dateTo=${dateTo}`)

export const savePurchasingList = (list: PurchasingList) => post("/api/purchasing-list", list)

export const changePurchasingList = (list: PurchasingList) =>
  put(`/api/purchasing-list/${list.id}`, list)

export const deletePurchasingList = (list: PurchasingList) =>
  deleteUrl(`/api/purchasing-list/${list.id}`, {})

//List rows handling
export const downloadListRows = (listId: number) => get(`/api/purchasing-list/rows/${listId}`)

export const processListRows = (listId: number, rows: PurchasingListRow[]) =>
  put(`/api/purchasing-list/rows/${listId}`, { rows: rows })

export const sendListToExecutor = () => post("/api/purchasing-list/notify", {})
