import store from "@/store";
import {OrderForOrderList} from "@/modules/admin/order-list/models/dishInterfaces";
import {OrderedDish} from "@/utils/data/interfaces";
import {AdditionalRoles} from "@/modules/admin/AppSettings/models/rolesHelper";

export const updateOrdersAccordingToMenu = async (dates: Array<string>, menusWithIds: Map<string, Array<number>>) => {

  await store.dispatch("downloadOrderList", {dateFrom: dates[0], dateTo: dates[dates.length - 1]})

  const orderList = store.getters.getOrderListWithAdminDishes as Array<OrderForOrderList>

  let datesWithOrder = new Set<string>()
  orderList.forEach(item => datesWithOrder.add(item.orderDate))

  for (let date of [...datesWithOrder.values()]) {
    const tempDishes = menusWithIds.get(date) as Array<number>

    const orderForThisDate = orderList.filter((order: OrderForOrderList) => order.orderDate === date)

    const verifiedOrders = orderForThisDate.map((order: OrderForOrderList) => {
      const dishesInOrder = order.dishes.filter((dish: OrderedDish) => !!tempDishes.find((dishId: number) => dishId === dish.dishId))
      return {
        ...order,
        dishes: dishesInOrder
      }

    })
    verifiedOrders.forEach((order: OrderForOrderList) => store.dispatch("changeUserOrder", {
      orderForDB: order,
      isSpareDishes: false,
      userAdditionalRole: AdditionalRoles.COOK
    }))
  }
}
