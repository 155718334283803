
import { computed, defineComponent, ref } from "vue"
import { useStore } from "vuex"

//interfaces
import { Dish } from "@/utils/data/interfaces"

//composables
import useTableFilters from "@/modules/admin/dish-guidebook/composables/useTableFilters"
import ConvertIngredientsForBack from "@/store/modules/admin/utils/ConvertIngredientsForBack";
import ConvertEmojisForBack from "@/store/modules/admin/utils/ConvertEmojisForBack";

export default defineComponent({
  name: "archiveDeletedDish",
  setup() {
    const { menuFilters, categoryFilters } = useTableFilters()
    const store = useStore()
    store.dispatch("getDeletedDishes")
    //Для автовыравнивания таблицы
    const tableLayout = ref("auto")
    //Функция поиска по имени
    const search = ref("")
    const handleSearchInput = (value: string) => {
      search.value = value
    }
    const filterTableData = computed(() => store.getters.getTableDeletedDishes.filter(
      (data: Dish) =>
        !search.value || data.name.toLowerCase().includes(search.value.toLowerCase())
    ))

    const dishForPreview = ref<Dish | false>(false)
    const handleCloseDishPreview = () => dishForPreview.value = false
    const handleOpenDishPreview = async (dish: Dish) => {
      await store.dispatch("downloadFullSizeImage", dish.dishId)
      dishForPreview.value = dish
    }

    //Фильтр отдельный для типов меню
    const filterHandlerMenu = (value: string, row: Dish) => {
      return row.menuType === value
    }
    const filterHandlerCategory = (value: string, row: Dish) => {
      return row.category === value
    }
    const handleRestore = async (row: Dish) => {
      const tempDish = store.getters.getDeletedDishesForFind.find((dish: Dish) => row.dishId === dish.dishId) as Dish
      tempDish.isDeleted = false
      await store.dispatch("restoreDish", {
        ...tempDish,
        ingredients: ConvertIngredientsForBack(tempDish.ingredients),
        emojiIds: ConvertEmojisForBack(tempDish.emojis),
      })
    }

    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    return {
      isNightSelect,
      search,
      tableLayout,
      filterTableData,
      filterHandlerMenu,
      filterHandlerCategory,
      menuFilters,
      categoryFilters,
      handleRestore,
      dishForPreview,
      handleCloseDishPreview,
      handleOpenDishPreview,
      handleSearchInput,
    }
  },
})
