import DishCategoryName from "@/modules/admin/models/records/DishCategoryName"
import DishMenuTypeName from "@/modules/admin/models/records/DishMenuTypeName"

interface dishFilterInterface {
  text: string
  value: string
}

export default function useDishTableFilters() {

  const categoryFilters = [...Object.values(DishCategoryName).map(category => {
    return {text: category, value: category}
  })] as Array<dishFilterInterface>

  const menuFilters = [...Object.values(DishMenuTypeName).map(category => {
    return {text: category, value: category}
  })] as Array<dishFilterInterface>

  return {
    menuFilters,
    categoryFilters
  }
}



