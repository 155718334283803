import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10e76271"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_item = _resolveComponent("menu-item")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, {
    class: _normalizeClass(["box-card", { night: _ctx.isNightSelect }]),
    shadow: "always"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["card-header", { night: _ctx.isNightSelect }])
      }, [
        _createElementVNode("img", {
          class: "menu-type-icon",
          src: _ctx.cardImage,
          alt: "Card image"
        }, null, 8, _hoisted_1),
        _createElementVNode("span", null, _toDisplayString(_ctx.cardName), 1)
      ], 2),
      _createVNode(_component_menu_item, {
        disabled: _ctx.disabled,
        "dish-menu": _ctx.dishMenu
      }, null, 8, ["disabled", "dish-menu"])
    ]),
    _: 1
  }, 8, ["class"]))
}