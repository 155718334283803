import { Module } from 'vuex';
import {
  PurchasingList,
  PurchasingListRow,
} from '@/modules/admin/purchasing-products/models/listInterfaces';
import {
  downloadLists,
  savePurchasingList,
  changePurchasingList,
  deletePurchasingList,
  processListRows,
  sendListToExecutor,
} from '@/modules/admin/purchasing-products/models/fetch-modules/fetchLists';
import { elMessage } from '@/modules/order/models/elMessage';
import { SystemMessages, TypeMessages } from '@/modules/order/models/data/enums';
import { ListStatus } from '@/modules/admin/purchasing-products/models/enums';
import { AdditionalRoles } from '@/modules/admin/AppSettings/models/rolesHelper';
import store from '@/store';

export const purchasingListsModule: Module<any, any> = {
  state: {
    purchasingListsMap: new Map<number, PurchasingList>(),
    listsDownloaded: false,
    //True if there is no purchasing lists in period
    endOfData: false,
  },
  getters: {
    getPurchasingListsTableData: (state) => (isBuyer: boolean) => {
      if (isBuyer)
        return (Array.from(state.purchasingListsMap.values()) as Array<PurchasingList>).filter(
          (row) =>
            store.getters.getUser.additionalRole === AdditionalRoles.BUYER
              ? row.status !== ListStatus.DRAFT
              : true
        );
      else return Array.from(state.purchasingListsMap.values()) as Array<PurchasingList>;
    },
    getPurchasingListMap(state) {
      return state.purchasingListsMap;
    },
    isEndOfData(state) {
      return state.endOfData;
    },
    isListsDownloaded(state) {
      return state.listsDownloaded;
    },
  },
  mutations: {
    //Lists
    fetchPurchasingLists(state, data) {
      if (data.length) {
        data.forEach((list: PurchasingList) => state.purchasingListsMap.set(list.id, list));
        state.endOfData = false;
        state.listsDownloaded = true;
      } else state.endOfData = true;
    },

    removeTableRow(state, row: PurchasingList) {
      state.purchasingListsMap.delete(row.id);
    },

    clearTable(state) {
      state.purchasingListsMap = new Map<number, PurchasingList>();
    },
  },
  actions: {
    downloadPurchasingLists: async ({ commit }, { dateFrom, dateTo }) => {
      const res = await downloadLists(dateFrom, dateTo);
      if (res.ok) {
        const data = await res.json();
        commit('fetchPurchasingLists', data.data);
      } else elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR);
    },

    savePurchasingList: async ({ commit }, { list, listRows }) => {
      const res = await savePurchasingList(list).then(async (res) => {
        const data = await res.json();
        list.id = data.data.id;
        list.dailyNumber = data.data.dailyNumber;
        listRows.forEach((row: PurchasingListRow) => (row.purchasingListId = list.id));
        return await processListRows(list.id, listRows);
      });
      if (res.ok) elMessage(SystemMessages.SAVED, TypeMessages.SUCCESS);
      else elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR);
    },

    async changePurchasingList({ commit }, { list, listRows }) {
      if (listRows.length) {
        const res = await changePurchasingList(list).then(() => {
          listRows.forEach((row: PurchasingListRow) => (row.purchasingListId = list.id));
          return processListRows(list.id, listRows);
        });
        if (res.ok) elMessage(SystemMessages.CHANGED, TypeMessages.SUCCESS);
        else elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR);
      } else {
        const res = await changePurchasingList(list);
        if (res.ok) {
          this.getters.getPurchasingListMap.get(list.id).status = ListStatus.CANCELED;
          elMessage(SystemMessages.CANCELED, TypeMessages.SUCCESS);
        } else elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR);
      }
    },

    deletePurchasingList: async ({ commit }, list) => {
      const res = await deletePurchasingList(list);
      if (res.ok) {
        elMessage(SystemMessages.DELETED, TypeMessages.SUCCESS);
        commit('removeTableRow', list);
      } else elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR);
    },
    sendListToExecutor: async () => {
      const res = await sendListToExecutor();
      if (res.ok) elMessage(SystemMessages.SENT, TypeMessages.SUCCESS);
      else elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR);
    },
    returnToWork: async ({ commit }, list) => {
      const res = await changePurchasingList(list);
      if (res.ok) elMessage(SystemMessages.RETURNED, TypeMessages.SUCCESS);
      else elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR);
    },
  },
};

export default purchasingListsModule;
