import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-273cbd26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "buttons-horizontal-panel lock" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_order_view = _resolveComponent("order-view")!
  const _component_dialog_window = _resolveComponent("dialog-window")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_custom_button, {
        "type-button": "success",
        onClick: _ctx.saveButtonClicked,
        disabled: _ctx.disabled || _ctx.isOrderChanged
      }, {
        default: _withCtx(() => [
          _createTextVNode("СОХРАНИТЬ ")
        ]),
        _: 1
      }, 8, ["onClick", "disabled"]),
      _createVNode(_component_custom_button, {
        "type-button": "reset",
        onClick: _ctx.showClearDialog,
        disabled: _ctx.disabled || _ctx.resetDisabled
      }, {
        default: _withCtx(() => [
          _createTextVNode("ОЧИСТИТЬ ")
        ]),
        _: 1
      }, 8, ["onClick", "disabled"])
    ]),
    _createVNode(_component_order_view, {
      modelValue: _ctx.dialogSaveVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialogSaveVisible) = $event)),
      "order-view-type": "ordering",
      order: _ctx.orderForDB,
      dishesByTypes: _ctx.dishTypes,
      onOkButtonClicked: _ctx.savingConfirmed,
      onCloseOrderDescription: _ctx.hideSaveDialog,
      onIsInPlasticChanged: _ctx.isInPlasticChanged
    }, null, 8, ["modelValue", "order", "dishesByTypes", "onOkButtonClicked", "onCloseOrderDescription", "onIsInPlasticChanged"]),
    _createVNode(_component_dialog_window, {
      modelValue: _ctx.dialogClearVisible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dialogClearVisible) = $event)),
      title: "Подтвердите действие",
      "main-text": [`Вы хотите очистить поля заказа?`],
      onCloseDialog: _ctx.hideClearDialog,
      onCancelClicked: _ctx.hideClearDialog,
      onOkClicked: _ctx.clearOrder,
      onClosed: _ctx.clearDialogClosed
    }, null, 8, ["modelValue", "onCloseDialog", "onCancelClicked", "onOkClicked", "onClosed"]),
    _createVNode(_component_dialog_window, {
      modelValue: _ctx.dialogEmptyOrderVisible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialogEmptyOrderVisible) = $event)),
      title: "Внимание",
      "main-text": [_ctx.SystemMessages.CHOOSE_DISH],
      "show-ok-cancel": false,
      onCloseDialog: _ctx.closeEmptyOrderDialog
    }, null, 8, ["modelValue", "main-text", "onCloseDialog"])
  ], 64))
}