import { permissions } from "@/modules/portal/models/enums/permissions";

const PermissionsName: Record<permissions, string> = {
  [permissions.CREATE_MENU]: 'Заполнить меню',
  [permissions.ORDER_LIST]: 'Список заказов',
  [permissions.PROCUREMENT]: 'Закупка продуктов',
  [permissions.ORDER_ISSUING]: 'Выдача заказов',
  [permissions.REPORT]: 'Сформировать отчет',
  [permissions.GUIDES]: 'Справочники',
  [permissions.PAYMENT]: 'Оплата',
  [permissions.SYSTEM_SETTINGS]: 'Настройки',
  [permissions.MENU]: 'Меню',
  [permissions.ORDER_HISTORY]: 'История заказов',
  [permissions.NOTIFICATIONS]: 'Уведомления',
};
export default PermissionsName