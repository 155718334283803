
import {computed, defineComponent, PropType, ref} from "vue"
import {useStore} from "vuex"
import moment from "moment"
import {CommentData, Dish, Ingredient, User} from "@/utils/data/interfaces"
import ArrowButton from "@/common/components/buttons/ArrowButton.vue"
import {FULLDATE_FORMAT} from "@/utils/data/moment-formats"
import {LikeIcons} from "@/modules/order/models/data/enums"
import {getUserSurnameAndInitials} from "@/utils/helpers/getUserSurnameAndInitials"
import {focusElement} from "@/utils/helpers/focusElement"

export default defineComponent({
  name: "DishDescription",
  components: {ArrowButton},
  props: {
    // Dish clicked to see description
    clickedDish: {
      type: Object as PropType<Dish>,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ["closeDescription"],
  setup(props, {emit}) {
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    const userId = store.getters.getUserId
    //Likes fetching
    const currentDishLikeAmount = computed(() => store.getters.getLikeAmount)
    const isCurrentDishLiked = computed(() => store.getters.isLiked)
    const likesClass = ref("")
    const likeIcon = computed(() => {
      if (isCurrentDishLiked.value) {
        likesClass.value = "unliked liked"
        return LikeIcons.LIKED
      } else {
        likesClass.value = "unliked"
        return LikeIcons.UNLIKED
      }
    })
    const likeChanged = () => {
      const dishId = props.clickedDish.dishId
      if (isCurrentDishLiked.value) {
        store.dispatch("unlikeDish", {userId, dishId})
      } else {
        store.dispatch("likeDish", {userId, dishId})
      }
    }
    //Ingredients fetching
    const ingredients = (dishIngredients: Array<Ingredient>): string => {
      const ingredients: Array<string> = dishIngredients.map((ingredient, index) =>
        index > 0 ? ingredient.name.toLocaleLowerCase() : ingredient.name)
      return ingredients.join(" / ")
    }
    //Comment fetching
    const commentIndex = ref(0)
    //-comment navigation
    const commentListLeft = () => commentIndex.value--
    const commentListRight = () => commentIndex.value++
    const currentDishComments = computed(() => store.getters.getComments.map((comment: CommentData) => (
      {
        ...comment,
        user: store.getters.getUsers.has(comment.userId) ? getUserSurnameAndInitials(store.getters.getUsers.get(comment.userId)) : {} as User
      }
    )))
    const isListLeftDisabled = computed(() => commentIndex.value === 0)
    const isListRightDisabled = computed(() => commentIndex.value === currentDishComments.value.length - 1 || currentDishComments.value.length === 0)
    //-displaying comment
    const currentComment = computed(() => {
      const currentComment = {user: "", date: "", text: ""}
      if (currentDishComments.value.length === 0) currentComment.text = "Комментарии отсутствуют"
      else {
        currentComment.user = `${currentDishComments.value[commentIndex.value].user}`
        currentComment.date = `${moment(currentDishComments.value[commentIndex.value].date).format(FULLDATE_FORMAT)} в ${moment(currentDishComments.value[commentIndex.value].date).format("HH:mm")}`
        currentComment.text = currentDishComments.value[commentIndex.value].message
      }
      return currentComment
    })
    //-this variable determines wether text aria is displaed: with comments or with adding comment
    const commentFieldVisible = ref(true)
    const showAddCommentField = () => {
      commentFieldVisible.value = false
      focusElement("comment")
    }
    //-clearing comment field after closing description
    const dialogClosed = () => {
      commentIndex.value = 0
      commentFieldVisible.value = true
      commentText.value = ""
    }
    //-saving entered comment
    const commentText = ref("");
    const saveComment = () => {

      const dishId = props.clickedDish.dishId
      const date = moment().toISOString()
      const addedComment: CommentData = {
        dishId: dishId,
        userId: userId,
        user: "Вы",
        date: date,
        message: commentText.value
      }
      cancel()
      store.commit("addComment", addedComment)
      store.dispatch("commentDish", {date, userId, dishId, addedComment})
    }
    const cancel = () => {
      commentText.value = ""
      commentFieldVisible.value = true
    }
    //Send comment button is desabled if there is no typed comment
    const disableSendButton = computed(() => {
      return commentText.value.length === 0
    })
    const closeDishDescription = () => {
      emit("closeDescription")
    }

    const fullSizeImage = computed(() => store.getters.getFullSizeImage === "" ? props.clickedDish.image : store.getters.getFullSizeImage)

    return {
      isNightSelect,
      currentDishLikeAmount,
      likesClass,
      likeIcon,
      likeChanged,
      ingredients,
      commentListLeft,
      commentListRight,
      isListLeftDisabled,
      isListRightDisabled,
      currentComment,
      commentFieldVisible,
      showAddCommentField,
      dialogClosed,
      commentText,
      saveComment,
      cancel,
      disableSendButton,
      closeDishDescription,
      fullSizeImage
    }
  },
})
