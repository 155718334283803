
import {computed, defineComponent, PropType} from "vue"
import {useStore} from "vuex";

export default defineComponent({
  name: "DialogWindow",
  props: {
    //The dialog's title
    title: {
      type: String,
      default: ""
    },
    //Show or hide close button
    showClose: {
      type: Boolean,
      default: true
    },
    //The main dialog's text
    mainText: {
      type: Array as PropType<Array<string>>,
      default: []
    },
    //Disables ok button
    disableOkButton: {
      type: Boolean,
      default: false
    },
    //Disables Cancel button
    disableCancelButton: {
      type: Boolean,
      default: false
    },
    //Show or hide footer buttons
    showOkCancel: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '500px'
    },
    okButtonText: {
      type: String,
      default: 'ОК'
    },
    cancelButtonText: {
      type: String,
      default: 'ОТМЕНА'
    }
  },
  emits: ["cancelClicked", "okClicked", "closeDialog"],
  setup(props, {emit}) {
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    const closeDialog = () => emit("closeDialog")
    const closeButtonClass = computed(() => props.showClose ? "" : "hide")
    const titleClass = computed(() => props.title === "" ? "hide" : "dialog-title")
    const dialogButtonClassCancel = computed(() => props.showOkCancel ? "" : "hide")
    const dialogButtonClassOk = computed(() => props.showOkCancel ? "" : "hide")
    const cancelClicked = () => {
      emit("cancelClicked")
    }
    const okClicked = () => {
      emit("okClicked")
    }
    return {
      isNightSelect,
      closeDialog,
      closeButtonClass,
      titleClass,
      dialogButtonClassCancel,
      dialogButtonClassOk,
      cancelClicked,
      okClicked
    }
  }
})
