
import {defineComponent} from "vue"
import {useStore} from "vuex"
import corporateLinksMap from "@/modules/portal/models/records/corporate-links-map"
import {corporateLinks} from "@/modules/portal/models/interfaces/corporate-links-interface"
import {computed} from "@vue/runtime-core";

export default defineComponent({
  name: "Footer",
  data() {
    return {}
  },
  setup() {
    const store = useStore()
    const valueLinksMap = Object.values(corporateLinksMap) as Array<corporateLinks>
    const menuShown = computed(()=>store.getters.isShowMenu)
    return {
      menuShown,
      valueLinksMap,
    }
  },
})
