import { SelectOption } from "@/modules/admin/purchasing-products/models/guideBooksSelectOptions"
import {
  PeriodType,
  ListStatus,
  ListFormed,
} from "@/modules/admin/purchasing-products/models/enums"
import {
  Manufacturer,
  PurchasingPlace,
} from "@/modules/admin/purchasing-products/models/guideBookInterfaces"
import { Ingredient } from "@/utils/data/interfaces"
import {
  TranslationListFormed,
  TranslationListStatus,
} from "@/modules/admin/purchasing-products/models/records"
import store from "@/store"
import { AdditionalRoles } from "../../AppSettings/models/rolesHelper"

export const periodTypeSelectOptions: Array<SelectOption> = Object.values(PeriodType).map(
  (value) => ({ label: value, value: value })
)

export const listStatusSelectOptions = (): Array<SelectOption> => {
  const listStatusSelectOptions: Array<SelectOption> = Object.entries(ListStatus).map((value) => ({
    label: TranslationListStatus[value[1] as ListStatus],
    value: value[1],
  }))
  if (store.getters.getUser.additionalRole !== AdditionalRoles.BUYER) return listStatusSelectOptions
  else return listStatusSelectOptions.filter((option) => option.value !== ListStatus.DRAFT)
}

export const listFormedSelectOptions: Array<SelectOption> = Object.entries(ListFormed).map(
  (value) => ({ label: TranslationListFormed[value[1] as ListFormed], value: value[1] })
)

//List row options
export const makeIngredientsSelectOptions = (
  ingredients: Map<number, Ingredient>
): Array<SelectOption> =>
  Array.from(ingredients.values()).map((ingredient) => ({
    label: ingredient.name,
    value: ingredient.id,
  }))

export const makeManufacturersSelectOptions = (
  manufacturers: Map<number, Manufacturer>
): Array<SelectOption> =>
  Array.from(manufacturers.values()).map((manufacturer) => ({
    label: manufacturer.name,
    value: manufacturer.id,
  }))

export const makeUnitsSelectOptions = (units: Map<number, Manufacturer>): Array<SelectOption> =>
  Array.from(units.values()).map((unit) => ({ label: unit.name, value: unit.id }))

export const makePurchasingPlaceSelectOptions = (
  purchasingPlaces: Map<number, PurchasingPlace>
): Array<SelectOption> =>
  Array.from(purchasingPlaces.values())
    .filter((place) => place.parentPlaceId)
    .map((place) => ({
      label: place.name,
      value: place.id,
    }))
