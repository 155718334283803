
import { defineComponent, PropType, ref, computed } from "vue"
import { Dish, Order, OrderedDish } from "@/utils/data/interfaces"
import { useStore } from "vuex"
import { FULLDATE_FORMAT } from "@/utils/data/moment-formats"
import { elMessage } from "@/modules/order/models/elMessage"
import { SystemMessages, TypeMessages } from "@/modules/order/models/data/enums"
import moment from "moment"
import { downloadDishesForPreview } from "@/utils/helpers/createPreviewDishes"

export default defineComponent({
  name: "SaveResetButtons",
  props: {
    //Disable Clear and Save buttons
    disabled: {
      type: Boolean,
      default: false,
    },
    //Date user formed order at and is going to save
    date: {
      type: String,
      required: true,
    },
    userOrders: {
      type: Map as PropType<Map<string, Order>>,
      required: true
    }
  },
  emits: [
    "saveOrder",
    "changeOrder"
  ],
  setup(props, { emit }) {
    const store = useStore()

    const isOrderChanged = computed(() => {
      const notEmptyDishes: Dish[] = store.getters.getDailyDishes(props.date).filter((dish: Dish) => dish.amount !== 0)
      const isAllDishesEmpty: boolean = !store.getters.getDailyDishes(props.date).find((dish: Dish) => dish.amount !== 0)
      const isOrderPresenst: boolean = props.userOrders.has(props.date)
      if (isOrderPresenst && isAllDishesEmpty) return true
      if (isOrderPresenst && props.userOrders.get(props.date)!.dishes.length !== notEmptyDishes.length) return false
      if (isOrderPresenst) {
        let counter = 0
        const tempDishMap = new Map<number, number>()
        notEmptyDishes.forEach((dish: Dish) => tempDishMap.set(dish.dishId, dish.amount as number))
        props.userOrders.get(props.date)!.dishes.forEach((dish: OrderedDish) => {
          if (tempDishMap.has(dish.dishId)) {
            if (tempDishMap.get(dish.dishId) !== dish.amount) counter++
          } else counter++
        })
        return !counter
      } else return isAllDishesEmpty
    })

    const resetDisabled = computed(() => !store.getters.getDailyDishes(props.date).find((dish: Dish) => dish.amount !== 0))

    const orderForDB = ref<Order>({
      userId: store.getters.getUserId,
      orderDate: "",
      dishes: [] as Array<OrderedDish>,
      isIssued: false
    })
    const dialogEmptyOrderVisible = ref(false)
    const closeEmptyOrderDialog = () => dialogEmptyOrderVisible.value = false
    const dialogSaveVisible = ref(false)
    const hideSaveDialog = () => {
      dialogSaveVisible.value = false
    }
    const dishTypes = ref([] as [string, Dish[]][])
    const saveButtonClicked = async () => {
      let orderPrice = 0
      orderForDB.value.dishes = []
      orderForDB.value.orderDate = moment(props.date, FULLDATE_FORMAT).hours(12).minutes(0).seconds(0).milliseconds(0).toISOString()
      store.getters.getDailyDishes(props.date).forEach((dish: Dish) => {
        if (dish.amount !== 0) {
          orderForDB.value.dishes.push({
            dishId: dish.dishId,
            dishName: dish.name,
            amount: dish.amount!,
            price: dish.price,
            isCanceled: false,
            isAdmin: false,
            issueAmount: 0,
            isIssued: false,
            isSpareOrder: false
          })
          orderPrice += dish.price * dish.amount!
        }
      })
      orderForDB.value.isInPlastic = props.userOrders.has(props.date) ? (props.userOrders.get(props.date) as Order).isInPlastic : false
      dishTypes.value = Array.from((await downloadDishesForPreview(orderForDB.value)).entries()).filter((dishType) => dishType[1].length !== 0)
      if (orderPrice === 0) {
        dialogEmptyOrderVisible.value = true
      } else dialogSaveVisible.value = true
    }
    const isInPlasticChanged = (isInPlastic: boolean) => orderForDB.value.isInPlastic = isInPlastic
    const savingConfirmed = () => {
      if (props.userOrders.has(props.date)) {
        emit("changeOrder", orderForDB.value)
      } else {
        emit("saveOrder", orderForDB.value)
      }
    }
    //Clear dialog
    const isFieldsCleared = ref(false)
    const dialogClearVisible = ref(false)
    const showClearDialog = () => {
      dialogClearVisible.value = true
    }
    const hideClearDialog = () => {
      dialogClearVisible.value = false
    }
    const clearOrder = () => {
      hideClearDialog()
      store.getters.getDailyDishes(props.date).forEach((dish: Dish) => {
        if (dish.date === props.date) dish.amount = 0
      })
      isFieldsCleared.value = true
    }

    const clearDialogClosed = () => {
      if (isFieldsCleared.value) elMessage(SystemMessages.FIELDS_CLEARED, TypeMessages.SUCCESS)
      isFieldsCleared.value = false
    }

    return {
      isOrderChanged,
      resetDisabled,
      dishTypes,
      orderForDB,
      dialogEmptyOrderVisible,
      closeEmptyOrderDialog,
      saveButtonClicked,
      isInPlasticChanged,
      savingConfirmed,
      dialogSaveVisible,
      hideSaveDialog,
      dialogClearVisible,
      showClearDialog,
      hideClearDialog,
      clearOrder,
      clearDialogClosed,
      SystemMessages
    }
  },
})
