
import { defineComponent, PropType, computed } from "vue"
import { Dish } from "@/utils/data/interfaces"
import { SideButtons } from "@/modules/order/models/data/enums"
import { elMessage } from "@/modules/order/models/elMessage"
import { SystemMessages, TypeMessages } from "@/modules/order/models/data/enums"
import store from "@/store"
import { ClickOnDishMethod } from "@/utils/data/types";

export default defineComponent({
  name: "DishesItems",
  props: {
    //Dishes in current dish type
    dishes: {
      type: Array as PropType<Array<Dish>>,
      required: true,
    },
    // Makes inputs disabled
    disabled: {
      type: Boolean,
      default: false,
    },
    // Determines whether to display maxAmount
    maxAmountDisplay: {
      type: String,
      default: "none"
    },
    // Determines whether to display dish counter
    isAmountSelectorVisible: {
      type: Boolean,
      default: true,
    },
    //Determines wether dish weight and price is displayed
    totalDisplaying: {
      type: Boolean,
      default: true,
    },
    //Uses in spare dishes for dishes identification 
    showIdentification: {
      type: Boolean,
      default: false,
    },
    // Determines whether to display dish amount
    isDishAmountVisible: {
      type: Boolean,
      default: false
    },
    typeClickOnDish: {
      type: String as PropType<ClickOnDishMethod>,
      default: 'view'
    },
  },
  setup() {
    const isMobile = computed(() => store.getters.getBreakpoints.mobile)

    const additionButton = (dish: Dish) => {
      if (dish.amount! < dish.maxAmount!) {
        dish.amount!++
        if (dish.amount === 20) {
          elMessage(SystemMessages.MAXIMUM_DISH_AMOUNT, TypeMessages.WARNING)
        }
      }
    }
    const subtractionButton = (dish: Dish) => {
      if (dish.amount! > 0) {
        dish.amount!--
      }
    }

    const maxAmount = (dish: Dish): string => `${dish.amount} / ${dish.maxAmount}`
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    return {
      isNightSelect,
      isMobile,
      SideButtons,
      additionButton,
      subtractionButton,
      maxAmount
    }
  },
}) 
