import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-560a4049"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search" }
const _hoisted_2 = { class: "user-name-wrapper" }
const _hoisted_3 = { class: "full-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_toggle_checkbox = _resolveComponent("toggle-checkbox")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_dialog_window = _resolveComponent("dialog-window")!

  return (_openBlock(), _createBlock(_component_dialog_window, {
    "show-close": true,
    cancelButtonText: "Напомнить всем",
    okButtonText: "Напомнить",
    title: "",
    width: _ctx.tableWidth,
    onCancelClicked: _ctx.remindAllUsers,
    onCloseDialog: _ctx.closeDialog,
    onOkClicked: _ctx.remindSelectUsers,
    disableOkButton: _ctx.disableRemindButton
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table, {
        data: _ctx.usersForRemind,
        class: _normalizeClass({ night: _ctx.isNightSelect })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            fixed: "",
            width: _ctx.searchColumnWidthValue
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.filterValueForNonPaidUsers,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterValueForNonPaidUsers) = $event)),
                  class: _normalizeClass({ night: _ctx.isNightSelect }),
                  placeholder: _ctx.placeholderValue,
                  "suffix-icon": "Search"
                }, null, 8, ["modelValue", "class", "placeholder"])
              ])
            ]),
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(scope.row.fullUserName), 1)
              ])
            ]),
            _: 1
          }, 8, ["width"]),
          _createVNode(_component_el_table_column, {
            align: "center",
            width: _ctx.toggleColumnWidthValue
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", {
                class: "buttons-horizontal-panel",
                onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createVNode(_component_toggle_checkbox, {
                  checked: scope.row.selectedForPayment,
                  type: "edit",
                  onClick: ($event: any) => (_ctx.addRemoveUserForRemind(scope.row))
                }, null, 8, ["checked", "onClick"])
              ])
            ]),
            _: 1
          }, 8, ["width"])
        ]),
        _: 1
      }, 8, ["data", "class"])
    ]),
    _: 1
  }, 8, ["width", "onCancelClicked", "onCloseDialog", "onOkClicked", "disableOkButton"]))
}