import {ImageInterface}from "@/utils/data/interfaces"
import encodeToBase64 from "@/modules/admin/dish-guidebook/composables/useFromImgToBase64";
import {UploadImageSettings} from "@/utils/data/constants";


export const useFromGetObjectImage = async (event: InputEvent): Promise<ImageInterface> => {
  const image = {} as ImageInterface
  image.imageInBase64 = await encodeToBase64((event.target as HTMLInputElement).files![0]);
  image.imageLink = URL.createObjectURL((event.target as HTMLInputElement).files![0]);
  if (image.imageLink) (event.target as HTMLInputElement).files![0].size > UploadImageSettings.maxSize ? image.imageHasSize = true : image.imageHasSize = false
  return image
}
export default useFromGetObjectImage