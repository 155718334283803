
import { computed, defineComponent, ref } from "vue"
import { useStore } from "vuex"
import { Delete, Edit } from "@element-plus/icons-vue"
//interfaces
import { Ingredient } from "@/utils/data/interfaces"

//composables
import useIngredientsTableFilters from "@/modules/admin/ingredients-guidebook/composables/useIngredientsTableFilters"

//utils
import { SaveFiltering } from "@/utils/helpers/table/saveTableFiltering"
import { SaveSorting } from "@/utils/helpers/table/saveTableSorting"

//child modules
import EditIngredient from "@/modules/admin/ingredients-guidebook/components/EditIngredient.vue"
import DialogDeleteIngredient from "@/modules/admin/ingredients-guidebook/components/DialogDeleteIngredient.vue"
import ArchiveIngredients from "@/modules/admin/ingredients-guidebook/components/ArchiveDeletedIngredient.vue"

export default defineComponent({
  name: "guideIngredients",
  components: {
    EditIngredient,
    DialogDeleteIngredient,
    ArchiveIngredients,
  },
  setup() {
    const { categoryFilters } = useIngredientsTableFilters()
    const store = useStore()
    store.dispatch("getIngredients")

    const typeOfEditing = ref<'post' | 'update'>('post')

    //переменные окрытия(закрытия) диалоговых окон
    const editIngredientDialogVisible = ref(false)
    const dialogDeleteIngredientVisible = ref(false)

    //Для автовыравнивания таблицы
    const tableLayout = ref("auto")

    const search = ref("")
    const handleSearchInput = (value: string) => {
      search.value = value
    }

    const currentFilters = ref<SaveFiltering.FilterMap>({})
    const currentSorting = ref<SaveSorting.CurrentSorting | null>(null)

    const handleFilterChange = (filters: SaveFiltering.FilterMap) => currentFilters.value = filters
    const handleSortChange = (sort: SaveSorting.CurrentSorting) => currentSorting.value = sort
    //Функция поиска по имени
    const computedTableData = computed(() => SaveSorting.applySavedSorting(
      currentSorting.value,
      store.getters.getIngredientsForGuide.filter(
        (ingredient: Ingredient) =>
          (!search.value || ingredient.name.toLowerCase().includes(search.value.toLowerCase())) &&
          (!currentFilters.value.category || SaveFiltering.isObjectFieldsSatisfyMapRequirments(ingredient, currentFilters.value))
      )
    )
    )

    const searchColumnWidth = computed(() => store.getters.getBreakpoints.mobile ? '167px' : '300px')

    const handleDeleteIngredient = (row: Ingredient) => {
      store.dispatch("setIngredientForEdit", row.id)
      dialogDeleteIngredientVisible.value = true
    }

    const handleUpdateIngredient = (row: Ingredient) => {
      editIngredientDialogVisible.value = true
      typeOfEditing.value = 'update'
      store.dispatch("setIngredientForEdit", row.id)

    }
    const handleCreateIngredient = () => {
      editIngredientDialogVisible.value = true
      typeOfEditing.value = 'post'
      store.dispatch("setIngredientForEdit")
    }

    const filterHandlerCategory = (value: string, row: Ingredient) => {
      return row.category === value
    }

    const closeDialog = () => {
      editIngredientDialogVisible.value = false
      dialogDeleteIngredientVisible.value = false
    }

    const formateCPFC = (row: Ingredient): string => `${row.calories}/${row.proteins}/${row.fats}/${row.carbs}`

    const isNightSelect = computed(() => store.getters.getIsSelectedNight)

    return {
      isNightSelect,
      handleCreateIngredient,
      closeDialog,
      computedTableData,
      filterHandlerCategory,
      Edit,
      Delete,
      handleDeleteIngredient,
      handleUpdateIngredient,
      search,
      handleSearchInput,
      tableLayout,
      categoryFilters,
      typeOfEditing,
      formateCPFC,
      searchColumnWidth,
      handleFilterChange,
      handleSortChange,
      editIngredientDialogVisible,
      dialogDeleteIngredientVisible,
    }
  },
})
