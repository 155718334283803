import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9e77749c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lunch-ordering-frame" }
const _hoisted_2 = {
  key: 0,
  class: "kitchen-dish-menu"
}
const _hoisted_3 = { class: "buttons-place" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_order_calendar = _resolveComponent("order-calendar")!
  const _component_spare_dishes = _resolveComponent("spare-dishes")!
  const _component_dish_card = _resolveComponent("dish-card")!
  const _component_save_reset_buttons = _resolveComponent("save-reset-buttons")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_order_calendar, {
      onDateButtonClick: _ctx.dateButtonClick,
      onButtonsGenerated: _ctx.calendarButtonsGenerated,
      onTomorrowDateCalculated: _ctx.tomorrowDateCalculated,
      "days-with-order": _ctx.daysWithOrder,
      "days-with-formed-dish-menu": _ctx.daysWithFormedDishMenu,
      "show-tooltip": true,
      orders: _ctx.userOrders
    }, null, 8, ["onDateButtonClick", "onButtonsGenerated", "onTomorrowDateCalculated", "days-with-order", "days-with-formed-dish-menu", "orders"]),
    _createVNode(_component_spare_dishes, {
      "is-unforming-time": _ctx.isUnformingTime,
      "is-order-time": _ctx.spareOrderTime,
      order: _ctx.currentOrder,
      "spare-dishes-date": _ctx.spareDishesDate,
      "unformed-order-dates": _ctx.unformedOrderDates,
      "calendar-buttons-amount": _ctx.calendarButtonsAmount,
      onUnformOrder: _ctx.unformOrder,
      onOrderIsMade: _ctx.saveSpareDishesOrder,
      onDeleteOrder: _ctx.deleteOrder,
      "disable-delete-button": _ctx.deleteOrderButtonDisabled
    }, null, 8, ["is-unforming-time", "is-order-time", "order", "spare-dishes-date", "unformed-order-dates", "calendar-buttons-amount", "onUnformOrder", "onOrderIsMade", "onDeleteOrder", "disable-delete-button"]),
    _createElementVNode("span", {
      class: _normalizeClass(["daily-title", { night: _ctx.isNightSelect }])
    }, _toDisplayString(_ctx.dailyTitle), 3),
    (_ctx.orderedDishesChecked)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isDishMenuAbsent)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: _normalizeClass(["no-menu", { night: _ctx.isNightSelect }])
              }, _toDisplayString(_ctx.SystemMessages.DISH_MENU_IS_ABSENT_TODAY), 3))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dishMenu, (dishMenuType, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(_ctx.dishCardClass(dishMenuType.dishTypes)),
              key: index
            }, [
              _createVNode(_component_dish_card, {
                "card-image": _ctx.menuTypeImages[index],
                "card-name": dishMenuType.menuType,
                disabled: _ctx.disableDishMenu,
                "dish-menu": dishMenuType.dishTypes
              }, null, 8, ["card-image", "card-name", "disabled", "dish-menu"])
            ], 2))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_save_reset_buttons, {
        disabled: _ctx.disableDishMenu,
        "user-orders": _ctx.userOrders,
        onSaveOrder: _ctx.saveOrder,
        onChangeOrder: _ctx.changeOrder,
        date: _ctx.activeDate
      }, null, 8, ["disabled", "user-orders", "onSaveOrder", "onChangeOrder", "date"])
    ])
  ]))
}