import { reactive, ref } from "vue"
import type { FormRules } from "element-plus"
import type { FormInstance } from "element-plus"

export const listRowformSize = ref("default")

export const listRowRuleFormRef = ref<FormInstance>()

export function useListRowFormRules() {
  const editingRowFormRules = reactive<FormRules>({
    name: [
      {
        required: true,
        message: "Пожалуйста, введите название",
        trigger: ["blur"],
      },
      {
        min: 3,
        max: 20,
        message: "Название должно содержать от 3х до 20ти символов",
        trigger: ["change"],
      },
    ],
  })
  return { editingRowFormRules }
}
