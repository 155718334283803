import ingredientsCategoryName from "@/modules/admin/models/records/IngredientsCategoryName";

interface ingredientsFilterInterface {
  text: string
  value: string
}

export default function useIngredientsTableFilters() {

  const categoryFilters = [...Object.values(ingredientsCategoryName).map(category => {
    return {text: category, value: category}
  })] as Array<ingredientsFilterInterface>

  return {
    categoryFilters
  }
}



