
import permissionsMenu from "@/modules/portal/models/records/permissions-menu-links";
import {MenuLink} from "@/modules/portal/models/interfaces/menu-links-interface";
import {Permission} from "@/utils/data/interfaces";


export default function verifyCurrentLinkByUserPermissions(link: string, permissionsFromBack: Array<Permission>):boolean {

  const userPermissions = permissionsFromBack.map((permissionsUser) => permissionsUser.name)

  const menu = Object.values(permissionsMenu) as Array<MenuLink>

  const itemMenuLink = menu.find((item: MenuLink) => link.includes(item.link))

  return userPermissions.includes(itemMenuLink?.permission ? itemMenuLink.permission : '')
}