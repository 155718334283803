import {Ingredient} from "@/utils/data/interfaces";

export const ConvertIngredientsForBack = (ingredients: Array<Ingredient>) => {
  return ingredients.map((ingredient: Ingredient) => {
    return {
      ingredientId: ingredient.id,
      amount: ingredient.amount
    }
  })

}
export default ConvertIngredientsForBack