
import { computed, defineComponent, ref, watch } from "vue"
import writeXlsxFile from 'write-excel-file'
import { useStore } from "vuex"
import moment from "moment"
import { OrderForOrderList } from "@/modules/admin/order-list/models/dishInterfaces"
import { User } from "@/utils/data/interfaces"
import { TableCell } from "@/modules/admin/report/models/tableInterfaces"
import { SystemMessages } from "@/modules/order/models/data/enums"
import { FULLDATE_FORMAT, FULLDATE_TIME_FORMAT } from "@/utils/data/moment-formats"
import { WeekConstants } from "@/utils/data/constants"


export default defineComponent({
  name: "Report",

  setup() {
    moment.locale("ru")
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    const user: User = store.getters.getUser
    const columns = [{ type: String, width: 40 }, { type: String, width: 40 }, { type: String, width: 12 }, { type: String }]

    enum Colors {
      WHITE = "#ffffff",
      GRAY = "#C8C8C8",
      ORANGE = "#FF9900",
      YELLOW = "#FFE01F"
    }

    const reportData = ref([] as Array<Array<TableCell>>)
    const dateFrom = ref(moment().toISOString())
    const dateTo = ref(moment().toISOString())

    //Period selector
    const periodFromChanged = () => {
      downloadOrderList(dateFrom.value, dateTo.value)
    }
    const periodToChanged = () => {
      downloadOrderList(dateFrom.value, dateTo.value)
    }

    //Disabled dates for date pickers
    const disableDatesFrom = (date: Date) => moment(date.toISOString()).day() === WeekConstants.SUNDAY || moment(date.toISOString()) > moment(dateTo.value)
    const disableDatesTo = (date: Date) =>
      moment(date.toISOString()) < moment(dateFrom.value) || moment(date.toISOString()).day() === WeekConstants.SUNDAY

    // Users downloading
    const downloadUsers = () => store.dispatch("downloadUsers")
    downloadUsers()

    const getFullUserName = (user: User) => {
      if (user) {
        const patronym = ` ${user.patronym}` ?? ""
        return `${user.surname} ${user.name}${patronym}`
      } else return ""
    }

    const periodChanged = (emittedDateFrom: string, emittedDateTo: string) => {
      dateFrom.value = emittedDateFrom
      dateTo.value = emittedDateTo
      downloadOrderList(dateFrom.value, dateTo.value)
    }

    const downloadOrderList = async (dateFrom: string, dateTo: string) => {
      await store.dispatch("downloadOrderList", {
        dateFrom: moment(dateFrom).hours(0).toISOString(),
        dateTo: moment(dateTo).hours(23).toISOString()
      })
    }
    downloadOrderList(dateFrom.value, dateTo.value)

    const noOrders = computed(() => !store.getters.getOrderListWithoutAdminDishes.length)

    const createTable = (dateFrom: string, dateTo: string) => {
      reportData.value = []
      addReportHeader(dateFrom, dateTo)
    }

    const addEmptyRow = () => reportData.value.push([{
      value: "", backgroundColor: Colors.WHITE
    }])

    const addReportHeader = (dateFrom: string, dateTo: string) => {
      reportData.value.push([{
        value: "ФИО:", backgroundColor: Colors.WHITE
      }, {
        value: getFullUserName(user), backgroundColor: Colors.WHITE
      }])
      reportData.value.push([{
        value: "Период", backgroundColor: Colors.WHITE
      }, {
        value: `${moment(dateFrom).format(FULLDATE_FORMAT)} - ${moment(dateTo).format(FULLDATE_FORMAT)}`,
        backgroundColor: Colors.WHITE
      }])
      reportData.value.push([{
        value: "Дата формирования", backgroundColor: Colors.WHITE
      }, {
        value: `${moment().format(FULLDATE_TIME_FORMAT)}`, backgroundColor: Colors.WHITE
      }])
      addEmptyRow()
    }

    const orders = ref([] as Array<OrderForOrderList>)
    watch(() => store.getters.getOrderListWithoutAdminDishes, () => {
      orders.value = store.getters.getOrderListWithoutAdminDishes
    })

    const getUserIdSet = (orders: Array<OrderForOrderList>): Set<number> => {
      const idSet = new Set<number>()
      orders.forEach((order) => idSet.add(order.userId))
      return idSet
    }

    const getOrderDates = (orders: Array<OrderForOrderList>): Set<string> => {
      const orderDates = new Set<string>()
      orders.forEach((order) => orderDates.add(moment(order.orderDate).format(FULLDATE_FORMAT)))
      return orderDates
    }

    const addOverall = (orders: Array<OrderForOrderList>) => {
      let monthOverall = 0
      const idSet = getUserIdSet(orders)
      const userMap = new Map<string, number>()
      idSet.forEach((id) => {
        let monthOrderPrice = 0
        let userFullName = ""
        orders.forEach((order) => {
          if (order.userId === id) {
            const user: User = store.getters.getUsers.get(id)
            userFullName = getFullUserName(user)
            order.dishes.forEach((dish) => monthOrderPrice += dish.amount * dish.price)
          }
        })
        userMap.set(userFullName, monthOrderPrice)
        monthOverall += monthOrderPrice
      })
      const userNames = Array.from(userMap.keys()).sort()
      let counter = 1
      userNames.forEach((name) => {
        const backgroundColor = counter % 2 === 0 ? Colors.WHITE : Colors.GRAY
        reportData.value.push([{
          value: counter, backgroundColor: backgroundColor
        }, {
          value: name, backgroundColor: backgroundColor
        },
        {
          value: userMap.get(name) as number, backgroundColor: backgroundColor
        }])
        counter++
      })
      reportData.value.push([{
        value: "Итого за период:", backgroundColor: Colors.ORANGE
      }, { value: "", backgroundColor: Colors.ORANGE }, {
        value: monthOverall, backgroundColor: Colors.ORANGE
      }])
      addEmptyRow()
    }
    const addDetailsHeader = () => reportData.value.push([{
      value: "День",
      backgroundColor: Colors.WHITE
    }, {
      value: "Название блюда",
      backgroundColor: Colors.WHITE
    }, {
      value: "Количество",
      backgroundColor: Colors.WHITE
    }, {
      value: "Цена",
      backgroundColor: Colors.WHITE
    }])

    const getOrderPrice = (order: OrderForOrderList): number =>
      order.dishes.reduce((orderPrice, dish) =>
        orderPrice += dish.amount * dish.price, 0
      )

    const addDetails = (orders: Array<OrderForOrderList>) => {
      const orderDates = getOrderDates(orders)
      addReportHeader(dateFrom.value, dateTo.value)
      addDetailsHeader()
      let detailsOverAll = 0

      orderDates.forEach((orderDate) => {
        reportData.value.push([{ value: orderDate, backgroundColor: Colors.WHITE }])
        const dailyOrders = new Map<string, OrderForOrderList>()
        orders.forEach((order) => {
          const user: User = store.getters.getUsers.get(order.userId)
          if (orderDate === moment(order.orderDate).format(FULLDATE_FORMAT)) {
            dailyOrders.set(getFullUserName(user), order)
          }
        })

        const dailyUserNames = Array.from(dailyOrders.keys()).sort()
        dailyUserNames.forEach((userName) => {
          const currentOrder = dailyOrders.get(userName) as OrderForOrderList
          reportData.value.push([{ value: userName, backgroundColor: Colors.YELLOW }, {
            value: "Итого за день:",
            backgroundColor: Colors.YELLOW
          }, { value: "", backgroundColor: Colors.YELLOW }, {
            value: getOrderPrice(currentOrder),
            backgroundColor: Colors.YELLOW
          }])

          currentOrder.dishes.forEach((dish) => {
            reportData.value.push([{ value: '', backgroundColor: Colors.GRAY }, {
              value: dish.dishName,
              backgroundColor: Colors.GRAY
            }, { value: dish.amount, backgroundColor: Colors.GRAY }, {
              value: dish.price,
              backgroundColor: Colors.GRAY
            }])

          })
          detailsOverAll += getOrderPrice(currentOrder)
        })
      })
      addEmptyRow()
      reportData.value.push([{ value: "Итого за период:", backgroundColor: Colors.ORANGE }, {
        value: "",
        backgroundColor: Colors.ORANGE
      }, { value: "", backgroundColor: Colors.ORANGE }, {
        value: detailsOverAll,
        backgroundColor: Colors.ORANGE
      }])
    }
    const generateReport = () => {
      createTable(dateFrom.value, dateTo.value)
      addOverall(orders.value)
      addDetails(orders.value)
      writeXlsxFile(reportData.value, {
        columns,
        fileName: `${moment(dateFrom.value).format("YYYY_MM")}_обеды.xlsx`
      })
    }
    return {
      isNightSelect,
      dateFrom,
      dateTo,
      periodFromChanged,
      periodToChanged,
      disableDatesFrom,
      disableDatesTo,
      periodChanged,
      noOrders,
      generateReport,
      SystemMessages,
      FULLDATE_FORMAT
    }


  },
})
