
import { computed, defineComponent, ref, watch } from "vue"
import { useRouter } from "vue-router"
import { useStore } from "vuex";

interface settingsVariants {
  label: string
  route: string
}

export default defineComponent({
  name: "Handbooks",

  setup() {
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    const router = useRouter()
    const settingsVariants = ref(router.getRoutes().filter((item) => item.path.includes("/AppSettings/")
    ).map((item) => ({ label: item.name as string, route: item.path })) as Array<settingsVariants>)
    const selectedSetting = ref()

    const identifySetting = () => {
      settingsVariants.value.forEach((variant: settingsVariants) => {
        if (router.currentRoute.value.path.includes(variant.route)) {
          selectedSetting.value = variant
        }
      })
    }
    identifySetting()

    watch(selectedSetting, () => {
      router.push(selectedSetting.value.route)

    })
    watch(() => router.currentRoute.value.path, () => {
      identifySetting()
    })

    return {
      settingsVariants,
      selectedSetting,
      isNightSelect,
    }
  },
})
