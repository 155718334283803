import {Module} from "vuex"
import {DayNightStatuses} from "@/modules/order/models/data/enums";

export const toggleSwitchDayNight: Module<any, any> = {
  state: {
    dayNightStatus: DayNightStatuses.DAY,
  },
  getters: {
    getDayNightStatus(state) {
      return state.dayNightStatus
    },
    getIsSelectedNight(state) {
      return state.dayNightStatus === DayNightStatuses.NIGHT
    },
    getIsSelectedDay(state) {
      return state.dayNightStatus === DayNightStatuses.DAY
    },

  },
  mutations: {
    changeDayNightStatus: (state, data) => {
      state.dayNightStatus = data
    },
  },
  actions: {
    switchDayNight({commit}) {
      commit("changeDayNightStatus", this.getters.getIsSelectedDay ? DayNightStatuses.NIGHT : DayNightStatuses.DAY)
    },
    initializeDayNightStatus({commit}, data) {
      commit("changeDayNightStatus", data)
    }
  },
}
export default toggleSwitchDayNight
