import { OrderedDish } from "@/utils/data/interfaces"

export const spaceValidator = (name: string): string => {
  name = name.replace(/\s+/g, " ")
  if (name.charAt(0) === " ") name = name.trim()
  return name
}

export const numberValidator = (value: string): string => {
  value = value.replace(/\s+/g, "")
  value = value.replace(/\.+/g, ".")
  if (value.charAt(0) === ".") value = value.slice(1)
  return value.replace(/[a-zA-Zа-яА-Я^%@!?&+*=,'"$(){}\][\-<>~`;:|\/№#]/g, "")
}
export const phoneFormatter = (value: string): string => {
  const regArr = value
    .replace(/\D/g, "")
    .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/) as RegExpMatchArray

  if (!regArr[2] && regArr[1] !== "") {
    value = regArr[1] === "8" ? regArr[1] : "8(" + regArr[1]
  } else {
    value = !regArr[3]
      ? regArr[1] + "(" + regArr[2]
      : regArr[1] + "(" + regArr[2] + ")" + regArr[3] + (regArr[4] ? "-" + regArr[4] : "")
  }
  return value
}

export const cardFormatter = (value: string): string => {
  const regArr = value
    .replace(/\D/g, "")
    .match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/) as RegExpMatchArray

  if (!regArr[2] && regArr[1] !== "") {
    value = regArr[1]
  } else {
    value = !regArr[3]
      ? regArr[1] + " " + regArr[2]
      : regArr[1] + " " + regArr[2] + " " + regArr[3] + (regArr[4] ? " " + regArr[4] : "")
  }
  return value
}

export const removeIsAdminDishes = (dishes: OrderedDish[]): OrderedDish[] =>
  dishes.filter((dish) => {
    if (!dish.isAdmin) return dish
  }) as Array<OrderedDish>
