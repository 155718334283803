
import { defineComponent, ref } from "vue";
import { QuestionMark } from "@/modules/order/models/data/enums";
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";

export default defineComponent({
  name: "QuestionMarkPopover",
  setup() {
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    const typeOfQuestionMark = computed(() => {
      if (isNightSelect.value) return isQuestionMarkActive.value ? QuestionMark.ACTIVATED_NIGHT : QuestionMark.DEACTIVATED_NIGHT
      return isQuestionMarkActive.value ? QuestionMark.ACTIVATED : QuestionMark.DEACTIVATED
    })

    const isQuestionMarkActive = ref<boolean>(false)
    const questionMarkActivate = () => {
      isQuestionMarkActive.value = true
    }
    const questionMarkDeactivate = () => {
      isQuestionMarkActive.value = false
    }
    return {
      isNightSelect,
      typeOfQuestionMark,
      isQuestionMarkActive,
      questionMarkActivate,
      questionMarkDeactivate,
    }
  }
})
