import {AdditionalRole, User} from "@/utils/data/interfaces"

export const prepareUserRoleFullList = (allUsers: Array<User>, idSelectedUser: number,  allRoles: Array<AdditionalRole>,): Array<AdditionalRole> => {
  const permissionsOfSelectedUser = (allUsers.find((user: User) => user.userId === idSelectedUser)?.additionalRole ?? '')

  return allRoles.map(role => {
    return {
      ...role,
      selected: permissionsOfSelectedUser.includes(role.name)
    }
  })
}
export default prepareUserRoleFullList