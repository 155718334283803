import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d75b5798"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "top" }
const _hoisted_3 = { class: "top-center" }
const _hoisted_4 = { class: "calendars" }
const _hoisted_5 = { class: "date-picker" }
const _hoisted_6 = { class: "radio-buttons" }
const _hoisted_7 = { class: "center" }
const _hoisted_8 = { class: "buttons-horizontal-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_order_calendar = _resolveComponent("order-calendar")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_menu_type_card = _resolveComponent("menu-type-card")!
  const _component_TableDishes = _resolveComponent("TableDishes")!
  const _component_order_view = _resolveComponent("order-view")!
  const _component_dialog_window = _resolveComponent("dialog-window")!
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_order_calendar, {
              "past-availability": true,
              "date-selector": true,
              "days-with-order": $setup.daysWithMenu,
              onDateButtonClick: $setup.getDailyDishMenu,
              onSelectorChanged: $setup.getDailyDishMenu,
              onLeftScroll: $setup.downloadNewWeek,
              onRightScroll: $setup.downloadNewWeek
            }, null, 8, ["days-with-order", "onDateButtonClick", "onSelectorChanged", "onLeftScroll", "onRightScroll"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_el_radio_group, {
            modelValue: $setup.activeMenuType,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.activeMenuType) = $event)),
            onChange: $setup.changeTypeMenu
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_radio, {
                label: $setup.DishMenuType.COMMON,
                class: _normalizeClass({ night: $setup.isNightSelect })
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.DishMenuTypeName[$setup.DishMenuType.COMMON]), 1)
                ]),
                _: 1
              }, 8, ["label", "class"]),
              _createVNode(_component_el_radio, {
                label: $setup.DishMenuType.DIET,
                class: _normalizeClass({ night: $setup.isNightSelect })
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.DishMenuTypeName[$setup.DishMenuType.DIET]), 1)
                ]),
                _: 1
              }, 8, ["label", "class"]),
              _createVNode(_component_el_radio, {
                label: $setup.DishMenuType.PREMIUM,
                class: _normalizeClass({ night: $setup.isNightSelect })
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.DishMenuTypeName[$setup.DishMenuType.PREMIUM]), 1)
                ]),
                _: 1
              }, 8, ["label", "class"])
            ]),
            _: 1
          }, 8, ["modelValue", "onChange"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_menu_type_card, {
        activeMenu: $setup.activeMenuType,
        pickedDate: $setup.pickedDate
      }, null, 8, ["activeMenu", "pickedDate"]),
      _createVNode(_component_TableDishes),
      _createVNode(_component_order_view, {
        modelValue: $setup.dailyMenuReviewVisible,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.dailyMenuReviewVisible) = $event)),
        "order-view-type": "menuReview",
        "show-total": false,
        "show-is-in-plastic": false,
        order: $setup.dailyMenuAsOrder,
        dishesByTypes: $setup.dishTypes,
        onSaveDailyMenu: $setup.createDishMenu,
        onCloseOrderDescription: $setup.hideMenuReviewDialog
      }, null, 8, ["modelValue", "order", "dishesByTypes", "onSaveDailyMenu", "onCloseOrderDescription"]),
      ($setup.clearDailyMenuReviewVisible)
        ? (_openBlock(), _createBlock(_component_dialog_window, {
            key: 0,
            modelValue: $setup.clearDailyMenuReviewVisible,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.clearDailyMenuReviewVisible) = $event)),
            "show-close": true,
            cancelButtonText: "Отменить",
            okButtonText: "Да",
            title: "Вы хотите удалить меню?",
            onCancelClicked: $setup.hideMenuReviewDialog,
            onCloseDialog: $setup.hideMenuReviewDialog,
            onOkClicked: $setup.createDishMenu
          }, null, 8, ["modelValue", "onCancelClicked", "onCloseDialog", "onOkClicked"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_custom_button, {
        "type-button": "success",
        disabled: $setup.disabledSendButton,
        onClick: $setup.sendMenu
      }, {
        default: _withCtx(() => [
          _createTextVNode("Отправить ")
        ]),
        _: 1
      }, 8, ["disabled", "onClick"]),
      _createVNode(_component_custom_button, {
        "type-button": "success",
        disabled: $setup.disabledSaveButton,
        onClick: $setup.showMenuReviewDialog
      }, {
        default: _withCtx(() => [
          _createTextVNode("Сохранить ")
        ]),
        _: 1
      }, 8, ["disabled", "onClick"]),
      _createVNode(_component_custom_button, {
        disabled: $setup.disabledClearButton,
        onClick: $setup.cleanDishMenu
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Очистить ")
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ])
  ]))
}