
import {defineComponent} from "vue"

export default defineComponent({
  name: "TimePicker",

  props: {
    //User can choose time after this our
    startHour: {
      type: Number,
      required: true
    },
    //User can choose time befor this our
    endHour: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const makeRange = (start: number, end: number) => [...Array(end - start)].map((item, index) => index + start) as number[]
    const disabledHours = () => makeRange(0, props.startHour).concat(makeRange(props.endHour + 1, 24))
    const disabledMinutes = (hour: number) => {
      if (hour === props.endHour) {
        return makeRange(1, 60)
      }
    }
    return {
      disabledHours,
      disabledMinutes
    }
  }
})
