import store from "@/store";
import router from "@/router";

export const handleFetchErrors = async (statusCode: number) => {
  if (statusCode === 401) {
    await store.dispatch("logoutFromBack")
    await router.replace("/login")
  }
  // TODO add indentify code smth like DISH_IN_USE and map for cyrylic text
  // if (statusCode === 405) {
  //     elMessage(SystemMessages.DISH_IN_USE, TypeMessages.ERROR)
  // } else {
  //     elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
  // }

}