import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-691c0970"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog-header" }
const _hoisted_2 = {
  key: 0,
  class: "is-in-plastic-wrapper"
}
const _hoisted_3 = { class: "price" }
const _hoisted_4 = { class: "buttons-horizontal-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_button = _resolveComponent("close-button")!
  const _component_dishes_items = _resolveComponent("dishes-items")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_toggle_checkbox = _resolveComponent("toggle-checkbox")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "show-close": false,
    top: "5vh",
    width: "550px",
    onOpen: _ctx.dialogOpen,
    class: _normalizeClass({ night: _ctx.isNightSelect })
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_close_button, { onClick: _ctx.closeOrderDescription }, null, 8, ["onClick"])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["dialog-title", { night: _ctx.isNightSelect }])
      }, _toDisplayString(_ctx.dialogTitle), 3),
      _createElementVNode("div", {
        class: _normalizeClass(["dialog-date", { night: _ctx.isNightSelect }])
      }, "на " + _toDisplayString(_ctx.moment(_ctx.order.orderDate).format(_ctx.FULLDATE_FORMAT)), 3)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_scrollbar, { "max-height": _ctx.scrollBarHeight }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dishesByTypes, (dishType, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "ordered-dishes"
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(["dish-type", { night: _ctx.isNightSelect }])
                }, _toDisplayString(dishType[0]), 3),
                _createVNode(_component_dishes_items, {
                  dishes: dishType[1],
                  "is-amount-selector-visible": false,
                  "is-dish-amount-visible": !_ctx.isMenuReviewType(),
                  "show-identification": _ctx.showIdentification
                }, null, 8, ["dishes", "is-dish-amount-visible", "show-identification"])
              ]))
            }), 128))
          ]),
          _: 1
        }, 8, ["max-height"]),
        (_ctx.showIsInPlastic)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", {
                class: _normalizeClass(["dish-type", { night: _ctx.isNightSelect }])
              }, "Пластиковая посуда", 2),
              _createVNode(_component_toggle_checkbox, {
                checked: _ctx.isInPlastic,
                "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isInPlastic) = $event)),
                disabled: _ctx.isHistoryType()
              }, null, 8, ["checked", "disabled"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.showTotal)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["dish-type total", { night: _ctx.isNightSelect }])
            }, [
              _createTextVNode(" ИТОГО: "),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.orderPrice) + " ₽", 1)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.isHistoryType())
          ? (_openBlock(), _createBlock(_component_custom_button, {
              key: 0,
              "type-button": "success",
              onClick: _ctx.closeOrderDescription
            }, {
              default: _withCtx(() => [
                _createTextVNode("ЗАКРЫТЬ ")
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.isOrderingType())
          ? (_openBlock(), _createBlock(_component_custom_button, {
              key: 1,
              "type-button": "success",
              disabled: _ctx.okButtonDisabled,
              onClick: _ctx.okButtonClicked
            }, {
              default: _withCtx(() => [
                _createTextVNode("ОК ")
              ]),
              _: 1
            }, 8, ["disabled", "onClick"]))
          : _createCommentVNode("", true),
        (_ctx.isOrderingType())
          ? (_openBlock(), _createBlock(_component_custom_button, {
              key: 2,
              onClick: _ctx.closeOrderDescription
            }, {
              default: _withCtx(() => [
                _createTextVNode("ОТМЕНА ")
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.isMenuReviewType())
          ? (_openBlock(), _createBlock(_component_custom_button, {
              key: 3,
              "type-button": "success",
              onClick: _ctx.saveDailyMenu
            }, {
              default: _withCtx(() => [
                _createTextVNode("СОХРАНИТЬ ")
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["onOpen", "class"]))
}