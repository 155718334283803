
import {computed, defineComponent} from "vue";
import {useStore} from "vuex";

export default defineComponent({
  name: "ToggleCheckbox",
  inheritAttrs: false,
  props: {
    label: { type: String, required: false },
    checked: { type: Boolean , default: false },
    disabled: { type: Boolean, required: false }
  },
  setup(){
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    return {
      isNightSelect
    }
  }
})
