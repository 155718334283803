
import { useStore } from "vuex"
import { computed, defineComponent, PropType, ref } from "vue"
import { GuideBookTableRow } from "@/modules/admin/purchasing-products/models/guideBookInterfaces"
import { formSize, ruleFormRef, useFormRules } from "@/modules/admin/purchasing-products/models/guideBooksFormRules"
import { compareObjects } from "@/utils/helpers/compareObjects"
import {
  ingredientsCategorySelectOptions,
  SelectOption
} from "@/modules/admin/purchasing-products/models/guideBooksSelectOptions"
import { PurchasingGuideBooks, SecondColumnNames } from "@/modules/admin/purchasing-products/models/enums"
import { isSavingAllowed } from "@/modules/admin/purchasing-products/models/helpers"
import IngredientsCategoryName from "@/modules/admin/models/records/IngredientsCategoryName"
import IngredientsCategory from "@/modules/admin/models/enums/IngredientsCategory"
import { spaceValidator } from "@/utils/helpers/validators"
import { focusElement } from "@/utils/helpers/focusElement"

export default defineComponent({
  name: "EditGuideBookRow",
  props: {
    guideBookRow: {
      type: Object as PropType<GuideBookTableRow>,
      required: true
    }
  },
  emits: ["closeDialog"],
  setup(props, { emit }) {
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    const title = computed<string>(() => props.guideBookRow.name.length === 0 ? "Добавление в справочник" : "Редактирование")
    const guideBookName = computed(() => props.guideBookRow.guideBookType)
    //Dialog handling
    const editDialogWidth = computed(() => store.getters.getBreakpoints.mobile ? "350px" : "600px")
    const dialogOpening = () => {
      editingRow.value = { ...props.guideBookRow }
      resetDialogValidation()
      focusElement("name")
    }
    const closeDialog = () => emit("closeDialog")
    const resetDialogValidation = () => ruleFormRef.value?.clearValidate()

    //Form handling
    const editingRow = ref({} as GuideBookTableRow)
    const parentPurchasingPlacesSelectOptions = computed<Array<SelectOption>>(() => {
      if (guideBookName.value === PurchasingGuideBooks.PURCHASING_PLACES) {
        const selectOptions: Array<SelectOption> = [...store.getters.getParentPlaces.values()]
          .filter((tableRow: GuideBookTableRow) => tableRow.id !== editingRow.value.id)
          .map((tableRow: GuideBookTableRow) => ({
            label: tableRow.name, value: tableRow.id
          }))
        selectOptions.unshift({ label: "Не выбрано", value: "" })
        return selectOptions
      }
      return []
    })

    //Inputs handling
    const validateName = () => editingRow.value.name = spaceValidator(editingRow.value.name)
    const validateShortName = () => editingRow.value.shortUnitName = spaceValidator(editingRow.value.shortUnitName)
    const manufacturerSelectChanged = () => {
      editingRow.value.column2Label = IngredientsCategoryName[editingRow.value.productCategory as IngredientsCategory]
      editingRow.value.column2Value = editingRow.value.productCategory ?? ""
    }
    const unitInputChanged = () => {
      editingRow.value.column2Value = editingRow.value.shortUnitName
      editingRow.value.column2Label = editingRow.value.shortUnitName
    }
    const purchasingPlacesSelectChanged = () => {
      if (store.getters.getParentPlaces.has(editingRow.value.parentPlace)) {
        editingRow.value.column2Value = store.getters.getParentPlaces.get(editingRow.value.parentPlace).id
        editingRow.value.column2Label = store.getters.getParentPlaces.get(editingRow.value.parentPlace).name
      }
      if (editingRow.value.parentPlace === "") {
        editingRow.value.column2Value = null
        editingRow.value.column2Label = ""
      }
    }

    //Buttons handling
    const isSaveButtonDisabled = computed(() => isResetButtonDisabled.value || !isSavingAllowed(editingRow.value))
    const isResetButtonDisabled = computed(() => compareObjects(props.guideBookRow, editingRow.value))
    const saveClicked = async () => {
      closeDialog()
      if (props.guideBookRow.name.length === 0) {
        await store.dispatch("saveGuideBookTableRow", editingRow.value)
        if (guideBookName.value === PurchasingGuideBooks.MANUFACTURERS) store.dispatch("downloadManufacturers")
        if (guideBookName.value === PurchasingGuideBooks.PURCHASING_PLACES) store.dispatch("downloadPurchasingPlaces")
        if (guideBookName.value === PurchasingGuideBooks.UNITS) store.dispatch("downloadUnits")
      } else store.dispatch("changeGuideBookTableRow", editingRow.value)
    }
    const resetForm = () => editingRow.value = { ...props.guideBookRow }

    return {
      isNightSelect,
      title,
      guideBookName,
      editDialogWidth,
      dialogOpening,
      closeDialog,
      resetDialogValidation,
      ...useFormRules(),
      formSize,
      ruleFormRef,
      editingRow,
      validateName,
      validateShortName,
      manufacturerSelectChanged,
      unitInputChanged,
      purchasingPlacesSelectChanged,
      isSaveButtonDisabled,
      isResetButtonDisabled,
      saveClicked,
      resetForm,
      ingredientsCategorySelectOptions,
      parentPurchasingPlacesSelectOptions,
      PurchasingGuideBooks,
      SecondColumnNames
    }
  }
})
