import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ba18cc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "buttons-horizontal-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_select, {
        class: _normalizeClass([{ night: _ctx.isNightSelect }, "m-2"]),
        modelValue: _ctx.selectedHandbook,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedHandbook) = $event)),
        "value-key": "label"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.handbooks, (handbook) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: handbook.label,
              label: handbook.label,
              value: handbook
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["class", "modelValue"]),
      _createVNode(_component_el_select, {
        class: _normalizeClass([{ night: _ctx.isNightSelect }, "m-2"]),
        modelValue: _ctx.selectedVariation,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedVariation) = $event)),
        "value-key": "label"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedHandbook.variants, (variant) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: variant.label,
              label: variant.label,
              value: variant
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["class", "modelValue"])
    ]),
    _createVNode(_component_router_view)
  ]))
}