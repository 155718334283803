import {removeEmptyFieldsFromObject} from "@/utils/helpers/compareObjects";


export const compareDish = (firstObject: Object, secondObject: Object, ignoreEmptyFields: boolean = false): boolean => {
  if (typeof firstObject !== 'object' || typeof secondObject !== 'object') {
    return false
  }
  // for saving initial object from mutating (deleting fields)
  const first:Object  = {...firstObject}
  const second:Object  = {...secondObject}

  if (ignoreEmptyFields) {
    removeEmptyFieldsFromObject(first)
    removeEmptyFieldsFromObject(second)
  }

  let firstProps = Object.getOwnPropertyNames(first)
  let secondProps = Object.getOwnPropertyNames(second)


  if (firstProps.length !== secondProps.length) {
    return false
  }
  for (let i = 0, len = firstProps.length; i < len; i++) {
    let propName = firstProps[i]

    if (JSON.stringify(first[propName as keyof Object]) !== JSON.stringify(second[propName as keyof Object])) {
      return false
    }
  }
  return true
}