export const DAYS_FORMAT = "days"

export const DAYS_MONTHS_FORMAT = "DD.MM"

export const UNIX_FORMAT = "x"

export const FULLDATE_FORMAT = "DD.MM.YYYY"

export const FULLDATE_TIME_FORMAT = "DD.MM.YYYY HH:mm"

export const YYYYMMDD_FORMAT = "YYYYMMDD"
