import { get, put, } from "@/utils/fetchQueries"
import { Receipt, ReceiptPicture } from "@/modules/admin/purchasing-products/models/listInterfaces"

export const downloadReceipts = (listId: number) => get(`/api/receipt/${listId}`)

export const downloadPicturesByReceiptId = (receiptId: number) => get(`/api/receipt/pictures?receiptId=${receiptId}`)

export const downloadPicturesByListId = (listId: number) => get(`/api/receipt/pictures?listId=${listId}`)

export const saveReceiptsAndPictures =
  (savingReceipts: Array<{ receipt: Receipt, receiptPictures: Array<ReceiptPicture> }>, listId: number) =>
    put(`/api/receipt/${listId}`, { receipts: savingReceipts })
