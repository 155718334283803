import IngredientsCategory from "@/modules/admin/models/enums/IngredientsCategory";

const IngredientsCategoryName: Record<IngredientsCategory, string> = {
  [IngredientsCategory.CEREAL]: "Крупы",
  [IngredientsCategory.SEAFOOD]: "Морепродукты",
  [IngredientsCategory.GROCERY]: "Бакалея",
  [IngredientsCategory.SPICE]: "Приправы",
  [IngredientsCategory.MILK]: "Молочные",
  [IngredientsCategory.CHEESE]: "Сыр",
  [IngredientsCategory.VEGETABLE]: "Овощи",
  [IngredientsCategory.FRUIT]: "Фрукты",
  [IngredientsCategory.MEAT]: "Мясо",
  [IngredientsCategory.FISH]: "Рыба",
  [IngredientsCategory.BROTHS]: "Бульоны",
  [IngredientsCategory.WATER]: "Вода",
  [IngredientsCategory.GREENS]: "Зелень",
  [IngredientsCategory.FREEZE]: "Заморозка",
};
export default IngredientsCategoryName;
