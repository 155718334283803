import { reactive, ref } from "vue"
import type { FormRules } from "element-plus"
import type { FormInstance } from "element-plus"

export const receiptformSize = ref("default")

export const receiptRuleFormRef = ref<FormInstance>()

export function useReceiptFormRules() {
  const receiptFormRules = reactive<FormRules>({
    date: [
      {
        required: true,
        message: "Пожалуйста, введите дату",
        trigger: ["blur"],
      },
    ],
    purchasingPlaceName: [
      { required: true, message: "Пожалуйста, введите место закупки", trigger: ["blur"] },
    ],
    price: [{ required: true, message: "Пожалуйста, введите сумму чека", trigger: ["blur"] }],
  })
  return { receiptFormRules }
}
