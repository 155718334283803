
import { defineComponent, ref, computed, onMounted } from "vue"
import { periodTypeSelectOptions, listStatusSelectOptions, listFormedSelectOptions } from "@/modules/admin/purchasing-products/models/listsSelectOptions"
import { FULLDATE_FORMAT } from "@/utils/data/moment-formats"
import moment from "moment"
import { FilterObject } from "@/modules/admin/purchasing-products/models/listInterfaces"
import { PeriodType, ListFormed, ListStatus } from "@/modules/admin/purchasing-products/models/enums"
import { useStore } from "vuex";

export default defineComponent({
    name: "Filters",
    emits: ["filterChanged"],

    setup(props, { emit }) {
        const store = useStore()
        const isNightSelect = computed(() => store.getters.getIsSelectedNight)
        //Filter object conteins all selected values in filters
        const filterObject = computed<FilterObject>(() => ({
            periodType: periodType.value,
            dateFrom: periodFrom.value,
            dateTo: periodTo.value,
            listStatus: listStatus.value,
            listFormed: listFormed.value
        } as FilterObject))

        //Period type select
        const periodType = !!localStorage.getItem("selectedPeriodType")
            ? ref(localStorage.getItem("selectedPeriodType"))
            : ref("")
        const periodTypeChanged = () => {
            localStorage.setItem("selectedPeriodType", periodType.value as string)
            if (periodType.value === PeriodType.NOT_SELECTED) {
                filterObject.value.dateFrom = ""
                filterObject.value.dateTo = ""
                periodFrom.value = ""
                periodTo.value = ""
                localStorage.setItem("periodFrom", "")
                localStorage.setItem("periodTo", "")
            }
            emit("filterChanged", filterObject.value)
        }

        //Date pickers
        const periodFrom = !!localStorage.getItem("periodFrom")
            ? ref(localStorage.getItem("periodFrom"))
            : ref("")

        const periodTo = !!localStorage.getItem("periodTo")
            ? ref(localStorage.getItem("periodTo"))
            : ref("")

        const disableDatesFrom = (date: Date) => moment(date.toISOString()).day() === 0 || moment(date.toISOString()) > moment(periodTo.value)
        const disableDatesTo = (date: Date) =>
            moment(date.toISOString()) < moment(periodFrom.value) || moment(date.toISOString()).day() === 0

        const renderKey = ref(0)
        const selectorShortcuts = [
            {
                text: 'Сегодня',
                value: () => {
                    periodFromChanged()
                    renderKey.value++
                    return moment().toISOString()
                },
            }]
        const datePickerDisabled = computed<boolean>(() => periodType.value === PeriodType.NOT_SELECTED || periodType.value === "")

        //-period changing
        const emitPeriodChanging = () =>
            emit("filterChanged", filterObject.value)

        const periodFromChanged = () => {
            filterObject.value.dateFrom = periodFrom.value ?? ""
            localStorage.setItem("periodFrom", periodFrom.value as string)
            emitPeriodChanging()
        }
        const periodToChanged = () => {
            localStorage.setItem("periodTo", periodTo.value as string)
            emitPeriodChanging()
        }

        //List status select
        const listStatus = !!localStorage.getItem("listStatus")
            ? ref(localStorage.getItem("listStatus"))
            : ref("")
        const listStatusChanged = () => {
            localStorage.setItem("listStatus", listStatus.value as string)
            emit("filterChanged", filterObject.value)
        }

        //List formed select
        const listFormed = !!localStorage.getItem("listFormed")
            ? ref(localStorage.getItem("listFormed"))
            : ref("")
        const listFormedChanged = () => {
            localStorage.setItem("listFormed", listFormed.value as string)
            emit("filterChanged", filterObject.value)
        }

        //Reset filters
        const resetClicked = () => {
            periodType.value = "",
                periodFrom.value = "",
                periodTo.value = "",
                listStatus.value = "",
                listFormed.value = ""
            localStorage.setItem("selectedPeriodType", "")
            localStorage.setItem("periodFrom", "")
            localStorage.setItem("periodTo", "")
            localStorage.setItem("listStatus", "")
            localStorage.setItem("listFormed", "")
            emit("filterChanged", filterObject.value)
        }

        //Sending initial values to parent component
        onMounted(() => emit("filterChanged", filterObject.value))

        return {
            isNightSelect,
            FULLDATE_FORMAT,
            periodTypeSelectOptions,
            listStatusSelectOptions,
            listFormedSelectOptions,
            periodType,
            periodTypeChanged,
            periodFrom,
            periodTo,
            disableDatesFrom,
            disableDatesTo,
            renderKey,
            selectorShortcuts,
            datePickerDisabled,
            periodFromChanged,
            periodToChanged,
            listStatus,
            listStatusChanged,
            listFormed,
            listFormedChanged,
            resetClicked
        }
    }
})
