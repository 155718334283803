import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3767fb0f"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ active: _ctx.menuShown }, "footer"])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valueLinksMap, (items) => {
      return (_openBlock(), _createElementBlock("div", {
        key: items.id,
        class: "company-links"
      }, [
        _createElementVNode("img", {
          src: items.linkIcon
        }, null, 8, _hoisted_1),
        _createElementVNode("a", {
          href: items.link,
          target: "_blank"
        }, _toDisplayString(items.title), 9, _hoisted_2)
      ]))
    }), 128))
  ], 2))
}