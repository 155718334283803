import {createApp} from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import components from "@/common/components"
import './registerServiceWorker'

import * as ElementPlusIconsVue from "@element-plus/icons-vue"
import ElementPlus from "element-plus"
import "element-plus/dist/index.css"
import ru from "element-plus/es/locale/lang/ru"
import 'dayjs/locale/ru'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus, {
  locale: ru,
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

components.forEach(component => {
  app.component(component.name, component)
})
app.mount("#app")
