import { post, deleteUrl, put, get } from "@/utils/fetchQueries"
import { Payment } from "@/utils/data/interfaces"

export const downloadPayment = () => get("/api/bank-details")

export const deletePayment = (paymentId: number) => deleteUrl(`/api/bank-details/${paymentId}`, {})

export const changePayment = (payment: Payment) => put(`/api/bank-details/${payment.id}`, payment)

export const savePayment = (payment: Payment) => post("/api/bank-details", payment)
