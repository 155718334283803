
import { computed, defineComponent, onMounted, ref, watch } from "vue"
import AddOrderDialog from "@/modules/admin/orders-issuing/components/AddOrderDialog.vue"
import IssueOrderDialog from "@/modules/admin/orders-issuing/components/IssueOrderDialog.vue"
import AddSpareDishesDialog from "@/modules/admin/orders-issuing/components/AddSpareDishesDialog.vue"
import { OrderForOrderList } from "@/modules/admin/order-list/models/dishInterfaces"
import { TableRow } from "@/modules/admin/orders-issuing/models/tableInterfaces"
import { useStore } from "vuex"
import moment from "moment"
import { Roles, SystemMessages } from "@/modules/order/models/data/enums"
import { getDishNames, makeOrderMap, makeOrderTable } from "@/modules/admin/orders-issuing/models/orderParser"
import { Dish, Order, User } from "@/utils/data/interfaces"
import { FULLDATE_FORMAT } from "@/utils/data/moment-formats"
import { dishSummaryMethod } from "@/modules/admin/orders-issuing/models/tableMethods"
import { Search } from '@element-plus/icons-vue'
import { IssueButtonImages } from "@/modules/admin/orders-issuing/models/data/enums"
import { AdditionalRoles } from "@/modules/admin/AppSettings/models/rolesHelper"

export default defineComponent({
  name: "OrdersIssuing",
  components: {
    AddOrderDialog,
    IssueOrderDialog,
    AddSpareDishesDialog
  },
  setup() {
    const store = useStore()
    //Blocking functions according additional role
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    const isFunctionsAllowed = computed(() =>
      store.getters.getUserAdditionalRole === AdditionalRoles.COOK || store.getters.getUserRole === Roles.ADMIN
    )

    const isReadyForShow = ref(false)
    const search = ref('')
    const handleSearchInput = (value: string) => {
      search.value = value
    }

    const searchColumnWidth = computed(() => store.getters.getBreakpoints.mobile ? '190px' : '300px')

    let currentDate = store.getters.getCurrentDate
    if (localStorage.getItem("lastClickedDate")) currentDate = moment(localStorage.getItem("lastClickedDate"))
    const activeDate = ref(currentDate.format(FULLDATE_FORMAT))
    const orderDate = computed(() => moment(activeDate.value, FULLDATE_FORMAT)
      .hours(12)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .toISOString())

    const defineDates = () => {
      currentDate = store.getters.getCurrentDate
      if (localStorage.getItem("lastClickedDate")) currentDate = moment(localStorage.getItem("lastClickedDate"))
      activeDate.value = currentDate.format(FULLDATE_FORMAT)
    }

    //Downloading users information
    const allUsers = ref(new Map<number, User>())
    const downloadUsers = async () => {
      await store.dispatch("downloadUsers")
      allUsers.value = store.getters.getUsers
    }
    downloadUsers()

    //Order list downloading
    const orders = ref(new Map<number, OrderForOrderList>())
    const downloadOrders = async (dateFrom: string, dateTo: string) => {
      orders.value.clear()
      await store.dispatch("downloadOrderList", { dateFrom, dateTo })
      //Making orderMap for fast order getting
      orders.value = makeOrderMap(store.getters.getOrderListWithoutAdminDishes)
    }

    //Menu downloading
    const daysWithFormedDishMenu = computed(() => store.getters.getDaysWithFormedDishMenu)
    const dishNames = ref(new Set<string>())
    const dishMap = ref(new Map<number, Dish>())
    const downloadDishMenu = async (dateFrom: string, dateTo: string) => {
      dishMap.value.clear()
      await store.dispatch("downloadDishMenu", { dateFrom, dateTo })
      dishNames.value = getDishNames(store.getters.getDishesForMenu)
      store.getters.getDishesForMenu.forEach((dish: Dish) => dishMap.value.set(dish.dishId, dish))
    }
    const checkSaturday = async (date: string) => {
      const dateFrom = moment(date).day(6).toISOString()
      const dateTo = moment(dateFrom).add(1, "day").toISOString()
      await store.dispatch("downloadDaysWithFormedDishMenu", { dateFrom, dateTo })

    }
    const isOrdersPresent = computed(() => store.getters.getOrderListWithoutAdminDishes.length)

    const orderTable = computed(() => makeOrderTable(orders.value, dishNames.value, allUsers.value)
      .filter(
        (data) =>
          !search.value ||
          data.userFullName.toLowerCase().includes(search.value.toLowerCase())
      )
    )

    const defineOrderTable = async () => {
      defineDates()
      const dateFrom = currentDate.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString()
      const dateTo = moment(dateFrom).add(1, "days").toISOString()
      await downloadOrders(dateFrom, dateTo)
      await downloadDishMenu(dateFrom, dateTo)
      isReadyForShow.value = true
    }
    defineOrderTable()

    watch(() => store.getters.getCurrentDate, () => defineOrderTable())

    const dateButtonClicked = (clickedDate: moment.Moment,
      tomorrowDate: moment.Moment) => {
      isReadyForShow.value = false
      nextDayWithMenu.value = tomorrowDate
      activeDate.value = clickedDate.format(FULLDATE_FORMAT)
      currentDate = clickedDate
      defineOrderTable()
    }

    const calendarScroll = (date: string) => checkSaturday(date)
    //Spare dishes adding is enabled only in current date
    const spareDishesAddingIsDisabled = computed(() => activeDate.value !== moment().format(FULLDATE_FORMAT))
    const nextDayWithMenu = ref(moment())
    const tomorrowDateCalculated = (date: moment.Moment) => nextDayWithMenu.value = date
    const isAddOrderDisabled = computed(() =>
      !(activeDate.value === moment().format(FULLDATE_FORMAT) || activeDate.value === nextDayWithMenu.value.format(FULLDATE_FORMAT)))
    //Adding order
    const addOrderVisible = ref(false)
    const showAddOrder = () => addOrderVisible.value = true
    const hideAddOrder = () => addOrderVisible.value = false

    //Issue order
    const issueButtonImage = (row: TableRow) => {
      const currentOrder = orders.value.get(row.userId) as OrderForOrderList
      if (currentOrder) return currentOrder.isIssued || row.isIssued ? IssueButtonImages.FULL : allUsers.value.get(row.userId)?.avatar ?? ''
    }
    //Issuing is allowed on current day.
    //Editing and deleting order is allowed on current day and the next day with formed dish menu(tomorrowDate)
    const issueButtonDisabled = (row: TableRow) =>
      !((activeDate.value === moment().format(FULLDATE_FORMAT)) || activeDate.value === nextDayWithMenu.value.format(FULLDATE_FORMAT))
      || row.isIssued
      || row.isCanceled

    const wrapperClass = (row: TableRow) => issueButtonDisabled(row) ? "wrapper-disabled" : "wrapper"
    const userNameClass = (row: TableRow) => issueButtonDisabled(row) ? "user-name-disabled" : "user-name"
    const issueOrderVisible = ref(false)
    const hideIssueOrder = () => issueOrderVisible.value = false

    const issuingOrder = ref({} as OrderForOrderList)
    const orderSelected = (row: TableRow) => {
      if (!issueButtonDisabled(row) && isFunctionsAllowed.value) {
        issuingOrder.value = orders.value.get(row.userId) as OrderForOrderList
        issuingOrder.value.userName = row.userFullName
        issueOrderVisible.value = true
      }
    }
    //Delete order
    const orderDeleted = (order: OrderForOrderList) => {
      orders.value.delete(order.userId)
      hideIssueOrder()
    }
    //Change order
    const orderChanged = (order: Order) => {
      const changingOrder = orders.value.get(order.userId) as OrderForOrderList
      changingOrder.dishes = order.dishes
      changingOrder.isInPlastic = order.isInPlastic

      hideIssueOrder()
    }
    //Add spare dishes
    const addSpareDishesVisible = ref(false)
    const showAddSpareDishes = () => addSpareDishesVisible.value = true
    const hideAddSpareDishes = () => addSpareDishesVisible.value = false

    onMounted(() => checkSaturday(moment(activeDate.value, FULLDATE_FORMAT).toISOString()))
    return {
      isNightSelect,
      handleSearchInput,
      isFunctionsAllowed,
      isReadyForShow,
      isOrdersPresent,
      activeDate,
      orderDate,
      daysWithFormedDishMenu,
      dishNames,
      orders,
      orderTable,
      allUsers,
      dishMap,
      issueButtonImage,
      search,
      dateButtonClicked,
      calendarScroll,
      spareDishesAddingIsDisabled,
      orderSelected,
      tomorrowDateCalculated,
      isAddOrderDisabled,
      addOrderVisible,
      hideAddOrder,
      showAddOrder,
      dishSummaryMethod,
      SystemMessages,
      issueButtonDisabled,
      wrapperClass,
      userNameClass,
      issueOrderVisible,
      hideIssueOrder,
      issuingOrder,
      orderDeleted,
      orderChanged,
      addSpareDishesVisible,
      showAddSpareDishes,
      hideAddSpareDishes,
      Search,
      searchColumnWidth,
      moment
    }

  },
})
