
import { computed, defineComponent, PropType, ref } from "vue"
import { ArrowImages } from "@/modules/order/models/data/enums"
import { ArrowButtonSize, ArrowType } from "@/utils/data/types"
import { useStore } from "vuex";

export default defineComponent({
  name: "ArrowButton",
  props: {
    //Direction of the arrow: "left"|"right"
    arrowType: {
      type: String as PropType<ArrowType>,
      required: true
    },
    //Arrow button size: "big"|"small"
    buttonSize: {
      type: String as PropType<ArrowButtonSize>,
      default: "big"
    },
    // Makes button disabled
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    const arrowWrapperClass = ref("")
    const arrowButtonClass = computed((): string => {
      return props.buttonSize === "big" ? "arrow-button" : "arrow-button small"
    })
    const arrowImage = computed(() => {
      if (props.arrowType === "left") {
        arrowWrapperClass.value = "arrow-wrapper left"
        if (isNightSelect.value) return props.disabled ? ArrowImages.LEFT_DISABLED_NIGHT : ArrowImages.LEFT_NIGHT
        return props.disabled ? ArrowImages.LEFT_DISABLED : ArrowImages.LEFT

      } else {
        arrowWrapperClass.value = "arrow-wrapper right"
        if (isNightSelect.value) return props.disabled ? ArrowImages.RIGHT_DISABLED_NIGHT : ArrowImages.RIGHT_NIGHT
        return props.disabled ? ArrowImages.RIGHT_DISABLED : ArrowImages.RIGHT
      }
    })

    return {
      isNightSelect,
      arrowButtonClass,
      arrowImage,
      arrowWrapperClass
    }
  }
})
