
import { useStore } from "vuex";
import { computed, defineComponent, ref } from "vue";
import router from "@/router";
import { PurchasingList, PurchasingListRow } from "@/modules/admin/purchasing-products/models/listInterfaces";
import { ElTable } from 'element-plus';
import { ListFormed, ListStatus, ListActionType, ConfirmationMessages } from "@/modules/admin/purchasing-products/models/enums";
import EditListRow from "@/modules/admin/purchasing-products/components/EditListRow.vue";
import ButtonGroup from "@/modules/admin/purchasing-products/components/ButtonGroup.vue";
import moment from "moment";
import CustomButton from "@/common/components/buttons/CustomButton.vue";
import { uploadListToXLS } from "@/modules/admin/purchasing-products/models/uploadList";
import { getUserSurnameAndInitials } from "@/utils/helpers/getUserSurnameAndInitials";

export default defineComponent({
    name: "PurchasingList",
    components: {
        ButtonGroup,
        EditListRow,
        CustomButton
    },
    setup() {
        const store = useStore();
        const isNightSelect = computed(() => store.getters.getIsSelectedNight);
        const backToListTable = () => {
            localStorage.removeItem('currentListRows');
            router.push("/PurchasingListsView");
        };
        const listActionType = localStorage.getItem("listActionType") as string;

        const isEditingAllowed = computed(() =>
            (editingList.value.status === ListStatus.NEW
                || editingList.value.status === ListStatus.DRAFT
                || editingList.value.status === ListStatus.ACTIVE)
            && listActionType !== ListActionType.VIEW);

        const editingList = computed<PurchasingList>(() => JSON.parse(localStorage.getItem("editingList") as string));

        //Data downloading
        const downloadListData = async () => {
            await store.dispatch("downloadManufacturers");
            await store.dispatch("downloadUnits");
            await store.dispatch("downloadPurchasingPlaces");
            await store.dispatch("getIngredients");
            if (listActionType !== ListActionType.ADD) await store.dispatch("downloadPurchasingListRows", editingList.value.id);
            else {
                store.commit("resetListRows");
                store.commit("clearList");
            }
        };
        downloadListData();

        //Table data forming
        const listRows = computed<Map<string, Map<number, PurchasingListRow>>>(() => store.getters.getPurchasingListRowsTableData(true));
        const getTableData = (category: string): Array<PurchasingListRow> => {
            if (listRows.value.has(category))
                return Array.from((listRows.value.get(category) as Map<number, PurchasingListRow>).values());

            else return [] as PurchasingListRow[];
        };
        //Table checkboxes handling
        const multipleTableRef = ref<InstanceType<typeof ElTable>>();
        const getRowKey = (row: PurchasingListRow): number => row.id ?? 0;
        const selectedRows = ref(new Map<number, PurchasingListRow>());
        const lastClickedTable = ref("");
        const tableClicked = (ingredientCategory: string) => lastClickedTable.value = ingredientCategory;
        const handleRowSelection = (selection: PurchasingListRow[], selectedRow: PurchasingListRow) => {
            if (selection.find((rowInSelection) => selectedRow.id === rowInSelection.id)) selectedRows.value.set(selectedRow.id!, selectedRow);
            else selectedRows.value.delete(selectedRow.id!);
        };
        const handleSelectAll = (selection: PurchasingListRow[]) => {
            if (selection.length === 0) {
                selectedRows.value.forEach((row) => { if (row.ingredientCategory === lastClickedTable.value) selectedRows.value.delete(row.id!); });
            } else {
                selection.forEach((row) => selectedRows.value.set(row.id!, row));
            }
        };

        //Editing dialog handling
        const editingDialogVisible = ref(false);
        const showEditingDialog = () => editingDialogVisible.value = true;
        const hideEditingDialog = () => {
            selectedRows.value.clear();
            editingDialogVisible.value = false;
        };

        //Add, edit, delete buttons
        const deleteButtonDisabled = computed(() => selectedRows.value.size === 0);

        const editingRow = ref<PurchasingListRow>({ isPurchased: false });
        const addListRow = () => {
            editingRow.value = { isPurchased: false };
            store.commit("nextRowLocalId");
            editingRow.value.id = store.getters.getRowLocalId;
            showEditingDialog();
        };

        const editListRow = (row: PurchasingListRow, column: { type: string; }) => {
            if (isEditingAllowed.value && column.type !== "selection") {
                editingRow.value = row;
                showEditingDialog();
            }
        };

        const deleteRow = () => {
            if (selectedRows.value.size !== 0)
                selectedRows.value.forEach((selectedRow: PurchasingListRow) => store.commit("removeListRow", selectedRow));
            selectedRows.value.clear();
        };
        //Save, send, reset buttons
        const saveButtonDisabled = computed(() => store.getters.getPurchasingListRowsTableData(true).size === 0);
        const sendButtonDisabled = computed(() =>
            saveButtonDisabled.value
            || editingList.value.status !== ListStatus.DRAFT);

        //List hadling
        const saveList = async () => {
            const savingRows: PurchasingListRow[] = [];
            store.getters.getPurchasingListRowsTableData(true).forEach((category: Map<number, PurchasingListRow>) =>
                category.forEach((purchasingListRow: PurchasingListRow) => savingRows.push({ ...purchasingListRow })));
            editingList.value.formationMethod = ListFormed.MANUAL;
            editingList.value.authorId = store.getters.getUserId;
            if (!store.getters.getUserById(store.getters.getUserId))
                await store.dispatch("downloadUsersById", [store.getters.getUserId]);
            editingList.value.authorName = getUserSurnameAndInitials(store.getters.getUser);
            editingList.value.createdAt = moment().toISOString();
            if (store.getters.getListActionType === ListActionType.ADD || store.getters.getListActionType === ListActionType.COPY) {
                await store.dispatch("savePurchasingList", { list: editingList.value, listRows: savingRows });
            } else await store.dispatch("changePurchasingList", { list: editingList.value, listRows: savingRows });
            backToListTable();
        };
        const resetList = () => {
            localStorage.removeItem('currentListRows');
            store.commit("setInitialRows");
            selectedRows.value.clear();
        };
        const sendList = () => {
            editingList.value.status = ListStatus.NEW;
            saveList();
            store.dispatch("sendListToExecutor");
        };
        const uploadList = () => uploadListToXLS(editingList.value, listRows.value);

        //Confirmation dialog
        const confirmationDialogVisible = ref(false);
        const confirmationDialogMainText = ref([""]);
        const actionType = ref(ListActionType.SAVE);
        const showConfirmationDialog = (action: ListActionType) => {
            confirmationDialogVisible.value = true;
            switch (action) {
                case ListActionType.SAVE:
                    actionType.value = ListActionType.SAVE;
                    confirmationDialogMainText.value = [ConfirmationMessages.SAVE_LIST];
                    return;

                case ListActionType.DELETE:
                    actionType.value = ListActionType.DELETE;
                    confirmationDialogMainText.value = [ConfirmationMessages.DELETE_ROWS];
                    return;

                case ListActionType.SEND:
                    actionType.value = ListActionType.SEND;
                    confirmationDialogMainText.value = [ConfirmationMessages.SEND];
                    return;

                case ListActionType.RESET:
                    actionType.value = ListActionType.RESET;
                    confirmationDialogMainText.value = [ConfirmationMessages.RESET];
                    return;
            }
        };
        const hideConfirmationDialog = () => confirmationDialogVisible.value = false;
        const okClicked = () => {
            hideConfirmationDialog();
            switch (actionType.value) {
                case ListActionType.SAVE:
                    saveList();
                    return;

                case ListActionType.DELETE:
                    deleteRow();
                    return;

                case ListActionType.SEND:
                    sendList();
                    return;

                case ListActionType.RESET:
                    resetList();
                    return;
            }
        };

        return {
            isNightSelect,
            backToListTable,
            isEditingAllowed,
            editingList,
            listRows,
            getTableData,
            multipleTableRef,
            getRowKey,
            tableClicked,
            handleRowSelection,
            handleSelectAll,
            editingDialogVisible,
            showEditingDialog,
            hideEditingDialog,
            deleteButtonDisabled,
            editingRow,
            addListRow,
            editListRow,
            deleteRow,
            saveButtonDisabled,
            sendButtonDisabled,
            saveList,
            resetList,
            sendList,
            uploadList,
            confirmationDialogVisible,
            confirmationDialogMainText,
            actionType,
            showConfirmationDialog,
            hideConfirmationDialog,
            okClicked,
            ListActionType
        };
    }
});
