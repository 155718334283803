
import {computed, defineComponent, PropType} from "vue"
import MenuItem from "@/modules/order/components/lunch-ordering/MenuItem.vue"
import {DishType} from "@/modules/order/models/dishInterfaces"
import {useStore} from "vuex";

export default defineComponent({
  name: "DishCard",
  components: {
    MenuItem,
  },
  props: {
    //Dish menu for the selected day
    dishMenu: {
      type: Array as PropType<Array<DishType>>,
      required: true,
    },
    // The name of the dish menu type(common, diet, premium)
    cardName: {
      type: String,
      default: "DishMenu card",
    },
    // Disables dish menu items
    disabled: {
      type: Boolean,
      default: false,
    },
    //Menu type icon
    cardImage: {
      type: String,
      required: true,
    }
  },
  setup() {
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    return {
      isNightSelect
    }
  }
})
