import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-93898bc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "description-header" }
const _hoisted_2 = { class: "like-icon-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "likeAmount" }
const _hoisted_5 = { class: "close-button-wrapper" }
const _hoisted_6 = { class: "dish-image-item" }
const _hoisted_7 = { class: "img-wrapper" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "dish-description" }
const _hoisted_10 = { class: "pfc" }
const _hoisted_11 = { class: "content" }
const _hoisted_12 = { class: "content" }
const _hoisted_13 = { class: "content" }
const _hoisted_14 = { class: "content" }
const _hoisted_15 = { class: "total" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { class: "comment-header" }
const _hoisted_18 = { class: "comment-title" }
const _hoisted_19 = {
  key: 0,
  class: "comment-title-separator"
}
const _hoisted_20 = { class: "comment-date" }
const _hoisted_21 = { class: "navi-buttons" }
const _hoisted_22 = { class: "comment-text" }
const _hoisted_23 = { class: "buttons-horizontal-panel" }
const _hoisted_24 = { key: 1 }
const _hoisted_25 = { class: "buttons-horizontal-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_button = _resolveComponent("close-button")!
  const _component_emoji_view = _resolveComponent("emoji-view")!
  const _component_arrow_button = _resolveComponent("arrow-button")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "show-close": false,
    width: "600px",
    top: "2vh",
    onClosed: _ctx.dialogClosed,
    "append-to-body": true,
    class: _normalizeClass({ night: _ctx.isNightSelect })
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.readonly)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.likesClass),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.likeChanged && _ctx.likeChanged(...args)))
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("img", { src: _ctx.likeIcon }, null, 8, _hoisted_3)
              ]),
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.currentDishLikeAmount), 1)
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_close_button, { onClick: _ctx.closeDishDescription }, null, 8, ["onClick"])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("img", {
            src: _ctx.fullSizeImage,
            alt: "Dish picture"
          }, null, 8, _hoisted_8)
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", {
          class: _normalizeClass(["dish-title", { night: _ctx.isNightSelect }])
        }, _toDisplayString(_ctx.clickedDish.name), 3),
        _createVNode(_component_emoji_view, {
          emojis: _ctx.clickedDish.emojis
        }, null, 8, ["emojis"]),
        _createElementVNode("div", {
          class: _normalizeClass(["ingredients", { night: _ctx.isNightSelect }])
        }, [
          (!!_ctx.clickedDish.description)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: _normalizeClass(["content", { night: _ctx.isNightSelect }])
              }, _toDisplayString(_ctx.clickedDish.description), 3))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["ingredients", { night: _ctx.isNightSelect }])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(["title", { night: _ctx.isNightSelect }])
          }, " Состав продукта:", 2),
          _createElementVNode("p", {
            class: _normalizeClass(["content", { night: _ctx.isNightSelect }])
          }, _toDisplayString(_ctx.ingredients(_ctx.clickedDish.ingredients)), 3)
        ], 2),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("p", {
            class: _normalizeClass(["title", { night: _ctx.isNightSelect }])
          }, [
            _createTextVNode(" К:"),
            _createElementVNode("span", _hoisted_11, _toDisplayString(` ${_ctx.clickedDish.calories}ккал / `), 1),
            _createTextVNode(" Б:"),
            _createElementVNode("span", _hoisted_12, _toDisplayString(` ${_ctx.clickedDish.proteins} / `), 1),
            _createTextVNode(" Ж:"),
            _createElementVNode("span", _hoisted_13, _toDisplayString(` ${_ctx.clickedDish.fats} / `), 1),
            _createTextVNode(" У:"),
            _createElementVNode("span", _hoisted_14, _toDisplayString(` ${_ctx.clickedDish.carbs}`), 1)
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("span", {
            class: _normalizeClass(["content", { night: _ctx.isNightSelect }])
          }, _toDisplayString(`${_ctx.clickedDish.weight}гр. / `), 3),
          _createElementVNode("span", {
            class: _normalizeClass(["title", { night: _ctx.isNightSelect }])
          }, _toDisplayString(`${_ctx.clickedDish.price} ₽`), 3)
        ]),
        (_ctx.commentFieldVisible && !_ctx.readonly)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createElementVNode("div", {
                class: _normalizeClass(["comments", { night: _ctx.isNightSelect }])
              }, [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("span", {
                      class: _normalizeClass(["user-name", { night: _ctx.isNightSelect }])
                    }, _toDisplayString(_ctx.currentComment.user), 3),
                    (_ctx.currentComment.user)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_19, "|"))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.currentComment.date), 1)
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_arrow_button, {
                      "arrow-type": "left",
                      "button-size": "small",
                      onClick: _ctx.commentListLeft,
                      disabled: _ctx.isListLeftDisabled
                    }, null, 8, ["onClick", "disabled"]),
                    _createVNode(_component_arrow_button, {
                      "arrow-type": "right",
                      "button-size": "small",
                      onClick: _ctx.commentListRight,
                      disabled: _ctx.isListRightDisabled
                    }, null, 8, ["onClick", "disabled"])
                  ])
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_el_scrollbar, { height: "90px" }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.currentComment.text), 1)
                    ]),
                    _: 1
                  })
                ])
              ], 2),
              _createElementVNode("div", _hoisted_23, [
                _createVNode(_component_custom_button, {
                  "type-button": "success",
                  onClick: _ctx.showAddCommentField
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("НАПИСАТЬ ОТЗЫВ ")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]))
          : (!_ctx.readonly)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.commentText) = $event)),
                  id: "comment",
                  class: _normalizeClass(["comments comment-text add-comment", { night: _ctx.isNightSelect }]),
                  name: "userComment",
                  cols: "30",
                  rows: "10",
                  maxlength: "1024",
                  placeholder: "Введите текст отзыва"
                }, null, 2), [
                  [_vModelText, _ctx.commentText]
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _createVNode(_component_custom_button, {
                    "type-button": "success",
                    disabled: _ctx.disableSendButton,
                    onClick: _ctx.saveComment
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" СОХРАНИТЬ ОТЗЫВ ")
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"]),
                  _createVNode(_component_custom_button, {
                    "type-button": "reset",
                    onClick: _ctx.cancel
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" ОТМЕНА ")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]))
            : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["onClosed", "class"]))
}