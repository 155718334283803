import { Module } from "vuex"
import { elMessage } from "@/modules/order/models/elMessage"
import { Notifications } from "@/modules/order/models/dishInterfaces"
import { SystemMessages, TypeMessages } from "@/modules/order/models/data/enums"
import {
  setNotifications as setNotificationsService,
  getNotifications,
} from "@/modules/order/models/fetchModules/fetchModuleNotifications"

const getInitNotifications = (): Notifications => ({
  menuMail: false,
  menuPush: false,
  spareDishMail: false,
  spareDishPush: false,
  orderMail: false,
  orderPush: false,
  canceledOrderMail: false,
  canceledOrderPush: false,
  orderMorningReminder: "11:00",
  orderMiddayReminder: "15:00",
  canceledOrderReminder: "11:00",
})

export const notificationsModule: Module<any, any> = {
  state: {
    settings: getInitNotifications(),
    unsavedSettings: {} as Notifications,
    settingsDownloaded: false,
  },

  getters: {
    getSettings(state) {
      return state.settings
    },
    getUnsavedSettings(state) {
      return state.unsavedSettings
    },
    getUnsavedPushSettings(state) {
      return [
        state.unsavedSettings.menuPush,
        state.unsavedSettings.spareDishPush,
        state.unsavedSettings.orderPush,
        state.unsavedSettings.canceledOrderPush,
      ]
    },
    getUnsavedMailSettings(state) {
      return [
        state.unsavedSettings.menuMail,
        state.unsavedSettings.spareDishMail,
        state.unsavedSettings.orderMail,
        state.unsavedSettings.canceledOrderMail,
      ]
    },
    getPushSettings(state) {
      return [
        state.settings.menuPush,
        state.settings.spareDishPush,
        state.settings.orderPush,
        state.settings.canceledOrderPush,
      ]
    },
    getMailSettings(state) {
      return [
        state.settings.menuMail,
        state.settings.spareDishMail,
        state.settings.orderMail,
        state.settings.canceledOrderMail,
      ]
    },
    isSettingsDownloaded(state) {
      return state.settingsDownloaded
    },
  },

  mutations: {
    setSettings: async (state, data) => {
      state.settings = data
      state.settingsDownloaded = true
    },
    setUnsavedSettings(state, data) {
      state.unsavedSettings = { ...data } as Notifications
    },
  },

  actions: {
    setNotifications: async ({ commit }, { userId, settings }) => {
      const res = await setNotificationsService(userId, settings)

      if (res.ok) {
        const data = await res.json()
        if (data.data) {
          commit("setSettings", data.data)
          commit("setUnsavedSettings", data.data)
        }
        elMessage(SystemMessages.SETTINGS_SENT, TypeMessages.SUCCESS)
      } else {
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.WARNING)
      }
    },

    getNotifications: async ({ commit }, userId) => {
      const res = await getNotifications(userId)

      if (res.ok) {
        const data = await res.json()
        commit("setSettings", data.data)
        commit("setUnsavedSettings", data.data)
      } else {
        elMessage(SystemMessages.SETTINGS_FAIL, TypeMessages.WARNING)
      }
    },

    setInitNotifications: ({ commit }) => {
      commit("setSettings", getInitNotifications())
    },
  },
}
export default notificationsModule
