import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-568093ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "calendar" }
const _hoisted_2 = { class: "date-button-group" }
const _hoisted_3 = { class: "dish-name" }
const _hoisted_4 = { class: "dish-amount" }
const _hoisted_5 = { class: "ordered" }
const _hoisted_6 = {
  key: 1,
  class: "period-selector"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_button = _resolveComponent("arrow-button")!
  const _component_calendar_button = _resolveComponent("calendar-button")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_arrow_button, {
        onClick: _ctx.leftCalendarButtonClick,
        disabled: _ctx.disableLeftCalendarButton,
        "arrow-type": "left"
      }, null, 8, ["onClick", "disabled"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getDays, (day, index) => {
        return (_openBlock(), _createBlock(_component_el_popover, {
          placement: _ctx.tooltipPosition(index),
          trigger: "hover",
          disabled: _ctx.tooltipDisabled(day.isoDate),
          "auto-close": 10000,
          "hide-after": 0,
          key: index
        }, {
          reference: _withCtx(() => [
            _createVNode(_component_calendar_button, {
              date: day.date,
              "week-day": day.weekDay,
              onClick: ($event: any) => (_ctx.dateButtonClick(day.isoDate)),
              disabled: _ctx.isButtonWithMenu(day.isoDate),
              display: _ctx.isButtonDisplayed(day.isoDate),
              "is-with-order": _ctx.isButtonWithOrder(day.isoDate),
              "is-clicked": _ctx.isButtonClicked(day.isoDate)
            }, null, 8, ["date", "week-day", "onClick", "disabled", "display", "is-with-order", "is-clicked"])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["container", { night: _ctx.isNightSelect }])
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(["ordered", { night: _ctx.isNightSelect }])
              }, "Заказано:", 2),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tooltipContent(day.isoDate), (textRow, index) => {
                return (_openBlock(), _createElementBlock("div", null, [
                  (_openBlock(), _createElementBlock("div", {
                    class: "tooltip-row",
                    key: index
                  }, [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(textRow.name), 1),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(` /${textRow.amount} шт`), 1)
                  ]))
                ]))
              }), 256)),
              _createElementVNode("span", _hoisted_5, _toDisplayString(`Всего: ${_ctx.orderPrice(day.isoDate)} ₽`), 1)
            ], 2)
          ]),
          _: 2
        }, 1032, ["placement", "disabled"]))
      }), 128)),
      _createVNode(_component_arrow_button, {
        "arrow-type": "right",
        onClick: _ctx.rightCalendarButtonClick,
        disabled: _ctx.disableRightCalendarButton
      }, null, 8, ["onClick", "disabled"])
    ]),
    (_ctx.dateSelector)
      ? (_openBlock(), _createBlock(_component_el_date_picker, {
          key: _ctx.renderKey,
          type: "date",
          name: "date-selector",
          class: _normalizeClass(["date-selector", { night: _ctx.isNightSelect }]),
          "popper-class": "date-selector-with-shortcuts",
          modelValue: _ctx.chosenDate,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.chosenDate) = $event)),
          clearable: false,
          editable: false,
          "disabled-date": _ctx.disableDatesFrom,
          shortcuts: _ctx.selectorShortcuts,
          format: _ctx.FULLDATE_FORMAT,
          "value-format": "YYYY-MM-DDTHH:mm:ss",
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dateSelectorChanged(_ctx.chosenDate)))
        }, null, 8, ["class", "modelValue", "disabled-date", "shortcuts", "format"]))
      : _createCommentVNode("", true),
    (_ctx.periodSelector)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_openBlock(), _createBlock(_component_el_date_picker, {
            key: _ctx.renderKey,
            type: "date",
            name: "date-from",
            class: _normalizeClass(["date-selector", { night: _ctx.isNightSelect }]),
            "popper-class": "date-selector-with-shortcuts",
            modelValue: _ctx.periodFrom,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.periodFrom) = $event)),
            clearable: false,
            editable: false,
            "disabled-date": _ctx.disableDatesFrom,
            shortcuts: _ctx.selectorShortcuts,
            format: _ctx.FULLDATE_FORMAT,
            "value-format": "YYYY-MM-DDTHH:mm:ss",
            onChange: _ctx.periodFromChanged
          }, null, 8, ["class", "modelValue", "disabled-date", "shortcuts", "format", "onChange"])),
          _createVNode(_component_el_date_picker, {
            type: "date",
            name: "date-to",
            class: _normalizeClass(["date-selector", { night: _ctx.isNightSelect }]),
            modelValue: _ctx.periodTo,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.periodTo) = $event)),
            clearable: false,
            editable: false,
            "disabled-date": _ctx.disableDatesTo,
            format: _ctx.FULLDATE_FORMAT,
            "value-format": "YYYY-MM-DDTHH:mm:ss",
            onChange: _ctx.periodToChanged
          }, null, 8, ["class", "modelValue", "disabled-date", "format", "onChange"])
        ]))
      : _createCommentVNode("", true)
  ]))
}