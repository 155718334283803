import { post, get, deleteBody } from "@/utils/fetchQueries"

export const putLike = (userId: number, dishId: number) =>
  post("/api/like", { userId, dishId })

export const deleteLike = (userId: number, dishId: number) =>
  deleteBody("/api/like", { userId, dishId })

export const downloadLikes = (
  userId: number,
  dishId: number,
) => get(`/api/like/${userId}`, { dishId })
