
import { defineComponent, ref, computed } from "vue"
import IconSwitcher from "@/common/components/buttons/IconSwitcher.vue"
import { SwitcherType } from "@/modules/admin/purchasing-products/models/enums"
import store from "@/store"

export default defineComponent({
    name: "ButtonGroup",
    props: {
        editDisabled: {
            type: Boolean,
            default: false
        },
        copyDisabled: {
            type: Boolean,
            default: false
        },
        joinDisabled: {
            type: Boolean,
            default: false
        },
        cancelDisabled: {
            type: Boolean,
            default: false
        },
        deleteDisabled: {
            type: Boolean,
            default: false
        },
        downloadDisabled: {
            type: Boolean,
            default: false
        },
        isCancelDisplayed: {
            type: Boolean,
            default: true
        },
        isAddDisplayed: {
            type: Boolean,
            default: true
        },
        isCopyDisplayed: {
            type: Boolean,
            default: true
        },
        isEditDisplayed: {
            type: Boolean,
            default: true
        },
        isDownloadDisplayed: {
            type: Boolean,
            default: false
        },
        isJoinDisplayed: {
            type: Boolean,
            default: true
        },
        //determines if background with shadow present
        background: {
            type: Boolean,
            default: true
        },
        viewFullSizeButtons: {
            type: Boolean,
            default: true
        }
    },
    components: {
        IconSwitcher
    },
    emits: [
        "addButtonClicked",
        "editButtonClicked",
        "copyButtonClicked",
        "joinButtonClicked",
        "cancelButtonClicked",
        "deleteButtonClicked",
        "downloadButtonClicked",],
    setup(props, { emit }) {
        const isNightSelect = computed(() => store.getters.getIsSelectedNight)

        const buttonGroupClass = computed(() => {
            const buttonGroupClass = props.background ? "button-group-background" : "button-group"
            if (isNightSelect.value)
                return `${buttonGroupClass} night`
            else return buttonGroupClass
        })
        const addButtonClicked = () => emit("addButtonClicked")
        const editButtonClicked = () => emit("editButtonClicked")
        const copyButtonClicked = () => emit("copyButtonClicked")
        const joinButtonClicked = () => emit("joinButtonClicked")
        const cancelButtonClicked = () => emit("cancelButtonClicked")
        const deleteButtonClicked = () => emit("deleteButtonClicked")
        const downloadButtonClicked = (files: File[]) => emit("downloadButtonClicked", files)

        const isFullSizeButtons = ref(!!JSON.parse(localStorage.getItem(SwitcherType.BUTTONS) as string))
        const switchButtons = (isSwitched: boolean) => isFullSizeButtons.value = isSwitched
        return {
            buttonGroupClass,
            addButtonClicked,
            editButtonClicked,
            copyButtonClicked,
            joinButtonClicked,
            cancelButtonClicked,
            deleteButtonClicked,
            downloadButtonClicked,
            isFullSizeButtons,
            switchButtons
        }
    }
})
