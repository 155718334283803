import {Module} from "vuex"
import {elMessage} from "@/modules/order/models/elMessage"
import {Order} from "@/utils/data/interfaces"
import {
  changeUserOrder,
  deleteUserOrder,
  downloadUserOrder,
  saveUserOrder,
} from "@/modules/order/models/fetchModules/fetchModuleOrdering"
import {makeOrder, unformOrder,} from "@/modules/order/models/fetchModules/fetchModuleSpareDishes"
import {SystemMessages, TypeMessages} from "@/modules/order/models/data/enums"
import moment from "moment"
import {FULLDATE_FORMAT} from "@/utils/data/moment-formats"
import {removeIsAdminDishes} from "@/utils/helpers/validators"

export const orderHandlingModule: Module<any, any> = {
  state: {
    userOrders: new Map<string, Order>(),
    daysWithOrder: new Set<string>(),
    unformedOrderDates: new Set<string>(),
    ordersDownloaded: false,
    adminOrderPresent: false,
    adminOrder: {} as Order,
  },
  getters: {
    isOrdersDownloaded(state) {
      return state.ordersDownloaded
    },
    getUserOrders(state) {
      return state.userOrders
    },
    getDaysWithOrder(state) {
      return state.daysWithOrder
    },
    getUnformedOrderDates(state) {
      return state.unformedOrderDates
    },
    isAdminOrderPresent(state) {
      return state.adminOrderPresent
    },
    getAdminOrder(state) {
      return state.adminOrder
    },
  },
  mutations: {
    fetchUserOrders(state, orders) {
      state.userOrders.clear()
      state.daysWithOrder.clear()
      state.unformedOrderDates.clear()
      orders.forEach((order: Order) => {
        const mapKey = moment(order.orderDate).format(FULLDATE_FORMAT)
        if (order.dishes.find((dish) => dish.isCanceled)) {
          state.unformedOrderDates.add(mapKey)
        } else {
          state.userOrders.set(mapKey, order)
          state.daysWithOrder.add(mapKey)
        }
      })
      state.ordersDownloaded = true
    },
    addOrder(state, orderForDB) {
      const orderDate = moment(orderForDB.orderDate).format(FULLDATE_FORMAT)
      state.userOrders.set(orderDate, {...orderForDB})
      state.daysWithOrder.add(orderDate)
    },
    removeOrder(state, orderDate) {
      state.userOrders.delete(moment(orderDate).format(FULLDATE_FORMAT))
      state.daysWithOrder.delete(moment(orderDate).format(FULLDATE_FORMAT))
    },
    addUnformedOrderDay(state, date) {
      state.unformedOrderDates.add(moment(date).format(FULLDATE_FORMAT))
    },
    fetchAdminOrder(state, data) {
      state.adminOrderPresent = data.data.length
      if (state.adminOrderPresent) state.adminOrder = data.data[0]
    },
  },
  actions: {
    downloadUserOrders: async ({commit}, {userId, dateFrom, dateTo}) => {
      const res = await downloadUserOrder(userId, dateFrom, dateTo)
      if (res.ok) {
        const data = await res.json()
        const userOrders: Order[] = []
        data.data.forEach((order: Order) => {
          order.dishes = removeIsAdminDishes(order.dishes)
          if (order.dishes.length) userOrders.push(order)
        })
        commit("fetchUserOrders", userOrders)
      } else {
        elMessage(SystemMessages.NO_ORDERS, TypeMessages.WARNING)
      }
    },
    checkAdminOrder: async ({commit}, {userId, dateFrom, dateTo}) => {
      const res = await downloadUserOrder(userId, dateFrom, dateTo)
      if (res.ok) {
        const data = await res.json()
        commit("fetchAdminOrder", data)
      } else {
        elMessage(SystemMessages.NO_ORDERS, TypeMessages.WARNING)
      }
    },
    saveUserOrder: async ({commit}, {orderForDB, isSpareDishes, userAdditionalRole, userRole}) => {
      const res = await saveUserOrder(orderForDB, userAdditionalRole, userRole)
      if (res.ok) {
        if (orderForDB.isAdmin)
          elMessage(SystemMessages.SPERE_DISHES_ADDED, TypeMessages.SUCCESS)
        else {
          commit("addOrder", orderForDB)
          isSpareDishes
            ? elMessage(SystemMessages.SPERE_DISHES_ADDED, TypeMessages.SUCCESS)
            : elMessage(SystemMessages.ORDER_SAVED, TypeMessages.SUCCESS)
        }
      } else
        res
          .json()
          .then((res) =>
            res.status && res.status === 403
              ? elMessage(SystemMessages.ORDER_IS_NOT_ALLOWED, TypeMessages.ERROR)
              : elMessage(
                SystemMessages.SOMETHING_WENT_WRONG,
                TypeMessages.ERROR
              )
          )
    },
    changeUserOrder: async ({commit}, {orderForDB, isSpareDishes, userAdditionalRole, userRole}) => {
      const res = await changeUserOrder(orderForDB, userAdditionalRole, userRole)
      if (res.ok) {
        const orderDate = orderForDB.orderDate
        commit("removeOrder", orderDate)
        commit("addOrder", orderForDB)
        isSpareDishes
          ? elMessage(SystemMessages.SPERE_DISHES_ADDED, TypeMessages.SUCCESS)
          : elMessage(SystemMessages.ORDER_CHANGED, TypeMessages.SUCCESS)
      } else
        res
          .json()
          .then((res) =>
            res.status && res.status === 403
              ? elMessage(SystemMessages.ORDER_IS_NOT_ALLOWED, TypeMessages.ERROR)
              : elMessage(
                SystemMessages.SOMETHING_WENT_WRONG,
                TypeMessages.ERROR
              )
          )
    },
    deleteUserOrder: async ({commit}, {userId, orderDate, userAdditionalRole, userRole}) => {
      const res = await deleteUserOrder(userId, orderDate, userAdditionalRole, userRole)
      if (res.ok) {
        commit("removeOrder", orderDate)
        elMessage(SystemMessages.ORDER_DELETED, TypeMessages.SUCCESS)
      } else
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.WARNING)
    },
    makeOrder: async ({commit}, confirmedOrder) => {
      const res = await makeOrder(confirmedOrder)
      if (res.ok) elMessage(SystemMessages.ORDER_SAVED, TypeMessages.SUCCESS)
      else res
        .json()
        .then((res) =>
          res.status && res.status === 403
            ? elMessage(SystemMessages.ORDER_IS_NOT_ALLOWED, TypeMessages.ERROR)
            : elMessage(
              SystemMessages.SOMETHING_WENT_WRONG,
              TypeMessages.ERROR
            )
        )
    },
    unformOrder: async ({commit}, order) => {
      const res = await unformOrder(order)
      if (res.ok) {
        commit("removeOrder", order.orderDate)
        commit("addUnformedOrderDay", order.orderDate)
        elMessage(SystemMessages.ORDER_UNFORMED, TypeMessages.SUCCESS)
      } else
        res
          .json()
          .then((res) =>
            res.status && res.status === 403
              ? elMessage(SystemMessages.ORDER_IS_NOT_ALLOWED, TypeMessages.ERROR)
              : elMessage(
                SystemMessages.SOMETHING_WENT_WRONG,
                TypeMessages.ERROR
              )
          )
    },
  },
}
export default orderHandlingModule
