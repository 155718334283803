import { reactive } from "vue"
import type { FormRules } from "element-plus"
import encodeToBase64 from "@/modules/admin/dish-guidebook/composables/useFromImgToBase64"
import { ImageInterface } from "@/utils/data/interfaces"

//Emoji handling
export function useDishRules() {
  const emojiFormRules = reactive<FormRules>({
    name: [
      {
        required: true,
        message: "Пожалуйста, введите название",
        trigger: "blur",
      },
      {
        min: 3,
        max: 20,
        message: "Название должно содержать от 3х до 20ти символов",
        trigger: "blur",
      },
    ],

    picture: [
      {
        required: true,
        message: "Пожалуйста, загрузите фото",
        trigger: "blur",
      },
    ],
  })
  return { emojiFormRules }
}

export type EmojiActionType = "change" | "add"

export const UploadImageSettings = {
  maxSize: 1048576, //1mb
  maxSizeInfoMessage:
    "Разрешается загрузить изображение в формате JPG или PNG до 1MB",
}

export const useFromGetObjectImage = async (
  event: InputEvent
): Promise<ImageInterface> => {
  const image = {} as ImageInterface
  image.imageInBase64 = await encodeToBase64(
    (event.target as HTMLInputElement).files![0]
  )
  image.imageLink = URL.createObjectURL(
    (event.target as HTMLInputElement).files![0]
  )
  if (image.imageLink)
    image.imageHasSize = (event.target as HTMLInputElement).files![0].size > UploadImageSettings.maxSize

  return image
}

//Payment handling
export function usePaymentRules() {
  const paymentFormRules = reactive<FormRules>({
    name: [
      {
        required: true,
        message: "Пожалуйста, введите название",
        trigger: ["blur", "change"]
      },
      {
        min: 3,
        max: 20,
        message: "Название должно содержать от 3х до 20ти символов",
      },
    ],

    details: [
      {
        required: true,
        message: "Пожалуйста, введите номер карты",
        trigger: ["blur", "change"]
      },
      {
        min: 19,
        max: 19,
        message: "Номер карты должен содержать 16 символов",
      },
    ],
    phone: [
      {
        required: true,
        message: "Пожалуйста, введите номер телефона",
        trigger: ["blur", "change"]
      },
      {
        min: 14,
        max: 14,
        message: "Номер телефона должен содержать 11 символов",
      },
    ],
  })
  return { paymentFormRules }
}