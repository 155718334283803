
import { computed, defineComponent, onMounted, ref, watch } from "vue"
import { useStore } from "vuex"
import moment from "moment"
import { SystemMessages } from "@/modules/order/models/data/enums"
import { DishForOrderList, Product } from "@/modules/admin/order-list/models/dishInterfaces"
import { Dish } from "@/utils/data/interfaces"
import { convertProductUnit, makeDishListMap } from "@/modules/admin/order-list/models/dishListMaker"
import { FULLDATE_FORMAT } from "@/utils/data/moment-formats"
import { selectListOptions } from "@/modules/admin/order-list/models/selectOptions"
import { downloadXLS } from "@/modules/admin/order-list/models/xlsGenerating"
import ingredientUnitsShortName from "@/modules/admin/models/records/IngredientUnitsShortName";

export default defineComponent({
  name: "OrderList",
  setup() {
    const store = useStore()

    const chosenList = ref(0)
    const cardName = computed(() => selectListOptions[chosenList.value].label)
    const averageUserAmount = computed(() => `Среднее количество заказов - ${store.getters.getAverageUserAmount}`)
    const isOrderListSelected = computed(() => chosenList.value === 0)
    //Due to the fact that some components of the template are hidden, these variables are assigned true values
    const isMailSending = ref(true)
    const isMattermostSending = ref(true)
    const isDownloading = ref(true)

    let currentDate = store.getters.getCurrentDate
    const activeDate = ref(currentDate.format(FULLDATE_FORMAT))
    const orderListTitle = ref(`на ${activeDate.value}`)
    let dateFrom = currentDate.hours(0).minutes(0).seconds(0).toISOString()
    let dateTo = currentDate.add(1, "day").toISOString()
    const defineDates = () => {
      currentDate = store.getters.getCurrentDate
      if (localStorage.getItem("lastClickedDate")) currentDate = moment(localStorage.getItem("lastClickedDate"))
      activeDate.value = currentDate.format(FULLDATE_FORMAT)
      orderListTitle.value = `на ${activeDate.value}`
      dateFrom = currentDate.hours(0).minutes(0).seconds(0).toISOString()
      dateTo = moment(dateFrom).add(1, "day").toISOString()
    }

    //Making dishes list
    const isOrdersDownloaded = computed(() => !!orderedDishes.value.length)
    const orderedDishes = ref(new Array<Dish>())
    const defineOrderedDishes = async (dateFrom: string, dateTo: string) => {
      defineDates()
      await store.dispatch("downloadOrderList", { dateFrom, dateTo })
      //Map with dishes
      const orderListMap: Map<number, DishForOrderList> = makeDishListMap(store.getters.getOrderListWithoutAdminDishes)
      const dishIds = Array.from(orderListMap.keys())
      await store.dispatch("downloadDishes", dishIds)
      orderedDishes.value = Array.from(store.getters.getDownloadedDishes.values())
      orderedDishes.value.forEach((dish) => dish.amount = orderListMap.get(dish.dishId)?.amount)
      defineGroceriesList()
    }
    defineOrderedDishes(dateFrom, dateTo)

    watch(() => store.getters.getCurrentDate, () => defineOrderedDishes(dateFrom, dateTo))
    //Making groceries list
    const groceryList = ref(new Array<[string, Product]>())
    const defineGroceriesList = () => {
      //Map for fast getting ingredients
      const groceriesMap = new Map<string, Product>()
      orderedDishes.value.forEach((dish) => {
        //Translating ingredient unit to short russian nemes("г.","мл.")
        dish.ingredients.forEach((ingredient) => groceriesMap.set(ingredient.name, {
          amount: 0,
          unit: ingredientUnitsShortName[ingredient.unit]
        }))
      })
      orderedDishes.value.forEach((dish) => {
        dish.ingredients.forEach((ingredient) => (groceriesMap.get(ingredient.name) as Product).amount += ingredient.amount! * dish.amount!)
      })
      //In case unit amount >1000, converting to "кг.", "л."
      convertProductUnit(Array.from(groceriesMap.values()))
      groceryList.value = Array.from(groceriesMap.entries())
    }

    const dateButtonClicked = (clickedDate: moment.Moment,
      tomorrowDate: moment.Moment) => {
      activeDate.value = clickedDate.format(FULLDATE_FORMAT)
      orderListTitle.value = `на ${activeDate.value}`
      const dateFrom = clickedDate.hours(0).minutes(0).seconds(0).toISOString()
      const dateTo = clickedDate.add(1, "day").toISOString()
      defineOrderedDishes(dateFrom, dateTo)
    }

    const periodToChanged = (dateFrom: string, dateTo: string) => {
      defineOrderedDishes(dateFrom, dateTo)
      orderListTitle.value = `на период c ${moment(dateFrom).format(FULLDATE_FORMAT)} по ${moment(dateTo).format(FULLDATE_FORMAT)}`
    }
    //Send button temporary named as UPLOAD
    const isSendButtonDisabled = computed(() => !(isMailSending.value || isMattermostSending.value || isDownloading.value))
    const sendButtonClick = () => {
      const sendingData: Array<{ itemName: string, amount: string }> = []
      if (chosenList.value === 0) {
        orderedDishes.value.forEach((dish) => sendingData.push({ itemName: dish.name, amount: `${dish.amount} шт.` }))
      } else if (chosenList.value === 1) {
        groceryList.value.forEach((product) => sendingData.push({
          itemName: product[0],
          amount: `${product[1].amount} ${product[1].unit}`
        }))
      }
      if (isDownloading.value) {
        downloadXLS(cardName.value, orderListTitle.value, sendingData)
      }
    }
    //Checking if saturday has menu
    const daysWithFormedDishMenu = computed(() => store.getters.getDaysWithFormedDishMenu)
    const checkSaturday = async (date: string) => {
      const dateFrom = moment(date).day(6).toISOString()
      const dateTo = moment(dateFrom).add(1, "day").toISOString()
      await store.dispatch("downloadDaysWithFormedDishMenu", { dateFrom, dateTo })

    }
    const calendarScroll = (date: string) => checkSaturday(date)

    onMounted(() => checkSaturday(moment(activeDate.value, FULLDATE_FORMAT).toISOString()))

    const isNightSelect = computed(() => store.getters.getIsSelectedNight)

    return {
      cardName,
      averageUserAmount,
      isOrderListSelected,
      chosenList,
      selectListOptions,
      isMailSending,
      isMattermostSending,
      isDownloading,
      isSendButtonDisabled,
      orderListTitle,
      orderedDishes,
      groceryList,
      isOrdersDownloaded,
      activeDate,
      SystemMessages,
      dateButtonClicked,
      periodToChanged,
      sendButtonClick,
      daysWithFormedDishMenu,
      calendarScroll,
      isNightSelect,
    }
  },
})
