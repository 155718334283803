
import { defineComponent, PropType, ref } from "vue"
import type { FormInstance } from "element-plus"
import { useStore } from "vuex"

//enums and references
import useIngredientsRules from "@/modules/admin/ingredients-guidebook/composables/useChangeIngredientValidation";

import IngredientsCategory from "@/modules/admin/models/enums/IngredientsCategory"
import IngredientsCategoryName from "@/modules/admin/models/records/IngredientsCategoryName"

import IngredientUnits from "@/modules/admin/models/enums/IngredientUnits"
import IngredientUnitsName from "@/modules/admin/models/records/IngredientUnitsName"
import { computed } from "@vue/runtime-core"

import { spaceValidator } from "@/utils/helpers/validators"

interface formIngredient {
  name: string,
  category: IngredientsCategory,
  unit: IngredientUnits,
  calories: string,
  proteins: string,
  fats: string,
  carbs: string
}

export default defineComponent({
  name: "EditIngredient",
  props: {
    type: {
      type: String as PropType<'post' | 'update'>,
      required: true,
    },
  },

  emits: ["closeDialog"],
  setup(props, { emit }) {

    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)

    const formSize = ref("default")
    const ruleFormRef = ref<FormInstance>()
    //TODO: отрефакторить
    const tempIngredient = computed(() => store.getters.getIngredientsForEdit as formIngredient)
    const validateName = () => tempIngredient.value.name = spaceValidator(tempIngredient.value.name)

    const closeDialog = () => emit("closeDialog")

    const prepareIngredientForBack = ((ingredient: formIngredient) => {
      return {
        ...ingredient,
        calories: +ingredient.calories,
        carbs: +ingredient.carbs,
        fats: +ingredient.fats,
        proteins: +ingredient.proteins,
      }
    })
    const submitForm = async () => {
      if (!ruleFormRef.value) return
      await ruleFormRef.value?.validate(async (valid, fields) => {
        if (valid) {
          if (props.type === 'post') {
            await store.dispatch("setIngredient", prepareIngredientForBack(tempIngredient.value))
          } else {
            await store.dispatch("updateIngredient", prepareIngredientForBack(tempIngredient.value))
          }

          closeDialog()
          ruleFormRef.value?.resetFields()
          if (!ruleFormRef.value) return

        }
      })
    }

    //Сброс введенных данных
    const resetForm = () => {
      ruleFormRef.value?.resetFields()
      if (!ruleFormRef.value) return
    }

    return {
      isNightSelect,
      tempIngredient,
      validateName,
      formSize,
      submitForm,
      resetForm,
      ruleFormRef,
      ...useIngredientsRules(),
      closeDialog,
      headerText: props.type === 'post' ? "Добавление ингредиента" : "Редактирование ингредиента",

      IngredientsCategory,
      IngredientsCategoryName,

      IngredientUnits,
      IngredientUnitsName,


    }
  },
})
