
import { defineComponent, PropType, ref } from "vue";
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";

interface UsersBySearch {
  value: string
  userId: number
}

export default defineComponent({
  name: "SearchByUsers",
  props: {
    arrayUsers: {
      type: Array as PropType<Array<UsersBySearch>>,
      required: true,
    },
  },
  emits: ["handleUserId", "handleFilterValue"],
  setup(props, { emit }) {

    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    const searchValue = ref<string>()
    const popperVisible = ref<boolean>(false)
    const catherPopperVisible = computed(() => popperVisible.value && !!props.arrayUsers.length)

    const isAnythingInSearch = computed(() => !!searchValue.value)
    const handleInput = () => {
      popperVisible.value = true
      emit("handleFilterValue", searchValue.value)
    }
    const setInputSearch = (user: UsersBySearch) => {
      popperVisible.value = false
      searchValue.value = user.value
      emit("handleUserId", user.userId)
    }
    const clearInputSearch = () => {
      popperVisible.value = false
      searchValue.value = ''
      emit("handleFilterValue", searchValue.value)
      emit("handleUserId")
    }
    const openPopper = () => {
      popperVisible.value = true
    }
    return {
      catherPopperVisible,
      popperVisible,
      searchValue,
      isAnythingInSearch,
      openPopper,
      handleInput,
      setInputSearch,
      clearInputSearch,
      isNightSelect,
    }
  }
})
