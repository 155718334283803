
import { defineComponent, ref, computed } from "vue"
import { Emoji } from "@/utils/data/interfaces"
import { useStore } from "vuex"
import { EmojiActionType } from "@/modules/admin/AppSettings/models/utils"
import EmojiHandling from "@/modules/admin/AppSettings/components/EmojiHandling.vue"

export default defineComponent({
    name: "EmojiSettings",
    components: { EmojiHandling },
    setup() {
        const store = useStore()
        const downloadEmojis = async () => {
            await store.dispatch("getEmoji")
        }
        downloadEmojis()
        const emojiTableData = computed<Array<Emoji>>(() => Array.from(store.getters.getEmojiMap.values()))
        const currentEmoji = ref({
            name: "",
            picture: ""

        } as Emoji)
        const emojiActionType = ref("add" as EmojiActionType)
        //Deleting emoji
        const deleteDialogVisible = ref(false)
        const showDeleteDialog = () => deleteDialogVisible.value = true
        const hideDeleteDialog = () => deleteDialogVisible.value = false

        const confirmMessage = ref([""])

        const emojiHandlingVisible = ref(false)
        const showEmojiHandling = () => emojiHandlingVisible.value = true
        const hideEmojiHandling = () => emojiHandlingVisible.value = false

        const deleteEmoji = (emoji: Emoji) => {
            currentEmoji.value = emoji
            confirmMessage.value = [`Вы хотите удалить эмодзи "${emoji.name}"?`]
            showDeleteDialog()
        }
        const deletingConfirmed = () => {
            hideDeleteDialog()
            store.dispatch("deleteEmoji", currentEmoji.value)

        }
        //Changing emoji
        const editEmoji = (emoji: Emoji) => {
            emojiActionType.value = "change"
            currentEmoji.value = emoji
            showEmojiHandling()
        }
        //Adding emoji
        const addEmoji = () => {
            emojiActionType.value = "add"
            currentEmoji.value = {
                id: 0,
                name: "",
                picture: ""
            }
            showEmojiHandling()
        }
        const isNightSelect = computed(() => store.getters.getIsSelectedNight)
        return {
            isNightSelect,
            emojiTableData,
            currentEmoji,
            emojiActionType,
            deleteDialogVisible,
            showDeleteDialog,
            hideDeleteDialog,
            confirmMessage,
            emojiHandlingVisible,
            hideEmojiHandling,
            deletingConfirmed,
            editEmoji,
            deleteEmoji,
            addEmoji,
        }
    }
}) 
