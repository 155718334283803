
import moment from "moment"
import {computed, defineComponent} from "vue"
import {useStore} from "vuex";

export default defineComponent({
  name: "RoundButton",
  props: {
    //Background color changes when the button clicked
    isClicked: {
      type: Boolean,
      dafault: false
    },
    // The day where the user made an order
    isWithOrder: {
      type: Boolean,
      default: false,
    },
    // Date inside date field
    date: {
      type: String,
      default: "date",
    },
    // Week day inside day field
    weekDay: {
      type: String,
      default: "day",
    },
    // Makes button disabled
    disabled: {
      type: Boolean,
      default: false,
    },
    // Display button or not
    display: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    const className = computed(() => {
      let className = ""
      if (props.isClicked) className = "calendar-button-clicked"
      else className = "calendar-button"
      if (props.isWithOrder) className = className + " calendar-button-whith-order"
      if (props.date === moment().format("DD.MM")) className = "current-day-calendar-button"
      if (props.date === moment().format("DD.MM") && props.isWithOrder) className = "current-day-calendar-button-whith-order"
      if (!props.display) className = "hide"
      return className
    })
    return {
      isNightSelect,
      className,
    }
  }
})
