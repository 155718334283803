import { Module } from "vuex"
import { elMessage } from "@/modules/order/models/elMessage"
import { DishLikeData } from "@/utils/data/interfaces"
import { SystemMessages, TypeMessages } from "@/modules/order/models/data/enums"
import {
  putLike,
  deleteLike,
  downloadLikes,
} from "@/modules/order/models/fetchModules/fetchModuleLike"

export const likeRequestModule: Module<any, any> = {
  state: {
    likeData: {} as DishLikeData,
    likesDownloaded: false,
  },
  getters: {
    getLikeData(state) {
      return state.likeData
    },
    isLikesDownloaded(state) {
      return state.likesDownloaded
    },
    getLikeAmount(state) {
      return state.likeData.likes
    },
    isLiked(state) {
      return state.likeData.isLiked
    },
  },
  mutations: {
    fetchDishLikes: (state, data) => {
      state.likesDownloaded = false
      state.likeData = data.data
      state.likesDownloaded = true
    },
    swichLikes: (state) => {
      if (state.likeData.isLiked) {
        state.likeData.likes--
        state.likeData.isLiked = false
      } else {
        state.likeData.likes++
        state.likeData.isLiked = true
      }
    },
  },
  actions: {
    likeDish: async ({ commit }, { userId, dishId }) => {
      const res = await putLike(userId, dishId)
      if (res.ok) {
        commit("swichLikes")
        elMessage(SystemMessages.LIKED, TypeMessages.SUCCESS)
      } else
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.WARNING)
    },
    unlikeDish: async ({ commit }, { userId, dishId }) => {
      const res = await deleteLike(userId, dishId)
      if (res.ok) {
        commit("swichLikes")
        elMessage(SystemMessages.UNLIKED, TypeMessages.SUCCESS)
      } else
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.WARNING)
    },
    downloadDishLikes: async ({ commit }, { userId, dishId }) => {
      const res = await downloadLikes(userId, dishId)
      if (res.ok) {
        const data = await res.json()
        commit("fetchDishLikes", data)
      } else {
        elMessage(SystemMessages.LIKE_FAIL, TypeMessages.WARNING)
      }
    },
  },
}
export default likeRequestModule
