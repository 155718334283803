
import { computed, defineComponent, PropType } from "vue"
import { Emoji } from "@/utils/data/interfaces"
import { useStore } from "vuex";

export default defineComponent({
  name: "EmojiView",
  props: {
    //Emojis for displaying
    emojis: {
      type: Array as PropType<Array<Emoji>>,
      default: [],
    },
  },
  setup() {
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    return {
      isNightSelect,
    }
  }
})
