import { Module } from "vuex"
import { elMessage } from "@/modules/order/models/elMessage"
import { Order } from "@/utils/data/interfaces"
import { SystemMessages } from "@/modules/order/models/data/enums"
import {
  downloadOrderHistory,
  downloadFirstOrder,
} from "@/modules/order/models/fetchModules/fetchMuduleOrderHistory"
import { removeIsAdminDishes } from "@/utils/helpers/validators"

export const orderHistoryModule: Module<any, any> = {
  state: {
    orderHistory: [] as Array<Order>,
    orderHistoryDownloaded: false,
    firstOrderDate: "",
  },
  getters: {
    getOrderHistory(state) {
      return state.orderHistory
    },
    isOrderHistoryDownloaded(state) {
      return state.orderHistoryDownloaded
    },
    getFirstOrderDate(state) {
      return state.firstOrderDate
    },
  },
  mutations: {
    fetchOrderHistory(state, data) {
      state.orderHistory = data
      state.orderHistoryDownloaded = true
    },
    fetchFirstUserOrder(state, firstOrder) {
      if (firstOrder) state.firstOrderDate = firstOrder.orderDate
    },
    clearHistory(state) {
      state.orderHistory = []
    },
  },
  actions: {
    downloadOrderHistory: async ({ commit }, { userId, dateFrom, dateTo }) => {
      const res = await downloadOrderHistory(userId, dateFrom, dateTo)
      if (res.ok) {
        const data = await res.json()
        const orderHistory: Order[] = []
        data.data.forEach((order: Order) => {
          order.dishes = removeIsAdminDishes(order.dishes)
          if (order.dishes.length) orderHistory.push(order)
        })
        commit("fetchOrderHistory", orderHistory)
      } else {
        elMessage(SystemMessages.NO_ORDER_HISTORY, "warning")
        commit("clearHistory")
      }
    },
    downloadFirstUserOrder: async ({ commit }, userId) => {
      const res = await downloadFirstOrder(userId)
      if (res.ok) {
        const orders = await res.json()
        const firstOrder: Order = orders.data[0]
        commit("fetchFirstUserOrder", firstOrder)
      }
    },
  },
}
export default orderHistoryModule
