
import {computed, defineComponent, PropType, ref} from "vue";
import {useStore} from "vuex";

export default  defineComponent({
  name: "SearchInput",
  props: {
    searchValue: {
      type: String as PropType<string>,
      default: ''
    },
    placeholderValue: {
      type: String as PropType<string>,
      default: 'Поиск'
    }
  },
  emits:["clearSearch", "handleSearch"],
  setup(props, {emit}){
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    const search = ref(props.searchValue)
    const setSearchValue = (inputValue:string) => {
      emit("handleSearch", inputValue)
    }
    const clearSearchValue = () => {
      search.value =""
      emit("clearSearch")
    }
    return {
      search,
      setSearchValue,
      clearSearchValue,
      isNightSelect
    }
  }
})
