
import store from "@/store"
import { computed, defineComponent, ref, PropType } from "vue"
import { ReceiptPicture } from "@/modules/admin/purchasing-products/models/listInterfaces"
import { saveReceipts } from '@/modules/admin/purchasing-products/models/fetch-modules/saveReceipts'

export default defineComponent({
    name: "PictureViewer",
    props: {
        isDeletingAllowed: {
            type: Boolean,
            default: true
        },
        viewType: {
            type: String as PropType<"list" | "receipt">,
            default: "receipt"
        }
    },
    emits: ["close-viewer", "no-pictures"],
    setup(props, { emit }) {
        const isNightSelect = computed(() => store.getters.getIsSelectedNight)
        const editDialogWidth = computed(() => store.getters.getBreakpoints.mobile ? "350px" : "800px")
        const viewingPictures = ref<Array<ReceiptPicture>>([])

        const dialogOpening = () => {
            if (props.viewType === "receipt")
                viewingPictures.value = store.getters.getPicturesFromStore(store.getters.getEditingReceipt.mapId)
            else viewingPictures.value = Array.from(store.getters.getListReceiptPictures.values())
            currentPictureIndex.value = 0
            rotationAngle.value = 0
        }

        //Right, left list
        const currentPicture = computed<string>(() => viewingPictures.value.length
            ? viewingPictures.value[currentPictureIndex.value].picture as string
            : "")
        const currentPictureIndex = ref(0)
        const leftButtonDisabled = computed(() => currentPictureIndex.value === 0)
        const rightButtonDisabled = computed(() => currentPictureIndex.value === viewingPictures.value.length - 1)

        const leftClicked = () => {
            currentPictureIndex.value -= 1
            rotationAngle.value = 0
        }
        const rightClicked = () => {
            currentPictureIndex.value += 1
            rotationAngle.value = 0
        }

        //Delete picture
        const deleteClicked = () => {
            viewingPictures.value[currentPictureIndex.value].picture = ""
            if (currentPictureIndex.value === viewingPictures.value.length - 1)
                currentPictureIndex.value = 0
            viewingPictures.value = viewingPictures.value.filter((picture) => picture.picture !== "")
            if (viewingPictures.value.length === 0) {
                emit("no-pictures")
                closeViewer()
            }
            rotationAngle.value = 0

        }

        //Rotation
        const rotationStep = 90
        const rotationAngle = ref(0)
        const rotationValue = computed(() => `${rotationAngle.value}deg`)
        const rotatePicture = () => rotationAngle.value += rotationStep

        const closeViewer = () => {
            store.commit("fetchDownloadedReceiptPictures", [...viewingPictures.value.filter((picture) => picture.picture !== "")])
            store.commit("addPicturesToStore")
            store.commit("addReceipt", store.getters.getEditingReceipt)
            store.commit("resetDownloadedReceiptPictures")
            emit("close-viewer")
            saveReceipts();
        }

        //Confirmation dialog
        const confirmationDialogVisible = ref(false)
        const confirmationDialogMainText = ref(["Удалить фотографию чека?"])
        const showConfirmationDialog = () => confirmationDialogVisible.value = true
        const hideConfirmationDialog = () => confirmationDialogVisible.value = false
        const okClicked = () => {
            hideConfirmationDialog()
            deleteClicked()
        }
        return {
            isNightSelect,
            editDialogWidth,
            dialogOpening,
            currentPicture,
            leftButtonDisabled,
            rightButtonDisabled,
            leftClicked,
            rightClicked,
            deleteClicked,
            rotationAngle,
            rotationValue,
            rotatePicture,
            closeViewer,
            confirmationDialogVisible,
            confirmationDialogMainText,
            showConfirmationDialog,
            hideConfirmationDialog,
            okClicked

        }
    }
})
