export enum SourceUrls {
  STAGE_URL = "https://kitchen-stage.relex-dev.ru",
  DEV_URL = "https://kitchen-dev.relex-dev.ru",
  PROD_URL = "https://kitchen.relex.ru",
}

export const headers = {
  "Content-Type": "application/json;charset=utf-8",
}

export const notAllowedSymbolsForLogin = /[а-яА-Я^%@!?&+*=.,'"$(){}\][\-<>~`;:|\/№#]/
export const notAllowedSymbolsForNumberInputs = /[а-яА-Яa-zA-Z^%@!?&+*=,'"$(){}\][\-<>~`;:|\/№#]/

export const UploadImageSettings = {
  maxSize: 5242880, //5mb
  maxSizeInfoMessage: "Разрешается загрузить изображение в формате JPG или PNG до 5MB",
}

export enum WeekConstants {
  MONDAY = 1,
  TUESDAY = 2,
  SATURDAY = 6,
  SUNDAY = 0,
  WEEK_LENGTH = 7,
}
