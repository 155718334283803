import { Module } from 'vuex';
import {
  ListFormed,
  ListStatus,
  ListActionType,
  IngredientGroupType,
} from '@/modules/admin/purchasing-products/models/enums';
import {
  TranslationListFormed,
  TranslationListStatus,
} from '@/modules/admin/purchasing-products/models/records';
import {
  PurchasingList,
  PurchasingListRow,
} from '@/modules/admin/purchasing-products/models/listInterfaces';
import { elMessage } from '@/modules/order/models/elMessage';
import { SystemMessages, TypeMessages } from '@/modules/order/models/data/enums';
import { downloadListRows } from '@/modules/admin/purchasing-products/models/fetch-modules/fetchLists';
import { makeListRowsTableData } from '@/modules/admin/purchasing-products/models/helpers';
import moment from 'moment';
import { FULLDATE_TIME_FORMAT } from '@/utils/data/moment-formats';
import { filterPurchasedRows } from '@/modules/admin/purchasing-products/models/helpers';
import store from '@/store';
import { AdditionalRoles } from '@/modules/admin/AppSettings/models/rolesHelper';

const makeArrayFromTableData = (
  tableData: Map<string, Map<number, PurchasingListRow>>
): PurchasingListRow[] => {
  const listRows: PurchasingListRow[] = [];
  tableData.forEach((map) => map.forEach((row) => listRows.push(row)));
  return listRows;
};

export const listStoreModule: Module<any, any> = {
  state: {
    editingList: {
      formationMethod: ListFormed.MANUAL,
      translatedFormationMethod: TranslationListFormed[ListFormed.MANUAL],
      status: ListStatus.DRAFT,
      translatedStatus: TranslationListStatus[ListStatus.DRAFT],
    } as PurchasingList,

    defaultList: {
      formationMethod: ListFormed.MANUAL,
      translatedFormationMethod: TranslationListFormed[ListFormed.MANUAL],
      status: ListStatus.DRAFT,
      translatedStatus: TranslationListStatus[ListStatus.DRAFT],
    } as PurchasingList,

    listActionType: ListActionType.ADD,
    listSaved: false,
    listPrice: 0,
    //List rows from backend
    initialRows: [] as Array<PurchasingListRow>,
    //List rows for table, sorted by ingredient category or purchasing place according ingredientGroupType
    purchasingListRowsTableData: new Map<string, Map<number, PurchasingListRow>>(),
    joinedRows: [] as Array<PurchasingListRow>,
    ingredientGroupType: IngredientGroupType.CATEGORY,
    //Id for fast access to list rows
    rowLocalId: 0,
  },
  getters: {
    getEditingList(state) {
      return state.editingList;
    },
    getListActionType(state) {
      return state.listActionType;
    },
    getPurchasingListRowsTableData: (state) => (isPurchased: boolean) => {
      return isPurchased
        ? state.purchasingListRowsTableData
        : filterPurchasedRows(state.purchasingListRowsTableData);
    },
    getJoinedRows(state) {
      return state.joinedRows;
    },
    isListSaved(state) {
      return state.listSaved;
    },
    getRowLocalId(state) {
      return state.rowLocalId;
    },
    getIngredientGroupType(state) {
      return state.ingredientGroupType;
    },
    getListPrice(state) {
      return state.listPrice;
    },
  },
  mutations: {
    //List
    setEditingList(state, list) {
      state.editingList = list;
    },
    resetEditingList(state) {
      state.editingList = state.defaultList;
      state.listActionType = ListActionType.ADD;
      state.purchasingListRowsTableData = new Map<string, Map<number, PurchasingListRow>>();
    },
    setListActionType(state, listActionType) {
      state.listActionType = listActionType;
    },
    clearList(state) {
      state.purchasingListRowsTableData = new Map<string, Map<number, PurchasingListRow>>();
    },

    //List row
    addListRow(state, row: PurchasingListRow) {
      let mapKey =
        state.ingredientGroupType === IngredientGroupType.CATEGORY
          ? row.ingredientCategory
          : row.purchasingPlaceName;
      if (!mapKey) mapKey = 'Место не выбрано';
      if (state.purchasingListRowsTableData.has(mapKey))
        state.purchasingListRowsTableData.get(mapKey).set(row.id, row);
      else {
        state.purchasingListRowsTableData.set(mapKey, new Map<number, PurchasingListRow>());
        state.purchasingListRowsTableData.get(mapKey).set(row.id, row);
      }

      localStorage.setItem(
        'currentListRows',
        JSON.stringify(makeArrayFromTableData(state.purchasingListRowsTableData))
      );
    },

    removeListRow(state, row: PurchasingListRow) {
      let mapKey =
        state.ingredientGroupType === IngredientGroupType.CATEGORY
          ? row.ingredientCategory
          : row.purchasingPlaceName;
      if (!mapKey) mapKey = 'Место не выбрано';
      if (state.purchasingListRowsTableData.get(mapKey).has(row.id))
        state.purchasingListRowsTableData.get(mapKey).delete(row.id);
      if (state.purchasingListRowsTableData.get(mapKey).size === 0)
        state.purchasingListRowsTableData.delete(mapKey);
      localStorage.setItem(
        'currentListRows',
        JSON.stringify(makeArrayFromTableData(state.purchasingListRowsTableData))
      );
    },

    setListPrice(state, price: number) {
      state.listPrice = price;
    },

    nextRowLocalId(state) {
      state.rowLocalId++;
    },

    //List rows
    fetchListRows(state, data) {
      state.initialRows = data;
    },

    setInitialRows(state) {
      let groupType = localStorage.getItem('ingredientGroupType')
        ? localStorage.getItem('ingredientGroupType')
        : state.ingredientGroupType;
      if (store.getters.getUserAdditionalRole !== AdditionalRoles.BUYER)
        groupType = IngredientGroupType.CATEGORY;
      state.ingredientGroupType = groupType;
      localStorage.setItem('ingredientGroupType', groupType);
      state.purchasingListRowsTableData = makeListRowsTableData(
        localStorage.getItem('currentListRows')
          ? JSON.parse(localStorage.getItem('currentListRows')!)
          : state.initialRows,
        groupType
      );
    },
    resetListRows(state) {
      state.initialRows = [];
    },

    changeIngredientGroupType(state, groupType: IngredientGroupType) {
      localStorage.setItem('ingredientGroupType', groupType);
      state.ingredientGroupType = groupType;
      const listRows: Array<PurchasingListRow> = [];
      state.purchasingListRowsTableData.forEach((entry: Map<number, PurchasingListRow>) =>
        listRows.push(...entry.values())
      );
      state.purchasingListRowsTableData = makeListRowsTableData(listRows, groupType);
    },
    //Rows joining
    fetchJoinedRows(state, rows: Array<PurchasingListRow>) {
      const uniqueIngredients = new Set<number>();
      state.joinedRows.forEach((row: PurchasingListRow) =>
        uniqueIngredients.add(row.ingredientId!)
      );
      rows.forEach((row) => {
        const id = row.ingredientId!;
        if (!uniqueIngredients.has(id)) {
          state.joinedRows.push(row);
          uniqueIngredients.add(id);
        }
      });
    },
    resetJoinedRows(state) {
      state.joinedRows = [];
    },
  },
  actions: {
    //ListRows
    downloadPurchasingListRows: async ({ commit }, listId) => {
      const res = await downloadListRows(listId);
      if (res.ok) {
        const data = await res.json();
        commit('clearList');
        commit('fetchListRows', data.data);
        commit('setInitialRows');
        commit('fetchJoinedRows', data.data);
        if (!localStorage.getItem('currentListRows'))
          localStorage.setItem('currentListRows', JSON.stringify(data.data));
      } else elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR);
    },

    //List
    addPurchasingList({ commit }) {
      commit('resetEditingList');
      localStorage.setItem('listActionType', ListActionType.ADD);
      localStorage.setItem('editingList', JSON.stringify({ ...this.getters.getEditingList }));
    },
    processExistingList({ commit }, [selectedList, actionType]) {
      if (actionType === ListActionType.COPY) {
        selectedList.status = ListStatus.DRAFT;
        selectedList.translatedStatus = TranslationListStatus[ListStatus.DRAFT];
        selectedList.createdAt = '';
        selectedList.createdAtForDisplaying = '';
        selectedList.authorId = undefined;
        selectedList.authorName = '';
      }
      commit('setEditingList', selectedList);
      commit('setListActionType', actionType);
      localStorage.setItem('listActionType', actionType);
      localStorage.setItem('editingList', JSON.stringify({ ...selectedList }));
    },
    processPurchasing({ commit }, row: PurchasingListRow) {
      row.isPurchased = !row.isPurchased;
      if (row.isPurchased) {
        row.date = moment().toISOString();
        row.dateForDisplaying = moment().format(FULLDATE_TIME_FORMAT);
      } else {
        row.date = undefined;
        row.dateForDisplaying = '';
      }
      commit('addListRow', row);
    },
  },
};

export default listStoreModule;
