import {Module} from "vuex"

import {Ingredient} from "@/utils/data/interfaces"
import ingredientsCategoryName from "@/modules/admin/models/records/IngredientsCategoryName"

import {SystemMessages, TypeMessages} from "@/modules/order/models/data/enums"
import {elMessage} from "@/modules/order/models/elMessage"

import forFetchPutIngredient from "@/store/modules/admin/utils/ForFetchPutIngredient"

import {get, post} from "@/utils/fetchQueries"
import ingredientUnitsShortName from "@/modules/admin/models/records/IngredientUnitsShortName"

const translateIngredientsForDisplay = (ingredients: Array<Ingredient>) => {
  return ingredients.map((ingredient: Ingredient) => {
    return {
      ...ingredient,
      unit: ingredientUnitsShortName[ingredient.unit],
      category: ingredientsCategoryName[ingredient.category],
    }
  })
}
export const ingredientsGuidebook: Module<any, any> = {
  state: {
    ingredients: [] as Array<Ingredient>,
    ingredientsMap: new Map<number, Ingredient>(),
    deletedIngredients: [] as Array<Ingredient>,
    ingredientForEdit: {} as Ingredient,
  },
  getters: {
    getIngredientMap(state) {
      return state.ingredientsMap
    },
    getIngredientsForGuide(state) {
      return translateIngredientsForDisplay(state.ingredients)
    },
    getDeletedIngredientsForArchive(state) {
      return translateIngredientsForDisplay(state.deletedIngredients)
    },
    getIngredientsForEdit(state) {
      return state.ingredientForEdit
    },
    getDeletedIngredientsForFind(state) {
      return state.deletedIngredients
    },
  },
  mutations: {
    setIngredients(state, data) {
      state.ingredients = data
      data.forEach((ingredient: Ingredient) =>
        state.ingredientsMap.set(ingredient.id, ingredient)
      )
    },

    setDeletedIngredients(state, data) {
      state.deletedIngredients = data
    },
    changeIngredientForEdit(state, idIngredient) {
      if (!idIngredient) {
        state.ingredientForEdit = {} as Ingredient
        return;
      }

      if (!state.ingredientsMap.has(idIngredient)) {
        state.ingredientForEdit = state.deletedIngredients.find((ingredient:Ingredient) => ingredient.id === idIngredient)
        return
      }

      state.ingredientForEdit = state.ingredientsMap.get(idIngredient)
    }
  },
  actions: {
    setIngredientForEdit({commit}, idIngredient?) {
      commit("changeIngredientForEdit", idIngredient)
    },

    async getIngredients({commit}) {
      const res = await get(`/api/ingredient`)
      if (!res.ok) {
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
      } else {
        const ingredientsFromBack: Ingredient[] = (await res.json()).data

        const ingredientsInGuide = [] as Array<Ingredient>
        const ingredientsInArchive = [] as Array<Ingredient>

        ingredientsFromBack.forEach((ingredient: Ingredient) => {
          if (ingredient.isDeleted) ingredientsInArchive.push(ingredient)
          else ingredientsInGuide.push(ingredient)
        })

        commit("setIngredients", ingredientsInGuide)
        commit("setDeletedIngredients", ingredientsInArchive)
      }
    },

    async setIngredient({commit}, setIngredient) {
      const res = await post(`/api/ingredient`, setIngredient)
      this.dispatch("getIngredients")
      if (res.ok) {
        elMessage(SystemMessages.INGREDIENT_ADDED, TypeMessages.SUCCESS)
      } else {
        res.json().then(res => res.status && res.status === 405
          ? elMessage(SystemMessages.INGREDIENT_ALREADY_EXISTS, TypeMessages.ERROR)
          : elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
        )
      }
    },

    async deleteIngredient({commit}, deletedIngredient) {
      const res = await forFetchPutIngredient({...deletedIngredient, isDeleted: true})
      this.dispatch("getIngredients")
      res.ok
        ? elMessage(SystemMessages.INGREDIENT_DELETED, TypeMessages.SUCCESS)
        : elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
    },

    async updateIngredient({commit}, changedIngredient) {
      const res = await forFetchPutIngredient(changedIngredient)
      this.dispatch("getIngredients")
      res.ok
        ? elMessage(SystemMessages.INGREDIENT_UPDATED, TypeMessages.SUCCESS)
        : elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
    },

    async restoreIngredient({commit}, restoredIngredient) {
      const res = await forFetchPutIngredient({...restoredIngredient, isDeleted: false})
      this.dispatch("getIngredients")
      res.ok
        ? elMessage(SystemMessages.INGREDIENT_RESTORED, TypeMessages.SUCCESS)
        : elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
    },
  },
}
export default ingredientsGuidebook
