export const removeEmptyFieldsFromObject = (object: any) => {
    for (let key in object) {
        if (object[key] === '' || object[key] === undefined) delete object[key]
    } 
}

export const compareObjects = (firstObject: Object, secondObject: Object, ignoreEmptyFields: boolean = false ): boolean => {
    // for saving initial object from mutating (deleting fields)
    const first = {...firstObject}
    const second = {...secondObject}

    if (ignoreEmptyFields) {
        removeEmptyFieldsFromObject(first)
        removeEmptyFieldsFromObject(second)
    }

    return JSON.stringify(first) === JSON.stringify(second)
}