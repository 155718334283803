import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47406b36"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "img-wrapper" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_dialog_window = _resolveComponent("dialog-window")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    class: "picture-viewer",
    width: _ctx.editDialogWidth,
    "show-close": false,
    "append-to-body": true,
    top: "1vh",
    onOpen: _ctx.dialogOpening
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: _ctx.currentPicture,
          alt: "",
          style: _normalizeStyle({ rotate: _ctx.rotationValue })
        }, null, 12, _hoisted_2)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["buttons", { night: _ctx.isNightSelect }])
      }, [
        _createVNode(_component_icon_button, {
          type: "left",
          disabled: _ctx.leftButtonDisabled,
          onClick: _ctx.leftClicked
        }, null, 8, ["disabled", "onClick"]),
        _createVNode(_component_icon_button, {
          type: "right",
          disabled: _ctx.rightButtonDisabled,
          onClick: _ctx.rightClicked
        }, null, 8, ["disabled", "onClick"]),
        _createVNode(_component_icon_button, {
          type: "rotate",
          onClick: _ctx.rotatePicture
        }, null, 8, ["onClick"]),
        (_ctx.isDeletingAllowed)
          ? (_openBlock(), _createBlock(_component_icon_button, {
              key: 0,
              type: "delete-black",
              onClick: _ctx.showConfirmationDialog
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_icon_button, {
          type: "close",
          onClick: _ctx.closeViewer
        }, null, 8, ["onClick"])
      ], 2),
      _createVNode(_component_dialog_window, {
        modelValue: _ctx.confirmationDialogVisible,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.confirmationDialogVisible) = $event)),
        title: "Внимание",
        "main-text": _ctx.confirmationDialogMainText,
        onCloseDialog: _ctx.hideConfirmationDialog,
        onCancelClicked: _ctx.hideConfirmationDialog,
        onOkClicked: _ctx.okClicked
      }, null, 8, ["modelValue", "main-text", "onCloseDialog", "onCancelClicked", "onOkClicked"])
    ]),
    _: 1
  }, 8, ["width", "onOpen"]))
}