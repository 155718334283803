import {
  CommentData,
  Dish,
  Emoji,
  Ingredient,
  Order,
  OrderedDish,
} from "@/utils/data/interfaces"
import { DailyMenu, MenuType } from "@/modules/order/models/dishInterfaces"
import moment from "moment"
import { FULLDATE_FORMAT } from "@/utils/data/moment-formats"

import DishCategory from "@/modules/admin/models/enums/DishCategory"
import DishCategoryName from "@/modules/admin/models/records/DishCategoryName"
import DishMenuType from "@/modules/admin/models/enums/DishMenuType"
import DishMenuTypeName from "@/modules/admin/models/records/DishMenuTypeName"

interface DishFromBackEnd {
  menuId: number
  dishId: number
  name: string
  menuType: DishMenuType
  category: DishCategory
  secondCourseType?: string
  isDeleted: boolean
  date: string
  price: number
  description?: string
  calories: number
  proteins: number
  fats: number
  carbs: number
  weight: number
  ingredients: Ingredient[]
  emojis?: Emoji[]
  image: string
}

export class DishMenuParser {
  private dishesForMenu: Array<Dish>

  constructor(dishesForMenu: Dish[]) {
    this.dishesForMenu = dishesForMenu
  }

  getDishesForMenu() {
    return this.dishesForMenu
  }

  getDaysWithFormedDishMenu() {
    const daysWithFormedDishMenu = new Set<string>()
    this.dishesForMenu.forEach((dish) => {
      daysWithFormedDishMenu.add(dish.date!)
    })
    return daysWithFormedDishMenu
  }

  getDishMenu() {
    const menu = new Map<string, DailyMenu>()
    //Dishes separeted dy date
    const draftMenu: Map<string, Dish[]> = new Map()
    this.dishesForMenu.forEach((dish) => {
      if (draftMenu.has(dish.date!)) draftMenu.get(dish.date!)?.push(dish)
      else draftMenu.set(dish.date!, [dish])
    })
    Array.from(draftMenu.entries()).forEach((dailyDraftMenu) => {
      const dailyMenu: DailyMenu = {
        date: "",
        menu: Object.values(DishMenuType).map((dishMenuType) => ({
          menuType: DishMenuTypeName[dishMenuType],
          dishTypes: Object.values(DishCategory).map((dishCategory) => ({
            typeName: DishCategoryName[dishCategory],
            dishes: [],
          })),
        })),
      }
      dailyMenu.date = dailyDraftMenu[0]
      dailyMenu.menu.forEach((menuType) => {
        menuType.dishTypes.forEach((dishType) => {
          dailyDraftMenu[1].forEach((dish) => {
            if (
              DishMenuTypeName[dish.menuType] === menuType.menuType &&
              DishCategoryName[dish.category] === dishType.typeName
            )
              dishType.dishes.push(dish)
          })
        })
      })
      menu.set(dailyMenu.date, dailyMenu)
    })
    return menu
  }

  getDishesForDescription(): Map<number, Dish> {
    const dishesForDescription = new Map<number, Dish>()
    this.dishesForMenu.forEach((dish) =>
      dishesForDescription.set(dish.dishId, dish)
    )
    return dishesForDescription
  }
}

export const convertToDishModel = (
  dishesFromBackend: Array<DishFromBackEnd>
): Array<Dish> => {
  let dishes: Array<Dish> = []
  dishesFromBackend.forEach((dish) => {
    const tempDish: Dish = {
      ...dish,
      date: moment(dish.date).format(FULLDATE_FORMAT),
      amount: 0,
      maxAmount: 20,
      likeAmount: 0,
      liked: false,
      comments: new Array<CommentData>(),
    }
    dishes.push(tempDish)
  })
  return dishes
}

export const checkOrderedDishes = (
  order: Order,
  dailyDishes: Dish[]
) => {
  const orderDate = moment(order.orderDate).format(FULLDATE_FORMAT)
  const orderedDishes = new Map<number, OrderedDish>()
  order.dishes.forEach((orderedDish) =>
    orderedDishes.set(orderedDish.dishId, orderedDish)
  )
  dailyDishes.forEach((dish) => {
    if (
      orderedDishes.has(dish.dishId) &&
      moment().format(FULLDATE_FORMAT) === orderDate
    )
      dish.amount =
        orderedDishes.get(dish.dishId)!.amount -
        orderedDishes.get(dish.dishId)!.issueAmount
    else if (orderedDishes.has(dish.dishId))
      dish.amount = orderedDishes.get(dish.dishId)!.amount
    else dish.amount = 0
  })

}

export const checkIssuedSpareOrders = (orders: Map<string, Order>) => {
  Array.from(orders.values()).forEach((order) => {
    order.isSpareOrder = !!order.dishes.find((dish) => dish.isSpareOrder)
  })
}

export const clearDailyMenu = (dailyDishes: Dish[]) => {
  dailyDishes.forEach((dish) => dish.amount = 0)
}
