import CustomButton from "@/common/components/buttons/CustomButton.vue"
import IconButton from "@/common/components/buttons/IconButton.vue"
import ToggleCheckbox from "@/common/components/ToggleCheckbox.vue"
import ArrowButton from "@/common/components/buttons/ArrowButton.vue"
import CloseButton from "@/common/components/buttons/CloseButton.vue"
import DishesItems from "@/common/components/dish-views/DishesItems.vue"
import DishView from "@/common/components/dish-views/DishView.vue"
import DialogWindow from "@/common/components/DialogWindow.vue"
import OrderCalendar from "@/common/components/OrderCalendar.vue"
import DishDescription from "@/common/components/DishDescription.vue"
import OrderView from "@/common/components/OrderView.vue"
import CustomCard from "@/common/components/CustomCard.vue"
import EmojiView from "@/common/components/EmojiView.vue"
import SelectorsMonthAndYear from "@/common/components/SelectorsMonthAndYear.vue"
import RoundCheckbox from "@/common/components/RoundCheckbox.vue"
import CustomCloseCross from "@/common/components/CustomCloseCross.vue"
import SearchInput from "@/common/components/SearchInput.vue"
import SearchByUsers from "@/common/components/SearchByUsers.vue"
import QuestionMarkPopover from "@/common/components/QuestionMarkPopover.vue"
import IconSwitcher from "@/common/components/buttons/IconSwitcher.vue"
import DownloadButton from "@/common/components/buttons/DownloadButton.vue"
export default [
  CustomButton,
  IconButton,
  ToggleCheckbox,
  ArrowButton,
  CloseButton,
  DishesItems,
  DishView,
  DialogWindow,
  OrderCalendar,
  DishDescription,
  OrderView,
  CustomCard,
  EmojiView,
  SelectorsMonthAndYear,
  RoundCheckbox,
  CustomCloseCross,
  SearchInput,
  SearchByUsers,
  QuestionMarkPopover,
  IconSwitcher,
  DownloadButton,
]
