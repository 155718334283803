
import { defineComponent, computed, ref, PropType } from "vue"
import {Dish, Order} from "@/utils/data/interfaces"
import moment from "moment"
import { FULLDATE_FORMAT } from "@/utils/data/moment-formats"
import { OrderStatusImages } from "@/modules/order/models/data/enums"
import {downloadDishesForPreview} from "@/utils/helpers/createPreviewDishes"
import {useStore} from "vuex";

export default defineComponent({
    name: "WeekItem",
    props: {
        //Current week monday date in ISO format
        date: {
            type: String,
            required: true
        },
        //User orders on current month
        orders: {
            type: Array as PropType<Array<Order>>,
            default: []
        },
        //Number of current month
        currentMonth: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const store = useStore()
        const orderImage = ref(OrderStatusImages.ISSUED)
        const dates = computed(() => {
            const dates: Array<moment.Moment> = []
            for (let counter = 0; counter < 6; counter++) {
                dates.push(moment(props.date).add(counter, "days"))
            }
            return dates
        })
        const orders = computed(() => {
            const orders: Map<string, Order> = new Map()
            props.orders.forEach((order) => orders.set(moment(order.orderDate).format(FULLDATE_FORMAT), order))
            return orders
        })
        const isOrderPresent = (date: moment.Moment): boolean => orders.value.has(date.format(FULLDATE_FORMAT))
        const isOrderIssued = (date: moment.Moment): boolean => !!orders.value.has(date.format(FULLDATE_FORMAT)) && !!orders.value.get(date.format(FULLDATE_FORMAT))?.isIssued
        //Date items gets opacity if they are not in current month
        const dateItemClass = (date: moment.Moment): string => {
          if(isNightSelect.value) return date.month() + 1 === props.currentMonth ? "date-item night" : "date-item night not-active"
          return date.month() + 1 === props.currentMonth ? "date-item" : "date-item not-active"
        }

        const getOrderPrice = (date: moment.Moment): number => {
            let orderPrice = 0
            if (orders.value.has(date.format(FULLDATE_FORMAT))) {
                (orders.value.get(date.format(FULLDATE_FORMAT)) as Order).dishes.forEach((dish) => {
                    if (dish)
                        orderPrice += dish.amount * dish.price
                })
            }
            return orderPrice
        }
        //Show order description
        const orderDescriptionVisible = ref(false)
        const viewedOrder = ref({} as Order)
        const orderDate = ref("")
        const dishTypes = ref([] as [string, Dish[]][])
        const showOrderDescription = async (date: moment.Moment) => {
            if (orders.value.has(date.format(FULLDATE_FORMAT))) {
              viewedOrder.value = (orders.value.get(date.format(FULLDATE_FORMAT)) as Order)
              //Deleting empty dish types
              dishTypes.value = Array.from( (await downloadDishesForPreview(viewedOrder.value)).entries()).filter((dishType) => dishType[1].length !== 0)
              orderDescriptionVisible.value = true
            }
        }
        const closeOrderDescription = () => {
            orderDescriptionVisible.value = false
        }
        const isNightSelect = computed(() => store.getters.getIsSelectedNight)
        return {
            dishTypes,
            orderImage,
            dates,
            isOrderPresent,
            isOrderIssued,
            dateItemClass,
            getOrderPrice,
            orderDescriptionVisible,
            viewedOrder,
            orderDate,
            showOrderDescription,
            closeOrderDescription,
            FULLDATE_FORMAT,
            isNightSelect,
        }
    }
})
