
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";

import moment from "moment";
import { TimeSettingName } from "@/modules/order/models/data/records";
import { timeSettings } from "@/modules/order/models/data/enums";
import { TimeSettingsInterface } from "@/utils/data/interfaces";
import TimePicker from "@/modules/order/components/common/TimePicker.vue";

export default defineComponent({
  name: "TimeSettings",
  components: { TimePicker },
  setup() {

    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    const times = ref<Array<TimeSettingsInterface>>([])
    //Автоматическая выдача заказов, уведомление о расформированном заказе
    const defineAppTimeSetting = async () => {
      await store.dispatch("getInstalledTimeSettings")
      times.value = (store.getters.getTimeSetting)
    }
    defineAppTimeSetting()

    const convertMomentDates = (date: string) => moment(new Date(date), "HH:mm").format("HH:mm")

    const setTimeSettings = () => {
      const timeSettings = times.value.map(setting => ({
        type: setting.type,
        timeSetting: convertMomentDates(setting.timeSetting)
      })
      )
      store.dispatch("setTimeSettings", timeSettings)
    }

    return {
      isNightSelect,
      times,
      setTimeSettings,
      TimeSettingName,
      timeSettings,
    }
  }
})
