import {Module} from "vuex"
import {downloadOrderList} from "@/modules/admin/order-list/models/fetch-modules/fetchModuleOrderList"
import {OrderForOrderList} from "@/modules/admin/order-list/models/dishInterfaces"
import {FULLDATE_FORMAT} from "@/utils/data/moment-formats"
import moment from "moment"
import {removeIsAdminDishes} from "@/utils/helpers/validators"

export const orderListModule: Module<any, any> = {
  state: {
    orderList: [] as Array<OrderForOrderList>,
    averageUserAmount: 0,
    orderListDownloaded: false,
  },
  getters: {
    getOrderListWithAdminDishes(state) {
      return state.orderList
    },
    getOrderListWithoutAdminDishes(state) {
      const orderList: Array<OrderForOrderList> = []
      state.orderList.forEach((order: OrderForOrderList) => {
        order.dishes = removeIsAdminDishes(order.dishes)
        if (order.dishes.length) orderList.push(order)
      })
      return orderList
    },
    isOrderListDownloaded(state) {
      return state.orderListDownloaded
    },
    getAverageUserAmount(state) {
      return state.averageUserAmount
    },
  },
  mutations: {
    fetchOrderList(state, data) {
      state.orderList = data
      state.orderListDownloaded = true
    },
    clearOrderList(state) {
      state.orderList = []
      state.orderListDownloaded = false
    },
    fetchAverageUserAmount(state, data) {
      state.averageUserAmount = data
    },
  },
  actions: {
    downloadOrderList: async ({commit}, {dateFrom, dateTo}) => {
      const res = await downloadOrderList(dateFrom, dateTo)
      if (res.ok) {
        const orderList = (await res.json()).data as Array<OrderForOrderList>
        commit("fetchOrderList", orderList)

        const [userAmount, days] = orderList.reduce(
          (res, order) => [
            res[0] + 1,
            res[1].add(moment(order.orderDate).format(FULLDATE_FORMAT)),
          ],
          [0, new Set()]
        )
        commit("fetchAverageUserAmount", Math.round(userAmount / days.size))
      }
    },
  },
}

export default orderListModule
