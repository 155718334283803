import { reactive } from "vue"
import type { FormRules } from "element-plus"

export default function useFeedbackRules() {
  const feedbackRules = reactive<FormRules>({
    title: [
      {
        required: true,
        message: "Пожалуйста, введите тему обращения",
        trigger: "blur",
      },
      {
        min: 3,
        max: 255,
        message: "Длина должна быть от 3-х до 255-ти символов",
        trigger: "blur",
      },
    ],
    description: [
      {
        required: true,
        message: "Пожалуйста, введите описание обращения",
        trigger: "change",
      },
      {
        max: 1024,
        message: "Длина должна быть до 1024-х символов",
        trigger: "blur",
      },
    ],
  })

  return { feedbackRules }
}
