export enum permissions {
  CREATE_MENU = "CREATE_MENU",
  ORDER_LIST = "ORDER_LIST",
  PROCUREMENT = "PROCUREMENT",
  ORDER_ISSUING = "ORDER_ISSUING",
  REPORT = "REPORT",
  GUIDES = "GUIDES",
  SYSTEM_SETTINGS = "SYSTEM_SETTINGS",
  PAYMENT = "PAYMENT",
  MENU = "MENU",
  ORDER_HISTORY = "ORDER_HISTORY",
  NOTIFICATIONS = "NOTIFICATIONS",
}
