import {
  GuideBookItem,
  GuideBookTableRow,
  Manufacturer,
  Unit,
  PurchasingPlace,
} from '@/modules/admin/purchasing-products/models/guideBookInterfaces';
import { PurchasingGuideBooks } from '@/modules/admin/purchasing-products/models/enums';
import {
  FilterObject,
  PurchasingList,
  PurchasingListRow,
} from '@/modules/admin/purchasing-products/models/listInterfaces';
import {
  PeriodType,
  ListFormed,
  ListStatus,
  IngredientGroupType,
} from '@/modules/admin/purchasing-products/models/enums';
import moment from 'moment';
import { FULLDATE_TIME_FORMAT } from '@/utils/data/moment-formats';
import IngredientsCategoryName from '@/modules/admin/models/records/IngredientsCategoryName';
import IngredientsCategory from '@/modules/admin/models/enums/IngredientsCategory';
import store from '@/store';
import encodeToBase64 from '@/modules/admin/dish-guidebook/composables/useFromImgToBase64';
import { elMessage } from '@/modules/order/models/elMessage';
import { SystemMessages, TypeMessages } from '@/modules/order/models/data/enums';
import { saveReceipts } from '@/modules/admin/purchasing-products/models/fetch-modules/saveReceipts';

//Returns data model according guide book type
export const makeSavingEntity = (
  tableRow: GuideBookTableRow
): Manufacturer | Unit | PurchasingPlace => {
  const guideBookItem: GuideBookItem = {
    id: tableRow.id,
    name: tableRow.name,
  };
  if (tableRow.guideBookType === PurchasingGuideBooks.MANUFACTURERS)
    return {
      ...guideBookItem,
      productCategory: tableRow.column2Value,
    } as Manufacturer;
  if (tableRow.guideBookType === PurchasingGuideBooks.PURCHASING_PLACES)
    return {
      ...guideBookItem,
      parentPlase: tableRow.column2Value,
    } as PurchasingPlace;
  if (tableRow.guideBookType === PurchasingGuideBooks.UNITS)
    return {
      ...guideBookItem,
      shortName: tableRow.column2Value,
    } as Unit;
  return tableRow;
};
export const isSavingAllowed = (tableRow: GuideBookTableRow): boolean => {
  if (
    tableRow.guideBookType === PurchasingGuideBooks.PURCHASING_PLACES &&
    tableRow.name.length >= 3 &&
    tableRow.name.length < 21
  )
    return true;
  if (
    tableRow.guideBookType === PurchasingGuideBooks.MANUFACTURERS &&
    tableRow.name.length >= 3 &&
    tableRow.name.length < 21 &&
    tableRow.column2Value !== ''
  )
    return true;
  if (
    tableRow.guideBookType === PurchasingGuideBooks.UNITS &&
    tableRow.name.length >= 3 &&
    tableRow.name.length < 21 &&
    tableRow.shortUnitName.length < 6 &&
    tableRow.shortUnitName.length > 1
  )
    return true;
  return false;
};

export const applyFilters = (
  tableData: Array<PurchasingList>,
  filterObject: FilterObject
): Array<PurchasingList> => {
  let tempTable = [...tableData];

  //Filters
  // -created date from
  if (filterObject.dateFrom && filterObject.periodType === PeriodType.CREATED_AT)
    tempTable = tempTable.filter(
      (row: PurchasingList) => moment(row.createdAt) >= moment(filterObject.dateFrom)
    );
  // -created date to
  if (filterObject.dateTo && filterObject.periodType === PeriodType.CREATED_AT)
    tempTable = tempTable.filter(
      (row: PurchasingList) => moment(row.createdAt) <= moment(filterObject.dateTo).add(1, 'day')
    );
  // - completed date from
  if (filterObject.dateFrom && filterObject.periodType === PeriodType.COMPLETED_AT)
    tempTable = tempTable.filter(
      (row: PurchasingList) =>
        row.status === ListStatus.DONE && moment(row.completedAt) >= moment(filterObject.dateFrom)
    );

  // - completed date to
  if (filterObject.dateTo && filterObject.periodType === PeriodType.COMPLETED_AT)
    tempTable = tempTable.filter(
      (row: PurchasingList) =>
        row.status === ListStatus.DONE &&
        moment(row.completedAt) <= moment(filterObject.dateTo).add(1, 'day')
    );
  // - list status
  if (filterObject.listStatus && filterObject.listStatus !== ListStatus.NOT_SELECTED)
    tempTable = tempTable.filter((row: PurchasingList) => row.status === filterObject.listStatus);
  // - list formed
  if (filterObject.listFormed && filterObject.listFormed !== ListFormed.NOT_SELECTED)
    tempTable = tempTable.filter(
      (row: PurchasingList) => row.formationMethod === filterObject.listFormed
    );

  return tempTable;
};

//List rows table data forming
export const makeListRowsTableData = (
  listRows: PurchasingListRow[],
  groupType: IngredientGroupType
): Map<string, Map<number, PurchasingListRow>> => {
  const manufacturers = store.getters.getManufacturers;
  const units = store.getters.getUnits;
  const purchasingPlaces = store.getters.getPurchasingPlaces;
  const ingredients = store.getters.getIngredientMap;
  const tableData = new Map<string, Map<number, PurchasingListRow>>();

  const addRowToTable = (
    mapKey: string,
    newRow: PurchasingListRow,
    tableData: Map<string, Map<number, PurchasingListRow>>
  ) => {
    if (tableData.has(mapKey)) {
      tableData.get(mapKey)?.set(newRow.id!, newRow);
    } else {
      tableData.set(mapKey, new Map<number, PurchasingListRow>());
      tableData.get(mapKey)?.set(newRow.id!, newRow);
    }
  };

  const noPlaceIngredients = new Map<number, PurchasingListRow>();
  listRows.forEach((row) => {
    store.commit('nextRowLocalId');
    const newRow: PurchasingListRow = {
      ...row,
      id: store.getters.getRowLocalId,
      dateForDisplaying: row.date ? moment(row.date).format(FULLDATE_TIME_FORMAT) : '',
      ingredientName: ingredients.get(row.ingredientId as number)?.name ?? '',
      unitPrice: row.unitPrice
        ? row.unitPrice
        : ingredients.get(row.ingredientId as number)?.lastUnitPrice ?? null,
      ingredientCategory:
        IngredientsCategoryName[
          ingredients.get(row.ingredientId as number)?.category as IngredientsCategory
        ],
      manufacturerName: manufacturers.get(row.manufacturerId as number)?.name ?? '',
      unitName: units.get(row.unitId as number)?.name ?? '',
      purchasingPlaceName: purchasingPlaces.get(row.purchasingPlaceId as number)?.name ?? '',
    };
    let mapKey =
      groupType === IngredientGroupType.CATEGORY
        ? newRow.ingredientCategory
        : newRow.purchasingPlaceName;
    if (!mapKey) mapKey = IngredientGroupType.NO_PLACE;

    if (mapKey === IngredientGroupType.NO_PLACE) noPlaceIngredients.set(newRow.id!, newRow);
    else addRowToTable(mapKey, newRow, tableData);
  });
  if (noPlaceIngredients.size) tableData.set(IngredientGroupType.NO_PLACE, noPlaceIngredients);
  return tableData;
};

//Making purchased rows visible or not
export const filterPurchasedRows = (
  initialRows: Map<string, Map<number, PurchasingListRow>>
): Map<string, Map<number, PurchasingListRow>> => {
  const filteredRows = new Map<string, Map<number, PurchasingListRow>>();
  initialRows.forEach((innerMap, groupKey) => {
    filteredRows.set(groupKey, new Map<number, PurchasingListRow>());
    innerMap.forEach((listRow) => {
      if (!listRow.isPurchased) filteredRows.get(groupKey)?.set(listRow.id!, listRow);
    });
    if (!filteredRows.get(groupKey)?.size) filteredRows.delete(groupKey);
  });
  return filteredRows;
};

//ipPermanentAdding means pictures to be loaded to store if true or to temporary array if false
export const addReceiptPictures = (files: File[], isPermanentAdding = true) => {
  files.forEach(async (file: File) => {
    await encodeToBase64(file).then((data) => {
      if (isPermanentAdding) {
        store.commit('addSinglePictureToStore', data);
        store.commit('addReceipt', store.getters.getEditingReceipt);
      } else store.commit('addReceiptPicture', data);
    });
    await saveReceipts();
  });
  elMessage(SystemMessages.ADDED, TypeMessages.SUCCESS);
};
