import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2362a1d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "day" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "order"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_order_view = _resolveComponent("order-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dates, (date, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_ctx.dateItemClass(date)),
        key: index,
        onClick: ($event: any) => (_ctx.showOrderDescription(date))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", {
            class: _normalizeClass(["day-number", { night: _ctx.isNightSelect }])
          }, _toDisplayString(date.format("D")), 3),
          _createElementVNode("div", null, [
            (_ctx.isOrderIssued(date))
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.orderImage,
                  alt: "Issued order"
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true)
          ])
        ]),
        (_ctx.isOrderPresent(date))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("span", {
                class: _normalizeClass(["order-is-present", { night: _ctx.isNightSelect }])
              }, "Заказ", 2),
              _createElementVNode("span", {
                class: _normalizeClass(["order-price", { night: _ctx.isNightSelect }])
              }, "На сумму " + _toDisplayString(` ${_ctx.getOrderPrice(date)} ₽`), 3)
            ]))
          : _createCommentVNode("", true)
      ], 10, _hoisted_2))
    }), 128)),
    _createVNode(_component_order_view, {
      modelValue: _ctx.orderDescriptionVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.orderDescriptionVisible) = $event)),
      order: _ctx.viewedOrder,
      dishesByTypes: _ctx.dishTypes,
      onCloseOrderDescription: _ctx.closeOrderDescription
    }, null, 8, ["modelValue", "order", "dishesByTypes", "onCloseOrderDescription"])
  ]))
}