import { createStore } from "vuex"

//common
import iconMenu from "@/store/modules/common/icon-menu"
import toggleSwitchDayNight from "@/store/modules/common/toggle-day-nigh"
import loginFormModule from "@/store/modules/authorization/login-form"
import windowBreakpoints from "@/store/modules/common/window-breakpoints"
import userHandlingModule from "@/store/modules/common/user-handling"
import dateStore from "@/store/modules/common/date-store"
import feedbackModule from "@/store/modules/common/feedback"

//adminsItems
import dishGuidebook from "@/store/modules/admin/dish-guidebook"
import ingredientsGuidebook from "@/store/modules/admin/ingredients-guidebook"
import creatingMenu from "@/store/modules/admin/creating-menu"
import emojiList from "@/store/modules/admin/emoji-list"
import orderListModule from "@/store/modules/admin/order-list"
import purchaisingListsModule from "@/store/modules/admin/purchasing-products/purchasing-lists"
import listStoreModule from "@/store/modules/admin/purchasing-products/list-store"
import receiptsModule from "@/store/modules/admin/purchasing-products/receipts"
import orderIssuingModule from "@/store/modules/admin/order-issuing"
import appSettings from "@/store/modules/admin/app-settings"
import purchaisingGuideBooksModule from "@/store/modules/admin/purchasing-products/purchaising-guide-books"
import payment from "@/store/modules/admin/payment"
import paymentSettings from "@/store/modules/admin/payment-settings"
import editDish from "@/store/modules/admin/edit-dish"
//userItems
import dishesModule from "@/store/modules/user/dishes"
import orderHistoryModule from "@/store/modules/user/order-history"
import notificationsModule from "@/store/modules/user/notifications"
import likeRequestModule from "@/store/modules/user/like-request"
import commentRequestModule from "@/store/modules/user/comment-request"
import spareDishesModule from "@/store/modules/user/spare-dishes"
import orderHandlingModule from "@/store/modules/user/order-handling"

export default createStore({
  modules: {
    //common
    loginFormModule,
    iconMenu,
    toggleSwitchDayNight,
    windowBreakpoints,
    userHandlingModule,
    dateStore,
    feedbackModule,

    //Admin modules
    appSettings,
    creatingMenu,

    dishGuidebook,
    editDish,
    ingredientsGuidebook,

    orderIssuingModule,
    orderListModule,

    listStoreModule,
    purchaisingListsModule,
    purchaisingGuideBooksModule,
    receiptsModule,
    payment,
    paymentSettings,
    emojiList,

    //User modules
    dishesModule,
    orderHistoryModule,
    notificationsModule,
    likeRequestModule,
    commentRequestModule,
    spareDishesModule,
    orderHandlingModule,
  },
})
