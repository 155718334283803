import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d9d94d82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bottom-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toggle_checkbox = _resolveComponent("toggle-checkbox")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_dialog_window = _resolveComponent("dialog-window")!

  return (_openBlock(), _createBlock(_component_dialog_window, {
    "show-close": true,
    cancelButtonText: "Сбросить",
    okButtonText: "Сохранить",
    title: "Ингредиенты для блюда",
    width: "900px",
    disableCancelButton: _ctx.disableResetButton,
    onCancelClicked: _ctx.resetIngredients,
    onCloseDialog: _ctx.closeDialog,
    onOkClicked: _ctx.addIngredientsToDish
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_table, {
          data: _ctx.ingredientsTable,
          "table-layout": _ctx.tableLayout,
          "max-height": "60vh",
          class: _normalizeClass({ night: _ctx.isNightSelect })
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              align: "center",
              fixed: "",
              width: "80px"
            }, {
              default: _withCtx((scope) => [
                _createVNode(_component_toggle_checkbox, {
                  checked: scope.row.isIngredientInDish,
                  "onUpdate:checked": ($event: any) => ((scope.row.isIngredientInDish) = $event),
                  onClick: ($event: any) => (_ctx.handleIngredient(scope.row))
                }, null, 8, ["checked", "onUpdate:checked", "onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              "column-key": "name",
              fixed: "",
              label: "Название",
              "min-width": "100px",
              prop: "name",
              sortable: "",
              "word-wrap": "break-word"
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", {
                  class: "search",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
                }, [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.search,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                    placeholder: "Поиск по названию",
                    "suffix-icon": "Search",
                    type: "search",
                    class: _normalizeClass({ night: _ctx.isNightSelect })
                  }, null, 8, ["modelValue", "class"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              "filter-method": _ctx.filterHandlerCategory,
              filters: _ctx.categoryFilters,
              "column-key": "category",
              label: "Категория",
              prop: "category",
              sortable: ""
            }, null, 8, ["filter-method", "filters"]),
            _createVNode(_component_el_table_column, {
              "column-key": "unit",
              label: "Ед.",
              prop: "unit",
              width: "60px"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "Вес / Количество",
              width: "200px"
            }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_input, {
                  modelValue: scope.row.amount,
                  "onUpdate:modelValue": ($event: any) => ((scope.row.amount) = $event),
                  modelModifiers: { number: true },
                  class: _normalizeClass({ off: !(scope.row.isIngredientInDish), 'is-error': _ctx.ingredientWeightValidation(scope.row.isIngredientInDish, scope.row.amount), night: _ctx.isNightSelect }),
                  disabled: !(scope.row.isIngredientInDish),
                  placeholder: _ctx.setPlaceholder(scope.row.isIngredientInDish),
                  onChange: ($event: any) => (_ctx.addRemoveIngredient(scope.row))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "disabled", "placeholder", "onChange"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data", "table-layout", "class"])
      ])
    ]),
    _: 1
  }, 8, ["disableCancelButton", "onCancelClicked", "onCloseDialog", "onOkClicked"]))
}