
import { computed, defineComponent, PropType } from "vue";
import {useStore} from "vuex";

export default defineComponent({
  name: "CustomButton",
  props: {
    typeButton: {
      type: String as PropType<"reset" | "success">,
      default: "reset",
    },
  },
  setup(props) {
    const store = useStore()

    const isBtnReset = computed(() => props.typeButton === "reset")
    const isBtnSuccess = computed(() => props.typeButton === "success")
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    return {
      isBtnSuccess,
      isBtnReset,
      isNightSelect,
    }
  }
})

