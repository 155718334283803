
import { computed, defineComponent, PropType, ref } from "vue"
import { Emoji, ImageInterface } from "@/utils/data/interfaces"
import { useStore } from "vuex"
import {
  EmojiActionType,
  UploadImageSettings,
  useDishRules,
  useFromGetObjectImage
} from "@/modules/admin/AppSettings/models/utils"
import type { FormInstance } from 'element-plus'
import { compareObjects } from "@/utils/helpers/compareObjects"
import { UtilitPictures } from "@/modules/order/models/data/enums"
import { spaceValidator } from "@/utils/helpers/validators"
import { focusElement } from "@/utils/helpers/focusElement"

export default defineComponent({
  name: "EmojiHandling",
  props: {
    //Emoji for handling
    emoji: {
      type: Object as PropType<Emoji>,
      required: true,
    },
    //Tipe of required action - change or add
    action: {
      type: String as PropType<EmojiActionType>,
      default: "add"
    }
  },
  emits: ["closeDialog"],
  setup(props, { emit }) {
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    const emojiHandlingWidth = computed(() => store.getters.getBreakpoints.mobile ? "350px" : "500px")
    const title = computed(() => props.action === "add" ? "Добавление эмодзи" : "Редактирование эмодзи")
    const ruleFormRef = ref<FormInstance>()
    const formSize = ref("default")

    const handlingEmoji = ref({ ...props.emoji } as Emoji)
    const setHandlingEmoji = () => handlingEmoji.value = { ...props.emoji }
    const validateName = () => handlingEmoji.value.name = spaceValidator(handlingEmoji.value.name)

    //Buttons handling
    const isResetButtonDisabled = computed(() => compareObjects(
      {
        ...handlingEmoji.value,
        picture: imageData.value.imageInBase64
      },
      { ...props.emoji }, true))

    const isSaveButtonDisabled = computed(() => isResetButtonDisabled.value
      || (handlingEmoji.value.name as string).length < 3
      || (handlingEmoji.value.name as string).length > 20
      || !imageData.value.imageLink
      || imageData.value.imageHasSize)

    const saveClicked = async () => {
      if (imageData.value.imageLink && !imageData.value.imageHasSize) {
        emit("closeDialog")
        if (props.action === "add") {
          const savingEmoji = {
            name: handlingEmoji.value.name,
            picture: imageData.value.imageInBase64
          }
          await store.dispatch("saveEmoji", savingEmoji)
          await store.dispatch("getEmoji")
        } else {
          const changingEmoji = {
            id: props.emoji.id,
            name: handlingEmoji.value.name,
            picture: imageData.value.imageInBase64
          }
          await store.dispatch("changeEmoji", changingEmoji)
        }
      }
    }

    const resetForm = () => {
      handlingEmoji.value = { ...props.emoji }
      imageData.value = setInitialImage()
    }

    //Image handling
    const setInitialImage = (): ImageInterface => {
      return props.action === 'add'
        ? {} as ImageInterface
        : {
          imageInBase64: props.emoji.picture,
          imageLink: props.emoji.picture
        } as ImageInterface
    }

    const imageData = ref(setInitialImage())

    const uploadImage = async (event: InputEvent) => {
      useFromGetObjectImage(event).then(data => {
        imageData.value = { ...data }
      })
    }
    //Dialog handling
    const closeDialog = () => emit("closeDialog")
    const dialogOpen = () => {
      setHandlingEmoji()
      imageData.value = setInitialImage()
      focusElement("name")
    }
    const dialogClosed = () => imageData.value = {} as ImageInterface

    return {
      isNightSelect,
      emojiHandlingWidth,
      title,
      ruleFormRef,
      formSize,
      handlingEmoji,
      setHandlingEmoji,
      validateName,
      ...useDishRules(),
      closeDialog,
      isResetButtonDisabled,
      isSaveButtonDisabled,
      resetForm,
      saveClicked,
      UploadImageSettings,
      UtilitPictures,
      uploadImage,
      imageData,
      dialogOpen,
      dialogClosed
    }
  }
})
