import {
  PeriodType,
  ListStatus,
  ListFormed,
} from "@/modules/admin/purchasing-products/models/enums"

export interface FilterObject {
  periodType: PeriodType
  dateFrom: string
  dateTo: string
  listStatus: ListStatus
  listFormed: ListFormed
}

//Purchasing list interfaces
export interface PurchasingList {
  id?: number
  createdAt?: string
  createdAtForDisplaying?: string
  completedAt?: string
  completedAtForDisplaying?: string
  dailyNumber?: number
  formationMethod: string
  translatedFormationMethod?: string
  authorId?: number
  authorName?: string
  executorIds?: Array<number>
  status: string
  translatedStatus?: string
  price?: number
  receiptPictureIds?: number[]
}

export interface Receipt {
  id?: number
  mapId?: number
  purchasingListId?: number
  date?: string
  dateForDisplaying?: string
  paymentMethod?: string
  purchasingPlaceId?: number
  purchasingPlaceName?: string
  price?: number
  executorId?: number
  note?: string
  pictureIds?: Array<number>
}

export interface ReceiptPicture {
  id?: number
  picture?: string
  receiptId?: number
  purchasingListId?: number
}

export interface PurchasingListRow {
  id?: number
  purchasingListId?: number
  date?: string
  dateForDisplaying?: string
  ingredientId?: number
  ingredientName?: string
  ingredientCategory?: string
  manufacturerId?: number
  manufacturerName?: string
  amount?: number
  unitId?: number
  unitName?: string
  unitPrice?: number
  purchasingPlaceId?: number
  purchasingPlaceName?: string
  isPurchased: boolean
  note?: string
  isCopy?: boolean
}

export enum UploadListType {
  ADMIN = "admin",
  BUYER = "buyer",
}
