export const handbooks = [
    {
        label: 'Блюда',
        variants: [
            {label: 'Справочник', route: '/GuideDishes', name: 'guideDishes'},
            {label: 'Архив', route: '/ArchiveDeletedDish', name: 'archiveDeletedDish'},
        ]
    },
    {
        label: 'Ингредиенты',
        variants: [
            {label: 'Справочник', route: '/GuideIngredients', name: 'guideIngredients'},
            {label: 'Архив', route: '/ArchiveDeletedIngredient', name: 'archiveIngredients'},
        ]
    }
]
