
import { defineComponent, ref, computed, watch } from "vue"
import SpareDishes from "@/modules/order/components/spare-dishes/SpareDishes.vue"
import DishCard from "@/modules/order/components/lunch-ordering/DishCard.vue"
import SaveResetButtons from "@/modules/order/components/lunch-ordering/SaveResetButtons.vue"
import Feedback from "@/modules/order/components/feedback/Feedback.vue"
import { Order, OrderedDish } from "@/utils/data/interfaces"
import {
  MenuType,
  DailyMenu,
  DishType
} from "@/modules/order/models/dishInterfaces"
import {
  checkOrderedDishes,
  checkIssuedSpareOrders,
  clearDailyMenu,
} from "@/modules/order/models/dishMenuParser"
import { SystemMessages, MenuTypeImages } from "@/modules/order/models/data/enums"
import { useStore } from "vuex"
import moment from "moment"
import { FULLDATE_FORMAT, DAYS_MONTHS_FORMAT } from "@/utils/data/moment-formats"
import { TimeSettingsInterface } from "@/utils/data/interfaces"
import { WeekConstants } from "@/utils/data/constants"

export default defineComponent({
  name: "LunchOrdering",
  components: {
    SpareDishes,
    DishCard,
    SaveResetButtons,
    Feedback
  },
  setup() {
    const store = useStore()
    const userId = store.getters.getUserId
    //Buttons amount is necessary for spare dishes buttons rendering according to the width of the calendar
    const calendarButtonsAmount = ref(5)
    const calendarButtonsGenerated = (amount: number) => {
      calendarButtonsAmount.value = amount
    }

    const menu = ref(new Map<string, DailyMenu>())
    const menuTypeImages = Object.values(MenuTypeImages)
    //Dates definition
    let currentDate = store.getters.getCurrentDate.hours(12)
    const spareDishesDate = ref(currentDate.toISOString())
    const activeDate = ref(currentDate.format(FULLDATE_FORMAT))
    const defineDates = async () => {
      if (localStorage.getItem("lastClickedDate")) currentDate = moment(localStorage.getItem("lastClickedDate"))
      else currentDate = store.getters.getCurrentDate
      spareDishesDate.value = currentDate.hours(12).toISOString()
      activeDate.value = currentDate.format(FULLDATE_FORMAT)
    }

    //Disables save, clear buttons and dish counters in menu
    const disableDishMenu = ref(true)
    //The time when user can place an order from spare dishes 
    const spareOrderTime = ref(disableDishMenu.value)
    //Date after today with formed dish menu
    const tomorrowDateCalculated = (tomorrowDate: moment.Moment) => {
      disableDishMenu.value = !isOrderTime(moment(activeDate.value, FULLDATE_FORMAT), tomorrowDate)
      spareOrderTime.value = disableDishMenu.value
    }

    //Menu card doesn't display if it's empty
    const dishCardClass = (dishTypes: Array<DishType>): string => {
      if (!!dishTypes.find(dishType => !!dishType.dishes.length)) return "dish-card"
      else return "hide"
    }

    //Order time limit dedinition
    const orderTimeLimit = ref(moment("23:59", "HH:mm"))
    const setOrderTimeLimit = async () => {
      await store.dispatch("getInstalledTimeSettings")
      orderTimeLimit.value = moment((store.getters.getTimeSetting as Array<TimeSettingsInterface>).find((limitType) => limitType.type === "ORDERTIMELIMIT")?.timeSetting)
    }
    setOrderTimeLimit()

    //Dish menu definition
    const dailyTitle = ref("")
    const dishMenu = ref([] as Array<MenuType>)
    const isDishMenuAbsent = ref(false)
    const daysWithFormedDishMenu = ref(new Set<string>())

    const setDailyMenu = (menu: Map<string, DailyMenu>, date: string) => {
      dishMenu.value = menu.get(date)?.menu as Array<MenuType>
      dailyTitle.value = `на ${date}`
    }

    const defineDishMenu = async () => {
      defineDates()

      let dateFrom = currentDate.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString()
      let dateTo = moment(dateFrom).add(1, "days").toISOString()

      await store.dispatch("downloadDishMenu", { dateFrom, dateTo })
      dateFrom = moment().day(WeekConstants.MONDAY).add(1).hours(0).minutes(0).seconds(0).milliseconds(0).toISOString()
      dateTo = moment(dateFrom).add(30, "days").toISOString()

      await store.dispatch("downloadDaysWithFormedDishMenu", { dateFrom, dateTo })

      daysWithFormedDishMenu.value = store.getters.getDaysWithFormedDishMenu
      menu.value = store.getters.getDishMenu

      if (daysWithFormedDishMenu.value.has(activeDate.value)) {
        setDailyMenu(menu.value, activeDate.value)
        isDishMenuAbsent.value = false
      }
      else isDishMenuAbsent.value = true

      store.dispatch("downloadSpareDishes", spareDishesDate.value)
    }
    defineDishMenu()

    watch(() => store.getters.getCurrentDate, () => defineDishMenu())
    //Orders definition
    const daysWithOrder = computed(() => store.getters.getDaysWithOrder)
    const userOrders = computed(() => store.getters.getUserOrders)
    const unformedOrderDates = computed(() => store.getters.getUnformedOrderDates)


    const defineUserOrders = async () => {
      const dateFrom = moment().day(1).hours(0).minutes(0).seconds(0).milliseconds(0).toISOString()
      const dateTo = moment(dateFrom).add(15, "days").toISOString()
      await store.dispatch("downloadUserOrders", { userId, dateFrom, dateTo })
      checkIssuedSpareOrders(userOrders.value)
      checkUnformingTime()
      updateDailyMenu(activeDate.value)
    }
    defineUserOrders()

    // Checking dish menu to put dish's amount and rerendering components
    const orderedDishesChecked = computed(() => {
      const isDownloaded = store.getters.isOrdersDownloaded && store.getters.isDishMenuDownloaded
      if (isDownloaded) updateDailyMenu(activeDate.value)
      return isDownloaded
    })

    //Order in clicked date. If there is no order in clicked date dishes array is empty
    const currentOrder = computed((): Order => {
      let currentOrder: Order = {
        userId: userId,
        orderDate: moment(activeDate.value, FULLDATE_FORMAT).hours(12).minutes(0).toISOString(),
        isIssued: false,
        isSpareOrder: false,
        dishes: []
      }
      if (userOrders.value.has(activeDate.value)) currentOrder = { ...(userOrders.value.get(activeDate.value) as Order) }
      return currentOrder
    })
    //Time when user can unform his order
    const isUnformingTime = ref(false)
    const checkUnformingTime = () => {
      isUnformingTime.value = (disableDishMenu.value && userOrders.value.has(activeDate.value))
      if (isUnformingTime.value) {
        isUnformingTime.value = !(currentOrder.value.isSpareOrder as boolean)
      }
      if (moment(activeDate.value, FULLDATE_FORMAT).hours(12) < moment().hours(0).minutes(0)) isUnformingTime.value = false
      if (currentOrder.value.isIssued) isUnformingTime.value = false
    }

    const dateButtonClick = async (
      clickedDate: moment.Moment,
      tomorrowDate: moment.Moment) => {
      //If daily menu wasn't downloaded earler, it downloads
      if (!menu.value.has(clickedDate.format(FULLDATE_FORMAT))) {
        const dateFrom = clickedDate.hours(0).minutes(0).seconds(0).milliseconds(0).toISOString()
        const dateTo = moment(dateFrom).add(1, "days").toISOString()
        await store.dispatch("downloadDishMenu", { dateFrom, dateTo })
        menu.value = store.getters.getDishMenu
      }
      activeDate.value = clickedDate.format(FULLDATE_FORMAT)
      dishMenu.value = menu.value.get(activeDate.value)?.menu as Array<MenuType>
      isDishMenuAbsent.value = !dishMenu.value.length
      disableDishMenu.value = !isOrderTime(clickedDate, tomorrowDate)
      spareOrderTime.value = disableDishMenu.value
      spareDishesDate.value = clickedDate.hours(12).toISOString()
      if (disableDishMenu.value && !(clickedDate < moment().hours(0))) {
        store.dispatch("downloadSpareDishes", spareDishesDate.value)
      }
      dailyTitle.value = "на " + activeDate.value
      checkUnformingTime()
      updateDailyMenu(activeDate.value)
    }

    //Order handling
    const changeOrder = async (orderForDB: Order) => {
      const isSpareDishes = false
      const userAdditionalRole = store.getters.getUserAdditionalRole
      await store.dispatch("changeUserOrder", { orderForDB, isSpareDishes, userAdditionalRole })
    }
    const saveOrder = async (orderForDB: Order) => {
      const isSpareDishes = false
      const userAdditionalRole = store.getters.getUserAdditionalRole
      await store.dispatch("saveUserOrder", { orderForDB, isSpareDishes, userAdditionalRole })
    }
    const deleteOrder = async (orderDate: string) => {
      const userAdditionalRole = store.getters.getUserAdditionalRole
      await store.dispatch("deleteUserOrder", { userId, orderDate, userAdditionalRole })
      clearDailyMenu(store.getters.getDailyDishes(activeDate.value))
    }
    const unformOrder = async (orderDate: string) => {
      const tempDate = moment(orderDate).format(FULLDATE_FORMAT)
      const order = userOrders.value.get(tempDate) as Order
      order.userId = userId
      order.isIssued = false
      order.canceledAt = moment().toISOString()
      await store.dispatch("unformOrder", order)
      checkUnformingTime()
      clearDailyMenu(store.getters.getDailyDishes(activeDate.value))
    }
    const saveSpareDishesOrder = async (confirmedOrder: Order) => {
      const order: Order = {
        userId: confirmedOrder.userId,
        orderDate: confirmedOrder.orderDate,
        isIssued: confirmedOrder.isIssued,
        dishes: []
      }
      if (userOrders.value.has(moment(confirmedOrder.orderDate).format(FULLDATE_FORMAT))) {
        const existingOrder = userOrders.value.get(moment(confirmedOrder.orderDate).format(FULLDATE_FORMAT)) as Order

        order.orderDate = existingOrder.orderDate
        order.dishes = existingOrder.dishes
        order.isInPlastic = existingOrder.isInPlastic
        //-map for fast dish getting
        const dishesInOrderMap = new Map<number, OrderedDish>()
        order.dishes.forEach((dish) => {
          dishesInOrderMap.set(dish.dishId, dish)
        })

        confirmedOrder.isInPlastic = existingOrder.isInPlastic
        confirmedOrder.dishes.forEach((dish) => {
          if (dishesInOrderMap.has(dish.dishId)) {
            (dishesInOrderMap.get(dish.dishId) as OrderedDish).amount += dish.amount
          } else order.dishes.push(dish)
        })
      } else {
        order.dishes = confirmedOrder.dishes
      }
      order.isSpareOrder = true
      currentOrder.value.isSpareOrder = true
      const tempDate = moment(order.orderDate).format(FULLDATE_FORMAT)
      store.commit("addOrder", order)
      await store.dispatch("makeOrder", confirmedOrder).then(() => store.dispatch("downloadSpareDishes", spareDishesDate.value))
      checkUnformingTime()
      updateDailyMenu(tempDate)
    }
    //Time when user can make order
    const isOrderTime = (clickedDate: moment.Moment, tomorrowDate: moment.Moment) => {
      const currentDate = moment()
      if (
        clickedDate.format(DAYS_MONTHS_FORMAT) === currentDate.format(DAYS_MONTHS_FORMAT) ||
        (tomorrowDate.format(DAYS_MONTHS_FORMAT) === clickedDate.format(DAYS_MONTHS_FORMAT) && currentDate >= orderTimeLimit.value)
      ) return false
      else if (tomorrowDate.format(DAYS_MONTHS_FORMAT) === clickedDate.format(DAYS_MONTHS_FORMAT) && (currentDate.day() === 0 || currentDate.day() === 6)) return false
      else if (moment(activeDate.value, FULLDATE_FORMAT) < currentDate) return false
      else return true
    }

    const deleteOrderButtonDisabled = computed(() => !userOrders.value.has(activeDate.value))

    const updateDailyMenu = (date: string) => {
      if (userOrders.value.has(date)) {
        checkOrderedDishes(userOrders.value.get(date) as Order, store.getters.getDailyDishes(date))
      } else {
        clearDailyMenu(store.getters.getDailyDishes(date))
      }
    }
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    return {
      orderedDishesChecked,
      calendarButtonsAmount,
      calendarButtonsGenerated,
      menuTypeImages,
      spareDishesDate,
      SystemMessages,
      isDishMenuAbsent,
      dishCardClass,
      userOrders,
      unformedOrderDates,
      dailyTitle,
      tomorrowDateCalculated,
      disableDishMenu,
      spareOrderTime,
      dishMenu,
      daysWithFormedDishMenu,
      daysWithOrder,
      activeDate,
      currentOrder,
      isUnformingTime,
      dateButtonClick,
      changeOrder,
      saveOrder,
      deleteOrder,
      unformOrder,
      saveSpareDishesOrder,
      deleteOrderButtonDisabled,
      isNightSelect,
    }
  },
}) 
