
import {computed, defineComponent} from "vue"
import moment from "moment"
import {useStore} from "vuex";

export default defineComponent({
    name: "WeekdayItem",
    props: {
        //Text in the item
        text: {
            type: String,
            default: ""
        },
    },
    setup() {
        const store = useStore()
        moment.locale("ru")
        const weekdays = moment.weekdays().filter((day) => day !== "воскресенье")
        const isNightSelect = computed(() => store.getters.getIsSelectedNight)
        return {
            weekdays,
            isNightSelect
        }
    }
})
