
import { defineComponent, ref, computed } from "vue"
import { Payment } from "@/utils/data/interfaces"
import { useStore } from "vuex"
import PaymentHandling from "@/modules/admin/AppSettings/components/PaymentHandling.vue"

export default defineComponent({
    name: "PaymentSettings",
    components: { PaymentHandling },
    setup() {
        const store = useStore()
        const downloadPayment = async () => {
            await store.dispatch("downloadPayment")
        }
        downloadPayment()
        const paymentTableData = computed<Array<Payment>>(() => store.getters.getPaymentMap.size ? Array.from(store.getters.getPaymentMap.values()) : [])
        const currentPayment = ref({
            name: "",
            details: "",
            phone: ""
        } as Payment)
        //Deleting payment
        const deleteDialogVisible = ref(false)
        const showDeleteDialog = () => deleteDialogVisible.value = true
        const hideDeleteDialog = () => deleteDialogVisible.value = false

        const confirmMessage = ref([""])

        const paymentHandlingVisible = ref(false)
        const showPaymentHandling = () => paymentHandlingVisible.value = true
        const hidePaymentHandling = () => paymentHandlingVisible.value = false

        const deletePayment = (payment: Payment) => {
            currentPayment.value = payment
            confirmMessage.value = [`Вы хотите удалить оплату ${payment.name}`, `${payment.details}?`]
            showDeleteDialog()
        }
        const deletingConfirmed = () => {
            hideDeleteDialog()
            store.dispatch("deletePayment", currentPayment.value)

        }
        //Changing payment
        const editPayment = (payment: Payment) => {
            currentPayment.value = payment
            showPaymentHandling()
        }
        //Adding payment
        const addPayment = () => {
            currentPayment.value = {
                name: "",
                details: "",
                phone: ""
            }
            showPaymentHandling()
        }
        const isNightSelect = computed(() => store.getters.getIsSelectedNight)
        return {
            isNightSelect,
            paymentTableData,
            currentPayment,
            deleteDialogVisible,
            showDeleteDialog,
            hideDeleteDialog,
            confirmMessage,
            paymentHandlingVisible,
            hidePaymentHandling,
            deletingConfirmed,
            editPayment,
            deletePayment,
            addPayment,
        }
    }
}) 
