
import { defineComponent, ref, computed } from "vue"


export default defineComponent({
    name: "AutoOrdering",
    components: {},
    setup() {

        return {

        }


    },
})
