
import {computed} from "@vue/runtime-core";
import {defineComponent, PropType} from "vue";
import {RoundCheckbox} from "@/modules/order/models/data/enums";

export default defineComponent({
  name: "RoundCheckbox",
  inheritAttrs: false,
  props: {
    label: {type: String, required: false},
    checked: {type: Boolean , default: false},
    disabled: {type: Boolean, required: false}
  },
  setup(props) {
    const iconUrl = computed(() => props.checked ? RoundCheckbox.CHECK : RoundCheckbox.UNCHECK)
    return {
      iconUrl
    }
  }

})
