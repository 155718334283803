
import { computed, defineComponent, PropType, ref } from "vue";
import { IconButtons } from "@/modules/order/models/data/enums"
import { SwitcherType } from "@/modules/admin/purchasing-products/models/enums"
import store from "@/store";

export default defineComponent({
    name: "IconSwitcher",
    props: {
        type: {
            type: String as PropType<"switch-filters" | "switch-buttons" | "switch-ingredients-group" | "switch-visible">,
            required: true
        },
    },
    emits: ["switched"],
    setup(props, { emit }) {
        const isNightSelect = computed(() => store.getters.getIsSelectedNight)
        const isSwitched = ref(!!JSON.parse(localStorage.getItem(props.type) as string))

        const iconUrl = computed(() => {
            switch (props.type) {

                case SwitcherType.FILTER:
                    return isSwitched.value ? IconButtons.HIDE_FILTERS : IconButtons.SHOW_FILTERS;
                case SwitcherType.BUTTONS:
                    return isSwitched.value ? IconButtons.LEFT : IconButtons.RIGHT;
                case SwitcherType.INGREDIENT_GROUP:
                    if (isNightSelect.value) return isSwitched.value
                        ? IconButtons.INGREDIENTS_GROUP_NIGHT
                        : IconButtons.PURCHASING_PLACES_GROUP_NIGHT;
                    else return isSwitched.value
                        ? IconButtons.INGREDIENTS_GROUP
                        : IconButtons.PURCHASING_PLACES_GROUP;
                case SwitcherType.VISIBLE:
                    return isSwitched.value ? IconButtons.SHOW_PURCHASED : IconButtons.HIDE_PURCHASED;
                default:
                    return '';
            }
        })

        const switchImage = () => {
            isSwitched.value = !isSwitched.value
            localStorage.setItem(props.type, JSON.stringify(isSwitched.value))
            emit("switched", isSwitched.value)
        }

        return {
            iconUrl,
            switchImage
        }
    }
})

