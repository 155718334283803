import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e3b305ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([_ctx.arrowButtonClass, { night: _ctx.isNightSelect }]),
    disabled: _ctx.disabled
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.arrowWrapperClass)
    }, [
      _createElementVNode("img", {
        src: _ctx.arrowImage,
        alt: ""
      }, null, 8, _hoisted_2)
    ], 2)
  ], 10, _hoisted_1))
}