import { Module } from "vuex"
import { elMessage } from "@/modules/order/models/elMessage"
import { SystemMessages, TypeMessages } from "@/modules/order/models/data/enums"
import {
  SpareDish,
  CheckedAvailability,
  DishIdentity,
} from "@/modules/order/models/dishInterfaces"
import {
  downloadSpareDishes,
  checkAvailability,
  getIdentification,
} from "@/modules/order/models/fetchModules/fetchModuleSpareDishes"
import { addSpareDishes } from "@/modules/admin/orders-issuing/models/fetch-modules/fetchModuleAddSpare"

export const spareDishesModule: Module<any, any> = {
  state: {
    spareDishes: [] as Array<SpareDish>,
    spareDishesDownloaded: false,
    dishAvailability: [] as Array<CheckedAvailability>,
    dishAvailabilityDownloaded: false,
    spareDishesIdentification: [] as Array<DishIdentity>,
  },
  getters: {
    getSpareDishes(state) {
      return state.spareDishes
    },
    isSpareDishesDownloaded(state) {
      return state.spareDishesDownloaded
    },
    isDishAvailabilityDownloaded(state) {
      return state.dishAvailabilityDownloaded
    },
    getDishAvailability(state) {
      return state.dishAvailability
    },
    getSpareDishesIdentification(state) {
      return state.spareDishesIdentification
    },
  },
  mutations: {
    fetchSpareDishes(state, data) {
      state.spareDishes = data.data as Array<SpareDish>
      state.spareDishesDownloaded = !!state.spareDishes.length
    },
    fetchDishAvailability(state, data) {
      state.dishAvailability = data.data
      state.dishAvailabilityDownloaded = true
    },
    fetchSpareDishesIdentification(state, data) {
      state.spareDishesIdentification = data.data
    },
  },
  actions: {
    downloadSpareDishes: async ({ commit }, spareDishesDate) => {
      const res = await downloadSpareDishes(spareDishesDate)
      if (res.ok) {
        const data = await res.json()
        commit("fetchSpareDishes", data)
      } else
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.WARNING)
    },
    checkAvailability: async ({ commit }, { dishIds, spareDishesDate }) => {
      const res = await checkAvailability(dishIds, spareDishesDate)
      if (res.ok) {
        const data = await res.json()
        commit("fetchDishAvailability", data)
      } else
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.WARNING)
    },
    addSpareDishes: async ({ commit }, spareDishes) => {
      const res = await addSpareDishes(spareDishes)
      if (res.ok) {
        elMessage(SystemMessages.SPERE_DISHES_ADDED, TypeMessages.SUCCESS)
      } else
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.WARNING)
    },
    getIdentification: async ({ commit }, { userId, date }) => {
      const res = await getIdentification(userId, date)
      if (res.ok) {
        const data = await res.json()
        commit("fetchSpareDishesIdentification", data)
      } else
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.WARNING)
    },
  },
}
export default spareDishesModule
