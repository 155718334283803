import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_time_picker = _resolveComponent("el-time-picker")!

  return (_openBlock(), _createBlock(_component_el_time_picker, {
    editable: false,
    clearable: false,
    size: "small",
    format: "HH:mm",
    "disabled-hours": _ctx.disabledHours
  }, null, 8, ["disabled-hours"]))
}