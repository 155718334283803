
import { defineComponent, ref, watch, computed } from "vue"
import { Dish } from "@/utils/data/interfaces"
import { Order, OrderedDish } from "@/utils/data/interfaces"
import { makeDishMenu, clearDishMenu } from "@/modules/admin/orders-issuing/models/orderParser"
import { useStore } from "vuex"
import moment from "moment"

export default defineComponent({
    name: "AddSpareDishesDialog",

    emits: ["hideAddSpareDishes"],
    setup(props, { emit }) {
        const store = useStore()
        const isNightSelect = computed(() => store.getters.getIsSelectedNight)
        const userId = store.getters.getUserId
        //This dialog
        const hideAddSpareDishes = () => {
            emit("hideAddSpareDishes")
            clearDishMenu(dishMenu.value)
        }
        //Dish menu includes all dishes for this day
        const dishMenu = ref([] as Array<Dish>)
        watch(() => store.getters.getDishesForMenu, () => dishMenu.value = makeDishMenu(store.getters.getDishesForMenu))
        //Disabling add button if no one dish selected
        const addSpareDishesDisabled = computed(() => !dishMenu.value.find(dish => !!dish.amount))
        //Confirmation with selected dishes
        const orderMessage = ref([] as Array<string>)
        const addSpareDishesDialogVisible = ref(false)
        const showAddSpareDishesDialog = () => {
            orderMessage.value = []
            dishMenu.value.forEach((dish) => {
                if (dish.amount !== 0) orderMessage.value.push(`${dish.name} - ${dish.amount} шт`)
            })
            addSpareDishesDialogVisible.value = true
        }
        const hideAddSpareDishesDialog = () => addSpareDishesDialogVisible.value = false
        //Adding spare dishes
        const addSpareDishes = async () => {
            hideAddSpareDishesDialog()
            const dateFrom = moment().hours(0).minutes(0).seconds(0).milliseconds(0).toISOString()
            const dateTo = moment(dateFrom).add(1, "days").toISOString()
            await store.dispatch("checkAdminOrder", { userId, dateFrom, dateTo })
            const addedDishes: OrderedDish[] = []
            dishMenu.value.forEach((dish) => {
                if (dish.amount !== 0) addedDishes.push({
                    dishId: dish.dishId,
                    dishName: dish.name,
                    price: dish.price,
                    amount: dish.amount!,
                    isCanceled: true,
                    issueAmount: 0,
                    isIssued: false,
                    isAdmin: true
                })
            })
            const orderForDB: Order = {
                userId: userId,
                orderDate: moment().hours(12).minutes(0).seconds(0).milliseconds(0).toISOString(),
                isIssued: false,
                dishes: [],
                canceledAt: moment().hours(23).minutes(0).seconds(0).toISOString()
            }
            const isSpareDishes = true
            const userAdditionalRole = store.getters.getUserAdditionalRole
            const userRole = store.getters.getUserRole
            if (store.getters.isAdminOrderPresent) {
                const existingOrder = store.getters.getAdminOrder
                orderForDB.orderDate = existingOrder.orderDate
                orderForDB.dishes = addedDishes.concat(existingOrder.dishes)
                store.dispatch("changeUserOrder", { orderForDB, isSpareDishes, userAdditionalRole, userRole })
            } else {
                orderForDB.dishes = addedDishes
                store.dispatch("saveUserOrder", { orderForDB, isSpareDishes, userAdditionalRole})
            }
            hideAddSpareDishes()
        }

        return {
            isNightSelect,
            hideAddSpareDishes,
            dishMenu,
            addSpareDishesDisabled,
            orderMessage,
            showAddSpareDishesDialog,
            hideAddSpareDishesDialog,
            addSpareDishesDialogVisible,
            addSpareDishes,
        }
    }
})
