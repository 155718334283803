
import { computed, defineComponent, onMounted, PropType, ref } from "vue"
import CalendarButton from "@/common/components/buttons/CalendarButton.vue"
import moment from "moment"
import { DAYS_MONTHS_FORMAT, FULLDATE_FORMAT } from "@/utils/data/moment-formats"
import { Order } from "@/utils/data/interfaces"
import { WeekConstants } from "@/utils/data/constants"
import { useStore } from "vuex";


export default defineComponent({
  name: "OrderCalendar",
  components: {
    CalendarButton,
  },
  props: {
    // Array with days where the dish menu is presents
    daysWithFormedDishMenu: {
      type: Set as PropType<Set<string>>,
      default: new Set<string>(),
    },
    //  Array with days where the current user made an order
    daysWithOrder: {
      type: Set as PropType<Set<string>>,
      default: new Set<string>(),
    },
    // If true calendar can be scrolled to the past
    pastAvailability: {
      type: Boolean,
      default: false
    },
    // Makes date selector visible
    dateSelector: {
      type: Boolean,
      dafault: false
    },
    //Makes period selector visible
    periodSelector: {
      type: Boolean,
      default: false
    },
    //Define if tooltip will be shown
    showTooltip: {
      type: Boolean,
      default: false
    },
    //User orders
    orders: {
      type: Object as PropType<Map<string, Order>>,
      default: new Map<string, Order>()
    }
  },
  emits: ["dateButtonClick", "selectorChanged", "periodToChanged", "leftScroll", "rightScroll", "buttonsGenerated", "tomorrowDateCalculated"],
  setup(props, { emit }) {
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)

    moment.locale("ru")

    //Tooltip description
    const tooltipPosition = (index: number): string => {
      if (index < WeekConstants.TUESDAY) return "bottom-start"
      else if (index === WeekConstants.TUESDAY) return "bottom"
      else return "bottom-end"
    }
    const tooltipContent = (date: string): Array<{ name: string, amount: number }> => {
      const tooltipText = [] as Array<{ name: string, amount: number }>
      if (props.orders.has(moment(date).format(FULLDATE_FORMAT))) {
        props.orders.get(moment(date).format(FULLDATE_FORMAT))?.dishes.forEach((dish) => {
          tooltipText.push({ name: dish.dishName, amount: dish.amount })
        })
      }
      return tooltipText
    }
    const orderPrice = (date: string): number => {
      let orderPrice = 0
      if (props.orders.has(moment(date).format(FULLDATE_FORMAT))) {
        props.orders.get(moment(date).format(FULLDATE_FORMAT))?.dishes.forEach((dish) => {
          orderPrice += dish.amount * dish.price
        })
      }
      return orderPrice
    }
    const tooltipDisabled = (date: string) => !props.showTooltip || !props.orders.get(moment(date).format(FULLDATE_FORMAT))?.dishes.length
    //Dates assignment
    const currentDate = ref(moment().toISOString())
    //-date was chosen in date picker
    const chosenDate = ref(currentDate.value)
    //-dates were chosen in period selector
    const periodFrom = ref(currentDate.value)
    const periodTo = ref(currentDate.value)

    const isThereFartherMenu = (date: string): boolean => {
      let isThereFartherMenu = false
      props.daysWithFormedDishMenu.forEach((day) => {
        if (moment(day, FULLDATE_FORMAT) > moment(date)) isThereFartherMenu = true
      })
      return isThereFartherMenu
    }
    // This is the next day after today with formed dish menu
    const tomorrowDate = computed(() => {
      let tomorrowDate = moment().add(1, "days")

      if (isThereFartherMenu(currentDate.value)) {
        while (!props.daysWithFormedDishMenu.has(tomorrowDate.format(FULLDATE_FORMAT))) {
          tomorrowDate.add(1, "days")
        }
      }
      if (moment().day() === WeekConstants.SATURDAY || moment().day() === WeekConstants.SUNDAY) tomorrowDate = moment()
      return tomorrowDate
    })

    const startDate = ref(moment().day(1).toISOString())
    // DeltaDate is the number of days by which the calendar is shifted
    const deltaDate = ref(0)
    // getDays returns array with days for calendar, and changes after scrolling(pushing arrow buttons)
    const getDays = computed(() => {
      const firstDate = moment(startDate.value)
      let days = [] as Array<{ date: string, weekDay: string, isoDate: string }>
      for (let index = 0; index < 6; index++) {
        firstDate
          .hours(12)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
        days.push({
          date: firstDate.format(DAYS_MONTHS_FORMAT),
          weekDay: moment.weekdaysMin()[firstDate.day()],
          isoDate: firstDate.toISOString()
        })
        firstDate.add(1, "days")
      }
      const daysAmount = isButtonDisplayed(days[5].isoDate) ? 6 : 5
      emit("buttonsGenerated", daysAmount)
      deltaDate.value = (firstDate.week() - moment().week()) * WeekConstants.WEEK_LENGTH
      emit("tomorrowDateCalculated", tomorrowDate.value)

      return days
    })

    const lastPressedButton = ref(moment().format(DAYS_MONTHS_FORMAT))
    const dateButtonClick = (clickedDate: string) => {
      lastPressedButton.value = moment(clickedDate).format(DAYS_MONTHS_FORMAT)
      localStorage.setItem("lastClickedDate", clickedDate)
      chosenDate.value = clickedDate
      periodFrom.value = clickedDate
      periodTo.value = clickedDate
      emit(
        "dateButtonClick",
        moment(clickedDate),
        tomorrowDate.value
      )
    }
    // LeftCalendarButton disables when the current date is present in the calendar
    const disableLeftCalendarButton = computed(() => props.pastAvailability ? !(deltaDate.value > -20) : !deltaDate.value)
    const leftCalendarButtonClick = () => {
      startDate.value = moment(startDate.value).subtract(WeekConstants.WEEK_LENGTH, "days").toISOString()
      deltaDate.value -= WeekConstants.WEEK_LENGTH
      emit("leftScroll", startDate.value)
    }
    // RightCalendarButton disables when the dish menu is missing
    const disableRightCalendarButton = computed(() => {
      let isDisabled = !isThereFartherMenu(getDays.value[4].isoDate)
      if (props.dateSelector || props.periodSelector) isDisabled = deltaDate.value > 10
      return isDisabled

    })
    const rightCalendarButtonClick = () => {
      startDate.value = moment(startDate.value).add(WeekConstants.WEEK_LENGTH, "days").toISOString()
      deltaDate.value += WeekConstants.WEEK_LENGTH
      emit("rightScroll", startDate.value)
    }
    //Date selector
    const dateSelectorChanged = (date: string) => {
      startDate.value = moment(date).hours(12).day(1).toISOString()
      currentDate.value = moment(date).hours(12).toISOString()
      lastPressedButton.value = moment(currentDate.value).format(DAYS_MONTHS_FORMAT)
      emit("selectorChanged", moment(currentDate.value), tomorrowDate.value)
      deltaDate.value = 0
    }
    //Period selector
    const periodFromChanged = () => {
      dateSelectorChanged(periodFrom.value)
      periodTo.value = periodFrom.value
    }
    const periodToChanged = () => {
      emit("periodToChanged", moment(periodFrom.value).hours(0).minutes(0).seconds(0).toISOString(),
        moment(periodTo.value).hours(23).minutes(59).seconds(0).toISOString())
    }
    //Disabled dates for date pickers
    const disableDatesFrom = (date: Date) => moment(date.toISOString()).day() === WeekConstants.SUNDAY
    const disableDatesTo = (date: Date) =>
      moment(date.toISOString()) < moment(periodFrom.value) || moment(date.toISOString()).day() === WeekConstants.SUNDAY

    const renderKey = ref(0)
    const selectorShortcuts = [
      {
        text: 'Сегодня',
        value: () => {
          chosenDate.value = moment().toISOString()
          periodFrom.value = moment().toISOString()
          dateSelectorChanged(chosenDate.value)
          periodFromChanged()
          renderKey.value++
          return moment().toISOString()
        },
      }]
    // Saturday will be displayed if there is a dish menu on that day
    const isButtonDisplayed = (date: string) => {
      if (moment(date).day() === WeekConstants.SATURDAY) {
        return props.daysWithFormedDishMenu.has(moment(date).format(FULLDATE_FORMAT))
          || props.daysWithOrder.has(moment(date).format(FULLDATE_FORMAT))
          || moment(chosenDate.value).format(FULLDATE_FORMAT) === moment(date).format(FULLDATE_FORMAT)
      }
      return true
    }
    const isButtonClicked = (date: string): boolean => lastPressedButton.value === moment(date).format(DAYS_MONTHS_FORMAT)
    const isButtonWithOrder = (date: string): boolean => props.daysWithOrder.has(moment(date).format(FULLDATE_FORMAT))
    const isButtonWithMenu = (date: string): boolean => props.pastAvailability ? false : !props.daysWithFormedDishMenu.has(moment(date).format(FULLDATE_FORMAT))

    //Checking last pressed date button after refresh
    const setDates = (date: string) => {
      startDate.value = moment(date).day(WeekConstants.MONDAY).toISOString()
      lastPressedButton.value = moment(date).format(DAYS_MONTHS_FORMAT)
      chosenDate.value = date
      periodFrom.value = date
      periodTo.value = date
    }
    onMounted(async () => {
      if (localStorage.getItem("lastClickedDate")) {
        const lastClickedDate = localStorage.getItem("lastClickedDate") as string
        setDates(lastClickedDate)
      } else {
        await store.dispatch("checkCurrentDate")
        const lastClickedDate = store.getters.getCurrentDate.hours(12).toISOString()
        setDates(lastClickedDate)
        localStorage.setItem("lastClickedDate", lastClickedDate)
      }
    })

    return {
      isNightSelect,
      chosenDate,
      periodFrom,
      periodTo,
      tooltipPosition,
      tooltipContent,
      orderPrice,
      tooltipDisabled,
      isButtonDisplayed,
      deltaDate,
      getDays,
      dateButtonClick,
      disableLeftCalendarButton,
      leftCalendarButtonClick,
      disableRightCalendarButton,
      rightCalendarButtonClick,
      dateSelectorChanged,
      periodFromChanged,
      periodToChanged,
      disableDatesTo,
      disableDatesFrom,
      renderKey,
      selectorShortcuts,
      FULLDATE_FORMAT,
      isButtonClicked,
      isButtonWithOrder,
      isButtonWithMenu
    }
  },
})
