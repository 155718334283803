
import {defineComponent} from "vue"
import {useStore} from "vuex"

export default defineComponent({

  emits: ["closeDialog"],
  setup(props, {emit}) {
    const store = useStore()

    const dropIngredient = async () => {
      await store.dispatch("deleteIngredient", store.getters.getIngredientsForEdit )
      emit("closeDialog")
    }
    const CloseModalWindow = () => {
      emit("closeDialog")
    }
    return {
      dropIngredient,
      CloseModalWindow
    }
  },
})
