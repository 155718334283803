import { ListFormed, ListStatus } from "@/modules/admin/purchasing-products/models/enums"

export const TranslationListFormed: Record<ListFormed, string> = {
  [ListFormed.AUTO]: "Автоматически",
  [ListFormed.MANUAL]: "Вручную",
  [ListFormed.NOT_SELECTED]: "Не выбрано",
}

export const TranslationListStatus: Record<ListStatus, string> = {
  [ListStatus.ACTIVE]: "В работе",
  [ListStatus.CANCELED]: "Отменен",
  [ListStatus.NOT_SELECTED]: "Не выбрано",
  [ListStatus.DONE]: "Завершен",
  [ListStatus.DRAFT]: "Черновик",
  [ListStatus.NEW]: "Новый",
}
