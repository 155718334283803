import {Permission, User} from "@/utils/data/interfaces"


export const prepareUserPermissionFullList = (allUsers: Array<User>, idSelectedUser: number, allPermissions: Array<Permission>): Array<Permission> => {

  const permissionsOfSelectedUser = (allUsers.find((user: User) => user.userId === idSelectedUser)?.permissions ?? [])
    .map((permission: Permission) => permission.name)

  return allPermissions.map(permission => {
    return {
      ...permission,
      selected: permissionsOfSelectedUser.includes(permission.name)
    }
  })
}
export default prepareUserPermissionFullList