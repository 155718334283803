import { Module } from "vuex"
import { CommentData } from "@/utils/data/interfaces"
import { elMessage } from "@/modules/order/models/elMessage"
import { SystemMessages, TypeMessages } from "@/modules/order/models/data/enums"
import {
  addComment,
  downloadComments,
} from "@/modules/order/models/fetchModules/fetchModuleComments"

export const commentRequestModule: Module<any, any> = {
  state: {
    comments: [] as Array<CommentData>,
    commentsDownloaded: false,
  },
  getters: {
    getComments(state) {
      return state.comments
    },
    iscommentsDownloaded(state) {
      return state.commentsDownloaded
    },
  },
  mutations: {
    fetchDishComments(state, data) {
      state.comments = data.data
      state.commentsDownloaded = true
    },
    addComment(state, addedComment) {
      state.comments.unshift(addedComment)
    },
  },
  actions: {
    commentDish: async ({ commit }, { date, userId, dishId, addedComment }) => {
      const message = addedComment.message
      const res = await addComment(date, userId, dishId, message)
      if (res.ok) {
        elMessage(SystemMessages.COMMENT_ADDED, TypeMessages.SUCCESS)
      } else
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.WARNING)
    },
    downloadDishComments: async ({ commit }, dishId) => {
      const res = await downloadComments(dishId)
      if (res.ok) {
        const data = await res.json()
        commit("fetchDishComments", data)
      } else {
        elMessage(SystemMessages.COMMENT_FAIL, TypeMessages.WARNING)
      }
    },
  },
}
export default commentRequestModule
