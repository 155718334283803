import { Module } from "vuex"
import { User } from "@/utils/data/interfaces"
import { get } from "@/utils/fetchQueries"

export const userHandlingModule: Module<any, any> = {
  state: {
    downloadedUsers: new Map<number, User>(),
    usersDownloadedByIds: false,
  },
  getters: {
    getUserById: (state) => (id: number) => {
      return state.downloadedUsers.get(id)
    },
    isUsersDownloadedByIds(state) {
      return state.usersDownloadedByIds
    },
  },
  mutations: {
    fetchDownloadedUsers: (state, data) => {
      data.forEach((user: User) => state.downloadedUsers.set(user.userId, user))
      state.usersDownloadedByIds = true
    },
  },
  actions: {
    async downloadUsersById({ commit }, ids: number[]) {
      const res = await get(`/api/auth/users/short-info?userIds=${ids.join(",")}`)
      const data = await res.json()
      if (res.ok) commit("fetchDownloadedUsers", data.data)
    },
  },
}
export default userHandlingModule
