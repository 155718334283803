import {Dish, Order} from "@/utils/data/interfaces";
import DishCategoryName from "@/modules/admin/models/records/DishCategoryName";
import store from "@/store";

export const downloadDishesForPreview =async (order: Order) => {
  let dishTypesMap: Map<string, Array<Dish>> = new Map()
  Object.values(DishCategoryName).forEach((dishCategory) => dishTypesMap.set(dishCategory, []))
  const dishIds: Array<number> = []
  order.dishes.forEach((dish) => {
    dishIds.push(dish.dishId)
  })
 await store.dispatch("downloadDishes", dishIds)
  order.dishes.forEach((dish) => {
    const foundDish: Dish = store.getters.getDownloadedDishes.get(dish.dishId)
    const tempDish: Dish = {
      ...foundDish,
      amount: dish.amount,
      identity: dish.refuser,
      price: dish.price
    }
    dishTypesMap.get(DishCategoryName[tempDish.category])!.push(tempDish)
  })

  return dishTypesMap
}