
import { defineComponent } from "vue"

export default defineComponent({
    name: "CloseButton",
    props: {
        size: {
            type: Number,
            default: 22,
        },
    },
    setup(props) {
        const closeIcon = "../images/common-icons/close.svg"

        return {
            closeIcon
        }
    }
})
