// router
import router from "@/router";

//interfaces
import {Commit} from "vuex";
import {User} from "@/utils/data/interfaces";

//utils-functions
import SortPermissions from "@/store/modules/admin/utils/for-login-form/SortPermissions";
import checkRoleForCurrentLink from "@/store/modules/admin/utils/for-login-form/CheckRoleForCurrentLink";
import verifyCurrentLinkByUserPermissions
  from "@/store/modules/admin/utils/for-login-form/VerifyCurrentLinkByUserPermissions";
import hasAnyPermissions from "@/store/modules/admin/utils/for-login-form/HasAnyPermissions";
import {MenuLink} from "@/modules/portal/models/interfaces/menu-links-interface";
import {Roles} from "@/modules/order/models/data/enums";


async function goToCurrentLink(link: string, commit: Commit) {
  await router.push(link)
  commit("setActiveLink", link)
}

function setUserData(commit: Commit, user: User) {
  commit("setUserDownloaded")
  commit("updateUserData", user)
}

export default async function userIdentificationAndMenu(commit: Commit, user: User, lastVisitedPage?: string) {
  setUserData(commit, user)
  const newMenu = SortPermissions(user.permissions)
  const hasUserPermissions = hasAnyPermissions(newMenu)

  commit("setActiveMenu", newMenu)
  commit("setHasAllTypesPermissions", (hasUserPermissions.adminPermissions && hasUserPermissions.userPermissions))

  if (lastVisitedPage) {

    if (!verifyCurrentLinkByUserPermissions(lastVisitedPage, user.permissions)) return
    commit("setActiveRole", checkRoleForCurrentLink(lastVisitedPage))
    await goToCurrentLink(lastVisitedPage, commit)

  } else {
    if (!hasUserPermissions.userPermissions && hasUserPermissions.adminPermissions && user.role === Roles.USER) {
      routeTo(commit, Roles.ADMIN, newMenu)
    } else {
      routeTo(commit, user.role, newMenu)
    }
  }
}

async function routeTo(commit: Commit, role: string, menu: Map<string, Array<MenuLink>>) {
  const firstMenuLink = menu.get(role)![0].link
  localStorage.setItem('lastSelectedPage', firstMenuLink)
  await goToCurrentLink(firstMenuLink, commit)
  commit("setActiveRole", role)
}