import { Module } from "vuex"
import {
  downloadUsers,
  issueOrder,
} from "@/modules/admin/orders-issuing/models/fetch-modules/fetchModuleIssuing"
import { User } from "@/utils/data/interfaces"
import { elMessage } from "@/modules/order/models/elMessage"
import { SystemMessages, TypeMessages } from "@/modules/order/models/data/enums"
import { getUserSurnameAndInitials } from "@/utils/helpers/getUserSurnameAndInitials"

export const orderIssuingModule: Module<any, any> = {
  state: {
    users: new Map<number, User>(),
    usersDownloaded: false,
  },
  getters: {
    getUsers(state) {
      return state.users
    },
    isUsersDownloaded(state) {
      return state.usersDownloaded
    },
  },
  mutations: {
    fetchUsers(state, data) {
      state.users = data
      state.usersDownloaded = true
    }
  },
  actions: {
    downloadUsers: async ({ commit }) => {
      const res = await downloadUsers()

      if (res.ok) {
        const response = await res.json()
        const usersMap = new Map<number, User>()

        if (response.data.length) {
          response.data.forEach((user: User) => {
            usersMap.set(user.userId, user)
            user.fullUserName = getUserSurnameAndInitials(user)
          })
          commit("fetchUsers", usersMap)
        }
      }
    },

    issueOrder: async ({ commit }, orderForDB) => {
      const res = await issueOrder(orderForDB)
      if (res.ok) {
        elMessage(SystemMessages.ORDER_IS_ISSUED, TypeMessages.SUCCESS)
      } else
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.WARNING)
    },
  },
}

export default orderIssuingModule
