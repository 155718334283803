import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dd078ce0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog-header" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "body" }
const _hoisted_4 = { class: "buttons-horizontal-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_button = _resolveComponent("close-button")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_toggle_checkbox = _resolveComponent("toggle-checkbox")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    width: _ctx.editDialogWidth,
    "show-close": false,
    "append-to-body": true,
    top: "1vh",
    onOpen: _ctx.dialogOpening,
    class: _normalizeClass({ night: _ctx.isNightSelect })
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_close_button, { onClick: _ctx.closeDialog }, null, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_form, {
          ref: "ruleFormRef",
          class: "edit-list-row",
          "label-position": "left",
          model: _ctx.editingRow,
          rules: _ctx.editingRowFormRules,
          size: _ctx.listRowformSize,
          onSubmit: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "Продукт",
              prop: "ingredientName"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  class: _normalizeClass([{ night: _ctx.isNightSelect }, "m-2"]),
                  disabled: _ctx.isBuyer,
                  modelValue: _ctx.editingRow.ingredientId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editingRow.ingredientId) = $event)),
                  filterable: "",
                  onChange: _ctx.ingredientChanged
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ingredientsSelectOptions, (option) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: option.value,
                        label: option.label,
                        value: option.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["class", "disabled", "modelValue", "onChange"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Производитель",
              prop: "manufacturerName"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.editingRow.manufacturerId,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editingRow.manufacturerId) = $event)),
                  class: _normalizeClass(["m-2", { night: _ctx.isNightSelect }]),
                  filterable: "",
                  onChange: _ctx.manufacturerChanged
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.manufacturersSelectOptions, (option) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: option.value,
                        label: option.label,
                        value: option.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "class", "onChange"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Количество",
              prop: "ammount"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.productAmount,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.productAmount) = $event)),
                  class: _normalizeClass({ night: _ctx.isNightSelect }),
                  maxlength: 5,
                  onInput: _ctx.amountChanged
                }, null, 8, ["modelValue", "class", "onInput"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Единица измерения",
              prop: "unitName"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.editingRow.unitId,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editingRow.unitId) = $event)),
                  class: _normalizeClass([{ night: _ctx.isNightSelect }, "m-2"]),
                  filterable: "",
                  onChange: _ctx.unitChanged
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unitsSelectOptions, (option) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: option.value,
                        label: option.label,
                        value: option.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "class", "onChange"])
              ]),
              _: 1
            }),
            (_ctx.isBuyer)
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  label: "Цена за единицу",
                  prop: "unitPrice"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.unitPrice,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.unitPrice) = $event)),
                      class: _normalizeClass({ night: _ctx.isNightSelect }),
                      maxlength: 5,
                      onInput: _ctx.unitPriceChanged
                    }, null, 8, ["modelValue", "class", "onInput"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.isBuyer)
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 1,
                  label: "Место закупки",
                  prop: "purchasingPlaceName"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.editingRow.purchasingPlaceId,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editingRow.purchasingPlaceId) = $event)),
                      class: _normalizeClass([{ night: _ctx.isNightSelect }, "m-2"]),
                      filterable: "",
                      onChange: _ctx.purchasingPlaceChanged
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.purchasingPlaceSelectOptions, (option) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: option.value,
                            label: option.label,
                            value: option.value
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "class", "onChange"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_el_form_item, {
              label: "Комментарий",
              prop: "note"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.editingRow.note,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editingRow.note) = $event)),
                  maxlength: 50,
                  class: _normalizeClass({ night: _ctx.isNightSelect })
                }, null, 8, ["modelValue", "class"])
              ]),
              _: 1
            }),
            (_ctx.isBuyer)
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 2,
                  label: "Закуплено",
                  prop: "isPurchased"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_toggle_checkbox, {
                      checked: _ctx.editingRow.isPurchased,
                      "onUpdate:checked": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editingRow.isPurchased) = $event))
                    }, null, 8, ["checked"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["model", "rules", "size"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_custom_button, {
            "type-button": "success",
            disabled: _ctx.isSaveButtonDisabled,
            onClick: _ctx.saveClicked
          }, {
            default: _withCtx(() => [
              _createTextVNode("СОХРАНИТЬ")
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]),
          (_ctx.isBuyer)
            ? (_openBlock(), _createBlock(_component_custom_button, {
                key: 0,
                disabled: _ctx.isResetButtonDisabled,
                onClick: _ctx.copyClicked
              }, {
                default: _withCtx(() => [
                  _createTextVNode("СКОПИРОВАТЬ")
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]))
            : _createCommentVNode("", true),
          _createVNode(_component_custom_button, {
            disabled: _ctx.isResetButtonDisabled,
            onClick: _ctx.dialogOpening
          }, {
            default: _withCtx(() => [
              _createTextVNode("СБРОСИТЬ")
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["width", "onOpen", "class"]))
}