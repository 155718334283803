import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-477e68e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "top" }
const _hoisted_3 = { class: "tables" }
const _hoisted_4 = { class: "leftTable" }
const _hoisted_5 = { class: "table" }
const _hoisted_6 = { class: "user-name-wrapper" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "full-name" }
const _hoisted_9 = { class: "buttons-horizontal-panel" }
const _hoisted_10 = { class: "rightTable" }
const _hoisted_11 = { class: "table" }
const _hoisted_12 = { class: "user-name-wrapper" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "full-name" }
const _hoisted_15 = { class: "buttons-horizontal-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectorsMonthAndYear = _resolveComponent("SelectorsMonthAndYear")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_toggle_checkbox = _resolveComponent("toggle-checkbox")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_remindCard = _resolveComponent("remindCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SelectorsMonthAndYear, {
        numberOfYearsAgo: _ctx.numbOfYearAgo,
        onSelectedPeriod: _ctx.periodChanged
      }, null, 8, ["numberOfYearsAgo", "onSelectedPeriod"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_el_table, {
            data: _ctx.allUsersTable,
            "max-height": "790px",
            class: _normalizeClass({ night: _ctx.isNightSelect })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                fixed: "",
                width: _ctx.searchColumnWidth
              }, {
                header: _withCtx(() => [
                  _createVNode(_component_SearchInput, {
                    searchValue: _ctx.filterValueForAllUsers,
                    placeholderValue: _ctx.searchPlaceholder,
                    onHandleSearch: _ctx.handleSearchInputAllUsers,
                    onClearSearch: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSearchInputAllUsers('')))
                  }, null, 8, ["searchValue", "placeholderValue", "onHandleSearch"])
                ]),
                default: _withCtx((scope) => [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("img", {
                      src: scope.row.avatar,
                      class: "avatar"
                    }, null, 8, _hoisted_7),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(scope.row.fullUserName), 1)
                  ])
                ]),
                _: 1
              }, 8, ["width"]),
              _createVNode(_component_el_table_column, {
                align: "center",
                width: _ctx.toggleColumnWidth
              }, {
                default: _withCtx((scope) => [
                  _createElementVNode("div", {
                    class: "buttons-horizontal-panel",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
                  }, [
                    _createVNode(_component_toggle_checkbox, {
                      checked: scope.row.selectedForPayment,
                      type: "edit",
                      onClick: ($event: any) => (_ctx.addRemoveSelectedUser(scope.row))
                    }, null, 8, ["checked", "onClick"])
                  ])
                ]),
                _: 1
              }, 8, ["width"])
            ]),
            _: 1
          }, 8, ["data", "class"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_custom_button, {
            "type-button": "success",
            disabled: _ctx.disableAddButton,
            onClick: _ctx.updateSelectedUserForPayment
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.textAddButton), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_el_table, {
            data: _ctx.selectedUsersTable,
            "highlight-current-row": "",
            "row-key": "userId",
            "max-height": "790px",
            class: _normalizeClass({ night: _ctx.isNightSelect })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                fixed: "",
                width: _ctx.searchColumnWidth
              }, {
                header: _withCtx(() => [
                  _createVNode(_component_SearchInput, {
                    searchValue: _ctx.filterValueForSelectUsers,
                    placeholderValue: _ctx.searchPlaceholder,
                    onHandleSearch: _ctx.handleSearchInputSelectedUsers,
                    onClearSearch: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSearchInputSelectedUsers('')))
                  }, null, 8, ["searchValue", "placeholderValue", "onHandleSearch"])
                ]),
                default: _withCtx((scope) => [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("img", {
                      src: scope.row.avatar,
                      class: "avatar"
                    }, null, 8, _hoisted_13),
                    _createElementVNode("div", _hoisted_14, _toDisplayString(scope.row.fullUserName), 1)
                  ])
                ]),
                _: 1
              }, 8, ["width"]),
              _createVNode(_component_el_table_column, {
                "column-key": "monthlyAmount",
                label: "Cумма",
                width: _ctx.checkColumnWidth,
                align: "center",
                prop: "monthlyAmount"
              }, null, 8, ["width"]),
              _createVNode(_component_el_table_column, {
                align: "center",
                width: _ctx.checkColumnWidth,
                label: "Оплачено"
              }, {
                default: _withCtx((scope) => [
                  _createElementVNode("div", {
                    class: "buttons-horizontal-panel",
                    onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
                  }, [
                    _createVNode(_component_toggle_checkbox, {
                      checked: scope.row.paid,
                      onClick: ($event: any) => (_ctx.userPaidMonth(scope.row))
                    }, null, 8, ["checked", "onClick"])
                  ])
                ]),
                _: 1
              }, 8, ["width"])
            ]),
            _: 1
          }, 8, ["data", "class"])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_custom_button, {
            "type-button": "success",
            disabled: _ctx.disableSaveButton,
            onClick: _ctx.updateSelectedUserForPayment
          }, {
            default: _withCtx(() => [
              _createTextVNode("Сохранить ")
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]),
          _createVNode(_component_custom_button, {
            onClick: _ctx.openRemindCard,
            disabled: _ctx.disableRemindButton
          }, {
            default: _withCtx(() => [
              _createTextVNode("Напомнить ")
            ]),
            _: 1
          }, 8, ["onClick", "disabled"])
        ])
      ])
    ]),
    (_ctx.remindCardVisible)
      ? (_openBlock(), _createBlock(_component_remindCard, {
          key: 0,
          modelValue: _ctx.remindCardVisible,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.remindCardVisible) = $event)),
          "placeholder-value": _ctx.searchPlaceholder,
          "search-column-width-value": _ctx.searchColumnWidth,
          "toggle-column-width-value": _ctx.toggleColumnWidth,
          onCloseDialog: _ctx.closeRemindCard
        }, null, 8, ["modelValue", "placeholder-value", "search-column-width-value", "toggle-column-width-value", "onCloseDialog"]))
      : _createCommentVNode("", true)
  ]))
}