import {reactive} from "vue"
import type {FormRules} from "element-plus"

export default function useDishRules() {
  const rules = reactive<FormRules>({
    name: [
      {
        required: true,
        message: "Пожалуйста, введите название",
        trigger: "blur",
      },
      {
        min: 3,
        message: "Длина должна быть не менее 3х символов",
        trigger: "blur",
      },
    ],
    menuType: [
      {
        required: true,
        message: "Пожалуйста, выберите тип меню",
        trigger: "change",
      },
    ],
    category: [
      {
        required: true,
        message: "Пожалуйста, выберите категорию",
        trigger: "change",
      },
    ],
    emoji: [
      {
        required: false,
      },
    ],
    price: [
      {
        required: true,
        message: "Пожалуйста, введите стоимость",
        trigger: "blur",
      },
      {
        type: "number",
        message: "Введённое значение должно быть числом",
      },
      {
        min: 1,
        type: "number",
        message: "Введённое значение должно быть больше 0",
      },
      {
        max:10000,
        type: "number",
        message: "Введённое значение не должно превышать 10000",
      },
    ],
    weight: [
      {
        required: true,
        message: "Пожалуйста, введите вес",
        trigger: "blur",
      },
      {
        min: 1,
        type: "number",
        message: "Некорректный ввод",
      },
    ],
    ingredients:  [
      {
        required: true,
        message: "Пожалуйста, выберите ингредиенты",
        trigger: "change",
        type: "array",
      },
    ],
    description: [
      {
        required: false,
        trigger: "blur",
      },
    ],
    note: [
      {
        required: false,
        trigger: "blur",
      },
    ],
    image: [
      {
        required: true,
        message: "Пожалуйста, загрузите фото",
        trigger: "change",
      },
    ],
  })

  return {rules}
}
