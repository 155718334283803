import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-86e36a14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form" }
const _hoisted_2 = { class: "many-items-row smaller-label-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_dialog_window = _resolveComponent("dialog-window")!

  return (_openBlock(), _createBlock(_component_dialog_window, {
    "show-close": true,
    title: _ctx.headerText,
    cancelButtonText: "Сбросить",
    okButtonText: "Сохранить",
    width: "1000px",
    onCancelClicked: _ctx.resetForm,
    onCloseDialog: _ctx.closeDialog,
    onOkClicked: _ctx.submitForm
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_form, {
          ref: "ruleFormRef",
          class: _normalizeClass({ night: _ctx.isNightSelect }),
          model: _ctx.tempIngredient,
          rules: _ctx.rules,
          size: _ctx.formSize,
          "require-asterisk-position": "right",
          "status-icon": "",
          onSubmit: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "Название",
              prop: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.tempIngredient.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tempIngredient.name) = $event)),
                  onInput: _ctx.validateName,
                  class: _normalizeClass({ night: _ctx.isNightSelect })
                }, null, 8, ["modelValue", "onInput", "class"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Категория",
              prop: "category"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  class: _normalizeClass({ night: _ctx.isNightSelect }),
                  modelValue: _ctx.tempIngredient.category,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tempIngredient.category) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.IngredientsCategory, (ingredientCategory) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: ingredientCategory,
                        label: _ctx.IngredientsCategoryName[_ctx.IngredientsCategory[ingredientCategory]],
                        value: ingredientCategory
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["class", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Единицы измерения",
              prop: "unit"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  class: _normalizeClass({ night: _ctx.isNightSelect }),
                  modelValue: _ctx.tempIngredient.unit,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tempIngredient.unit) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.IngredientUnits, (ingredientUnit) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: ingredientUnit,
                        label: _ctx.IngredientUnitsName[_ctx.IngredientUnits[ingredientUnit]],
                        value: ingredientUnit
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["class", "modelValue"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_form_item, {
                label: "Калории",
                prop: "calories"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.tempIngredient.calories,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.tempIngredient.calories) = $event)),
                    class: _normalizeClass(["cpfc", { night: _ctx.isNightSelect }])
                  }, null, 8, ["modelValue", "class"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "Белки",
                prop: "proteins"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.tempIngredient.proteins,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.tempIngredient.proteins) = $event)),
                    class: _normalizeClass(["cpfc", { night: _ctx.isNightSelect }])
                  }, null, 8, ["modelValue", "class"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "Жиры",
                prop: "fats"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.tempIngredient.fats,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.tempIngredient.fats) = $event)),
                    class: _normalizeClass(["cpfc", { night: _ctx.isNightSelect }])
                  }, null, 8, ["modelValue", "class"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "Углеводы",
                prop: "carbs"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.tempIngredient.carbs,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.tempIngredient.carbs) = $event)),
                    class: _normalizeClass(["cpfc", { night: _ctx.isNightSelect }])
                  }, null, 8, ["modelValue", "class"])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["class", "model", "rules", "size"])
      ])
    ]),
    _: 1
  }, 8, ["title", "onCancelClicked", "onCloseDialog", "onOkClicked"]))
}