import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cdd6834"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "emoji" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.emojis.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.emojis, (emoji, index) => {
          return (_openBlock(), _createBlock(_component_el_popover, {
            placement: "bottom",
            trigger: "hover",
            "auto-close": 3000,
            "hide-after": 0
          }, {
            reference: _withCtx(() => [
              _createElementVNode("div", null, [
                (_openBlock(), _createElementBlock("img", {
                  class: _normalizeClass(["emoji-icon", { night: _ctx.isNightSelect }]),
                  key: index,
                  src: emoji.picture,
                  alt: ""
                }, null, 10, _hoisted_2))
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["emoji-description", { night: _ctx.isNightSelect }])
              }, _toDisplayString(emoji.name), 3)
            ]),
            _: 2
          }, 1024))
        }), 256))
      : _createCommentVNode("", true)
  ]))
}