
import { useStore } from "vuex"
import { computed, ref, watch } from "vue"
import { DAYS_FORMAT } from "@/utils/data/moment-formats"
// new child modules
import TableDishes from "@/modules/admin/daily-dish-menu/component/TableDishes.vue"
import MenuTypeCard from "@/modules/admin/daily-dish-menu/component/MenuTypeCard.vue"

//enums and references
import DishMenuType from "@/modules/admin/models/enums/DishMenuType"
import DishMenuTypeName from "@/modules/admin/models/records/DishMenuTypeName"

import moment from "moment"


import { Dish, Order, OrderedDish } from "@/utils/data/interfaces"
import { downloadDishesForPreview } from "@/utils/helpers/createPreviewDishes";


export default {
  name: "CreateDishMenu",
  components: {
    TableDishes,
    MenuTypeCard,
  },
  setup() {
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    const activeMenuType = ref(DishMenuType.COMMON)
    store.dispatch("getDishesForMenu", activeMenuType.value)

    const pickedDate = ref(store.getters.getCurrentDate.hours(12).minutes(0).seconds(0).milliseconds(0).toISOString())

    const mondayThisWeek = ref(moment().startOf('isoWeek').hours(12).minutes(0).seconds(0).milliseconds(0).toISOString())
    const saturdayThisWeek = ref(moment().startOf('isoWeek').hours(12).minutes(0).seconds(0).milliseconds(0).add(5, DAYS_FORMAT).toISOString())

    if (localStorage.getItem("lastClickedDate")) {
      const lastClickedDate = localStorage.getItem("lastClickedDate") as string
      mondayThisWeek.value = moment(lastClickedDate).startOf('isoWeek').toISOString()
      saturdayThisWeek.value = moment(lastClickedDate).startOf('isoWeek').hours(12).minutes(0).seconds(0).milliseconds(0).add(5, DAYS_FORMAT).toISOString()
      pickedDate.value = moment(lastClickedDate).hours(12).minutes(0).seconds(0).milliseconds(0).toISOString()
    }

    store.dispatch("getDatesWithMenuOnSelectedPeriod", {
      mondayThisWeek: mondayThisWeek.value,
      fridayThisWeek: saturdayThisWeek.value
    })
    store.dispatch("getDailyDishMenu", { pickedDate: pickedDate.value, menuType: activeMenuType.value })
    store.dispatch("setSelectDate", pickedDate.value)

    const getDailyDishMenu = async (clickedDate: moment.Moment, tomorrowDate: moment.Moment) => {
      pickedDate.value = moment(clickedDate).hours(12).minutes(0).seconds(0).milliseconds(0).toISOString()
      await store.dispatch("setSelectDate", pickedDate.value)
      await store.dispatch("getDailyDishMenu", { pickedDate: pickedDate.value })
    }
    const changeTypeMenu = async () => {
      await store.dispatch("getDishesForMenu", activeMenuType.value)
    }

    watch(() => store.getters.getCurrentDate, async () => {
      pickedDate.value = store.getters.getCurrentDate.hours(12).minutes(0).seconds(0).milliseconds(0).toISOString()
      await store.dispatch("setSelectDate", pickedDate.value)
      await store.dispatch("getDailyDishMenu", { pickedDate: pickedDate.value })
    })

    //computed
    const todayAndFuture = computed(() => pickedDate.value < moment().hours(0).minutes(0).seconds(0).milliseconds(0).toISOString())
    const daysWithMenu = computed(() => store.getters.getDatesWithMenu)
    const disabledClearButton = computed(() => todayAndFuture.value || !idDishes.value.length)
    const disabledSendButton = computed(() => todayAndFuture.value || store.getters.getHasNewDatesWithUpdatedMenu)
    const disabledSaveButton = computed(() => todayAndFuture.value || store.getters.getIsSelectMenuChanged)

    const idDishes = computed(() => store.getters.getAllIdsDishesForSelectDate)


    //for reviewing formed dish menu via order-view component
    //it is necessary to bring it to the form of Order model
    const dailyMenuAsOrder = ref({} as Order)
    const dailyMenuReviewVisible = ref(false)
    const clearDailyMenuReviewVisible = ref(false)
    const dishTypes = ref([] as [string, Dish[]][])
    const showMenuReviewDialog = async () => {
      dailyMenuAsOrder.value.userId = 0,
        dailyMenuAsOrder.value.orderDate = pickedDate.value,
        dailyMenuAsOrder.value.isIssued = false,
        dailyMenuAsOrder.value.dishes = store.getters.getAllDishesForSelectDate.map((dish: Dish) => ({
          dishId: dish.dishId,
          dishName: dish.name,
          price: dish.price,
          amount: 0,
          isCanceled: false,
          issueAmount: 0,
          isAdmin: false,
          isIssued: false,
        } as OrderedDish))
      dishTypes.value = Array.from((await downloadDishesForPreview(dailyMenuAsOrder.value)).entries()).filter((dishType) => dishType[1].length !== 0)
      if (dailyMenuAsOrder.value.dishes.length) dailyMenuReviewVisible.value = true
      else clearDailyMenuReviewVisible.value = true
    }

    const hideMenuReviewDialog = () => {
      dailyMenuReviewVisible.value = false
      clearDailyMenuReviewVisible.value = false
    }

    const createDishMenu = () => {
      hideMenuReviewDialog()
      store.dispatch("addNewDateWithMenu", pickedDate.value)
    }
    const sendMenu = async () => {
      await store.dispatch("sendMenu", {
        userAdditionalRole: store.getters.getUserAdditionalRole,
        userRole: store.getters.getUserRole
      })
      await store.dispatch("getDailyDishMenu", { pickedDate: pickedDate.value })
      await store.dispatch("getDatesWithMenuOnSelectedPeriod", {
        mondayThisWeek: mondayThisWeek.value,
        fridayThisWeek: saturdayThisWeek.value
      })
    }
    const cleanDishMenu = async () => {
      store.getters.getDishesForSelectDate.filter((dish: Dish) => dish.menuType === activeMenuType.value).forEach((dish: Dish) => store.dispatch("addRemoveDishInMenu", dish))
    }

    const downloadNewWeek = async (newMonday: moment.Moment) => {
      mondayThisWeek.value = moment(newMonday).hours(12).minutes(0).seconds(0).milliseconds(0).toISOString()
      saturdayThisWeek.value = moment(newMonday).hours(12).minutes(0).seconds(0).milliseconds(0).add(5, DAYS_FORMAT).toISOString()
      store.dispatch("getDatesWithMenuOnSelectedPeriod", {
        mondayThisWeek: mondayThisWeek.value,
        fridayThisWeek: saturdayThisWeek.value,
      })
      store.dispatch("getDailyDishMenu", {
        pickedDate: pickedDate.value,
        menuType: activeMenuType.value,
      })
    }


    return {
      isNightSelect,
      dishTypes,
      todayAndFuture,
      daysWithMenu,
      idDishes,
      pickedDate,
      activeMenuType,
      getDailyDishMenu,
      changeTypeMenu,
      clearDailyMenuReviewVisible,
      dailyMenuReviewVisible,
      dailyMenuAsOrder,
      showMenuReviewDialog,
      hideMenuReviewDialog,
      createDishMenu,
      cleanDishMenu,
      DishMenuType,
      DishMenuTypeName,
      disabledClearButton,
      disabledSendButton,
      disabledSaveButton,
      downloadNewWeek,
      sendMenu,
    }
  },
}
