export enum AdditionalRoles {
  MANAGER = "MANAGER",
  COOK = "COOK",
  BUYER = "BUYER",
  EDITOR = "EDITOR",
  SUPPORT = "SUPPORT",
}

export const RoleNames: Record<AdditionalRoles, string> = {
  [AdditionalRoles.COOK]: "Повар",
  [AdditionalRoles.BUYER]: "Закупщик",
  [AdditionalRoles.MANAGER]: "Администратор",
  [AdditionalRoles.EDITOR]: "Оператор",
  [AdditionalRoles.SUPPORT]: "Поддержка",
}
export const MeaningRoles: Record<AdditionalRoles, string> = {
  [AdditionalRoles.COOK]:
    ': может выдавать, удалять, редактировать заказы сотрудников во вкладке "Выдача заказов".  Также может создавать новые заказы для сотрудников и добавлять в систему "Свободные блюда".',
  [AdditionalRoles.BUYER]:
    ': доступен весь функционал вкладок "Список заказов" и "Закупка продуктов".',
  [AdditionalRoles.MANAGER]:
    ': занимается составлением и заполнением меню во вкладке "Заполнить меню". Также может создавать новые блюда и ингредиенты для них во вкладке "Справочники". Есть возможность просматривать заказы во вкладке "Выдача заказов".',
  [AdditionalRoles.EDITOR]:
    ": может изменять меню в любое время и на любой день, даже если сотрудники уже успели сделать свои заказы.",
  [AdditionalRoles.SUPPORT]: ": получает сообщения обратной связи от пользователей",
}
