import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28892373"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog-header" }
const _hoisted_2 = { class: "body" }
const _hoisted_3 = { class: "buttons-horizontal-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_button = _resolveComponent("close-button")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    width: _ctx.width,
    "show-close": false,
    "append-to-body": true,
    class: _normalizeClass({ night: _ctx.isNightSelect })
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_close_button, {
          class: _normalizeClass(_ctx.closeButtonClass),
          onClick: _ctx.closeDialog
        }, null, 8, ["class", "onClick"])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.titleClass)
      }, _toDisplayString(_ctx.title), 3)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mainText, (textRow, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createElementVNode("div", {
              class: _normalizeClass(["text-row", { night: _ctx.isNightSelect }])
            }, _toDisplayString(textRow), 3)
          ]))
        }), 128)),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_custom_button, {
            "type-button": "success",
            disabled: _ctx.disableOkButton,
            class: _normalizeClass(_ctx.dialogButtonClassOk),
            onClick: _ctx.okClicked
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.okButtonText), 1)
            ]),
            _: 1
          }, 8, ["disabled", "class", "onClick"]),
          _createVNode(_component_custom_button, {
            "type-button": "reset",
            class: _normalizeClass(_ctx.dialogButtonClassCancel),
            disabled: _ctx.disableCancelButton,
            onClick: _ctx.cancelClicked
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.cancelButtonText), 1)
            ]),
            _: 1
          }, 8, ["class", "disabled", "onClick"])
        ])
      ])
    ]),
    _: 3
  }, 8, ["width", "class"]))
}