export const notificationItems: Array<{
  code: string
  mainText: string
  subText: string
  description: string
}> = [
  {
    code: "NEW_MENU",
    mainText: "Уведомление",
    subText: "о новом меню",
    description:
      "Каждый раз, когда происходит добавление нового меню или изменение существующего меню, Вам будет приходить уведомление, на какие дни произошло обновление меню.",
  },
  {
    code: "SPARE_DISH",
    mainText: "Уведомление",
    subText: "о свободном блюде",
    description:
      "Каждый раз, когда кто-то из сотрудников расформировывает свой заказ, Вам будет приходить уведомление о наличии появившихся свободных блюд для заказа. В уведомлении будут отображаться свободные блюда на сегодня, а также на завтра, если такие имеются. Также уведомление будет приходить, когда свободные блюда добавляет Повар в конце своего рабочего дня около 14:00.",
  },
  {
    code: "ORDER_REMINDER",
    mainText: "Уведомление",
    subText: "о заказе",
    description:
      "Это уведомление подскажет в 9:00, какой у Вас заказ на сегодня, если нет заказа на сегодня, сообщит об этом. Если Вы не забрали заказ в первой половине дня, в 14:00 напомнит об этом.",
  },
  {
    code: "UNFORM_ORDER_REMINDER",
    mainText: "Уведомление",
    subText: "о расформированном заказе",
    description:
      "Вам в течение дня будут приходить уведомления о ваших выкупленных блюдах. В 8:00 следующего дня это уведомление оповестит, какие блюда из вашего расформированного заказа были выкуплены, а какие остались за Вами.",
  },
]
