import writeXlsxFile from "write-excel-file";
import {
  PurchasingList,
  PurchasingListRow,
  UploadListType,
} from "@/modules/admin/purchasing-products/models/listInterfaces";

interface TableCell {
  value: string;
  backgroundColor: string;
}

enum Colors {
  ORANGE = "#FF9900",
  WHITE = "#ffffff",
  GRAY = "#C8C8C8",
  YELLOW = "#FFE01F",
}

const columns = [
  { type: String, width: 20 },
  { type: String, width: 18 },
  { type: String, width: 8 },
  { type: String, width: 10 },
  { type: String, width: 20 },
];

const addListTitle = (listData: Array<Array<TableCell>>, list: PurchasingList) => {
  listData.push([{ value: "Список для закупки продуктов", backgroundColor: Colors.WHITE }]);
  listData.push([
    { value: `Дата создания: ${list.createdAtForDisplaying}`, backgroundColor: Colors.WHITE },
  ]);
};

const addTableHeader = (listData: Array<Array<TableCell>>, uploadType: UploadListType) => {
  if (uploadType === UploadListType.ADMIN)
    listData.push([
      { value: "Наименование", backgroundColor: Colors.YELLOW },
      { value: "Производитель", backgroundColor: Colors.YELLOW },
      { value: "Кол-во", backgroundColor: Colors.YELLOW },
      { value: "Ед. измер.", backgroundColor: Colors.YELLOW },
      { value: "Примечание", backgroundColor: Colors.YELLOW },
    ]);
  else
    listData.push([
      { value: "Наименование", backgroundColor: Colors.YELLOW },
      { value: "Стоимость", backgroundColor: Colors.YELLOW },
    ]);
};

const fillTable = (
  tableData: Map<string, Map<number, PurchasingListRow>>,
  listData: Array<Array<TableCell>>,
  uploadType: UploadListType
) => {
  tableData.forEach((ingredientRows, category) => {
    if (uploadType === UploadListType.ADMIN)
      listData.push([
        { value: category, backgroundColor: Colors.ORANGE },
        { value: "", backgroundColor: Colors.ORANGE },
        { value: "", backgroundColor: Colors.ORANGE },
        { value: "", backgroundColor: Colors.ORANGE },
        { value: "", backgroundColor: Colors.ORANGE },
      ]);
    else
      listData.push([
        { value: category, backgroundColor: Colors.ORANGE },
        { value: "", backgroundColor: Colors.ORANGE },
      ]);
    Array.from(ingredientRows.values()).forEach((row, index) => {
      const color: Colors = (index + 1) % 2 === 0 ? Colors.GRAY : Colors.WHITE;
      if (uploadType === UploadListType.ADMIN)
        listData.push([
          { value: row.ingredientName as string, backgroundColor: color },
          { value: row.manufacturerName ?? "", backgroundColor: color },
          { value: row.amount?.toString() ?? "", backgroundColor: color },
          { value: row.unitName ?? "", backgroundColor: color },
          { value: row.note ?? "", backgroundColor: color },
        ]);
      else {
        if (!row.amount) row.amount = 0;
        if (!row.unitPrice) row.unitPrice = 0;
        listData.push([
          { value: row.ingredientName as string, backgroundColor: Colors.WHITE },
          { value: (row.amount! * row.unitPrice!).toString() ?? "", backgroundColor: Colors.WHITE },
        ]);
      }
    });
    addEmptyRow(listData);
    addEmptyRow(listData);
  });
};

const addEmptyRow = (listData: Array<Array<TableCell>>) => {
  listData.push([{ value: "", backgroundColor: Colors.WHITE }]);
};

export const uploadListToXLS = (
  list: PurchasingList,
  tableData: Map<string, Map<number, PurchasingListRow>>,
  uploadType: UploadListType = UploadListType.ADMIN
) => {
  const listData: Array<Array<TableCell>> = [];
  addListTitle(listData, list);
  addTableHeader(listData, uploadType);
  fillTable(tableData, listData, uploadType);

  writeXlsxFile(listData, {
    columns,
    fileName: `Список закупок ${list.createdAtForDisplaying}.xlsx`,
  });
};
