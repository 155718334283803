import { Module } from "vuex"
import { post } from "@/utils/fetchQueries"
import { elMessage } from "@/modules/order/models/elMessage"
import { SystemMessages, TypeMessages } from "@/modules/order/models/data/enums"

export interface Feedback {
  title: string
  description: string
}

export const feedbackModule: Module<any, any> = {
  actions: {
    async sendFeedback(_, feedback: Feedback[]) {
      const res = await post("/api/", feedback)
      if (res.ok) elMessage(SystemMessages.FEEDBACK_SENT, TypeMessages.SUCCESS)
      else elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
    },
  },
}
export default feedbackModule
