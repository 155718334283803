import { Module } from "vuex"
import {
  GuideBookTableRow,
  Manufacturer,
  Unit,
  PurchasingPlace,
} from "@/modules/admin/purchasing-products/models/guideBookInterfaces"
import { PurchasingGuideBooks } from "@/modules/admin/purchasing-products/models/enums"
import IngredientsCategoryName from "@/modules/admin/models/records/IngredientsCategoryName"
import {
  downloadManufacturers,
  downloadPurchasingPlaces,
  downloadUnits,
  saveGuideBookTableRow,
  updateGuideBookTableRow,
  deleteGuideBookTableRow,
} from "@/modules/admin/purchasing-products/models/fetch-modules/fetchGuideBooks"
import { elMessage } from "@/modules/order/models/elMessage"
import { SystemMessages, TypeMessages } from "@/modules/order/models/data/enums"

export const purchaisingGuideBooksModule: Module<any, any> = {
  state: {
    purchasingGuideBookTableData: new Map<number, GuideBookTableRow>(),
    manufacturers: new Map<number, Manufacturer>(),
    units: new Map<number, Unit>(),
    purchasingPlaces: new Map<number, PurchasingPlace>(),
    parentPlaces: new Map<number, PurchasingPlace>(),
  },
  getters: {
    getPurchasingGuideBookTableData(state) {
      return state.purchasingGuideBookTableData
    },
    getParentPlaces(state) {
      return state.parentPlaces
    },
    getManufacturers(state) {
      return state.manufacturers
    },
    getUnits(state) {
      return state.units
    },
    getPurchasingPlaces(state) {
      return state.purchasingPlaces
    },
  },
  mutations: {
    fetchManufacturers(state, data) {
      data.forEach((manufacturer: Manufacturer) => {
        state.manufacturers.set(manufacturer.id, manufacturer)
        state.purchasingGuideBookTableData.set(manufacturer.id, {
          id: manufacturer.id,
          name: manufacturer.name,
          column2Value: manufacturer.productCategory,
          column2Label: IngredientsCategoryName[manufacturer.productCategory],
          parentPlace: "",
          shortUnitName: "",
          productCategory: IngredientsCategoryName[manufacturer.productCategory],
          guideBookType: PurchasingGuideBooks.MANUFACTURERS,
        } as GuideBookTableRow)
      })
    },
    fetchUnits(state, data) {
      data.forEach((unit: Unit) => {
        state.units.set(unit.id, unit)
        state.purchasingGuideBookTableData.set(unit.id, {
          id: unit.id,
          name: unit.name,
          parentPlace: "",
          shortUnitName: unit.shortName,
          column2Value: unit.shortName,
          column2Label: unit.shortName,
          guideBookType: PurchasingGuideBooks.UNITS,
        } as GuideBookTableRow)
      })
    },
    fetchPurchasingPlaces(state, data) {
      const parentPlacesMap = new Map<number, PurchasingPlace>()
      data
        .filter((place: PurchasingPlace) => !place.parentPlaceId)
        .forEach((parentPlace: PurchasingPlace) => parentPlacesMap.set(parentPlace.id, parentPlace))
      state.parentPlaces = parentPlacesMap

      data.forEach((place: PurchasingPlace) => {
        state.purchasingPlaces.set(place.id, place)
        state.purchasingGuideBookTableData.set(place.id, {
          id: place.id,
          name: place.name,
          column2Value: place.parentPlaceId,
          column2Label: parentPlacesMap.get(place.parentPlaceId as number)?.name ?? "",
          parentPlace: parentPlacesMap.get(place.parentPlaceId as number)?.name ?? "",
          shortUnitName: "",
          guideBookType: PurchasingGuideBooks.PURCHASING_PLACES,
        } as GuideBookTableRow)
      })
    },

    addTableRow(state, row: GuideBookTableRow) {
      if (
        row.guideBookType === PurchasingGuideBooks.PURCHASING_PLACES &&
        !row.column2Value &&
        !state.parentPlaces.has(row.id)
      )
        state.parentPlaces.set(row.id, row)
      state.purchasingGuideBookTableData.set(row.id, row)
    },

    removeTableRow(state, row: GuideBookTableRow) {
      state.purchasingGuideBookTableData.delete(row.id, row)
    },

    clearTable(state) {
      state.purchasingGuideBookTableData = new Map<number, GuideBookTableRow>()
    },
  },
  actions: {
    downloadManufacturers: async ({ commit }) => {
      const res = await downloadManufacturers()
      if (res.ok) {
        const data = await res.json()
        commit("clearTable")
        commit("fetchManufacturers", data.data)
      }
    },

    downloadUnits: async ({ commit }) => {
      const res = await downloadUnits()
      if (res.ok) {
        const data = await res.json()
        commit("clearTable")
        commit("fetchUnits", data.data)
      }
    },

    downloadPurchasingPlaces: async ({ commit }) => {
      const res = await downloadPurchasingPlaces()
      if (res.ok) {
        const data = await res.json()
        commit("clearTable")
        commit("fetchPurchasingPlaces", data.data)
      }
    },

    saveGuideBookTableRow: async ({ commit }, row: GuideBookTableRow) => {
      const res = await saveGuideBookTableRow(row)
      if (res.ok) {
        elMessage(SystemMessages.SAVED, TypeMessages.SUCCESS)
      } else if (res.status === 405) elMessage(SystemMessages.ALREADY_EXIST, TypeMessages.WARNING)
      else elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.WARNING)
    },

    changeGuideBookTableRow: async ({ commit }, row: GuideBookTableRow) => {
      const res = await updateGuideBookTableRow(row)
      if (res.ok) {
        commit("addTableRow", row)
        elMessage(SystemMessages.CHANGED, TypeMessages.SUCCESS)
      } else if (res.status === 405) elMessage(SystemMessages.ALREADY_EXIST, TypeMessages.WARNING)
      else elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.WARNING)
    },

    deleteGuideBookTableRow: async ({ commit }, row: GuideBookTableRow) => {
      const res = await deleteGuideBookTableRow(row)
      if (res.ok) {
        commit("removeTableRow", row)
        elMessage(SystemMessages.DELETED, TypeMessages.SUCCESS)
      } else elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.WARNING)
    },
  },
}

export default purchaisingGuideBooksModule
