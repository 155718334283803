
import {useStore} from "vuex"
import {defineComponent} from "vue";


export default defineComponent({
  name: "Logout",
  emits: ["closeDialog"],
  setup(props, {emit}) {
    const store = useStore()
    const resetUser = () => {
      store.dispatch('logOut')
      emit("closeDialog")
    }
    const CloseModalWindow = () => {
      emit("closeDialog")
    }
    return {
      resetUser,
      CloseModalWindow
    }
  },
})
