import { post, get } from "@/utils/fetchQueries"

export const addComment = (
  date: string,
  userId: number,
  dishId: number,
  message: string
) => post("/api/review", { date, userId, dishId, message })

export const downloadComments = (dishId: number) =>
  get(`/api/review/${dishId}`)
