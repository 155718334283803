
import { defineComponent, ref, PropType, computed, watch } from "vue"
import { useStore } from "vuex"
import { Order, OrderedDish } from "@/utils/data/interfaces"
import { Dish } from "@/utils/data/interfaces"
import { SpareDish, CheckedAvailability, DishIdentity } from "@/modules/order/models/dishInterfaces"
import moment from "moment"
import { SystemMessages } from "@/modules/order/models/data/enums"
import { FULLDATE_FORMAT } from "@/utils/data/moment-formats"
import { User } from "@/utils/data/interfaces"
import { TimeSettingsInterface } from "@/utils/data/interfaces"
import { downloadDishesForPreview } from "@/utils/helpers/createPreviewDishes";

export default defineComponent({
    name: "SpareDishes",
    props: {
        //Order in clicked day
        order: {
            type: Object as PropType<Order>,
            required: true,
        },
        //Is now correct time for unforming order
        isUnformingTime: {
            type: Boolean,
            default: true,
        },
        //Check if user can make an order
        isOrderTime: {
            type: Boolean,
            dafault: false
        },
        //The date on which the spare dishes are loaded
        spareDishesDate: {
            type: String,
            required: true,
        },
        //Disables Delete Order button
        disableDeleteButton: {
            type: Boolean,
            reqired: true
        },
        //Days with unformed orders
        unformedOrderDates: {
            type: Set,
            default: new Set<string>()
        },
        //Number of buttons in order calendar
        calendarButtonsAmount: {
            type: Number,
            default: 5
        }
    },
    emits: ["unformOrder", "orderIsMade", "deleteOrder"],
    setup(props, { emit }) {
        const store = useStore()
        const isNightSelect = computed(() => store.getters.getIsSelectedNight)

        const userId = store.getters.getUserId
        store.dispatch("downloadUsers")
        const allUsers = computed<Map<number, User>>(() => store.getters.getUsers)

        //Geting auto issuing time
        const autoIssuingTime = ref("")
        watch(() => store.getters.getTimeSetting, () => {
            autoIssuingTime.value = store.getters.getTimeSetting.find((timeInterface: TimeSettingsInterface) =>
                timeInterface.type === "AUTOISSUING").timeSetting
        }, { deep: true })

        //Label above dish menu
        const orderDescription = computed((): string => {

            if (moment(props.spareDishesDate) < moment().hours(0).minutes(0).seconds(0).milliseconds(0)) return "Было очень вкусно"
            if (isUnformedOrderPresent.value) return SystemMessages.UNFORMED_ORDER
            else if (isIssuedOrder.value) return SystemMessages.ORDER_IS_ISSUED
            else if (props.order.isSpareOrder) return SystemMessages.SPARE_ORDER
            else if (props.order.dishes.length === 0) return "Сделайте свой выбор"
            else return "Приятного аппетита"
        })

        //Determines wich buttons will be visible
        const orderDate = computed(() => moment(props.spareDishesDate).format(FULLDATE_FORMAT))
        const isUnformedOrderPresent = computed((): boolean => props.unformedOrderDates.has(orderDate.value))
        const isIssuedOrder = computed(() => {
            if (!!props.order.dishes.length) return !props.order.dishes.find((dish) => dish.issueAmount < dish.amount)
            else return false
        })
        const spareDishesButtonDisabled = computed(() => !props.isOrderTime || !store.getters.getSpareDishes.length || props.unformedOrderDates.has(orderDate.value) || (moment(props.spareDishesDate) < moment().hours(0).minutes(0)))

        //Spare dishes downloading and rendering
        const spareDishesVisible = ref(false)
        const isSpareDishesDownloaded = computed(() => store.getters.isSpareDishesDownloaded)
        const spareDishes = ref([] as Array<Dish>)

        //For updating component
        const renderKey = ref(0)
        const updateSpareDishes = computed(() => store.getters.isSpareDishesDownloaded ? renderKey.value++ : renderKey.value)
        const defineSpareDishes = () => {
            spareDishes.value = []
            const spareDishesFromDB: Array<SpareDish> = store.getters.getSpareDishes
            spareDishesFromDB.forEach((spareDish) => {
                const tempDish = store.getters.getDishesForDescription.get(spareDish.dishId) as Dish
                spareDishes.value.push({
                    ...tempDish,
                    date: moment(props.spareDishesDate).format(FULLDATE_FORMAT),
                    amount: 0,
                    maxAmount: spareDish.amount
                })
            })
        }
        watch(() => store.getters.getSpareDishes, defineSpareDishes, { deep: true })
        //Spare dishes window displayibg 
        const spareButtonClicked = async () => {
            await store.dispatch("downloadSpareDishes", props.spareDishesDate)
            spareDishesVisible.value = true
        }
        const closeSpareDishes = () => {
            spareDishesVisible.value = false
        }
        const cancelClicked = () => {
            hideConfirmationDialog()
            spareDishesVisible.value = true
        }
        //Determines whether order will be deleted or unformed 
        const deleteOrUnform = ref("")
        const deleteOrUnformTitle = ref("")

        const confirmUnformigVisible = ref(false)
        const showUnformConfirmationDialog = () => {
            deleteOrUnform.value = "расформировать"
            deleteOrUnformTitle.value = "Расформирование заказа"
            confirmUnformigVisible.value = true
        }
        //Check if ordered spare dishes is available
        const checkAvailability = (dishIds: Array<number>, spareDishesDate: string) => {
            store.dispatch("checkAvailability", { dishIds, spareDishesDate })
        }
        const isAvailabilityDownloaded = computed(() => store.getters.isDishAvailabilityDownloaded)
        const confirmedOrder: Order = {
            userId: store.getters.getUserId,
            orderDate: "",
            isIssued: false,
            dishes: []
        }
        const defineAvailability = () => {
            const confirmationMessage = [] as Array<string>
            confirmedDishes.value = []
            const dishAvailabilityMap = new Map<number, number>()
            const downloadedData = store.getters.getDishAvailability as Array<CheckedAvailability>
            downloadedData.forEach((dishAvailability) => {
                dishAvailabilityMap.set(dishAvailability.dishId, dishAvailability.amount)
            })
            let orderPrice = 0
            orderedDishes.value.forEach((dish) => {
                if ((dishAvailabilityMap.get(dish.dishId) as number) >= dish.amount) {
                    orderPrice += dish.price * dish.amount
                    confirmationMessage.push(`${dish.dishName} - ${dish.amount} - доступно`)
                    confirmedDishes.value.push(dish)
                } else confirmationMessage.push(`${dish.dishName} - ${dish.amount} - недоступно(остаток-${dishAvailabilityMap.get(dish.dishId)})`)
            })
            confirmationMessage.push(`Итого: ${orderPrice} ₽`)
            disableConfirmButton.value = (confirmedDishes.value.length === 0)
            availability.value = confirmationMessage
        }
        watch(() => store.getters.getDishAvailability, defineAvailability, { deep: true })

        //Order forming
        const orderedDishes = ref([] as Array<OrderedDish>)
        const confirmedDishes = ref([] as Array<OrderedDish>)
        const disableConfirmButton = ref(false)
        const availability = ref([] as Array<string>)
        const makeOrder = () => {
            const dishIds: Array<number> = []
            orderedDishes.value = []
            spareDishes.value.forEach((dish) => {
                if (dish.amount !== 0) {
                    dishIds.push(dish.dishId)
                    orderedDishes.value.push({
                        dishId: dish.dishId,
                        dishName: dish.name,
                        price: dish.price,
                        amount: dish.amount!,
                        isCanceled: false,
                        issueAmount: 0,
                        isIssued: false,
                        isSpareOrder: true,
                        isAdmin: false
                    })
                }
            })
            checkAvailability(dishIds, props.spareDishesDate)
            showConfirmationDialog()
        }
        //Order confirmation
        const confirmOrderVisible = ref(false)
        const showConfirmationDialog = () => {
            confirmOrderVisible.value = true
            confirmedOrder.orderDate = moment(props.spareDishesDate).hours(12).minutes(0).seconds(0).milliseconds(0).toISOString()
        }
        const hideConfirmationDialog = () => confirmOrderVisible.value = false
        const confirmOrder = () => {
            spareDishesVisible.value = false
            hideConfirmationDialog()
            confirmedOrder.dishes = confirmedDishes.value as Array<OrderedDish>
            confirmedOrder.isSpareOrder = true
            confirmedOrder.isInPlastic = true
            confirmedOrder.isIssued = moment(confirmedOrder.orderDate).format(FULLDATE_FORMAT) === moment().format(FULLDATE_FORMAT) ?
                moment() > moment(autoIssuingTime.value) : false
            emit("orderIsMade", confirmedOrder)
        }
        const makeOrderDisabled = computed(() => !spareDishes.value.find(dish => !!dish.amount)
        )
        //Delete or unform order
        const processOrder = () => {
            hideUnformConfirmationDialog()
            const spareDishesDate = props.spareDishesDate
            if (deleteOrUnform.value === "расформировать") {
                emit("unformOrder", spareDishesDate)
                props.unformedOrderDates.add(moment(spareDishesDate).format(FULLDATE_FORMAT))
            } else {
                emit("deleteOrder", spareDishesDate)
            }
        }
        const hideUnformConfirmationDialog = () => confirmUnformigVisible.value = false
        const showDeleteConfirmationDialog = () => {
            deleteOrUnform.value = "удалить"
            deleteOrUnformTitle.value = "Удаление заказа"
            confirmUnformigVisible.value = true
        }
        //Spare dishes identification
        const unformButtonClass = computed(() => props.order.isSpareOrder && !isIssuedOrder.value ? "hide" : "spare-dishes-button")
        const identificationButtonClass = computed(() => props.order.isSpareOrder && !isIssuedOrder.value ? "spare-dishes-button" : "hide")
        const identificationVisible = ref(false)
        const identificationDate = computed(() => props.spareDishesDate)
        const identificationOrder = ref<Order>({
            userId: userId,
            orderDate: identificationDate.value,
            isIssued: false,
            isSpareOrder: true,
            dishes: []
        })
        const identification = ref([] as DishIdentity[])
        const dishTypes = ref([] as [string, Dish[]][])
        const showIdentificationDialog = async () => {
            const date = identificationDate.value
            await store.dispatch("getIdentification", { userId, date })
            identification.value = store.getters.getSpareDishesIdentification
            identificationOrder.value.dishes = identification.value.map((dish) => {
                const orderedDish = {
                    dishId: dish.dishId,
                    dishName: dish.dishName,
                    price: dish.price!,
                    amount: dish.amount,
                    isCanceled: false,
                    issueAmount: 0,
                    isIssued: false,
                    isAdmin: false,
                    refuser: allUsers.value.get(dish.userId)?.fullUserName
                }
                if (dish.userSurname === "Свободное" && dish.userName === "блюдо") orderedDish.refuser = "Свободное блюдо"
                return orderedDish
            })

            dishTypes.value = Array.from((await downloadDishesForPreview(identificationOrder.value)).entries()).filter((dishType) => dishType[1].length !== 0)

            identificationVisible.value = true
        }
        const hideIdentificationDialog = () => {
            identificationVisible.value = false
        }
        //It is necessary for spare dishes buttons rendering according to the width of the calendar
        const spaceForCalendarClass = computed(() => props.calendarButtonsAmount === 6
            ? "space-for-calendar-six-buttons" : "space-for-calendar-five-buttons")
        return {
            isNightSelect,
            dishTypes,
            orderDate,
            orderDescription,
            isUnformedOrderPresent,
            isIssuedOrder,
            spareDishes,
            spareDishesButtonDisabled,
            spareDishesVisible,
            isSpareDishesDownloaded,
            updateSpareDishes,
            spareButtonClicked,
            closeSpareDishes,
            confirmUnformigVisible,
            deleteOrUnform,
            showUnformConfirmationDialog,
            isAvailabilityDownloaded,
            confirmOrderVisible,
            cancelClicked,
            makeOrder,
            disableConfirmButton,
            availability,
            confirmOrder,
            makeOrderDisabled,
            deleteOrUnformTitle,
            processOrder,
            hideUnformConfirmationDialog,
            showDeleteConfirmationDialog,
            unformButtonClass,
            identificationButtonClass,
            identificationVisible,
            identificationOrder,
            showIdentificationDialog,
            hideIdentificationDialog,
            spaceForCalendarClass,
            SystemMessages,
        }
    },
})
