import {TimeSettingsInterface} from "@/utils/data/interfaces";
import moment from "moment/moment";

export default function SetFormatTimeSetting(settings:Array<TimeSettingsInterface>) {
  return settings.map(setting => {
    return {
      type: setting.type,
      timeSetting: moment(setting.timeSetting, "HH:mm").toISOString()
    }
  })
}
