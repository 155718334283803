import { PurchasingGuideBooks } from "@/modules/admin/purchasing-products/models/enums"
import IngredientsCategoryName from "@/modules/admin/models/records/IngredientsCategoryName"

export interface SelectOption {
  label: string
  value: any
}
export const guideBooksSelectOptions: Array<SelectOption> = Object.entries(
  PurchasingGuideBooks
).map((guideBook) => ({ label: guideBook[1], value: guideBook[0] }))

export const ingredientsCategorySelectOptions: Array<SelectOption> =
  Object.entries(IngredientsCategoryName).map((ingredientsCategory) => ({
    label: ingredientsCategory[1],
    value: ingredientsCategory[0],
  }))
