
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";
import { User, UserForPayment } from "@/utils/data/interfaces"
import remindCard from "@/modules/admin/payment/components/RemindCard.vue";
import { elMessage } from '@/modules/order/models/elMessage';
import { SystemMessages, TypeMessages } from '@/modules/order/models/data/enums';

export default defineComponent({
  name: "Payment",
  components: {
    remindCard
  },
  setup() {
    const store = useStore()
    const filterValueForAllUsers = ref('')
    const filterValueForSelectUsers = ref('')
    const numbOfYearAgo = ref(1)
    const dateFrom = ref()
    const dateTo = ref()

    const handleSearchInputAllUsers = (value: string) => {
      filterValueForAllUsers.value = value
    }
    const handleSearchInputSelectedUsers = (value: string) => {
      filterValueForSelectUsers.value = value
    }
    const isMobile = computed(() => store.getters.getBreakpoints.mobile)
    const checkColumnWidth = computed(() => store.getters.getBreakpoints.mobile ? '76px' : '160px')
    const searchColumnWidth = computed(() => store.getters.getBreakpoints.mobile ? '210px' : '400px')
    const toggleColumnWidth = computed(() => store.getters.getBreakpoints.mobile ? 'auto' : '140px')
    const searchPlaceholder = computed(() => store.getters.getBreakpoints.mobile ? 'Введите фамилию' : 'Введите ФИО')


    const allUsersTable = computed(() => store.getters.getUsersForDisplaying(filterValueForAllUsers.value))
    const selectedUsersTable = computed(() => store.getters.getSelectedUsers(filterValueForSelectUsers.value))


    const disableSaveButton = computed(() => store.getters.getIsListSelectedUsersChanged || store.getters.getHasAnyUserSelectedEmptyMonthlyAmount)
    const disableAddButton = computed(() => store.getters.getIsChangeSelectedUsers)
    const disableRemindButton = computed(() => !store.getters.getIsAllUsersPaid)
    const textAddButton = computed(() =>
      store.getters.getHasAnyUsersInSourceSelectedUsers && !store.getters.getIsChangeSelectedUsers && !store.getters.getHasAnyUserSelectedEmptyMonthlyAmount ? "Сохранить" : "Добавить"
    )

    const remindCardVisible = ref(false)
    const openRemindCard = () => {
      if(store.getters.getHasAnyUserSelectedEmptyMonthlyAmount) {
        elMessage(SystemMessages.USERS_HAS_ZERO_AMOUNT, TypeMessages.WARNING)
        return
      }
      if(!store.getters.getIsListSelectedUsersChanged) {
        elMessage(SystemMessages.SAVE_USER, TypeMessages.WARNING)
        return
      }

      remindCardVisible.value = true
    }
    const closeRemindCard = () => {
      remindCardVisible.value = false
    }

    const addRemoveSelectedUser = (user: User) => {
      store.dispatch("addRemoveSelectedUser", user)
    }

    const updateSelectedUserForPayment = async () => {
      await store.dispatch("updateSelectedUserForPayment", {
        dateFrom: dateFrom.value,
        dateTo: dateTo.value,
        isMobile: isMobile.value
      })
    }
    const periodChanged = (fromDate: string, toDate: string) => {
      dateFrom.value = fromDate
      dateTo.value = toDate

      store.dispatch("getAllUsersForPayment", store.getters.getBreakpoints.mobile)
      store.dispatch("getSelectedUserForPayment", {
        dateFrom: dateFrom.value,
        dateTo: dateTo.value,
        isMobile: isMobile.value
      })
    }

    const userPaidMonth = (user: UserForPayment) => {
      setTimeout(() => {
        store.dispatch("userPaidMonth", user)
      }, 300)
    }
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    return {
      isNightSelect,
      disableSaveButton,
      disableAddButton,
      disableRemindButton,
      remindCardVisible,
      openRemindCard,
      closeRemindCard,
      periodChanged,
      addRemoveSelectedUser,
      updateSelectedUserForPayment,
      userPaidMonth,
      handleSearchInputAllUsers,
      handleSearchInputSelectedUsers,
      searchColumnWidth,
      checkColumnWidth,
      toggleColumnWidth,
      searchPlaceholder,
      allUsersTable,
      selectedUsersTable,
      filterValueForAllUsers,
      filterValueForSelectUsers,
      numbOfYearAgo,
      textAddButton,
    }
  }
})
