

import { computed, defineComponent, ref, watch } from "vue"
import { useStore } from "vuex"
import { AdditionalRole, Permission } from "@/utils/data/interfaces"
import TimePicker from "@/modules/order/components/common/TimePicker.vue"
import PermissionsName from "@/modules/portal/models/records/permissions-name"
import { permissions } from "@/modules/portal/models/enums/permissions"
import { AdditionalRoles, MeaningRoles, RoleNames } from "@/modules/admin/AppSettings/models/rolesHelper"

export default defineComponent({
  name: "PermissionsAndRolesSettings",
  components: { TimePicker },
  setup() {
    const store = useStore()

    const isContentHeightMobile = computed(() => store.getters.getBreakpoints.mobile)
    //Data downloading
    store.dispatch('getAllRoles')
    store.dispatch('getAllUsers')
    store.dispatch('getAllPermissions')

    //Users
    const idSelectedUser = ref<number>()
    const selectUserId = (userId: number) => {
      idSelectedUser.value = userId ?? -1
    }
    watch(() => {
      idSelectedUser.value, store.getters.getAllUsers
    }, () => {
      store.dispatch("updateSelectedUser", idSelectedUser.value)
    }, { deep: true })


    const filterValue = ref<string>("")
    const usersForSelect = computed(() => store.getters.getAllUsersForSearch(filterValue.value))
    const catcherFilterValue = (value: string) => filterValue.value = value

    //disabling
    const disableButtons = computed(() => store.getters.getIsChangeUserPermissions && store.getters.getIsChangeUserRoles)

    //Permissions
    const allPermissions = computed(() => (store.getters.getSelectedUserPermissions) as Array<Permission>)
    const saveUserSettings = () => {
      store.dispatch("setUserSettings", store.getters.getUserByID(idSelectedUser.value))
    }
    const clearUserSettings = () => {
      store.dispatch("clearUserPermission")
      store.commit("resetSelectedRole")
    }

    const addRemovePermission = (idPermissions: number) => {
      store.dispatch("addRemovePermission", idPermissions)
    }

    //Roles
    const allRoles = computed<Array<AdditionalRole>>(() => store.getters.getSelectedUserRoles)
    const handleChangeRole = (changedRole: AdditionalRole) => {
      store.commit("updateSelectedRole", changedRole)
    }

    return {
      selectUserId,
      catcherFilterValue,
      usersForSelect,
      isContentHeightMobile,
      saveUserSettings,
      clearUserSettings,
      addRemovePermission,
      disableButtons,
      idSelectedUser,
      allPermissions,
      allRoles,
      handleChangeRole,
      RoleNames,
      AdditionalRoles,
      MeaningRoles,
      PermissionsName,
      permissions,
    }
  }
})
