import { Order } from "@/utils/data/interfaces"
import {
  DishForOrderList,
  Product,
} from "@/modules/admin/order-list/models/dishInterfaces"

export const makeDishListMap = (
  orders: Array<Order>
): Map<number, DishForOrderList> => {
  const dishList = new Map<number, DishForOrderList>()
  orders.forEach((order) => {
    order.dishes.forEach((dish) => {
      if (dishList.has(dish.dishId))
        (dishList.get(dish.dishId) as DishForOrderList).amount += dish.amount
      else
        dishList.set(dish.dishId, {
          dishId: dish.dishId,
          amount: dish.amount,
        })
    })
  })
  return dishList
}

export const convertProductUnit = (products: Array<Product>) => {
  products.forEach((product) => {
    if (product.amount >= 1000 && product.unit !== "") {
      product.amount = product.amount / 1000
      switch (product.unit) {
        case "г.":
          product.unit = "кг."
          break
        case "мл.":
          product.unit = "л."
      }
    }
  })
}
