import { reactive, ref } from "vue"
import type { FormRules } from "element-plus"
import type { FormInstance } from "element-plus"

export const formSize = ref("default")

export const ruleFormRef = ref<FormInstance>()

export function useFormRules() {
  const editingRowFormRules = reactive<FormRules>({
    name: [
      {
        required: true,
        message: "Пожалуйста, введите название",
        trigger: ["blur"]
      },
      {
        min: 3,
        max: 20,
        message: "Название должно содержать от 3х до 20ти символов",
        trigger: ["change"]
      },
    ],

    shortUnitName: [
      {
        required: true,
        message: "Пожалуйста, введите краткое наименование",
        trigger: ["blur"]
      },
      {
        min: 2,
        message: "Длина должна быть не менее 2х символов",
        trigger: ["change"]
      },
      {
        max: 5,
        message: "Длина должна быть не более 5 символов",
        trigger: ["change"]
      },
    ],
    productCategory: [
      {
        required: true,
        message: "Пожалуйста, выберите категорию",
        trigger: ["change", "blur"]
      },
    ]
  })
  return { editingRowFormRules }
}
