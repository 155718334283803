import {MenuLink} from "@/modules/portal/models/interfaces/menu-links-interface";

function highlightFirstMenuItem(link: string, menuItems: Array<MenuLink>,) {
  localStorage.setItem('lastSelectedPage', menuItems[0].link)
  menuItems.forEach((menuItem: MenuLink, index) => {
    menuItem.isActive = index === 0
  })
}

export default function highlightMenuItem(menuLinks: Array<MenuLink>, currentLink: string): Array<MenuLink> {

  const links = menuLinks.find((menuItem: MenuLink) => currentLink.includes(menuItem.link))

  if (!links) {
    highlightFirstMenuItem(currentLink, menuLinks)
  } else {
    menuLinks.forEach((menuItem: MenuLink) => {
      menuItem.isActive = currentLink.includes(menuItem.link)
    })
  }
  return menuLinks
}



