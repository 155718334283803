import corporateSourceNames from "@/modules/portal/models/enums/corporate-source-names"

const corporateLinksMap: Record<corporateSourceNames, object> = {
  [corporateSourceNames.INTRANET]: {
    id: 1,
    linkIcon: "",
    link: "https://intranet.relex.ru/",
    title: "Интранет",
  },
  [corporateSourceNames.RELEX]: {
    id: 2,
    linkIcon: "",
    link: "https://www.relex.ru/ru/",
    title: "Сайт РЕЛЭКС",
  },
  [corporateSourceNames.VKONTAKTE]: {
    id: 3,
    linkIcon: "/images/common-icons/vk.png",
    link: "https://vk.com/relex_ru",
    title: "ВКонтакте",
  },
  [corporateSourceNames.TELEGRAM]: {
    id: 4,
    linkIcon: "/images/common-icons/telegram.png",
    link: "https://t.me/relexru",
    title: "Телеграм",
  },
}
export default corporateLinksMap
