import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6dbd6d69"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "orders-issuing-main" }
const _hoisted_2 = { class: "calendar" }
const _hoisted_3 = { class: "table" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "user-name-wrapper" }
const _hoisted_6 = { class: "row-number" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "order-markers-wrapper" }
const _hoisted_10 = {
  key: 0,
  class: "canceled-order"
}
const _hoisted_11 = {
  key: 1,
  class: "is-in-plastic-icon"
}
const _hoisted_12 = { class: "loading" }
const _hoisted_13 = {
  key: 0,
  class: "buttons-horizontal-panel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_order_calendar = _resolveComponent("order-calendar")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_add_spare_dishes_dialog = _resolveComponent("add-spare-dishes-dialog")!
  const _component_add_order_dialog = _resolveComponent("add-order-dialog")!
  const _component_issue_order_dialog = _resolveComponent("issue-order-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_order_calendar, {
          "past-availability": true,
          "date-selector": true,
          "days-with-formed-dish-menu": _ctx.daysWithFormedDishMenu,
          onDateButtonClick: _ctx.dateButtonClicked,
          onSelectorChanged: _ctx.dateButtonClicked,
          onTomorrowDateCalculated: _ctx.tomorrowDateCalculated,
          onLeftScroll: _ctx.calendarScroll,
          onRightScroll: _ctx.calendarScroll
        }, null, 8, ["days-with-formed-dish-menu", "onDateButtonClick", "onSelectorChanged", "onTomorrowDateCalculated", "onLeftScroll", "onRightScroll"])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        _withDirectives(_createVNode(_component_el_table, {
          class: _normalizeClass({ night: _ctx.isNightSelect }),
          border: true,
          data: _ctx.orderTable,
          "highlight-current-row": "",
          "show-summary": "",
          "sum-text": "Итого",
          "summary-method": _ctx.dishSummaryMethod
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              fixed: "",
              "min-width": "160px",
              width: _ctx.searchColumnWidth
            }, {
              header: _withCtx(() => [
                _createVNode(_component_SearchInput, {
                  searchValue: _ctx.search,
                  placeholderValue: "Поиск",
                  onHandleSearch: _ctx.handleSearchInput,
                  onClearSearch: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSearchInput('')))
                }, null, 8, ["searchValue", "onHandleSearch"])
              ]),
              default: _withCtx((scope) => [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.wrapperClass(scope.row)),
                  onClick: ($event: any) => (_ctx.orderSelected(scope.row))
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(scope.row.rowNumber), 1),
                    _createElementVNode("button", {
                      class: "issue-button",
                      disabled: _ctx.issueButtonDisabled(scope.row)
                    }, [
                      _createElementVNode("img", {
                        src: _ctx.issueButtonImage(scope.row),
                        class: "avatar",
                        alt: "Выдача"
                      }, null, 8, _hoisted_8)
                    ], 8, _hoisted_7),
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.userNameClass(scope.row))
                    }, _toDisplayString(scope.row.userFullName), 3)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    (scope.row.isCanceled)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10))
                      : _createCommentVNode("", true),
                    (scope.row.isInPlastic)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11))
                      : _createCommentVNode("", true)
                  ])
                ], 10, _hoisted_4)
              ]),
              _: 1
            }, 8, ["width"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dishNames, (dishName, index) => {
              return (_openBlock(), _createBlock(_component_el_table_column, {
                align: "center",
                "min-width": "120px",
                key: index,
                prop: dishName,
                label: dishName
              }, null, 8, ["prop", "label"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["class", "data", "summary-method"]), [
          [_vShow, _ctx.isOrdersPresent]
        ]),
        _withDirectives(_createElementVNode("p", { class: "orders-not-found" }, _toDisplayString(_ctx.SystemMessages.NO_ORDERS), 513), [
          [_vShow, !_ctx.isOrdersPresent]
        ])
      ], 512), [
        [_vShow, _ctx.isReadyForShow]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_12, "Загрузка... ", 512), [
        [_vShow, !_ctx.isReadyForShow]
      ]),
      (_ctx.isFunctionsAllowed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createVNode(_component_custom_button, {
              "type-button": "success",
              disabled: _ctx.isAddOrderDisabled,
              onClick: _ctx.showAddOrder
            }, {
              default: _withCtx(() => [
                _createTextVNode("Добавить заказ ")
              ]),
              _: 1
            }, 8, ["disabled", "onClick"]),
            _createVNode(_component_custom_button, {
              disabled: _ctx.spareDishesAddingIsDisabled,
              onClick: _ctx.showAddSpareDishes
            }, {
              default: _withCtx(() => [
                _createTextVNode("Добавить свободное блюдо ")
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_add_spare_dishes_dialog, {
        modelValue: _ctx.addSpareDishesVisible,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.addSpareDishesVisible) = $event)),
        onHideAddSpareDishes: _ctx.hideAddSpareDishes
      }, null, 8, ["modelValue", "onHideAddSpareDishes"]),
      _createVNode(_component_add_order_dialog, {
        modelValue: _ctx.addOrderVisible,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.addOrderVisible) = $event)),
        orders: _ctx.orders,
        "all-users": _ctx.allUsers,
        "order-date": _ctx.orderDate,
        onHideAddOrder: _ctx.hideAddOrder
      }, null, 8, ["modelValue", "orders", "all-users", "order-date", "onHideAddOrder"]),
      _createVNode(_component_issue_order_dialog, {
        modelValue: _ctx.issueOrderVisible,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.issueOrderVisible) = $event)),
        order: _ctx.issuingOrder,
        "daily-dishes": _ctx.dishMap,
        onHideIssueOrder: _ctx.hideIssueOrder,
        onOrderDeleted: _ctx.orderDeleted,
        onOrderChanged: _ctx.orderChanged
      }, null, 8, ["modelValue", "order", "daily-dishes", "onHideIssueOrder", "onOrderDeleted", "onOrderChanged"])
    ])
  ]))
}