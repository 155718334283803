import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
//common
import LoginForm from "@/modules/login/views/LoginForm.vue"

//admin's
import OrderList from "@/modules/admin/order-list/views/OrderList.vue"
import PurchasingListsView from "@/modules/admin/purchasing-products/views/PurchasingListsView.vue"
import PurchasingList from "@/modules/admin/purchasing-products/views/PurchasingList.vue"
import PurchasingGuideBooks from "@/modules/admin/purchasing-products/views/PurchasingGuideBooks.vue"
import ListForBuyer from "@/modules/admin/purchasing-products/views/ListForBuyer.vue"
import OrdersIssuing from "@/modules/admin/orders-issuing/views/OrdersIssuing.vue"
import CreateDishMenu from "@/modules/admin/daily-dish-menu/views/CreateDishMenu.vue"
import Report from "@/modules/admin/report/views/Report.vue"
import GuideDishes from "@/modules/admin/dish-guidebook/views/GuideDishes.vue"
import ArchiveDeletedDish from "@/modules/admin/dish-guidebook/components/ArchiveDeletedDish.vue"
import EditDish from "@/modules/admin/dish-guidebook/components/EditDish.vue"
import Handbooks from "@/modules/admin/handbooks/Handbooks.vue"
import GuideIngredients from "@/modules/admin/ingredients-guidebook/views/GuideIngredients.vue"
import ArchiveDeletedIngredient from "@/modules/admin/ingredients-guidebook/components/ArchiveDeletedIngredient.vue"
import AppSettings from "@/modules/admin/AppSettings/views/AppSettings.vue"
import EmojiSettings from "@/modules/admin/AppSettings/components/EmojiSettings.vue"
import Payment from "@/modules/admin/payment/views/Payment.vue"
import PermissionsAndRolesSettings from "@/modules/admin/AppSettings/components/PermissionsAndRolesSettings.vue"
import TimeSettings from "@/modules/admin/AppSettings/components/TimeSettings.vue"
import PaymentSettings from "@/modules/admin/AppSettings/components/PaymentSettings.vue"
//user's
import LunchOrdering from "@/modules/order/views/LunchOrdering.vue"
import OrderHistory from "@/modules/order/views/OrderHistory.vue"
import NotificationSettings from "@/modules/order/views/NotificationSettings.vue"
import AutoOrdering from "@/modules/order/views/AutoOrdering.vue"

import store from "@/store"

const routes: Array<RouteRecordRaw> = [
  //Модули администратора
  {
    path: "/CreateDishMenu",
    name: "CreateDishMenu",
    component: CreateDishMenu,
  },
  {
    path: "/OrderList",
    name: "orderList",
    component: OrderList,
  },
  {
    path: "/PurchasingListsView",
    name: "purchasingListsView",
    component: PurchasingListsView,
  },
  {
    path: "/PurchasingListsView/PurchasingList",
    name: "purchasingList",
    component: PurchasingList,
  },
  {
    path: "/PurchasingListsView/PurchasingGuideBooks",
    name: "purchasingGuideBooks",
    component: PurchasingGuideBooks,
  },
  {
    path: "/PurchasingListsView/ListForBuyer",
    name: "listForBuyer",
    component: ListForBuyer,
  },
  {
    path: "/OrdersIssuing",
    name: "ordersIssuing",
    component: OrdersIssuing,
  },
  {
    path: "/Report",
    name: "report",
    component: Report,
  },
  {
    path: "/Handbooks",
    name: "handbooks",
    redirect: '/Handbooks/GuideDishes',
    component: Handbooks,
    children: [
      {
        path: "GuideDishes",
        name: "guideDishes",
        component: GuideDishes,
      },
      {
        path: "ArchiveDeletedDish",
        name: "archiveDeletedDish",
        component: ArchiveDeletedDish,
      },
      {
        path: "GuideIngredients",
        name: "guideIngredients",
        component: GuideIngredients,
      },
      {
        path: "ArchiveDeletedIngredient",
        name: "archiveIngredients",
        component: ArchiveDeletedIngredient,
      },
    ],
  },
  {
    path: "/Handbooks/EditDish",
    name: "editDish",
    component: EditDish,
  },
  {
    path: "/AppSettings",
    name: "appSetting",
    component: AppSettings,
    redirect: "/AppSettings/Permissions&RolesSettings",
    children: [
      {
        path: "/AppSettings/Permissions&RolesSettings",
        name: "Настройки прав и ролей",
        component: PermissionsAndRolesSettings,
      },
      {
        path: "/AppSettings/TimeSettings",
        name: "Настройки времени",
        component: TimeSettings
      },
      {
        path: "/AppSettings/DetailsSettings",
        name: "Настройки оплаты",
        component: PaymentSettings
      },
      {
        path: "/AppSettings/EmojiSettings",
        name: "Настройки эмодзи",
        component: EmojiSettings,
      },
    ]
  },
  {
    path: "/Payment",
    name: "payment",
    component: Payment,
  },
  //Модули пользователя
  {
    path: "/Menu",
    name: "lunchOrdering",
    component: LunchOrdering,
  },
  {
    path: "/NotificationSettings",
    name: "notificationSettings",
    component: NotificationSettings,
  },
  {
    path: "/AutoOrdering",
    name: "autoOrdering",
    component: AutoOrdering,
  },
  {
    path: "/OrderHistory",
    name: "orderHistory",
    component: OrderHistory,
  },
  {
    path: "/login",
    name: "login",
    component: LoginForm,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !store.getters.isUserAuthorized) {
    return { name: 'login' }
  } else next()
})
export default router
