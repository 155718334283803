export namespace SaveSorting {

    export type CurrentSorting = {
        prop: string,
        order: 'ascending' | 'descending'
    }

    export type SortingObject = {[key: string]: string | any}

    export const applySavedSorting = (savedSorting: CurrentSorting | null, arrayToSort: Array<SortingObject>): Array<SortingObject> => {
        
        if (savedSorting && arrayToSort.length > 0) {

            const isAsc = savedSorting.order === 'ascending'

            if (typeof arrayToSort[0][savedSorting.prop] === 'number') {
                return arrayToSort.sort((obj1, obj2) => isAsc 
                    ? obj1[savedSorting.prop] - obj2[savedSorting.prop] 
                    : obj2[savedSorting.prop] - obj1[savedSorting.prop] 
                )
            }

            if (typeof arrayToSort[0][savedSorting.prop] === 'string') {
                return arrayToSort.sort((obj1, obj2) => {
                    if (isAsc) {
                        return obj1[savedSorting.prop] < obj2[savedSorting.prop] ? -1 : 1
                    } else {
                        return obj1[savedSorting.prop] > obj2[savedSorting.prop] ? -1 : 1
                    }
                })
            }
        }

        return arrayToSort
    }
}
