import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-431bd3ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onMouseover: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMouseOn && _ctx.handleMouseOn(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMouseLeave && _ctx.handleMouseLeave(...args))),
    disabled: _ctx.disabled
  }, [
    _createElementVNode("img", {
      src: _ctx.iconUrl,
      alt: ""
    }, null, 8, _hoisted_2),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 40, _hoisted_1))
}