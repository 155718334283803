
import {computed, defineComponent} from "vue"

import {SortingDishCategory} from "@/modules/admin/daily-dish-menu/composables/useDishesSortingForCard"
import {useStore} from "vuex"

import {CategoryDishes} from "@/utils/data/interfaces"
import CustomCard from "@/common/components/CustomCard.vue";

export default defineComponent({
  name: "MenuTypeCard",
  components: {CustomCard},

  setup() {
    const store = useStore()
    const DailyDishes = computed(() => {
      return SortingDishCategory(store.getters.getDishesForSelectDate) as Array<CategoryDishes>
    })
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    return {
      isNightSelect,
      DailyDishes,
    }
  },
})
