
import { useStore } from "vuex"
import { computed, defineComponent, ref } from "vue"
import { useReceiptFormRules, receiptformSize, receiptRuleFormRef } from "@/modules/admin/purchasing-products/models/receiptFormRules"
import { compareObjects } from "@/utils/helpers/compareObjects"
import { makePurchasingPlaceSelectOptions } from "@/modules/admin/purchasing-products/models/listsSelectOptions"
import { Receipt, ReceiptPicture } from "@/modules/admin/purchasing-products/models/listInterfaces"
import { numberValidator } from "@/utils/helpers/validators"
import { FULLDATE_FORMAT } from "@/utils/data/moment-formats"
import moment from "moment"
import IconButton from "@/common/components/buttons/IconButton.vue"
import { addReceiptPictures } from "@/modules/admin/purchasing-products/models/helpers"
import { saveReceipts } from '@/modules/admin/purchasing-products/models/fetch-modules/saveReceipts'

export default defineComponent({
    components: { IconButton },
    name: "EditReceipt",

    emits: ["closeDialog"],
    setup(props, { emit }) {
        const store = useStore()
        const isNightSelect = computed(() => store.getters.getIsSelectedNight)
        const editingReceipt = computed<Receipt>(() => store.getters.getEditingReceipt)

        const receiptPicturesAmount = computed(() => {
            if (editingReceipt.value.mapId)
                return store.getters.getDownloadedReceiptPictures?.length + store.getters.getAddedReceiptPictures?.length
            else return 0
        })

        const title = computed<string>(() => editingReceipt.value.id ? "Редактирование строки" : "Добавление строки")
        //Dialog handling
        const editDialogWidth = computed(() => store.getters.getBreakpoints.mobile ? "350px" : "600px")
        const dialogOpening = () => {
            store.commit("setEditingReceipt", { ...store.getters.getDefaultReceipt })
            store.commit("resetAddedReceiptPictures")
            if (store.getters.getPicturesFromStore(store.getters.getEditingReceipt.mapId))
                store.commit("fetchDownloadedReceiptPictures", store.getters.getPicturesFromStore(store.getters.getEditingReceipt.mapId))
            receiptPrice.value = editingReceipt.value.price?.toString() ?? ""
            resetDialogValidation()
        }
        const closeDialog = () => {
            store.commit("resetAddedReceiptPictures")
            store.commit("resetDownloadedReceiptPictures")
            emit("closeDialog")
        }
        const resetDialogValidation = () => receiptRuleFormRef.value?.clearValidate()

        //Inputs handling
        const receiptPrice = ref(editingReceipt.value.price?.toString() ?? "")
        const receiptPriceChanged = () => {
            receiptPrice.value = numberValidator(receiptPrice.value)
            editingReceipt.value.price = parseFloat(receiptPrice.value)
        }
        const purchasingPlaceChanged = () => {
            editingReceipt.value.purchasingPlaceName = store.getters.getPurchasingPlaces.get(editingReceipt.value.purchasingPlaceId).name
        }

        //Inputs select options
        const purchasingPlaceSelectOptions = computed(() => makePurchasingPlaceSelectOptions(store.getters.getPurchasingPlaces))

        //Buttons handling

        const isSaveButtonDisabled = computed(() =>
            isResetButtonDisabled.value
            || !editingReceipt.value.date
            || !editingReceipt.value.purchasingPlaceId
            || !editingReceipt.value.price
            || (!receiptPicturesAmount.value && !editingReceipt.value.note))

        const isResetButtonDisabled = computed(() =>
            compareObjects(store.getters.getDefaultReceipt, editingReceipt.value)
            && !store.getters.getAddedReceiptPictures.length)

        const saveClicked = () => {
            editingReceipt.value.dateForDisplaying = moment(editingReceipt.value.date).format(FULLDATE_FORMAT)
            if (!editingReceipt.value.pictureIds) editingReceipt.value.pictureIds = []
            store.commit("addPicturesToStore")
            store.commit("addReceipt", editingReceipt.value)
            closeDialog()
            saveReceipts()
        }

        //Receipt picture handling
        const downloadClicked = (files: File[]) => {
            addReceiptPictures(files, false)
        }

        return {
            isNightSelect,
            title,
            editDialogWidth,
            dialogOpening,
            closeDialog,
            resetDialogValidation,
            ...useReceiptFormRules(),
            receiptformSize,
            receiptRuleFormRef,
            editingReceipt,
            receiptPicturesAmount,
            receiptPrice,
            receiptPriceChanged,
            purchasingPlaceChanged,
            purchasingPlaceSelectOptions,
            isSaveButtonDisabled,
            isResetButtonDisabled,
            saveClicked,
            downloadClicked,
            FULLDATE_FORMAT
        }
    }
})
