import { OrderForOrderList } from "@/modules/admin/order-list/models/dishInterfaces"
import { TableRow } from "@/modules/admin/orders-issuing/models/tableInterfaces"
import { CommentData, Dish } from "@/utils/data/interfaces"
import { User } from "@/utils/data/interfaces"

export const makeOrderMap = (
  orders: Array<OrderForOrderList>
): Map<number, OrderForOrderList> => {
  const orderMap = new Map<number, OrderForOrderList>()
  orders.forEach((order) => {
    order.dishes.filter((dish) => !dish.isAdmin)
    orderMap.set(order.userId, order)
  })
  return orderMap
}
export const getDishNames = (dishes: Array<Dish>): Set<string> => {
  const dishNames = new Set<string>()
  dishes.forEach((dish) => dishNames.add(dish.name))
  return dishNames
}
export const makeDishMenu = (dishes: Array<Dish>): Array<Dish> => {
  const dishMenu: Array<Dish> = []
  dishes.forEach((dish) => {
    dishMenu.push({
      ...dish,
      amount: 0,
      likeAmount: 0,
      liked: false,
      comments: new Array<CommentData>(),
      maxAmount: 20,
    })
  })
  return dishMenu
}
export const clearDishMenu = (dishMenu: Array<Dish>) => {
  dishMenu.forEach((dish) => (dish.amount = 0))
}

export const makeOrderTable = (
  orders: Map<number, OrderForOrderList>,
  dishNames: Set<string>,
  users: Map<number, User>
): Array<TableRow> => {
  const orderTable: Array<TableRow> = []
  orders.forEach((order) => {
    order.isCanceled = !!order.dishes.find((dish) => dish.isCanceled)
    const tableRow: TableRow = {
      userId: order.userId,
      isIssued: order.isIssued as boolean,
      isInPlastic: !!order.isInPlastic,
      userFullName: users.get(order.userId)?.fullUserName ?? "",
      isCanceled: order.isCanceled
    }

    const dishMap = new Map<string, string>()
    order.dishes.forEach((dish) => {
      if (order.isIssued) dish.issueAmount = dish.amount
      if (!dish.isAdmin) dishMap.set(dish.dishName, `${dish.issueAmount} / ${dish.amount}`)
    })

    dishNames.forEach((dishName) => {
      if (dishMap.has(dishName))
        Object.assign(tableRow, { [dishName]: dishMap.get(dishName) as string })
      else Object.assign(tableRow, { [dishName]: `- / -` })
    })

    if (dishMap.size) orderTable.push(tableRow)
  })

  return orderTable
    .sort((item1, item2) => (item1.userFullName === item2.userFullName) ? 0 : item1.userFullName > item2.userFullName ? 1 : -1)
    .map((tableRow, index) => ({ ...tableRow, rowNumber: `${index + 1}.` }))
}
