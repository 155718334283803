
import {computed, defineComponent, PropType} from "vue";
import {useStore} from "vuex";

export default defineComponent({
  name: "CustomCard",
  props: {
    fitContent: {
      type: Boolean as PropType<boolean>,
      default: false
    },
  },
  setup(props) {
    const store = useStore()
    const cardHeight = computed(() => store.getters.getBreakpoints.desktop ? props.fitContent ? "fit-content" : "675px" : "fit-content")
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    return {
      isNightSelect,
      cardHeight
    }
  }
})
