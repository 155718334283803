import {Dish} from "@/utils/data/interfaces"

import DishCategoryName from "@/modules/admin/models/records/DishCategoryName";

export const SortingForTableDishes = (dishes: Array<Dish>, category: string, search?: string): Array<Dish> => {
  if (category === '') return dishes.filter(
    (data: Dish) =>
      (!search || data.name.toLowerCase().includes(search.toLowerCase()))
  )
  else {
    return dishes.filter(dish => {
      if (!search || dish.name.toLowerCase().includes(search.toLowerCase())) {
        if (DishCategoryName[dish.category] === category) return dish
      }
    })
  }
}
