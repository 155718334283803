import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseBold = _resolveComponent("CloseBold")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_search = _resolveComponent("search")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_input, {
      modelValue: _ctx.search,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
      class: _normalizeClass({ night: _ctx.isNightSelect }),
      placeholder: _ctx.placeholderValue,
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setSearchValue(_ctx.search)))
    }, {
      suffix: _withCtx(() => [
        (_ctx.search)
          ? (_openBlock(), _createBlock(_component_el_icon, {
              key: 0,
              class: "el-input__icon",
              onClick: _ctx.clearSearchValue
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CloseBold)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_icon, { class: "el-input__icon" }, {
          default: _withCtx(() => [
            _createVNode(_component_search)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "class", "placeholder"])
  ]))
}