
import { useStore } from "vuex"
import { computed, defineComponent, ref, PropType } from "vue"
import { useListRowFormRules, listRowformSize, listRowRuleFormRef } from "@/modules/admin/purchasing-products/models/listRowFormRules"
import { compareObjects } from "@/utils/helpers/compareObjects"
import {
    makeIngredientsSelectOptions,
    makeManufacturersSelectOptions,
    makeUnitsSelectOptions,
    makePurchasingPlaceSelectOptions
} from "@/modules/admin/purchasing-products/models/listsSelectOptions"
import { SelectOption } from "@/modules/admin/purchasing-products/models/guideBooksSelectOptions"
import { PurchasingListRow } from "@/modules/admin/purchasing-products/models/listInterfaces"
import IngredientsCategoryName from "@/modules/admin/models/records/IngredientsCategoryName"
import IngredientsCategory from "@/modules/admin/models/enums/IngredientsCategory"
import { numberValidator } from "@/utils/helpers/validators"
import { Ingredient } from "@/utils/data/interfaces"
import { AdditionalRoles } from "../../AppSettings/models/rolesHelper"
import moment from "moment"
import { FULLDATE_TIME_FORMAT } from "@/utils/data/moment-formats"

export default defineComponent({
    name: "EditListRow",
    props: {
        listRow: {
            type: Object as PropType<PurchasingListRow>,
            required: true
        },
    },
    emits: ["closeDialog"],
    setup(props, { emit }) {
        const store = useStore()
        const isNightSelect = computed(() => store.getters.getIsSelectedNight)
        const isBuyer = computed(() => store.getters.getUser.additionalRole === AdditionalRoles.BUYER)
        const title = computed<string>(() => props.listRow.id ? "Редактирование строки" : "Добавление строки")
        //Dialog handling
        const editDialogWidth = computed(() => store.getters.getBreakpoints.mobile ? "350px" : "600px")
        const dialogOpening = () => {
            editingRow.value = { ...props.listRow }
            productAmount.value = editingRow.value.amount?.toString() ?? ""
            unitPrice.value = editingRow.value.unitPrice?.toString() ?? ""
        }
        const closeDialog = () => emit("closeDialog")
        const resetDialogValidation = () => listRowRuleFormRef.value?.clearValidate()

        //Form handling
        const editingRow = ref({} as PurchasingListRow)

        //Inputs handling
        const ingredientChanged = () => {
            const ingredient: Ingredient = store.getters.getIngredientMap.get(editingRow.value.ingredientId)
            editingRow.value.ingredientName = ingredient.name
            if (ingredient.lastManufacturerId) {
                editingRow.value.manufacturerId = ingredient.lastManufacturerId
                editingRow.value.manufacturerName = store.getters.getManufacturers.get(ingredient.lastManufacturerId)?.name
            }
            if (ingredient.lastPurchasingPlaceId) {
                editingRow.value.purchasingPlaceId = ingredient.lastPurchasingPlaceId
                editingRow.value.purchasingPlaceName = store.getters.getPurchasingPlaces.get(ingredient.lastPurchasingPlaceId)?.name
            }
            if (ingredient.lastUnitId) {
                editingRow.value.unitId = ingredient.lastUnitId
                editingRow.value.unitName = store.getters.getUnits.get(editingRow.value.unitId)?.name
            }
            if (ingredient.lastUnitPrice)
                editingRow.value.unitPrice = ingredient.lastUnitPrice
        }
        const manufacturerChanged = () => {
            editingRow.value.manufacturerName = store.getters.getManufacturers.get(editingRow.value.manufacturerId)?.name
        }
        const productAmount = ref(editingRow.value.amount?.toString() ?? "")
        const amountChanged = () => {
            if (productAmount.value !== "") {
                productAmount.value = numberValidator(productAmount.value)
                editingRow.value.amount = parseFloat(productAmount.value)
            }
            else editingRow.value.amount = undefined
        }
        const unitPrice = ref(editingRow.value.unitPrice?.toString() ?? "")
        const unitPriceChanged = () => {
            if (unitPrice.value !== "") {
                unitPrice.value = numberValidator(unitPrice.value)
                editingRow.value.unitPrice = parseFloat(unitPrice.value)
            }
            else editingRow.value.unitPrice = undefined
        }
        const unitChanged = () => {
            editingRow.value.unitName = store.getters.getUnits.get(editingRow.value.unitId).name
        }
        const purchasingPlaceChanged = () => {
            editingRow.value.purchasingPlaceName = store.getters.getPurchasingPlaces.get(editingRow.value.purchasingPlaceId).name
        }

        //Inputs select options
        const ingredientsSelectOptions = computed<SelectOption[]>(() => makeIngredientsSelectOptions(store.getters.getIngredientMap))

        const manufacturersSelectOptions = computed<SelectOption[]>(() => makeManufacturersSelectOptions(store.getters.getManufacturers))

        const unitsSelectOptions = computed<SelectOption[]>(() => makeUnitsSelectOptions(store.getters.getUnits))

        const purchasingPlaceSelectOptions = computed(() => makePurchasingPlaceSelectOptions(store.getters.getPurchasingPlaces))

        //Buttons handling
        const isSaveButtonDisabled = computed<boolean>(() => isResetButtonDisabled.value || !editingRow.value.ingredientId)
        const isResetButtonDisabled = computed<boolean>(() => compareObjects(props.listRow, editingRow.value))

        const saveClicked = () => {
            closeDialog()
            editingRow.value.ingredientCategory = IngredientsCategoryName[store.getters.getIngredientMap.get(editingRow.value.ingredientId).category as IngredientsCategory]
            if (editingRow.value.isPurchased) {
                editingRow.value.date = moment().toISOString()
                editingRow.value.dateForDisplaying = moment().format(FULLDATE_TIME_FORMAT)
            }
            if (isBuyer.value) store.commit("removeListRow", props.listRow)
            store.commit("addListRow", editingRow.value)
        }
        const copyClicked = () => {
            closeDialog()
            const newRow: PurchasingListRow = { ...editingRow.value }
            newRow.isCopy = true
            newRow.isPurchased = false
            newRow.date = undefined
            newRow.dateForDisplaying = ""
            store.commit("nextRowLocalId")
            newRow.id = store.getters.getRowLocalId
            newRow.ingredientCategory =
                IngredientsCategoryName[store.getters.getIngredientMap.get(newRow.ingredientId).category as IngredientsCategory]
            store.commit("addListRow", newRow)
        }

        return {
            isNightSelect,
            isBuyer,
            title,
            editDialogWidth,
            dialogOpening,
            closeDialog,
            resetDialogValidation,
            ...useListRowFormRules(),
            listRowformSize,
            listRowRuleFormRef,
            editingRow,
            ingredientChanged,
            manufacturerChanged,
            productAmount,
            amountChanged,
            unitPrice,
            unitPriceChanged,
            unitChanged,
            purchasingPlaceChanged,
            ingredientsSelectOptions,
            manufacturersSelectOptions,
            unitsSelectOptions,
            purchasingPlaceSelectOptions,
            isSaveButtonDisabled,
            isResetButtonDisabled,
            saveClicked,
            copyClicked,
        }
    }
})
