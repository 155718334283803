
import { defineComponent, PropType, ref, watch, computed } from "vue"
import { Dish, OrderedDish } from "@/utils/data/interfaces"
import { Order } from "@/utils/data/interfaces"
import { OrderForOrderList } from "@/modules/admin/order-list/models/dishInterfaces"
import { useStore } from "vuex"
import moment from "moment"
import { FULLDATE_FORMAT } from "@/utils/data/moment-formats"
import { IconButtons } from "@/modules/order/models/data/enums"


export default defineComponent({
    name: "IssueOrderDialog",
    props: {
        //Issuing order
        order: {
            type: Object as PropType<OrderForOrderList>,
            required: true
        },
        //Dishes in current menu
        dailyDishes: {
            type: Map as PropType<Map<number, Dish>>,
            required: true
        }
    },
    emits: ["hideIssueOrder", "orderDeleted", "orderChanged"],
    setup(props, { emit }) {
        const store = useStore()
        const isNightSelect = computed(() => store.getters.getIsSelectedNight)
        //Determines type of dialog - issuing or editing order
        const isIssuingDialog = ref(true)

        const maxAmountDisplay = computed(() => isIssuingDialog.value ? "inline" : "none")

        const userFullName = computed(() => props.order.userName)
        //Dishes for rendering in dialog
        const displayingDishes = ref([] as Array<Dish>)

        let dialogType = ref("выдача" as "выдача" | "редактирование")
        const dialogTitle = computed(() => dialogType.value === "выдача" ? "Выдача заказа" : "Редактирование заказа")
        const dialogTitleClass = computed(() => dialogType.value === "выдача" ? "issue-order-title" : "edit-order-title")

        const makeIssuingDishes = async () => {
            //Making issuing dishes
            displayingDishes.value = []
            props.order.dishes.forEach((dish) => {
                if ((dish.issueAmount) < dish.amount) {
                    displayingDishes.value.push({
                        ...(props.dailyDishes.get(dish.dishId) as Dish),
                        amount: 0,
                        maxAmount: dish.amount - dish.issueAmount,
                    })
                }
            })
        }
        watch(() => props.order, makeIssuingDishes, { deep: true })
        //Issue button disables if no one dish is selected or active(clicked) date is not current date
        const issueOrderDisabled = computed(() =>
            !displayingDishes.value.find((dish) => dish.amount !== 0)
            || moment(props.order.orderDate).format(FULLDATE_FORMAT) !== moment().format(FULLDATE_FORMAT))
        //IssueAll button disables if active(clicked) date is not current date
        const issueAllDisabled = computed(() => moment(props.order.orderDate).format(FULLDATE_FORMAT) !== moment().format(FULLDATE_FORMAT))
        //Issuing confirmation
        const issueDialogVisible = ref(false)
        const showIssueDialog = () => issueDialogVisible.value = true
        const hideIssueDialog = () => issueDialogVisible.value = false
        const issueMessage = ref([] as Array<string>)
        const issueAll = ref(false)

        const issueClicked = () => {
            issueAll.value = false
            issueMessage.value = [userFullName.value]
            displayingDishes.value.forEach((dish) => { if (dish.amount !== 0) issueMessage.value.push(`${dish.name} - ${dish.amount} шт.`) })
            showIssueDialog()
        }

        const issueAllClicked = () => {
            issueMessage.value = [userFullName.value]
            displayingDishes.value.forEach((dish) => issueMessage.value.push(`${dish.name} - ${dish.maxAmount}шт.`))
            issueAll.value = true
            showIssueDialog()
        }

        const issueOrder = async () => {
            hideIssueDialog()
            emit("hideIssueOrder")
            const dishMap: Map<number, number> = new Map()
            displayingDishes.value.forEach((dish) => {
                if (issueAll.value) dishMap.set(dish.dishId, (dish.maxAmount as number))
                else dishMap.set(dish.dishId, dish.amount!)
            })
            props.order.dishes.forEach((dish) => {
                if (dishMap.has(dish.dishId)) {
                    const amount = dishMap.get(dish.dishId) as number
                    dish.issueAmount += amount
                }
            })
            props.order.isIssued = !props.order.dishes.find((dish) => dish.issueAmount < dish.amount)
            const orderForDB: Order = {
                userId: props.order.userId,
                orderDate: props.order.orderDate,
                isIssued: props.order.isIssued,
                dishes: props.order.dishes,
                isInPlastic: props.order.isInPlastic
            }
            await store.dispatch("issueOrder", orderForDB)
        }
        //Delete order
        const deleteButtonImage = IconButtons.DELETE_HOVERED
        //-deleting and editing order is disabled if the order is partly issued
        const deleteButtonDisabled = computed(() => !!props.order.dishes.find((dish) => dish.issueAmount !== 0) || !isIssuingDialog.value)

        const deleteDialogVisible = ref(false)
        const showDeleteDialog = () => deleteDialogVisible.value = true
        const hideDeleteDialog = () => deleteDialogVisible.value = false

        const deleteOrderMessage = computed(() => ["Вы хотите удалить заказ", `Пользователь: ${props.order.userName}`, `Дата: ${moment(props.order.orderDate).format(FULLDATE_FORMAT)}`])

        const deleteOrder = async () => {
            hideDeleteDialog()
            const orderDate = props.order.orderDate
            const userId = props.order.userId
            const userAdditionalRole = store.getters.getUserAdditionalRole
            const userRole = store.getters.getUserRole
            await store.dispatch("deleteUserOrder", { userId, orderDate, userAdditionalRole, userRole })
            emit("orderDeleted", props.order)
        }
        //Edit order
        const isInPlastic = ref(props.order.isInPlastic)
        const editButtonImage = IconButtons.EDIT_HOVERED
        //-save order button is disabled if no one dish selected
        const saveOrderButtonDisabled = computed(() => !displayingDishes.value.find((dish) => dish.amount !== 0))
        const editOrder = () => {
            dialogType.value = "редактирование"
            const orderedDishIds: Array<number> = []
            const tempDishArray: Array<Dish> = props.order.dishes.map((orderedDish) => {
                const tempDish = props.dailyDishes.get(orderedDish.dishId) as Dish
                tempDish.amount = orderedDish.amount
                orderedDishIds.push(orderedDish.dishId)
                return tempDish
            })
            tempDishArray.push(...Array.from(props.dailyDishes.values()).filter((dish) => !orderedDishIds.includes(dish.dishId)))

            displayingDishes.value = tempDishArray
            isInPlastic.value = props.order.isInPlastic
            isIssuingDialog.value = false
        }

        const saveEditedOrderDialogVisible = ref(false)
        const saveEditedOrderMessage = ref([] as Array<string>)
        const orderedDishes = ref([] as Array<OrderedDish>)

        const showSaveEditedOrderDialog = () => {
            orderedDishes.value = []
            saveEditedOrderMessage.value = [`Заказ ${props.order.userName}`, `на ${moment(props.order.orderDate).format(FULLDATE_FORMAT)}`]
            displayingDishes.value.forEach((dish) => {
                if (dish.amount !== 0) {
                    orderedDishes.value.push({
                        dishId: dish.dishId,
                        dishName: dish.name,
                        price: dish.price,
                        amount: dish.amount,
                        isCanceled: false,
                        isSpareOrder: false,
                        issueAmount: 0,
                        isAdmin: false,
                        isIssued: false,
                    } as OrderedDish)
                    saveEditedOrderMessage.value.push(`${dish.name} - ${dish.amount} шт.`)
                }
            })
            saveEditedOrderDialogVisible.value = true
        }

        const hideSaveEditedOrderDialog = () => saveEditedOrderDialogVisible.value = false

        const saveEditedOrder = async () => {
            hideSaveEditedOrderDialog()
            const orderForDB: Order = {
                userId: props.order.userId,
                orderDate: props.order.orderDate,
                isSpareOrder: false,
                isIssued: false,
                isInPlastic: isInPlastic.value,
                dishes: orderedDishes.value
            }
            const userId = props.order.userId
            const userAdditionalRole = store.getters.getUserAdditionalRole
            const userRole = store.getters.getUserRole
            await store.dispatch("changeUserOrder", { userId, orderForDB, userAdditionalRole, userRole })
            emit("orderChanged", orderForDB)
        }
        //Dialog handler
        const hideIssueOrder = () => {
            emit("hideIssueOrder")
        }
        const dialogOpen = () => {
            dialogType.value = "выдача"
            makeIssuingDishes()
            isIssuingDialog.value = true
        }
        const dialogClose = () => displayingDishes.value.forEach((dish) => dish.amount = 0)
        return {
            isIssuingDialog,
            maxAmountDisplay,
            deleteButtonImage,
            hideIssueOrder,
            userFullName,
            displayingDishes,
            dialogTitle,
            dialogTitleClass,
            issueOrderDisabled,
            issueAllDisabled,
            issueDialogVisible,
            hideIssueDialog,
            issueMessage,
            issueClicked,
            issueAllClicked,
            issueOrder,
            deleteButtonDisabled,
            deleteDialogVisible,
            showDeleteDialog,
            hideDeleteDialog,
            deleteOrderMessage,
            deleteOrder,
            isInPlastic,
            editButtonImage,
            saveOrderButtonDisabled,
            editOrder,
            saveEditedOrderDialogVisible,
            saveEditedOrderMessage,
            showSaveEditedOrderDialog,
            hideSaveEditedOrderDialog,
            saveEditedOrder,
            dialogOpen,
            dialogClose,
            isNightSelect
        }
    }

})
