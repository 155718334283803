
import { computed, defineComponent, ref } from "vue"
import { useStore } from "vuex"
import { Delete, Edit } from "@element-plus/icons-vue"
//interfaces
import { Dish } from "@/utils/data/interfaces"

//composables
import useTableFilters from "@/modules/admin/dish-guidebook/composables/useTableFilters"

//child modules
import DialogDeleteDish from "@/modules/admin/dish-guidebook/components/DialogDeleteDish.vue"
import ArchiveDeletedDish from "@/modules/admin/dish-guidebook/components/ArchiveDeletedDish.vue"
import { useRouter } from "vue-router";
import IconButton from "@/common/components/buttons/IconButton.vue"
import { SaveFiltering } from "@/utils/helpers/table/saveTableFiltering"
import { SaveSorting } from "@/utils/helpers/table/saveTableSorting"

export default defineComponent({
  name: "guideDishes",
  components: {
    DialogDeleteDish,
    ArchiveDeletedDish,
    IconButton,
  },
  setup() {
    const { menuFilters, categoryFilters } = useTableFilters()
    const store = useStore()
    const router = useRouter()
    store.dispatch("getDishes")

    const deleteDishDialogVisible = ref(false)

    const dishForPreview = ref<Dish | false>(false)
    const handleCloseDishPreview = () => dishForPreview.value = false
    const handleOpenDishPreview = async (dish: Dish) => {
      await store.dispatch("downloadFullSizeImage", dish.dishId)
      dishForPreview.value = dish
    }
    const descriptionVisible = computed<boolean>(() => !!dishForPreview.value)

    //Для автовыравнивания таблицы
    const tableLayout = ref("auto")

    const searchColumnWidth = computed(() => store.getters.getBreakpoints.mobile ? '167px' : '300px')

    const search = computed<string>({
      get: () => store.getters.getSavedSearch,
      set: (newSearch) => store.commit('saveSearch', newSearch)
    })
    const filters = computed<SaveFiltering.FilterMap>({
      get: () => store.getters.getSavedFiltering,
      set: (filters) => store.commit('saveFilter', filters)
    })
    const sort = computed<SaveSorting.CurrentSorting>({
      get: () => store.getters.getSavedSorting,
      set: (newSort) => store.commit('saveSort', newSort)
    })
    const handleSearchInput = (value: string) => {
      search.value = value
    }
    const handleFilterChange = (newFilters: SaveFiltering.FilterMap) => filters.value = newFilters
    const handleSortChange = (newSort: SaveSorting.CurrentSorting) => sort.value = newSort

    const computedTableData = computed(() => SaveSorting.applySavedSorting(
      store.getters.getSavedSorting,
      store.getters.getDishesForGuide.filter(
        (dish: Dish) =>
          (!search.value || dish.name.toLowerCase().includes(search.value.toLowerCase())) &&
          (!store.getters.getSavedFiltering || SaveFiltering.isObjectFieldsSatisfyMapRequirments(dish, store.getters.getSavedFiltering))
      )
    )
    )

    //Кнопка удалить
    const deleteDish = ref({} as Dish)
    const handleDelete = (row: Dish) => {
      deleteDishDialogVisible.value = true
      deleteDish.value = row
    }

    const handleDish = (row?: Dish) => {
      if (row) {
        localStorage.setItem("dishId", `${row.dishId}`)
      } else {
        localStorage.removeItem("dishId")
      }
      router.push("/Handbooks/EditDish")
    }

    //Фильтр отдельный для типов меню
    const filterHandlerMenu = (value: string, row: Dish) => {
      return row.menuType === value
    }
    const filterHandlerCategory = (value: string, row: Dish) => {
      return row.category === value
    }
    const closeDialog = () => {
      deleteDishDialogVisible.value = false
    }
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    return {
      isNightSelect,
      closeDialog,
      handleDish,
      handleDelete,
      deleteDish,
      computedTableData,
      filterHandlerMenu,
      filterHandlerCategory,
      Edit,
      Delete,
      search,
      handleSearchInput,
      filters,
      tableLayout,
      menuFilters,
      categoryFilters,
      dishForPreview,
      handleCloseDishPreview,
      handleOpenDishPreview,
      descriptionVisible,
      searchColumnWidth,
      deleteDishDialogVisible,
      handleFilterChange,
      handleSortChange,
    }
  },
})
