
import { computed, defineComponent } from "@vue/runtime-core"
import { useStore } from "vuex"
import { ref } from "vue"

//child components
import LogoRelex from "@/modules/portal/components/header/components/LogoRelex.vue"
import Logout from "@/modules/portal/components/header/components/Logout.vue"
import Menu from "@/modules/portal/components/header/components/menu/Menu.vue"
import MenuIcon from "@/modules/portal/components/header/components/menu/MenuIcon.vue"
import DayNightToggleSwitchVue from "@/common/components/buttons/DayNightToggleSwitch.vue"
//functions
import { getUserSurnameAndInitials } from "@/utils/helpers/getUserSurnameAndInitials"
// interfaces
import { User } from "@/utils/data/interfaces"
import router from "@/router"

export default defineComponent({
  name: "Header",
  components: {
    MenuIcon,
    Menu,
    DayNightToggleSwitchVue,
    Logout,
    LogoRelex,
  },

  setup() {
    const store = useStore()
    const LogOutDialogVisible = ref(false)
    const browAppUpdateVisible = ref(false)
    const menuShown = computed(() => store.getters.isShowMenu)

    const isUserAuthorized = computed(() => store.getters.isUserAuthorized && router.currentRoute.value.path !== '/login')
    const hasAllTypesPermissions = computed(() => store.getters.getHasAllTypesPermissions)

    const userMenuActive = computed(() => !store.getters.isAdminMenuEnabled)
    const adminMenuActive = computed(() => store.getters.isAdminMenuEnabled)

    const activeRole = computed(() => store.getters.getActiveRole)

    const userData = computed(() => store.getters.getUser as User)
    const avatar = computed(() => store.getters.getUser.avatar as string)

    const titleUserName = computed(() => !!userData.value.userId ? getUserSurnameAndInitials(userData.value) : "")

    const changeMenu = async (role: string) => {
      if (role == activeRole.value) return
      localStorage.removeItem("lastClickedDate")
      await store.dispatch("changeActiveRole", role)
      await navigateToDefaultPage()
    }

    const goToDefaultPage = async () => {
      await navigateToDefaultPage()
    }

    const navigateToDefaultPage = async () => {
      await router.push(store.getters.getDefaultPageLink)
    }

    const CloseDialog = () => {
      LogOutDialogVisible.value = false
    }

    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    const isNotMobile = computed(() => !store.getters.getBreakpoints.mobile)
    return {
      menuShown,
      browAppUpdateVisible,
      userMenuActive,
      adminMenuActive,
      changeMenu,
      userData,
      LogOutDialogVisible,
      CloseDialog,
      goToDefaultPage,
      isUserAuthorized,
      hasAllTypesPermissions,
      isNightSelect,
      activeRole,
      avatar,
      titleUserName,
      isNotMobile
    }
  },
})
