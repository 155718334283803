
import {useStore} from "vuex"

export default {
  name: "MenuIcon",

  setup() {
    const store = useStore()

    const menuIconClick = () => {
      store.dispatch("toggleMenuIcon")
    }
    return {
      menuIconClick
    }
  }
}
