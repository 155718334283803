
import { computed, defineComponent, PropType, ref } from "vue"

import moment from "moment"
import { createYearOptions, monthOptions } from "@/modules/order/models/filterOptions"
import { useStore } from "vuex";

export default defineComponent({
  name: "SelectorsMonthAndYear",
  props: {
    numberOfYearsAgo: {
      type: Number as PropType<number>,
      required: true
    }
  },
  emits: ["selectedPeriod"],
  setup(props, { emit }) {
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    moment.locale("ru")

    const monthValue = ref(moment().month() + 1)
    const yearValue = ref(moment().year())
    const yearOptions = createYearOptions(moment().year() - props.numberOfYearsAgo)

    const date = computed(() => (moment().year(yearValue.value).month(monthValue.value - 1).date(1).hours(0).minutes(0).seconds(0).milliseconds(0)))
    //adding 3 hours is necessary to remove the difference of 3 hours when converting time to UTC
    //TODO:find a way not to add 3 hours, but to get immediately 00:00 of the first day of the selected month
    const dateFrom = computed(() => date.value.add(3, "hour").toISOString())
    const dateTo = computed(() => date.value.add(1, "month").subtract(1, "hour").toISOString())

    emit("selectedPeriod", dateFrom.value, dateTo.value)
    const periodChanged = () => emit("selectedPeriod", dateFrom.value, dateTo.value)

    return {
      isNightSelect,
      monthValue,
      yearValue,
      yearOptions,
      monthOptions,
      periodChanged,
    }
  },
})
