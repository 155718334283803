import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31c35036"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toggle" }
const _hoisted_2 = { class: "toggle-control" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isNightSelect) = $event)),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleSwitchClick && _ctx.toggleSwitchClick(...args)))
      }, null, 512), [
        [_vModelCheckbox, _ctx.isNightSelect]
      ]),
      _createElementVNode("span", {
        class: _normalizeClass(["control", { night: _ctx.isNightSelect }])
      }, null, 2)
    ])
  ]))
}