
import Kitchen from "@/modules/portal/views/Kitchen.vue"

import { useStore } from "vuex"
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "App",
  components: {
    Kitchen,
  },
  created() {
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    navigator.serviceWorker?.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },
  setup() {
    const store = useStore()
    store.dispatch("fetchUserData")
    store.dispatch("startWatchBreakpoints")

    const registration = ref<ServiceWorkerRegistration>()
    const updateExists = ref<Boolean>(false)
    const refreshing = ref<Boolean>(false)

    const updateAvailable = (event: any) => {
      registration.value = (<CustomEvent>event).detail
      updateExists.value = true
      refreshApp()
    }
    const refreshApp = () => {
      updateExists.value = false
      if (!registration.value || !registration.value.waiting) return
      registration.value.waiting.postMessage({ type: 'SKIP_WAITING' })
    }

    const bodyElement = document.querySelector("body")
    watch(() => store.getters.getIsSelectedNight, () => {
      if (store.getters.getIsSelectedNight) bodyElement?.classList.add("night")
      else bodyElement?.classList.remove("night")
    })

    return {
      refreshing,
      registration,
      updateExists,
      updateAvailable,
      refreshApp
    };
  }
})
