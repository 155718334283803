
import { useStore } from "vuex"
import { computed, defineComponent, ref, onMounted } from "vue"
import router from "@/router"
import EditGuideBookRow from "@/modules/admin/purchasing-products/components/EditGuideBookRow.vue"
import { guideBooksSelectOptions } from "@/modules/admin/purchasing-products/models/guideBooksSelectOptions"
import { PurchasingGuideBooks, SecondColumnNames } from "@/modules/admin/purchasing-products/models/enums"
import { GuideBookTableRow } from "@/modules/admin/purchasing-products/models/guideBookInterfaces"

export default defineComponent({
    name: "PurchasingGuideBooks",
    components: {
        EditGuideBookRow
    },
    setup() {
        const store = useStore()
        const selectedGuideBook = ref(PurchasingGuideBooks.PURCHASING_PLACES)
        const showPurchasingLists = () => router.push("/PurchasingListsView")
        //guideBookTableData is formed in store module according chosen guidebook
        const guideBookTableData = computed(() => Array.from(store.getters.getPurchasingGuideBookTableData.values()) as Array<GuideBookTableRow>)
        //Second column name in guidebook table is determined according chosen guidebook
        const column2Label = ref(SecondColumnNames.PURCHASING_PLACES)
        const downloadTableData = async () => {
            if (!!localStorage.getItem("selectedGuideBook")) selectedGuideBook.value = localStorage.getItem("selectedGuideBook") as PurchasingGuideBooks
            if (selectedGuideBook.value === PurchasingGuideBooks.MANUFACTURERS) {
                column2Label.value = SecondColumnNames.MANUFACTURERS
                await store.dispatch("downloadManufacturers")
            }
            if (selectedGuideBook.value === PurchasingGuideBooks.PURCHASING_PLACES) {
                column2Label.value = SecondColumnNames.PURCHASING_PLACES
                await store.dispatch("downloadPurchasingPlaces")
            }
            if (selectedGuideBook.value === PurchasingGuideBooks.UNITS) {
                column2Label.value = SecondColumnNames.UNITS
                await store.dispatch("downloadUnits")
            }
        }
        //Download initial data
        downloadTableData()

        const guideBookSelectorChanged = async () => {
            localStorage.setItem("selectedGuideBook", selectedGuideBook.value)
            store.commit("clearTable")
            await downloadTableData()
            localStorage.setItem("column2Label", column2Label.value)
        }
        //Table row handling
        const addRow = () => {
            editingRow.value = {
                id: 0,
                name: "",
                guideBookType: selectedGuideBook.value,
                column2Label: "",
                column2Value: "",
                shortUnitName: "",
                parentPlace: "",
                productCategory: "",

            }
            showEditingDialog()
        }
        const editRow = (row: GuideBookTableRow) => {
            editingRow.value = row
            showEditingDialog()
        }
        const deleteRow = (row: GuideBookTableRow) => {
            editingRow.value = row
            showDeletingDialogWindow(row)
        }

        //Editing dialog
        const editingRow = ref({} as GuideBookTableRow)
        const editingDialogVisible = ref(false)
        const hideEditingDialog = () => editingDialogVisible.value = false
        const showEditingDialog = () => editingDialogVisible.value = true

        //Deleting dialog hadling
        const deletingDialogText = ref([""])
        const deletingDialogWindowVisible = ref(false)
        const showDeletingDialogWindow = (editingRow: GuideBookTableRow) => {
            deletingDialogText.value = [`${editingRow.guideBookType}`.toUpperCase(), `${editingRow.name}`]
            deletingDialogWindowVisible.value = true
        }
        const hideDeletingDialogWindow = () => deletingDialogWindowVisible.value = false
        const confirmDeleting = () => {
            hideDeletingDialogWindow()
            store.dispatch("deleteGuideBookTableRow", editingRow.value)
        }

        onMounted(() => {
            if (localStorage.getItem("selectedGuideBook")) {
                selectedGuideBook.value = localStorage.getItem("selectedGuideBook") as PurchasingGuideBooks
                column2Label.value = localStorage.getItem("column2Label") as SecondColumnNames
            }
        })

        const isNightSelect = computed(() => store.getters.getIsSelectedNight)
        return {
            isNightSelect,
            guideBooksSelectOptions,
            selectedGuideBook,
            showPurchasingLists,
            guideBookTableData,
            column2Label,
            guideBookSelectorChanged,
            addRow,
            editRow,
            deleteRow,
            editingRow,
            editingDialogVisible,
            hideEditingDialog,
            showEditingDialog,
            deletingDialogText,
            deletingDialogWindowVisible,
            showDeletingDialogWindow,
            hideDeletingDialogWindow,
            confirmDeleting
        }
    }
})
