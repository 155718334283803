import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56f4fffa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top-right" }
const _hoisted_2 = { class: "menu-items" }
const _hoisted_3 = { class: "menu-item" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoRelex = _resolveComponent("LogoRelex")!
  const _component_DayNightToggleSwitchVue = _resolveComponent("DayNightToggleSwitchVue")!
  const _component_custom_close_cross = _resolveComponent("custom-close-cross")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ active: _ctx.menuShown, night: _ctx.isNightSelect }, "menu"])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["top", { hide: !_ctx.menuShown }])
    }, [
      _createVNode(_component_LogoRelex, {
        class: "left-part-logo",
        visible: !_ctx.menuShown
      }, null, 8, ["visible"]),
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createVNode(_component_DayNightToggleSwitchVue, null, null, 512), [
          [_vShow, _ctx.isMobile]
        ]),
        _createVNode(_component_custom_close_cross, {
          "is-show": _ctx.menuShown,
          onClick: _ctx.menuIconClick
        }, null, 8, ["is-show", "onClick"])
      ])
    ], 2),
    _createElementVNode("nav", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedMenu, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.id,
          class: _normalizeClass(["menu-items-container", { 'selected-route': item.isActive, night: _ctx.isNightSelect }])
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass(["link", { night: _ctx.isNightSelect }]),
              onClick: ($event: any) => (_ctx.handleLinkClick(item))
            }, _toDisplayString(item.title), 11, _hoisted_4)
          ])
        ], 2))
      }), 128))
    ])
  ], 2))
}