import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bfa019c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "dishes" }
const _hoisted_3 = { class: "buttons-horizontal-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_button = _resolveComponent("close-button")!
  const _component_dishes_items = _resolveComponent("dishes-items")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_dialog_window = _resolveComponent("dialog-window")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "show-close": false,
    width: "550px",
    top: "5vh",
    class: _normalizeClass({ night: _ctx.isNightSelect })
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_close_button, { onClick: _ctx.hideAddSpareDishes }, null, 8, ["onClick"])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["title", { night: _ctx.isNightSelect }])
      }, "Добавить свободное блюдо", 2)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_scrollbar, { height: 300 }, {
          default: _withCtx(() => [
            _createVNode(_component_dishes_items, {
              dishes: _ctx.dishMenu,
              "type-click-on-dish": "add",
              "user-role": "admin"
            }, null, 8, ["dishes"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_custom_button, {
            name: "order-button",
            "type-button": "success",
            disabled: _ctx.addSpareDishesDisabled,
            onClick: _ctx.showAddSpareDishesDialog
          }, {
            default: _withCtx(() => [
              _createTextVNode("Добавить")
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])
      ]),
      _createVNode(_component_dialog_window, {
        "show-close": false,
        modelValue: _ctx.addSpareDishesDialogVisible,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.addSpareDishesDialogVisible) = $event)),
        title: "Добавление свободных блюд",
        "main-text": _ctx.orderMessage,
        onCancelClicked: _ctx.hideAddSpareDishesDialog,
        onOkClicked: _ctx.addSpareDishes
      }, null, 8, ["modelValue", "main-text", "onCancelClicked", "onOkClicked"])
    ]),
    _: 1
  }, 8, ["class"]))
}