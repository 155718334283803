import { createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a3d8780"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "time-settings-wrapper" }
const _hoisted_4 = { class: "set-timing" }
const _hoisted_5 = { class: "timing" }
const _hoisted_6 = { class: "timing-label" }
const _hoisted_7 = { class: "picker" }
const _hoisted_8 = { class: "buttons-horizontal-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_time_picker = _resolveComponent("time-picker")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_custom_card = _resolveComponent("custom-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_custom_card, { fitContent: true }, {
        header: _withCtx(() => [
          _createTextVNode("Настройки времени")
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.times, (time) => {
                return (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.TimeSettingName[time.type as timeSettings]), 1),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_time_picker, {
                      class: _normalizeClass(["time-settings", { night: _ctx.isNightSelect }]),
                      modelValue: time.timeSetting,
                      "onUpdate:modelValue": ($event: any) => ((time.timeSetting) = $event),
                      "start-hour": 8,
                      "end-hour": 23
                    }, null, 8, ["class", "modelValue", "onUpdate:modelValue"])
                  ])
                ]))
              }), 256))
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_custom_button, {
                "type-button": "success",
                onClick: _ctx.setTimeSettings
              }, {
                default: _withCtx(() => [
                  _createTextVNode("СОХРАНИТЬ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}