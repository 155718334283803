/* eslint-disable no-console */
import {register} from 'register-service-worker'

register(`/service-worker.js`, {
  ready() {
    console.log(
      'Приложение обслуживается с помощью service worker'
    )
  },
  registered(registration) {
    console.log('Service worker зарегестрирован')
    setInterval(() => registration.update(), 30000)
  },
  cached() {
    console.log('Содержимое было кэшировано для использования в автономном режиме')
  },
  updatefound() {
    console.log('Загружается новый контент.')
  },

  updated(registration) {
    console.log('Доступен новый контент; пожалуйста, обновите страницу')
    document.dispatchEvent(
      new CustomEvent('swUpdated', {detail: registration})
    )
  },
  offline() {
    console.log('Нет подключения к Интернету. Приложение работает в автономном режиме')
  },
  error(error) {
    console.error('Ошибка при регистрации service worker:', error)
  }
})