enum IngredientsCategory {
  CEREAL = "CEREAL",
  SEAFOOD = "SEAFOOD",
  GROCERY = "GROCERY",
  SPICE = "SPICE",
  MILK = "MILK",
  CHEESE = "CHEESE",
  VEGETABLE = "VEGETABLE",
  FRUIT = "FRUIT",
  MEAT = "MEAT",
  FISH = "FISH",
  BROTHS = "BROTHS",
  WATER = "WATER",
  FREEZE = "FREEZE",
  GREENS = "GREENS",
}

export default IngredientsCategory;
