import moment from "moment"
import { Module } from "vuex"
import { downloadDaysWithFormedDishMenu } from "@/modules/order/models/fetchModules/fetchModuleOrdering"
import { FULLDATE_FORMAT } from "@/utils/data/moment-formats"
import { WeekConstants } from "@/utils/data/constants"

export const dateStore: Module<any, any> = {
  state: {
    currentDate: moment(),
    dateChecked: false,
  },
  getters: {
    getCurrentDate(state) {
      return state.currentDate
    },
    isDateChecked(state) {
      return state.dateChecked
    },
  },
  mutations: {
    fetchCurrentDate: (state, currentDate: moment.Moment) => {
      state.currentDate = currentDate
      state.dateChecked = true
    },
    resetCheck(state) {
      state.dateChecked = false
    },
  },
  actions: {
    async initCurrentDate({commit}){
      commit("fetchCurrentDate", moment())
    },
    async checkCurrentDate({ commit }) {
      if (moment().day() === WeekConstants.SUNDAY)
        commit("fetchCurrentDate", moment().add(1, "day"))
      if (moment().day() === WeekConstants.SATURDAY) {
        const dateFrom = moment().hours(0).toISOString()
        const dateTo = moment(dateFrom).add(1, "day").toISOString()
        const res = await downloadDaysWithFormedDishMenu(dateFrom, dateTo)
        if (res.ok) {
          const data = await res.json()
          const daysWithMenu: Array<string> = data.data.dates.map((date: string) =>
            moment(date).format(FULLDATE_FORMAT)
          )
          if (!daysWithMenu.includes(moment().format(FULLDATE_FORMAT)))
            commit("fetchCurrentDate", moment().add(2, "day"))
          else commit("fetchCurrentDate", moment())
        }
      }
    },
  },
}
export default dateStore
