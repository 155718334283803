import { vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8a749ca8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "disabled-icon"
}
const _hoisted_2 = { class: "download-icon-button" }
const _hoisted_3 = ["multiple"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.disabled)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [
          [_vShow, !_ctx.isFullSize]
        ])
      : _createCommentVNode("", true),
    (_ctx.disabled)
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["fullsize-button disabled-fullsize", { night: _ctx.isNightSelected }])
        }, "ЗАГРУЗИТЬ ЧЕК", 2)), [
          [_vShow, _ctx.isFullSize]
        ])
      : (_openBlock(), _createElementBlock("label", {
          key: 2,
          for: "file-upload",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.inputRef?.click()))
        }, [
          _withDirectives(_createElementVNode("div", _hoisted_2, null, 512), [
            [_vShow, !_ctx.isFullSize]
          ]),
          _withDirectives(_createElementVNode("div", {
            class: _normalizeClass(["fullsize-button reset", { night: _ctx.isNightSelected }])
          }, "ЗАГРУЗИТЬ ЧЕК", 2), [
            [_vShow, _ctx.isFullSize]
          ])
        ])),
    _createElementVNode("input", {
      onInput: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.downloadClicked && _ctx.downloadClicked(...args)), ["stop"])),
      multiple: _ctx.multipleSelection,
      ref: "inputRef",
      accept: ".jpg,.png,.webp,.svg",
      capture: "",
      style: {"display":"none"},
      type: "file"
    }, null, 40, _hoisted_3)
  ]))
}