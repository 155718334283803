import {headers} from "@/utils/data/constants"
import {handleFetchErrors} from "./handleFetchErrors"

const _fetch = (url: string, requestOptions: any) =>
  fetch(url, requestOptions).then((response) => {
    if (!response.ok) {
      // HANDLE ERRORS
      if (response.status) handleFetchErrors(response.status)
    }
    return response
  })

export const get = (url: string, params?: any) => {
  if (params) {
    const query = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&")
    url = `${url}?${query}`
  }
  const requestOptions = {
    method: "GET",
    headers,
  }
  return _fetch(url, requestOptions)
}

export const post = async (url: string, params?: any) => {
  const requestOptions = {
    method: "POST",
    headers,
    body: JSON.stringify(params),
  }
  return await _fetch(url, requestOptions)
}

export const deleteBody = async (url: string, params: any) => {
  const requestOptions = {
    method: "DELETE",
    headers,
    body: JSON.stringify(params),
  }
  return await _fetch(url, requestOptions)
}

export const deleteUrl = (url: string, params: any) => {
  if (params) {
    const query = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&")
    url = `${url}?${query}`
  }
  const requestOptions = {
    method: "DELETE",
    headers,
  }
  return _fetch(url, requestOptions)
}

export const put = async (url: string, params: any) => {
  const requestOptions = {
    method: "PUT",
    headers,
    body: JSON.stringify(params),
  }
  return await _fetch(url, requestOptions)
}
