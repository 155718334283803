
import {Dish} from "@/utils/data/interfaces"
import {defineComponent, PropType} from "vue"
import {useStore} from "vuex"
import ConvertIngredientsForBack from "@/store/modules/admin/utils/ConvertIngredientsForBack";
import ConvertEmojisForBack from "@/store/modules/admin/utils/ConvertEmojisForBack";


export default defineComponent({
  props: {
    dish: {
      type: Object as PropType<Dish>,
      required: true,
    },
  },
  emits: ["closeDialog"],
  setup(props, {emit}) {
    const store = useStore()

    const dropDish = async () => {
      const tempDish = store.getters.getDishesForFind.find((dish:Dish)=> props.dish.dishId === dish.dishId)  as Dish
      tempDish.isDeleted = true
      await store.dispatch("deleteDish", {...tempDish,
        ingredients: ConvertIngredientsForBack(tempDish.ingredients),
        emojiIds: ConvertEmojisForBack(tempDish.emojis),
      })
      emit("closeDialog")
    }
    const CloseModalWindow = () => {
      emit("closeDialog")
    }
    return {
      dropDish,
      CloseModalWindow
    }
  },
})
