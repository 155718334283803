import { Module } from "vuex"
import { elMessage } from "@/modules/order/models/elMessage"
import {
  downloadDishMenu,
  downloadDishes,
  downloadDaysWithFormedDishMenu,
  downloadFullSizeImage
} from "@/modules/order/models/fetchModules/fetchModuleOrdering"
import { SystemMessages, TypeMessages } from "@/modules/order/models/data/enums"
import { Dish } from "@/utils/data/interfaces"
import { convertToDishModel } from "@/modules/order/models/dishMenuParser"
import { DishMenuParser } from "@/modules/order/models/dishMenuParser"
import {
  DailyMenu,
} from "@/modules/order/models/dishInterfaces"
import moment from "moment"
import { FULLDATE_FORMAT } from "@/utils/data/moment-formats"

export const dishesModule: Module<any, any> = {
  state: {
    //dishesForMenu includes dishes downloaded on currrent period {dateFrom, dateTo}
    dishesForMenu: [] as Array<Dish>,
    //dishMenu contains dishes downloaded on current period and all previous periods
    dishMenu: new Map<string, DailyMenu>(),
    //dishesForDescription contents unique dishes present in menu
    dishesForDescription: new Map<number, Dish>(),

    daysWithFormedDishMenu: new Set<string>(),
    dishMenuDownloaded: false,
    //Dishes downloaded by Id
    downloadedDishes: new Map<number, Dish>(),
    //All downloaded dishes sorted by date
    dailyDishes: new Map<string, Dish[]>(),

    //Non compressed dish image
    fullSizeImage: "",
  },
  getters: {
    getDishesForMenu(state) {
      return state.dishesForMenu
    },
    getDishMenu(state) {
      return state.dishMenu
    },
    getDaysWithFormedDishMenu(state) {
      return state.daysWithFormedDishMenu
    },
    getDishesForDescription(state) {
      return state.dishesForDescription
    },
    isDishMenuDownloaded(state) {
      return state.dishMenuDownloaded
    },
    getDownloadedDishes(state) {
      return state.downloadedDishes
    },
    getFullSizeImage(state) {
      return state.fullSizeImage
    },
    getDailyDishes: (state) => (date: string): Array<Dish> =>
      state.dailyDishes.has(date) ? state.dailyDishes.get(date) : [] as Array<Dish>,
  },
  mutations: {
    fetchDishMenu(state, data) {
      const dishMenuParser = new DishMenuParser(convertToDishModel(data.data))
      state.dishesForMenu = dishMenuParser.getDishesForMenu()
      Array.from(dishMenuParser.getDishMenu().entries()).forEach((dailyMenu) => {
        if (!state.dishMenu.has(dailyMenu[0])) state.dishMenu.set(dailyMenu[0], dailyMenu[1])
      })
      state.dishMenuDownloaded = true
    },
    fetchDishesForDescription(state) {
      state.dishesForMenu.forEach((dish: Dish) => {
        if (!state.dishesForDescription.has(dish.dishId)) state.dishesForDescription.set(dish.dishId, dish)
      })
    },
    fetchDailyDishes(state) {
      state.dishesForMenu.forEach((dish: Dish) => {
        let isPresent = false
        if (state.dailyDishes.has(dish.date)) {
          state.dailyDishes.get(dish.date).forEach((dishDish: Dish) => { if (dishDish.dishId === dish.dishId) isPresent = true })
          if (!isPresent) state.dailyDishes.get(dish.date).push(dish)
        }
        else state.dailyDishes.set(dish.date, [dish])
      })
    },
    fetchDaysWithFormedDishMenu(state, data) {
      data.data.dates.forEach((date: string) =>
        state.daysWithFormedDishMenu.add(moment(date).format(FULLDATE_FORMAT))
      )
    },

    fetchDownloadedDishes(state, dishMap) {
      state.downloadedDishes = dishMap
    },
    fetchFullSizeImage(state, data) {
      state.fullSizeImage = data.data.fullSizeImage
    }
  },
  actions: {
    async downloadDishMenu({ commit }, { dateFrom, dateTo }) {
      const res = await downloadDishMenu(dateFrom, dateTo)
      if (res.ok) {
        const data = await res.json()
        commit("fetchDishMenu", data)
        commit("fetchDishesForDescription")
        commit("fetchDailyDishes")
      } else {
        elMessage(SystemMessages.DISH_MENU_FAIL, TypeMessages.ERROR)
      }
    },
    downloadDaysWithFormedDishMenu: async (
      { commit },
      { dateFrom, dateTo }
    ) => {
      const res = await downloadDaysWithFormedDishMenu(dateFrom, dateTo)
      if (res.ok) {
        const data = await res.json()
        commit("fetchDaysWithFormedDishMenu", data)
      }
    },
    downloadDishes: async ({ commit }, dishIds) => {
      const res = await downloadDishes(dishIds)
      if (res.ok) {
        const data = await res.json()
        const dishMap = new Map<number, Dish>(data.data.map((dish: Dish) => {
          return [dish.dishId, dish]
        }))
        commit("fetchDownloadedDishes", dishMap)
      } else {
        elMessage(SystemMessages.NO_ORDERS, TypeMessages.WARNING)
      }
    },
    downloadFullSizeImage: async ({ commit }, dishId) => {
      const res = await downloadFullSizeImage(dishId)
      if (res.ok) {
        const data = await res.json()
        commit("fetchFullSizeImage", data)
      } else
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.WARNING)
    }
  },
}
export default dishesModule
