
import { useStore } from "vuex"
import { defineComponent, ref } from "vue";
import { DayNightStatuses } from "@/modules/order/models/data/enums";

export default defineComponent({
  name: "ToggleSwitchDayNight",

  setup() {
    const store = useStore()
    store.dispatch("initializeDayNightStatus", localStorage.getItem("dayNightStatus") ?? DayNightStatuses.DAY)
    const isNightSelect = ref(store.getters.getIsSelectedNight)

    const toggleSwitchClick = () => {
      store.dispatch("switchDayNight")
      localStorage.setItem("dayNightStatus", store.getters.getDayNightStatus)
    }
    return {
      isNightSelect,
      toggleSwitchClick
    }
  },

})
