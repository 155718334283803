enum DishCategory {
  BREAKFAST="BREAKFAST",
  SOUP="SOUP",
  GARNISH="GARNISH",
  SECONDCOURSE="SECONDCOURSE",
  SALAD="SALAD",
  BAKERY="BAKERY",
  DRINK="DRINK",
}

export default DishCategory
