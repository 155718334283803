
import { useStore } from "vuex"
import { computed, defineComponent, watch, ref } from "vue"

import Header from "@/modules/portal/components/header/views/Header.vue"
import Footer from "@/modules/portal/components/footer/Footer.vue"
import LogoRelex from "@/modules/portal/components/header/components/LogoRelex.vue";
import LoginForm from "@/modules/login/views/LoginForm.vue";
import router from "@/router";
import checkRoleForCurrentLink from "@/store/modules/admin/utils/for-login-form/CheckRoleForCurrentLink";

export default defineComponent({
  name: "Home",
  components: {
    LoginForm,
    LogoRelex,
    Header,
    Footer,
  },
  setup() {
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    const menuShown = computed(() => store.getters.isShowMenu)
    const backgroundHeight = ref("100vh")

    window.onscroll = () => backgroundHeight.value = `${document.getElementById("home")?.scrollHeight}px`

    watch(
      () => router.currentRoute.value.path,
      () => {
        const checkedRole = checkRoleForCurrentLink(router.currentRoute.value.path)
        if (checkedRole !== store.getters.getActiveRole) {
          store.dispatch("changeActiveRole", checkedRole)
        }
        store.dispatch("selectMenuItem", router.currentRoute.value.path)
      },
      { deep: true }
    )

    return {
      isNightSelect,
      backgroundHeight,
      menuShown,
    }
  }
})
