import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ec8e905c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "bottom-table" }
const _hoisted_3 = { class: "bottom" }
const _hoisted_4 = { class: "buttons-horizontal-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogDeleteDish = _resolveComponent("DialogDeleteDish")!
  const _component_dish_description = _resolveComponent("dish-description")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DialogDeleteDish, {
      modelValue: _ctx.deleteDishDialogVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.deleteDishDialogVisible) = $event)),
      dish: _ctx.deleteDish,
      onCloseDialog: _ctx.closeDialog
    }, null, 8, ["modelValue", "dish", "onCloseDialog"]),
    (_ctx.descriptionVisible)
      ? (_openBlock(), _createBlock(_component_dish_description, {
          key: 0,
          modelValue: _ctx.descriptionVisible,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.descriptionVisible) = $event)),
          clickedDish: _ctx.dishForPreview,
          readonly: true,
          onClosed: _ctx.handleCloseDishPreview,
          onCloseDescription: _ctx.handleCloseDishPreview
        }, null, 8, ["modelValue", "clickedDish", "onClosed", "onCloseDescription"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_table, {
        data: _ctx.computedTableData,
        "table-layout": _ctx.tableLayout,
        "max-height": "60vh",
        onRowClick: _ctx.handleOpenDishPreview,
        onFilterChange: _ctx.handleFilterChange,
        onSortChange: _ctx.handleSortChange,
        class: _normalizeClass(["selectable-preview", { night: _ctx.isNightSelect }])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            "column-key": "name",
            fixed: "",
            "min-width": "100px",
            prop: "name",
            sortable: "",
            width: _ctx.searchColumnWidth,
            "word-wrap": "break-word"
          }, {
            header: _withCtx(() => [
              _createVNode(_component_SearchInput, {
                onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
                searchValue: _ctx.search,
                placeholderValue: "Поиск",
                onHandleSearch: _ctx.handleSearchInput,
                onClearSearch: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleSearchInput('')))
              }, null, 8, ["searchValue", "onHandleSearch"])
            ]),
            _: 1
          }, 8, ["width"]),
          _createVNode(_component_el_table_column, {
            "filter-method": _ctx.filterHandlerCategory,
            filters: _ctx.categoryFilters,
            "filtered-value": _ctx.filters.category,
            label: "Кагегория",
            "min-width": "160px",
            "column-key": "category",
            prop: "category"
          }, null, 8, ["filter-method", "filters", "filtered-value"]),
          _createVNode(_component_el_table_column, {
            "filter-method": _ctx.filterHandlerMenu,
            filters: _ctx.menuFilters,
            "filtered-value": _ctx.filters.menuType,
            label: "Тип меню",
            "min-width": "160px",
            "column-key": "menuType",
            prop: "menuType"
          }, null, 8, ["filter-method", "filters", "filtered-value"]),
          _createVNode(_component_el_table_column, {
            "column-key": "price",
            label: "Цена (Rub)",
            "min-width": "120px",
            prop: "price",
            sortable: ""
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            width: "200px"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", {
                class: "buttons-horizontal-panel",
                onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createVNode(_component_icon_button, {
                  type: "edit",
                  onClick: ($event: any) => (_ctx.handleDish(scope.row))
                }, null, 8, ["onClick"]),
                _createVNode(_component_icon_button, {
                  type: "delete",
                  onClick: ($event: any) => (_ctx.handleDelete(scope.row))
                }, null, 8, ["onClick"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data", "table-layout", "onRowClick", "onFilterChange", "onSortChange", "class"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_custom_button, {
            "type-button": "success",
            onClick: _ctx.handleDish
          }, {
            default: _withCtx(() => [
              _createTextVNode("ДОБАВИТЬ БЛЮДО")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ])
  ]))
}