import {CategoryDishes, Dish,} from "@/utils/data/interfaces"
import DishCategoryName from "@/modules/admin/models/records/DishCategoryName";

export const SortingDishCategory = (
  dishes: Array<Dish>,
): Array<CategoryDishes> => {
  const dishesFiltered: Map<string, Array<string>> = new Map()
  const tableDishes = Array<CategoryDishes>()

  Object.values(DishCategoryName).forEach((categoryName) => {
    dishesFiltered.set(categoryName, [])
  })
  if(dishes) {
    dishes.forEach((dish: Dish) => {
        dishesFiltered.get(DishCategoryName[dish.category])?.push(` ${dish.name}`)
    })
  }
  dishesFiltered.forEach((value, key) => {
    tableDishes.push({
      CategoryName: key,
      dishes: value,
    })
  })

  return tableDishes
}
