
import {computed, defineComponent, PropType} from "vue"
import { Dish } from "@/utils/data/interfaces"
import { DishType } from "@/modules/order/models/dishInterfaces"
import {useStore} from "vuex";

export default defineComponent({
  name: "MenuItem",
  props: {
    //Dish menu on selected day
    dishMenu: {
      type: Array as PropType<Array<DishType>>,
      required: true,
    },
    //Color of dish type name
    dishTypeColor: {
      type: String,
      default: "black",
    },
    // Makes inputs disabled
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["dishClicked"],
  setup() {
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    //Dish type name turns blue if at least one dish ordered
    const textColor = (dishes: Array<Dish>): string => {
      let counter = 0
      dishes.forEach((dish) => {
        if (dish.amount! > 0) {
          counter++
        }
      })
      if (counter > 0) {
        return "#4681c1"
      }
      if(isNightSelect.value) {
        return "#F4F6F9"
      }
      return "#5E6972"
    }
    const isRender = (dishes: Array<Dish>): boolean => !!dishes.length
    return {
      textColor,
      isRender,
      isNightSelect,
    }
  },
}) 
