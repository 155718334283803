import {Module} from "vuex"
import {Payment} from "@/utils/data/interfaces"
import {
  changePayment,
  deletePayment,
  downloadPayment,
  savePayment
} from "@/modules/admin/AppSettings/models/fetchModules/processPayment";
import {elMessage} from "@/modules/order/models/elMessage"
import {SystemMessages, TypeMessages} from "@/modules/order/models/data/enums"

export const paymentSettings: Module<any, any> = {
  state: {
    paymentList: [] as Array<Payment>,
    paymentMap: new Map<number, Payment>()
  },
  getters: {
    getPaymentList(state) {
      return state.paymentList
    },
    getPaymentMap(state) {
      return state.paymentMap
    }
  },
  mutations: {
    setPaymentList(state, data) {
      (data as Array<Payment>).forEach((payment: Payment) => state.paymentMap.set(payment.id, payment))
      state.paymentList = data
    },
    addPayment(state, payment) {
      state.paymentMap.set(payment.id, payment)
    },
    removePayment(state, payment) {
      state.paymentMap.delete(payment.id)
    }
  },
  actions: {
    downloadPayment: async ({commit}) => {
      const res = await downloadPayment()
      if (res.ok) {
        const data = await res.json()
        commit("setPaymentList", data.data)
      }
    },
    changePayment: async ({commit}, payment) => {
      const res = await changePayment(payment)
      if (res.ok) {
        commit("addPayment", payment)
        elMessage(SystemMessages.CHANGED, TypeMessages.SUCCESS)
      } else elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.WARNING)
    },
    savePayment: async ({commit}, payment) => {
      const res = await savePayment(payment)
      if (res.ok) elMessage(SystemMessages.SAVED, TypeMessages.SUCCESS)
      else elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.WARNING)
    },
    deletePayment: async ({commit}, payment) => {
      const res = await deletePayment(payment.id)
      if (res.ok) {
        commit("removePayment", payment)
        elMessage(SystemMessages.DELETED, TypeMessages.SUCCESS)
      } else elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.WARNING)
    }
  },
}

export default paymentSettings
