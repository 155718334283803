import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-504772c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    trigger: "click",
    onShow: _ctx.questionMarkActivate,
    onHide: _ctx.questionMarkDeactivate
  }, {
    reference: _withCtx(() => [
      _createElementVNode("img", {
        class: _normalizeClass(["questionMark", { active: _ctx.isQuestionMarkActive }]),
        src: _ctx.typeOfQuestionMark,
        alt: ""
      }, null, 10, _hoisted_1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["content", { night: _ctx.isNightSelect }])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2)
    ]),
    _: 3
  }, 8, ["onShow", "onHide"]))
}