
import { defineComponent, PropType, ref, watch, computed } from "vue"
import {Dish} from "@/utils/data/interfaces"
import { Order } from "@/utils/data/interfaces"
import { User } from "@/utils/data/interfaces"
import { OrderForOrderList } from "@/modules/admin/order-list/models/dishInterfaces"
import { makeDishMenu, clearDishMenu } from "@/modules/admin/orders-issuing/models/orderParser"
import { useStore } from "vuex"

export default defineComponent({
    name: "AddOrderDialog",
    props: {
        //All dayly orders
        orders: {
            type: Object as PropType<Map<number, OrderForOrderList>>,
            required: true
        },
        //All registered users
        allUsers: {
            type: Object as PropType<Map<number, User>>,
            required: true
        },
        //Adding order date
        orderDate: {
            type: String,
            required: true
        }
    },
    emits: ["hideAddOrder"],
    setup(props, { emit }) {
        const store = useStore()
        const searchByUsersRef = ref()
        const hideAddOrder = () => {
            emit("hideAddOrder")
        }
        const idSelectedUser = ref()
        const selectUserId = (userId: number) => {
        idSelectedUser.value = userId ?? -1
      }
      const filterValue = ref<string>("")
      const catcherFilterValue = (value: string) => filterValue.value = value
        //Options for selecting user
        const userOptions = computed(() => {
            let userOptions: Array<{ value: string; userId: number }> = []
            Array.from(props.allUsers.values())
              .filter((user) => !filterValue.value || user.fullUserName?.toLowerCase().includes(filterValue.value.toLowerCase()))
              .forEach((user) => {
                userOptions.push({
                  value: `${user.fullUserName} (${user.email})`,
                  userId: user.userId,
                })
            })
            //Order can't be added to current user or user with existing order
            userOptions = userOptions.filter((option) => !props.orders.has(option.userId))
            userOptions = userOptions.filter((option) => option.userId !== store.getters.getUserId)
            return userOptions.sort((item1, item2) => (item1.value === item2.value) ? 0 : item1.value > item2.value ? 1 : -1)


        })
        //Dish menu includes all dishes for this day
        const dishMenu = ref([] as Array<Dish>)
        watch(() => store.getters.getDishesForMenu, () => dishMenu.value = makeDishMenu(store.getters.getDishesForMenu))
        //Adding order disables if no one dish or user selected
        const addOrderDisabled = computed(() => !dishMenu.value.find(dish => !!dish.amount) || !idSelectedUser.value)
        //Confirmation massege
        const orderMessage = ref([] as Array<string>)
        //Add order dialog
        const saveDialogVisible = ref(false)
        const showSaveDialog = () => {
            const currentUser = props.allUsers.get(idSelectedUser.value) as User
            orderMessage.value = [`${currentUser.surname} ${currentUser.name}`]
            let orderPice = 0
            dishMenu.value.forEach((dish) => {
                orderPice += dish.price * dish.amount!
                if (dish.amount !== 0) orderMessage.value.push(`${dish.name} - ${dish.amount} шт`)
            })
            orderMessage.value.push(`Итого: ${orderPice} ₽`)
            saveDialogVisible.value = true
        }
        const hideSaveDialog = () => saveDialogVisible.value = false
        //Saving order
        const isInPlastic = ref(false)
        const saveOrder = async () => {
            hideSaveDialog()
            const orderForDB: Order = {
                userId: idSelectedUser.value,
                orderDate: props.orderDate,
                dishes: [],
                isIssued: false,
                isInPlastic: isInPlastic.value
            }
            dishMenu.value.forEach((dish) => {
                if (dish.amount !== 0) orderForDB.dishes.push({
                    dishId: dish.dishId,
                    dishName: dish.name,
                    price: dish.price,
                    amount: dish.amount!,
                    isCanceled: false,
                    isSpareOrder: false,
                    issueAmount: 0,
                    isIssued: false,
                    isAdmin: false
                })
            })
            hideAddOrder()
            const isSpareDishes = false
            const userAdditionalRole = store.getters.getUserAdditionalRole
            const userRole = store.getters.getUserRole
            await store.dispatch("saveUserOrder", { orderForDB, isSpareDishes, userAdditionalRole, userRole })
            props.orders.set(orderForDB.userId, {
                orderDate: orderForDB.orderDate,
                userId: orderForDB.userId,
                userName: props.allUsers.get(orderForDB.userId)?.name as string,
                userSurname: props.allUsers.get(orderForDB.userId)?.surname as string,
                dishes: orderForDB.dishes,
                isIssued: false,
                isInPlastic: orderForDB.isInPlastic
            })
        }
        const dialogClose = () => {
            isInPlastic.value = false
            idSelectedUser.value = -1
            searchByUsersRef.value.clearInputSearch()
            clearDishMenu(dishMenu.value)
        }

        const isNightSelect = computed(() => store.getters.getIsSelectedNight)
        return {
            isNightSelect,
            hideAddOrder,
            idSelectedUser,
            selectUserId,
            userOptions,
            dishMenu,
            addOrderDisabled,
            orderMessage,
            showSaveDialog,
            hideSaveDialog,
            saveDialogVisible,
            isInPlastic,
            saveOrder,
            dialogClose,
            catcherFilterValue,
            searchByUsersRef,
        }
    }

})
