import moment from "moment"
interface SelectOptions {
  value: number
  label: string
}
const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
]

export const monthOptions: Array<{ value: number; label: string }> = months.map(
  (month, index) => ({ value: index + 1, label: month })
)

export const createYearOptions = (firstYear: number): Array<SelectOptions> => {
  const currentDate = moment()
  const currentYear = currentDate.year()
  const yearsArray = []
  const yearOptions = [] as Array<SelectOptions>
  for (let year = firstYear; year <= currentYear; year++) {
    yearsArray.push(year)
  }
  yearsArray.forEach((year) =>
    yearOptions.push({ value: year, label: `${year}` })
  )
  return yearOptions
}
