import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62dbb486"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "bottom-table" }
const _hoisted_3 = { class: "bottom" }
const _hoisted_4 = { class: "buttons-horizontal-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditIngredient = _resolveComponent("EditIngredient")!
  const _component_DialogDeleteIngredient = _resolveComponent("DialogDeleteIngredient")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.editIngredientDialogVisible)
      ? (_openBlock(), _createBlock(_component_EditIngredient, {
          key: 0,
          modelValue: _ctx.editIngredientDialogVisible,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editIngredientDialogVisible) = $event)),
          type: _ctx.typeOfEditing,
          onCloseDialog: _ctx.closeDialog
        }, null, 8, ["modelValue", "type", "onCloseDialog"]))
      : _createCommentVNode("", true),
    _createVNode(_component_DialogDeleteIngredient, {
      modelValue: _ctx.dialogDeleteIngredientVisible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dialogDeleteIngredientVisible) = $event)),
      onCloseDialog: _ctx.closeDialog
    }, null, 8, ["modelValue", "onCloseDialog"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_table, {
        data: _ctx.computedTableData,
        "table-layout": _ctx.tableLayout,
        "max-height": "60vh",
        onFilterChange: _ctx.handleFilterChange,
        onSortChange: _ctx.handleSortChange,
        class: _normalizeClass({ night: _ctx.isNightSelect })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            width: _ctx.searchColumnWidth,
            "column-key": "name",
            fixed: "",
            label: "Название",
            "min-width": "70px",
            prop: "name",
            sortable: "",
            "word-wrap": "break-word"
          }, {
            header: _withCtx(() => [
              _createVNode(_component_SearchInput, {
                onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
                searchValue: _ctx.search,
                placeholderValue: "Поиск",
                onHandleSearch: _ctx.handleSearchInput,
                onClearSearch: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleSearchInput('')))
              }, null, 8, ["searchValue", "onHandleSearch"])
            ]),
            _: 1
          }, 8, ["width"]),
          _createVNode(_component_el_table_column, {
            "filter-method": _ctx.filterHandlerCategory,
            filters: _ctx.categoryFilters,
            "column-key": "category",
            label: "Категория",
            "min-width": "120px",
            prop: "category",
            sortable: ""
          }, null, 8, ["filter-method", "filters"]),
          _createVNode(_component_el_table_column, {
            "column-key": "unit",
            label: "Ед. измерения",
            "min-width": "120px",
            prop: "unit",
            sortable: ""
          }),
          _createVNode(_component_el_table_column, {
            formatter: _ctx.formateCPFC,
            label: "К / Б / Ж / У",
            "min-width": "120px"
          }, null, 8, ["formatter"]),
          _createVNode(_component_el_table_column, {
            align: "center",
            width: "200px"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", {
                class: "buttons-horizontal-panel",
                onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createVNode(_component_icon_button, {
                  type: "edit",
                  onClick: ($event: any) => (_ctx.handleUpdateIngredient(scope.row))
                }, null, 8, ["onClick"]),
                _createVNode(_component_icon_button, {
                  type: "delete",
                  onClick: ($event: any) => (_ctx.handleDeleteIngredient(scope.row))
                }, null, 8, ["onClick"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data", "table-layout", "onFilterChange", "onSortChange", "class"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_custom_button, {
            "type-button": "success",
            onClick: _ctx.handleCreateIngredient
          }, {
            default: _withCtx(() => [
              _createTextVNode("ДОБАВИТЬ ИНГРЕДИЕНТ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ])
  ]))
}