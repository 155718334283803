import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-498c60fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-wrapper" }
const _hoisted_2 = { class: "buttons-horizontal-panel" }
const _hoisted_3 = { class: "buttons-horizontal-panel" }
const _hoisted_4 = { class: "table-wrapper" }
const _hoisted_5 = { class: "buttons-horizontal-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_edit_guide_book_row = _resolveComponent("edit-guide-book-row")!
  const _component_dialog_window = _resolveComponent("dialog-window")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_custom_button, { onClick: _ctx.showPurchasingLists }, {
        default: _withCtx(() => [
          _createTextVNode("Списки закупок")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_custom_button, { disabled: true }, {
        default: _withCtx(() => [
          _createTextVNode("Справочники")
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["title", { night: _ctx.isNightSelect }])
    }, "Справочники", 2),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_select, {
        class: _normalizeClass([{ night: _ctx.isNightSelect }, "m-2"]),
        modelValue: _ctx.selectedGuideBook,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedGuideBook) = $event)),
        onChange: _ctx.guideBookSelectorChanged
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.guideBooksSelectOptions, (option) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: option.label,
              label: option.label,
              value: option.label
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["class", "modelValue", "onChange"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_el_table, {
        data: _ctx.guideBookTableData,
        border: true,
        "highlight-current-row": "",
        class: _normalizeClass({ night: _ctx.isNightSelect })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            label: "Наименование",
            prop: "name",
            sortable: true
          }),
          _createVNode(_component_el_table_column, {
            label: _ctx.column2Label,
            prop: "column2Label",
            sortable: true
          }, null, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            align: "center",
            "min-width": "50px"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", {
                class: "edit-buttons-wrapper",
                onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createVNode(_component_icon_button, {
                  type: "edit",
                  onClick: ($event: any) => (_ctx.editRow(scope.row))
                }, null, 8, ["onClick"]),
                _createVNode(_component_icon_button, {
                  type: "delete",
                  onClick: ($event: any) => (_ctx.deleteRow(scope.row))
                }, null, 8, ["onClick"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data", "class"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_custom_button, {
        "type-button": "success",
        onClick: _ctx.addRow
      }, {
        default: _withCtx(() => [
          _createTextVNode("Добавить")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_edit_guide_book_row, {
      modelValue: _ctx.editingDialogVisible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editingDialogVisible) = $event)),
      "guide-book-row": _ctx.editingRow,
      onCloseDialog: _ctx.hideEditingDialog
    }, null, 8, ["modelValue", "guide-book-row", "onCloseDialog"]),
    _createVNode(_component_dialog_window, {
      modelValue: _ctx.deletingDialogWindowVisible,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.deletingDialogWindowVisible) = $event)),
      title: "Удалить из справочника",
      "show-close": false,
      "main-text": _ctx.deletingDialogText,
      onOkClicked: _ctx.confirmDeleting,
      onCancelClicked: _ctx.hideDeletingDialogWindow
    }, null, 8, ["modelValue", "main-text", "onOkClicked", "onCancelClicked"])
  ]))
}