
import {computed, defineComponent, PropType, ref} from "vue"
import {Payment} from "@/utils/data/interfaces"
import {useStore} from "vuex"
import {usePaymentRules} from "@/modules/admin/AppSettings/models/utils"
import type {FormInstance} from "element-plus"
import {compareObjects} from "@/utils/helpers/compareObjects"
import {cardFormatter, phoneFormatter, spaceValidator} from "@/utils/helpers/validators"
import {focusElement} from "@/utils/helpers/focusElement"

export default defineComponent({
  name: "PaymentHandling",
  props: {
    //Payment for handling
    payment: {
      type: Object as PropType<Payment>,
      required: true,
    },
  },
  emits: ["closeDialog"],
  setup(props, {emit}) {
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    const paymentHandlingWidth = computed(() => store.getters.getBreakpoints.mobile ? "350px" : "500px")
    const title = computed(() => props.payment.id ? "Редактирование реквизитов" : "Добавление реквизитов")
    const ruleFormRef = ref<FormInstance>()
    const formSize = ref("default")

    const handlingPayment = ref<Payment>({...props.payment})
    const setHandlingPayment = () => handlingPayment.value = {...props.payment}
    //Fields validation
    const validateName = () => handlingPayment.value.name = spaceValidator(handlingPayment.value.name)
    const validateDetails = (value: string) => handlingPayment.value.details = cardFormatter(value)
    const validatePhone = (value: string) => handlingPayment.value.phone = phoneFormatter(value)
    const isValid = ref(false)
    const isFormValid = async () => {
      await ruleFormRef.value?.validate(valid => isValid.value = valid)
      ruleFormRef.value?.clearValidate()
      return isValid
    }
    //Buttons handling
    const isResetButtonDisabled = computed(() =>
      compareObjects({...handlingPayment.value}, {...props.payment}, true))

    const isSaveButtonDisabled = computed(() => {
      isFormValid()
      return isResetButtonDisabled.value || !isValid.value
    })

    const saveClicked = async () => {
      emit("closeDialog")
      if (props.payment.id) {
        await store.dispatch("changePayment", {...handlingPayment.value})
      } else {
        await store.dispatch("savePayment", {
          name: handlingPayment.value.name,
          details: handlingPayment.value.details,
          phone: handlingPayment.value.phone
        })
        await store.dispatch("downloadPayment")
      }
    }

    const resetForm = () => {
      handlingPayment.value = {...props.payment}
    }

    //Dialog handling
    const closeDialog = () => emit("closeDialog")
    const dialogOpen = () => {
      setHandlingPayment()
      ruleFormRef.value?.clearValidate()
      focusElement("name")
    }

    return {
      isNightSelect,
      paymentHandlingWidth,
      title,
      ruleFormRef,
      formSize,
      handlingPayment,
      setHandlingPayment,
      validateName,
      validateDetails,
      validatePhone,
      ...usePaymentRules(),
      closeDialog,
      isResetButtonDisabled,
      isSaveButtonDisabled,
      resetForm,
      saveClicked,
      dialogOpen,
    }
  }
})
