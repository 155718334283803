
import {computed, defineComponent, ref} from "vue"
import {useStore} from "vuex"

//interfaces
import {Ingredient} from "@/utils/data/interfaces"

//composables
import useIngredientsTableFilters from "@/modules/admin/ingredients-guidebook/composables/useIngredientsTableFilters"


export default defineComponent({
  name: "archiveDeletedIngredient",
  setup() {
    const {categoryFilters} = useIngredientsTableFilters()
    const store = useStore()
    store.dispatch("getIngredients")
    //Для автовыравнивания таблицы
    const tableLayout = ref("auto")
    //Функция поиска по имени
    const search = ref("")
    const handleSearchInput = (value: string) => {
      search.value = value
    }

    const filterTableData = computed(() => store.getters.getDeletedIngredientsForArchive.filter(
      (data: Ingredient) =>
        !search.value || data.name.toLowerCase().includes(search.value.toLowerCase())
    ))

    const filterHandlerCategory = (value: string, row: Ingredient): boolean => row.category === value

    const handleRestore = async (row: Ingredient) => {
      await store.dispatch("setIngredientForEdit", row.id)
      await store.dispatch("restoreIngredient", store.getters.getIngredientsForEdit)
    }
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    return {
      isNightSelect,
      search,
      handleSearchInput,
      tableLayout,
      filterTableData,
      filterHandlerCategory,
      categoryFilters,
      handleRestore,
    }
  },
})
