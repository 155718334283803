import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dishes_items = _resolveComponent("dishes-items")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dishMenu, (dishType, index) => {
    return (_openBlock(), _createElementBlock("div", null, [
      (_ctx.isRender(dishType.dishes))
        ? (_openBlock(), _createElementBlock("details", {
            key: index,
            open: ""
          }, [
            _createElementVNode("summary", {
              class: "dish-item",
              style: _normalizeStyle({ color: _ctx.textColor(dishType.dishes) })
            }, _toDisplayString(dishType.typeName), 5),
            _createVNode(_component_dishes_items, {
              dishes: dishType.dishes,
              disabled: _ctx.disabled
            }, null, 8, ["dishes", "disabled"])
          ]))
        : _createCommentVNode("", true)
    ]))
  }), 256))
}