import { get, post, deleteUrl } from "@/utils/fetchQueries"
import { Order } from "@/utils/data/interfaces"
import moment from "moment"

export const downloadSpareDishes = (spareDishesDate: string) => {
  const dateFrom = moment(spareDishesDate)
    .hours(0)
    .minutes(0)
    .seconds(0)
    .milliseconds(0)
    .toISOString()
  const dateTo = moment(dateFrom).add(1, "days").toISOString()
  return get("/api/spare-dish", { dateFrom, dateTo })
}

export const checkAvailability = (
  dishIds: Array<number>,
  spareDishesDate: string
) => {
  const dateFrom = moment(spareDishesDate)
    .hours(0)
    .minutes(0)
    .seconds(0)
    .milliseconds(0)
    .toISOString()
  const dateTo = moment(dateFrom).add(1, "days")
  return get("/api/spare-dish/availability", {
    dateFrom,
    dateTo,
    ids: dishIds.join(","),
  })
}

export const makeOrder = (order: Order) => post("/api/spare-dish", order)

export const unformOrder = (order: Order) => {
  //adding 3 hours is necessary to remove the difference of 3 hours when converting time to UTC
  const dateFrom = moment(order.orderDate)
    .hours(3)
    .minutes(0)
    .seconds(0)
    .milliseconds(0)
    .toISOString()
  const dateTo = moment(dateFrom).add(1, "days").toISOString()
  return deleteUrl(`/api/spare-dish/${order.userId}`, { dateFrom, dateTo })
}
export const getIdentification = (userId: number, date: string) => {
  const dateFrom = moment(date)
    .hours(0)
    .minutes(0)
    .seconds(0)
    .milliseconds(0)
    .toISOString()
  const dateTo = moment(dateFrom).add(1, "days").toISOString()
  return get(`/api/spare-dish/${userId}`, { dateFrom, dateTo })
}
