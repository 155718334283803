
import {defineComponent, PropType} from "vue";


export default defineComponent({
  name: "CustomCloseCross",
  props: {
    isShow: {
      type: Boolean as PropType<boolean>,
      default: false
    },
},
})
