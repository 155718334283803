import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, vModelText as _vModelText, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c92d7d84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "dish-item-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "amount-selector"
}
const _hoisted_4 = ["onClick", "disabled"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["onUpdate:modelValue"]
const _hoisted_7 = ["onClick", "disabled"]
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dish_view = _resolveComponent("dish-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dishes, (dish, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "dish",
        key: index
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_dish_view, {
            dish: dish,
            "max-amount-display": _ctx.maxAmountDisplay,
            "total-displaying": _ctx.totalDisplaying,
            "show-identification": _ctx.showIdentification,
            "use-dish-backlite": _ctx.isAmountSelectorVisible,
            "click-on-dish-method": _ctx.typeClickOnDish,
            onAddDish: ($event: any) => (_ctx.additionButton(dish))
          }, {
            default: _withCtx(() => [
              (_ctx.isDishAmountVisible)
                ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["dish-amount", { night: _ctx.isNightSelect }])
                  }, "x " + _toDisplayString(dish.amount), 3)), [
                    [_vShow, _ctx.isMobile]
                  ])
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["dish", "max-amount-display", "total-displaying", "show-identification", "use-dish-backlite", "click-on-dish-method", "onAddDish"])
        ]),
        (_ctx.isAmountSelectorVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", {
                class: _normalizeClass(["max-amount", { night: _ctx.isNightSelect }]),
                style: _normalizeStyle({ display: _ctx.maxAmountDisplay })
              }, _toDisplayString(_ctx.maxAmount(dish)), 7),
              _createElementVNode("button", {
                class: _normalizeClass(["side-button sub", { night: _ctx.isNightSelect }]),
                onClick: ($event: any) => (_ctx.subtractionButton(dish)),
                disabled: _ctx.disabled
              }, [
                _createElementVNode("img", {
                  src: _ctx.SideButtons.MINUS,
                  alt: "-"
                }, null, 8, _hoisted_5)
              ], 10, _hoisted_4),
              _withDirectives(_createElementVNode("input", {
                name: "dishCounter",
                type: "number",
                "onUpdate:modelValue": ($event: any) => ((dish.amount) = $event),
                disabled: true
              }, null, 8, _hoisted_6), [
                [_vShow, false],
                [_vModelText, dish.amount]
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["dish-counter", { night: _ctx.isNightSelect }])
              }, _toDisplayString(dish.amount), 3),
              _createElementVNode("button", {
                class: _normalizeClass(["side-button add", { night: _ctx.isNightSelect }]),
                onClick: ($event: any) => (_ctx.additionButton(dish)),
                disabled: _ctx.disabled
              }, [
                _createElementVNode("img", {
                  src: _ctx.SideButtons.PLUS,
                  alt: "+"
                }, null, 8, _hoisted_8)
              ], 10, _hoisted_7)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isDishAmountVisible)
          ? _withDirectives((_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass([{ night: _ctx.isNightSelect }, "dish-amount"])
            }, "x " + _toDisplayString(dish.amount), 3)), [
              [_vShow, !_ctx.isMobile]
            ])
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}