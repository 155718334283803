import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dialog_window = _resolveComponent("dialog-window")!

  return (_openBlock(), _createBlock(_component_dialog_window, {
    "show-close": true,
    title: "Вы действительно хотите выйти?",
    cancelButtonText: "Отмена",
    okButtonText: "Да",
    onOkClicked: _ctx.resetUser,
    onCancelClicked: _ctx.CloseModalWindow,
    onCloseDialog: _ctx.CloseModalWindow
  }, null, 8, ["onOkClicked", "onCancelClicked", "onCloseDialog"]))
}