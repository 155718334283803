import {Dish, Ingredient} from "@/utils/data/interfaces"
import IngredientsUnitShortName from "@/modules/admin/models/records/IngredientUnitsShortName";
import IngredientUnits from "@/modules/admin/models/enums/IngredientUnits";
import {DishActionType} from "@/utils/data/types";

export default function updateCPFC(ingredients: Array<Ingredient>, dish: Dish, typeOfForm: DishActionType, deltaDishWeight: number) {
  if (ingredients.length === 0) {
    dish.weight = 0
    return
  }

  let calories = 0, proteins = 0, fats = 0, carbs = 0, weight = 0

  ingredients.forEach((currentIngredient: Ingredient) => {
    if (currentIngredient.amount === undefined || currentIngredient.amount === 0) return

    if (currentIngredient.unit === IngredientsUnitShortName[IngredientUnits.PIECES]) {
      calories += currentIngredient.calories * currentIngredient.amount
      proteins += currentIngredient.proteins * currentIngredient.amount
      fats += currentIngredient.fats * currentIngredient.amount
      carbs += currentIngredient.carbs * currentIngredient.amount
    } else {
      calories += currentIngredient.calories * currentIngredient.amount / 100
      proteins += currentIngredient.proteins * currentIngredient.amount / 100
      fats += currentIngredient.fats * currentIngredient.amount / 100
      carbs += currentIngredient.carbs * currentIngredient.amount / 100
      weight += currentIngredient.amount
    }

  })

  dish.calories = Math.ceil(calories)
  dish.proteins = Math.ceil(proteins)
  dish.fats = Math.ceil(fats)
  dish.carbs = Math.ceil(carbs)
  if (typeOfForm === "post") dish.weight = weight
  if (typeOfForm === "update") {
    let newWeight = 0
    ingredients.forEach(ingredient => {
      newWeight += ingredient.amount ?? 0
    })
    dish.weight = newWeight - deltaDishWeight
  }
}
