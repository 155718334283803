import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-190f3b1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    class: "close",
    src: _ctx.closeIcon,
    alt: "X",
    style: _normalizeStyle({ width: _ctx.size + 'px', height: _ctx.size + 'px' })
  }, null, 12, _hoisted_1))
}