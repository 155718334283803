
import { defineComponent, computed, ref } from "vue"
import store from "@/store"

export default defineComponent({
    name: "DownloadButton",
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        multipleSelection: {
            type: Boolean,
            default: true
        },
        isFullSize: {
            type: Boolean,
            default: false
        }
    },
    emits: ["downloadClicked", "download"],
    setup(props, { emit }) {
        const inputRef = ref()
        const downloadClicked = (event: Event) =>
            emit("downloadClicked", Array.from((event.target as HTMLInputElement).files ?? []))

        const isNightSelected = computed(() => store.getters.getIsSelectedNight)
        return {
            downloadClicked,
            isNightSelected,
            inputRef,
        }
    }
})

