import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55ae85fd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "delete-edit-buttons-wrapper" }
const _hoisted_3 = { class: "dishes" }
const _hoisted_4 = {
  key: 0,
  class: "is-in-plastic-wrapper"
}
const _hoisted_5 = { class: "buttons buttons-horizontal-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_close_button = _resolveComponent("close-button")!
  const _component_dishes_items = _resolveComponent("dishes-items")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_toggle_checkbox = _resolveComponent("toggle-checkbox")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_dialog_window = _resolveComponent("dialog-window")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "show-close": false,
    width: "550px",
    top: "5vh",
    onOpen: _ctx.dialogOpen,
    onClose: _ctx.dialogClose,
    class: _normalizeClass({ night: _ctx.isNightSelect })
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.isIssuingDialog)
            ? (_openBlock(), _createBlock(_component_el_popover, {
                key: 0,
                placement: "right",
                trigger: "hover"
              }, {
                reference: _withCtx(() => [
                  _createVNode(_component_icon_button, {
                    type: "redo",
                    class: "delete-edit-button",
                    onClick: _ctx.dialogOpen
                  }, null, 8, ["onClick"])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["container", { night: _ctx.isNightSelect }])
                  }, "Вернуться к выдаче заказа", 2)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_icon_button, {
            type: "edit",
            class: "delete-edit-button",
            disabled: _ctx.deleteButtonDisabled,
            onClick: _ctx.editOrder
          }, null, 8, ["disabled", "onClick"]),
          _createVNode(_component_icon_button, {
            type: "delete",
            class: "delete-edit-button",
            disabled: _ctx.deleteButtonDisabled,
            onClick: _ctx.showDeleteDialog
          }, null, 8, ["disabled", "onClick"])
        ]),
        _createVNode(_component_close_button, { onClick: _ctx.hideIssueOrder }, null, 8, ["onClick"])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.dialogTitleClass, { night: _ctx.isNightSelect }])
      }, _toDisplayString(_ctx.dialogTitle), 3),
      _createElementVNode("div", {
        class: _normalizeClass(["user-name", { night: _ctx.isNightSelect }])
      }, _toDisplayString(_ctx.userFullName), 3)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_scrollbar, {
          class: "scrollbar",
          "max-height": 375
        }, {
          default: _withCtx(() => [
            _createVNode(_component_dishes_items, {
              dishes: _ctx.displayingDishes,
              "type-click-on-dish": "add",
              "user-role": "admin",
              disabled: _ctx.issueAllDisabled && _ctx.isIssuingDialog,
              "max-amount-display": _ctx.maxAmountDisplay
            }, null, 8, ["dishes", "disabled", "max-amount-display"])
          ]),
          _: 1
        }),
        (!_ctx.isIssuingDialog)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("span", {
                class: _normalizeClass(["dish-type", { night: _ctx.isNightSelect }])
              }, "Пластиковая посуда", 2),
              _createVNode(_component_toggle_checkbox, {
                checked: _ctx.isInPlastic,
                "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isInPlastic) = $event))
              }, null, 8, ["checked"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.isIssuingDialog)
            ? (_openBlock(), _createBlock(_component_custom_button, {
                key: 0,
                "type-button": "success",
                disabled: _ctx.issueOrderDisabled,
                onClick: _ctx.issueClicked
              }, {
                default: _withCtx(() => [
                  _createTextVNode("ВЫДАТЬ")
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]))
            : _createCommentVNode("", true),
          (_ctx.isIssuingDialog)
            ? (_openBlock(), _createBlock(_component_custom_button, {
                key: 1,
                disabled: _ctx.issueAllDisabled,
                onClick: _ctx.issueAllClicked
              }, {
                default: _withCtx(() => [
                  _createTextVNode("ВЫДАТЬ ВСЁ")
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]))
            : (_openBlock(), _createBlock(_component_custom_button, {
                key: 2,
                disabled: _ctx.saveOrderButtonDisabled,
                "type-button": "success",
                onClick: _ctx.showSaveEditedOrderDialog
              }, {
                default: _withCtx(() => [
                  _createTextVNode("СОХРАНИТЬ")
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]))
        ])
      ]),
      _createVNode(_component_dialog_window, {
        "show-close": false,
        modelValue: _ctx.issueDialogVisible,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.issueDialogVisible) = $event)),
        title: "Выдать заказ",
        "main-text": _ctx.issueMessage,
        onCancelClicked: _ctx.hideIssueDialog,
        onOkClicked: _ctx.issueOrder
      }, null, 8, ["modelValue", "main-text", "onCancelClicked", "onOkClicked"]),
      _createVNode(_component_dialog_window, {
        "show-close": false,
        modelValue: _ctx.deleteDialogVisible,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.deleteDialogVisible) = $event)),
        title: "Удалить заказ",
        "main-text": _ctx.deleteOrderMessage,
        onCancelClicked: _ctx.hideDeleteDialog,
        onOkClicked: _ctx.deleteOrder
      }, null, 8, ["modelValue", "main-text", "onCancelClicked", "onOkClicked"]),
      _createVNode(_component_dialog_window, {
        "show-close": false,
        modelValue: _ctx.saveEditedOrderDialogVisible,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.saveEditedOrderDialogVisible) = $event)),
        title: "Подтвердите заказ",
        "main-text": _ctx.saveEditedOrderMessage,
        onCancelClicked: _ctx.hideSaveEditedOrderDialog,
        onOkClicked: _ctx.saveEditedOrder
      }, null, 8, ["modelValue", "main-text", "onCancelClicked", "onOkClicked"])
    ]),
    _: 1
  }, 8, ["onOpen", "onClose", "class"]))
}