import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-528134f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-card" }
const _hoisted_2 = { class: "icon" }
const _hoisted_3 = { class: "subheader" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["custom-card", { night: _ctx.isNightSelect}]),
    style: _normalizeStyle({height: _ctx.cardHeight})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "header", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "header-icon", { class: "icon" }, undefined, true)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "subheader", {}, undefined, true)
      ]),
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "additional", {}, undefined, true)
      ])
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 6))
}