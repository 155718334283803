//router
import router from "@/router"

//imtarfaces
import {Module} from "vuex"
import {RuleForm} from "@/modules/login/models/login-model"
import {User} from "@/utils/data/interfaces"
import {MenuLink} from "@/modules/portal/models/interfaces/menu-links-interface"
import {Roles} from "@/modules/order/models/data/enums";

//utils-functions
import {get, post} from "@/utils/fetchQueries"
import highlightMenuItem from "@/store/modules/admin/utils/for-login-form/HighlightMenuItem"
import userIdentificationAndMenu from "@/store/modules/admin/utils/for-login-form/UserIdentificationAndMenu"

export const loginFormModule: Module<any, any> = {
  state: {
    user: {} as User,
    userDownloaded: false,
    errorLogin: false,
    hasAllTypesPermissions: false,
    activeMenu: new Map<string, Array<MenuLink>>(),
    activeRole: Roles.USER as string,
    activeLink: ''
  },

  getters: {
    getUser(state) {
      return state.user
    },
    isUserAuthorized(state) {
      return state.userDownloaded
    },
    getUserId(state) {
      return state.user.userId
    },
    getUserRole(state) {
      return state.user.role
    },
    getUserAdditionalRole(state) {
      return state.user?.additionalRole
    },
    getActiveRole(state) {
      return state.activeRole
    },
    getErrorLogin(state) {
      return state.errorLogin
    },
    getUserPermissions(state) {
      return state.user.permissions
    },
    getActiveMenu(state) {
      if (!localStorage.getItem("lastSelectedPage")) {
        localStorage.setItem('lastSelectedPage', state.activeMenu.get(state.activeRole)[0].link)
      }
      return highlightMenuItem(state.activeMenu.get(state.activeRole), state.activeLink)
    },
    getDefaultPageLink(state) {
      return state.activeMenu.get(state.activeRole)[0].link
    },
    getHasAllTypesPermissions(state) {
      return state.hasAllTypesPermissions
    },
    isAdminMenuEnabled(state) {
      return state.activeRole === Roles.ADMIN;
    },
  },
  mutations: {
    clearLoginErrorInfo(state) {
      state.errorLogin = false
    },
    setLoginErrorInfo(state) {
      state.errorLogin = true
    },
    setUserDownloaded(state) {
      state.userDownloaded = true
    },
    updateUserData(state, data) {
      state.user = data
    },
    clearUserData(state) {
      state.user = {}
      state.hasAllTypesPermissions = false
      state.userDownloaded = false
      state.activeRole = Roles.USER
      state.activeMenu = {}
    },
    setActiveMenu(state, data) {
      state.activeMenu = data
    },
    setHasAllTypesPermissions(state, data) {
      state.hasAllTypesPermissions = data
    },
    setActiveLink(state, data) {
      state.activeLink = data
    },
    setActiveRole(state, data) {
      state.activeRole = data
    },
    setHighlightMenuItem(state, link) {
      localStorage.setItem('lastSelectedPage', link)
      state.activeLink = link
    },
  },
  actions: {
    changeActiveRole({commit}, role) {
      commit("setActiveRole", role)
    },
    selectMenuItem({commit}, menuItemLink) {
      commit("setHighlightMenuItem", menuItemLink)
    },
    async logoutFromBack({commit}) {
      commit("clearUserData")
    },
    async logOut({commit}) {
      const res = await post(`/api/auth/sign-out`)
      if (res.ok) {
        commit("clearLoginErrorInfo")
        commit("clearUserData")
        await router.replace("/login")
      }
    },
    async loginUser({commit}, authForm: RuleForm) {
      const res = await post(`/api/auth/sign-in`, authForm)
      if (res.ok) {
        commit("clearLoginErrorInfo")
        const user: User = (await res.json()).data
        userIdentificationAndMenu(commit, user)
      } else commit("setLoginErrorInfo")
    },
    async fetchUserData({commit}) {
      const res = await get(`/api/auth/user-info`)
      if (res.ok) {
        commit("clearLoginErrorInfo")
        const user: User = (await res.json()).data
        userIdentificationAndMenu(commit, user, localStorage.getItem('lastSelectedPage')!)
      }

    },
  },
}
export default loginFormModule
