import writeXlsxFile from 'write-excel-file'

interface TableCell {
    value: string
}

interface TableRow {
    index: number
    itemName: string
    amount: string
}
const columns = [{ type: String, width: 5 }, { type: String, width: 40 }, { type: String, width: 12 }]
let sendingData: Array<Array<TableCell>> = []

const addEmptyRow = () => sendingData.push([{
    value: ""
}])

const addFilleTitle = (title: string, period: string) => {
    sendingData.push([{ value: "" }, { value: title }, { value: "" }])
    sendingData.push([{ value: "" }, { value: period }, { value: "" }])
    addEmptyRow()
}

const addTableHeader = () => {
    sendingData.push([{ value: "" }, { value: "Наименование" }, { value: "Количество" }])
    addEmptyRow()
}

const addTableRow = (row: TableRow) => {
    sendingData.push([{ value: `${row.index}` }, { value: row.itemName }, { value: row.amount }])
}

const generateTable = (title: string, period: string, data: Array<{ itemName: string, amount: string }>): Array<Array<TableCell>> => {
    sendingData = []
    addFilleTitle(title, period)
    addTableHeader()
    data.forEach((dataItem, index) => addTableRow({ index: index + 1, itemName: dataItem.itemName, amount: dataItem.amount }))
    return sendingData
}

export const downloadXLS = (title: string, period: string, data: Array<{ itemName: string, amount: string }>) => {
    writeXlsxFile(generateTable(title, period, data), {
        columns,
        fileName: `${title} ${period}.xlsx`
    })
}