import { permissions } from "@/modules/portal/models/enums/permissions";
import permissionsName from "@/modules/portal/models/records/permissions-name";
import { Roles } from "@/modules/order/models/data/enums";
import {PermissionInMenu} from "@/utils/data/interfaces";

const permissionsMenu: Record<permissions, PermissionInMenu> = {
  [permissions.CREATE_MENU]: {
    id: 1,
    title: permissionsName[permissions.CREATE_MENU],
    role: Roles.ADMIN,
    link: "/CreateDishMenu",
    permission: permissions.CREATE_MENU,
    isActive: false
  },
  [permissions.ORDER_LIST]: {
    id: 2,
    title: permissionsName[permissions.ORDER_LIST],
    role: Roles.ADMIN,
    link: "/OrderList",
    permission: permissions.ORDER_LIST,
    isActive: false
  },
  [permissions.ORDER_ISSUING]: {
    id: 3,
    title: permissionsName[permissions.ORDER_ISSUING],
    role: Roles.ADMIN,
    link: "/OrdersIssuing",
    permission: permissions.ORDER_ISSUING,
    isActive: false
  },
  [permissions.REPORT]: {
    id: 4,
    title: permissionsName[permissions.REPORT],
    role: Roles.ADMIN,
    link: "/Report",
    permission: permissions.REPORT,
    isActive: false
  },
  [permissions.GUIDES]: {
    id: 5,
    title: permissionsName[permissions.GUIDES],
    role: Roles.ADMIN,
    link: "/Handbooks",
    permission: permissions.GUIDES,
    isActive: false
  },
  [permissions.PROCUREMENT]: {
    id: 6,
    title: permissionsName[permissions.PROCUREMENT],
    role: Roles.ADMIN,
    link: "/PurchasingListsView",
    permission: permissions.PROCUREMENT,
    isActive: false
  },
  [permissions.PAYMENT]: {
    id: 7,
    title: permissionsName[permissions.PAYMENT],
    role: Roles.ADMIN,
    link: "/Payment",
    permission: permissions.PAYMENT,
    isActive: false
  },
  [permissions.SYSTEM_SETTINGS]: {
    id: 8,
    title: permissionsName[permissions.SYSTEM_SETTINGS],
    role: Roles.ADMIN,
    link: "/AppSettings",
    permission: permissions.SYSTEM_SETTINGS,
    isActive: false
  },
  [permissions.MENU]: {
    id: 9,
    title: permissionsName[permissions.MENU],
    role: Roles.USER,
    link: "/Menu",
    permission: permissions.MENU,
    isActive: false
  },
  [permissions.ORDER_HISTORY]: {
    id: 10,
    title: permissionsName[permissions.ORDER_HISTORY],
    role: Roles.USER,
    link: "/OrderHistory",
    permission: permissions.ORDER_HISTORY,
    isActive: false
  },
  [permissions.NOTIFICATIONS]: {
    id: 11,
    title: permissionsName[permissions.NOTIFICATIONS],
    role: Roles.USER,
    link: "/NotificationSettings",
    permission: permissions.NOTIFICATIONS,
    isActive: false
  },
}
export default permissionsMenu
