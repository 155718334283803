import {Module} from "vuex"
import {Emoji} from "@/utils/data/interfaces"
import {get} from "@/utils/fetchQueries";
import {changeEmoji, deleteEmoji, saveEmoji} from "@/modules/admin/AppSettings/models/fetchModules/processEmoji";
import {elMessage} from "@/modules/order/models/elMessage"
import {SystemMessages, TypeMessages} from "@/modules/order/models/data/enums"

export const emojiList: Module<any, any> = {
  state: {
    emojiList: [] as Array<Emoji>,
    emojiMap: new Map<number, Emoji>()
  },
  getters: {
    getEmojiList(state) {
      return state.emojiList
    },
    getEmojiMap(state) {
      return state.emojiMap
    }
  },
  mutations: {
    setEmojiList(state, data) {
      (data as Array<Emoji>).forEach((emoji: Emoji) => state.emojiMap.set(emoji.id, emoji))
      state.emojiList = data
    },
    replaceEmoji(state, emoji) {
      state.emojiMap.delete(emoji.id)
      state.emojiMap.set(emoji.id, emoji)
    },
    removeEmoji(state, emoji) {
      state.emojiMap.delete(emoji.id)
    }
  },
  actions: {
    getEmoji: async ({commit}) => {
      const res = await get('/api/emoji')
      if (res.ok) {
        const data = await res.json()
        commit("setEmojiList", data.data)
      }
    },
    changeEmoji: async ({commit}, emoji) => {
      const res = await changeEmoji(emoji)
      if (res.ok) {
        commit("replaceEmoji", emoji)
        elMessage(SystemMessages.EMOJI_CHANGED, TypeMessages.SUCCESS)
      } else elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.WARNING)
    },
    saveEmoji: async ({commit}, emoji) => {
      const res = await saveEmoji(emoji)
      if (res.ok) elMessage(SystemMessages.EMOJI_SAVED, TypeMessages.SUCCESS)
      else elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.WARNING)
    },
    deleteEmoji: async ({commit}, emoji) => {
      const res = await deleteEmoji(emoji.id)
      if (res.ok) {
        commit("removeEmoji", emoji)
        elMessage(SystemMessages.EMOJI_DELETED, TypeMessages.SUCCESS)
      } else elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.WARNING)
    }
  },
}

export default emojiList
