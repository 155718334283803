
import { computed, defineComponent, PropType, ref } from "vue";
import { IconButtons } from "@/modules/order/models/data/enums"

export default defineComponent({
  name: "IconButton",
  props: {
    type: {
      type: String as PropType<"delete" | "edit" | "redo" | "reset" | "add" | "edit-list" | "copy"
        | "cancel" | "download" | "receipt" | "left" | "right" | "rotate" | "delete-black" | "close" | "join">,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {

    const hovered = ref(false)

    const handleMouseOn = () => hovered.value = true
    const handleMouseLeave = () => hovered.value = false

    const iconUrl = computed(() => {
      switch (props.type) {

        case 'delete':
          return hovered.value ? IconButtons.DELETE_HOVERED : IconButtons.DELETE;

        case 'edit':
          return hovered.value ? IconButtons.EDIT_HOVERED : IconButtons.EDIT;

        case 'redo':
          return hovered.value ? IconButtons.REDO_HOVERED : IconButtons.REDO;

        case 'reset':
          if (props.disabled) return IconButtons.RESET_DISABLED
          else return hovered.value ? IconButtons.RESET_FILTERS_HOVER : IconButtons.RESET_FILTERS;

        case 'edit-list':
          if (props.disabled) return IconButtons.EDIT_DISABLED
          else return hovered.value ? IconButtons.EDIT_HOVER : IconButtons.EDIT_LIST;

        case 'add':
          return hovered.value ? IconButtons.ADD_HOVER : IconButtons.ADD;

        case 'copy':
          if (props.disabled) return IconButtons.COPY_DISABLED
          else return hovered.value ? IconButtons.COPY_HOVER : IconButtons.COPY;

        case 'cancel':
          if (props.disabled) return IconButtons.CANCEL_DISABLED
          else return hovered.value ? IconButtons.CANCEL_HOVER : IconButtons.CANCEL;

        case 'download':
          if (props.disabled) return IconButtons.DOWNLOAD_DISABLED
          return hovered.value ? IconButtons.DOWNLOAD_HOVER : IconButtons.DOWNLOAD;

        case 'receipt':
          return hovered.value ? IconButtons.RECEIPT_HOVER : IconButtons.RECEIPT;

        case 'left':
          if (props.disabled) return IconButtons.LIST_LEFT_DISABLED
          return hovered.value ? IconButtons.LIST_LEFT_HOVER : IconButtons.LIST_LEFT;

        case 'right':
          if (props.disabled) return IconButtons.LIST_RIGHT_DISABLED
          return hovered.value ? IconButtons.LIST_RIGHT_HOVER : IconButtons.LIST_RIGHT;

        case 'rotate':
          return hovered.value ? IconButtons.ROTATE : IconButtons.ROTATE_HOVER;

        case 'delete-black':
          if (props.disabled) return IconButtons.DELETE_BLACK_DISABLED
          return hovered.value ? IconButtons.DELETE_BLACK_HOVER : IconButtons.DELETE_BLACK;

        case 'close':
          return IconButtons.CLOSE;

        case 'join':
          if (props.disabled) return IconButtons.JOIN_DISABLED
          else return hovered.value ? IconButtons.JOIN_HOVER : IconButtons.JOIN;
        default:
          return '';
      }
    })

    return {
      iconUrl,
      handleMouseOn,
      handleMouseLeave
    }
  }
})

