import { reactive } from "vue"
import type { FormItemRule, FormRules } from "element-plus"
import { notAllowedSymbolsForNumberInputs } from "@/utils/data/constants"

const validateCPFC = (rule: any, value: string | number, callback: any) => {
  if (value === "" || !value) {
    callback(new Error("Введите значение"))
    return
  }
  if (typeof value === "string" && value.match(notAllowedSymbolsForNumberInputs)) {
    callback(new Error("Введен некорректный символ"))
    return
  }

  callback()
}
const cpfcStandartRules: FormItemRule[] = [
  {
    validator: validateCPFC,
    trigger: "change",
    required: true,
  },
]

export default function useIngredientsRules() {
  const rules = reactive<FormRules>({
    name: [
      {
        required: true,
        message: "Пожалуйста, введите название",
        trigger: "blur",
      },
      {
        min: 3,
        message: "Длина должна быть не менее 3х символов",
        trigger: "blur",
      },
    ],
    category: [
      {
        required: true,
        message: "Пожалуйста, выберите категорию",
        trigger: "change",
      },
    ],

    unit: [
      {
        required: true,
        message: "Пожалуйста, выберите ед. измерения",
        trigger: "change",
      },
    ],
    calories: cpfcStandartRules,
    proteins: cpfcStandartRules,
    fats: cpfcStandartRules,
    carbs: cpfcStandartRules,
  })

  return { rules }
}
