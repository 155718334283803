import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20cb5453"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "bottom-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dish_description = _resolveComponent("dish-description")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.dishForPreview)
      ? (_openBlock(), _createBlock(_component_dish_description, {
          key: 0,
          modelValue: _ctx.dishForPreview,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dishForPreview) = $event)),
          clickedDish: _ctx.dishForPreview,
          readonly: true,
          onCloseDescription: _ctx.handleCloseDishPreview
        }, null, 8, ["modelValue", "clickedDish", "onCloseDescription"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_table, {
        data: _ctx.filterTableData,
        "table-layout": _ctx.tableLayout,
        "max-height": "60vh",
        onRowClick: _ctx.handleOpenDishPreview,
        class: _normalizeClass({ night: _ctx.isNightSelect })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            "column-key": "name",
            fixed: "",
            label: "Название",
            "min-width": "70px",
            prop: "name",
            sortable: "",
            width: "300px",
            "word-wrap": "break-word"
          }, {
            header: _withCtx(() => [
              _createVNode(_component_SearchInput, {
                onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
                searchValue: _ctx.search,
                placeholderValue: "Поиск",
                onHandleSearch: _ctx.handleSearchInput,
                onClearSearch: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSearchInput('')))
              }, null, 8, ["searchValue", "onHandleSearch"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            "filter-method": _ctx.filterHandlerCategory,
            filters: _ctx.categoryFilters,
            label: "Кагегория",
            "min-width": "160px",
            prop: "category"
          }, null, 8, ["filter-method", "filters"]),
          _createVNode(_component_el_table_column, {
            "filter-method": _ctx.filterHandlerMenu,
            filters: _ctx.menuFilters,
            label: "Тип меню",
            "min-width": "160px",
            prop: "menuType"
          }, null, 8, ["filter-method", "filters"]),
          _createVNode(_component_el_table_column, {
            "column-key": "price",
            label: "Цена (Rub)",
            "min-width": "120px",
            prop: "price",
            sortable: ""
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            width: "200px"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_icon_button, {
                type: "redo",
                onClick: [
                  ($event: any) => (_ctx.handleRestore(scope.row)),
                  _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
                ]
              }, null, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data", "table-layout", "onRowClick", "class"])
    ])
  ]))
}