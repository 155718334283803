import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11efd8c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["checked", "disabled"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("input", _mergeProps(_ctx.$attrs, {
      class: "input",
      type: "checkbox",
      checked: _ctx.checked,
      disabled: _ctx.disabled,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:checked', $event.target.checked)))
    }), null, 16, _hoisted_2),
    _createElementVNode("img", {
      src: _ctx.iconUrl,
      alt: ""
    }, null, 8, _hoisted_3)
  ]))
}