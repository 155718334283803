import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-580dcb92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "bottom-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_table, {
        data: _ctx.filterTableData,
        "table-layout": _ctx.tableLayout,
        "max-height": "60vh",
        class: _normalizeClass({ night: _ctx.isNightSelect })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            "column-key": "name",
            fixed: "",
            label: "Название",
            "min-width": "70px",
            prop: "name",
            sortable: "",
            width: "300px",
            "word-wrap": "break-word"
          }, {
            header: _withCtx(() => [
              _createVNode(_component_SearchInput, {
                onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
                searchValue: _ctx.search,
                placeholderValue: "Поиск",
                onHandleSearch: _ctx.handleSearchInput,
                onClearSearch: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSearchInput('')))
              }, null, 8, ["searchValue", "onHandleSearch"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            "filter-method": _ctx.filterHandlerCategory,
            filters: _ctx.categoryFilters,
            "column-key": "category",
            label: "Категория",
            "min-width": "120px",
            prop: "category",
            sortable: ""
          }, null, 8, ["filter-method", "filters"]),
          _createVNode(_component_el_table_column, {
            "column-key": "unit",
            label: "Ед. измерения",
            "min-width": "120px",
            prop: "unit",
            sortable: ""
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            width: "200px"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_icon_button, {
                type: "redo",
                onClick: [
                  ($event: any) => (_ctx.handleRestore(scope.row)),
                  _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
                ]
              }, null, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data", "table-layout", "class"])
    ])
  ]))
}