import {MenuLink} from "@/modules/portal/models/interfaces/menu-links-interface";
import {Permission, PermissionInMenu} from "@/utils/data/interfaces"
import {Roles} from "@/modules/order/models/data/enums";
import permissionsMenu from "@/modules/portal/models/records/permissions-menu-links";
import {permissions} from "@/modules/portal/models/enums/permissions";

export default function sortPermissions(permissions: Array<Permission>): Map<string, Array<MenuLink>> {

  const menuByRoles: Map<string, Array<MenuLink>> = new Map()

  Object.values(Roles).forEach(role => {
    menuByRoles.set(role, [])
  })
  // Для правильной последовательности вкладок
  const menuInCorrectSequence = Object.values(permissionsMenu).filter((item: PermissionInMenu) =>
    permissions.find((permission: Permission) => permission.name === item.permission)
  )

  //C бека прилетает в userData массив пермишенов вида Permission, задача состоит в разделении прилетающих пермишенов по 2 типам меню
  //на пользовательское и администраторское, что и происходит в forEach ниже

  menuInCorrectSequence.forEach(((item: PermissionInMenu) => {
      if (item.role.includes((Roles.USER as string)))
        menuByRoles.get((Roles.USER as string))?.push(<MenuLink>permissionsMenu[(item.permission as permissions)])
      else
        menuByRoles.get((Roles.ADMIN as string))?.push(<MenuLink>permissionsMenu[(item.permission as permissions)])
    }
  ))

  return menuByRoles


}



