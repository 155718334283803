
import {computed, defineComponent, PropType, ref} from "vue"
import {Dish, Ingredient} from "@/utils/data/interfaces"
import {useStore} from "vuex"
import {ClickOnDishMethod} from "@/utils/data/types";

export default defineComponent({
  name: "DishView",
  props: {
    //Dish in dish menu
    dish: {
      type: Object as PropType<Dish>,
      default: {},
    },
    // Determines whether to display the remainder
    maxAmountDisplay: {
      type: String,
      default: "none"
    },
    //Determines wether dish weight and price is displayed
    totalDisplaying: {
      type: Boolean,
      default: true,
    },
    //Uses in spare dishes for dishes identification
    showIdentification: {
      type: Boolean,
      default: false,
    },
    //Determines if chosen dishes label will be blue
    useDishBacklite: {
      type: Boolean,
      default: false
    },
    clickOnDishMethod: {
      type: String as PropType<ClickOnDishMethod>,
      default: 'view'
    },
  },
  emits: ["addDish"],
  setup(props, {emit}) {
    const store = useStore()
    const userId = store.getters.getUserId
    const ingredients = (dishIngredients: Array<Ingredient>): string => {
      const ingredients: Array<string> = dishIngredients.map((ingredient, index) =>
        index > 0 ? ingredient.name.toLocaleLowerCase() : ingredient.name)
      return ingredients.join(" / ")
    }
    //Dish label has a blue color when dish amount is more than zero
    const dishLabelClass = (amount: number | undefined): string => {
      if (props.useDishBacklite) return amount ? "dish-label chosen-dish" : "dish-label"
      else return "dish-label"
    }
    //Dish description
    const dishDescriptionVisible = ref(false)
    const dishClicked = async () => {
      if (props.clickOnDishMethod === "add") {
        emit("addDish")
        return
      }
      const dishId = props.dish.dishId
      if (store.getters.getUsers.size === 0) await store.dispatch("downloadUsers")
      await store.dispatch("downloadDishLikes", {userId, dishId})
      await store.dispatch("downloadDishComments", dishId)
      await store.dispatch("downloadFullSizeImage", dishId)
      dishDescriptionVisible.value = true
    }
    const closeDishDescription = () => {
      dishDescriptionVisible.value = false
    }
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    return {
      isNightSelect,
      ingredients,
      dishLabelClass,
      dishDescriptionVisible,
      dishClicked,
      closeDishDescription,
    }
  },
})
