import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aeab8dc0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog-header" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "guide-book-name" }
const _hoisted_4 = { class: "body" }
const _hoisted_5 = { class: "buttons-horizontal-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_button = _resolveComponent("close-button")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    class: _normalizeClass({ night: _ctx.isNightSelect }),
    width: _ctx.editDialogWidth,
    "show-close": false,
    "append-to-body": true,
    onOpen: _ctx.dialogOpening
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_close_button, { onClick: _ctx.closeDialog }, null, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.guideBookName), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_el_form, {
          class: "purchasing-guide-books-form",
          ref: "ruleFormRef",
          "label-position": "top",
          model: _ctx.editingRow,
          rules: _ctx.editingRowFormRules,
          size: _ctx.formSize,
          onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "Название",
              prop: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  class: _normalizeClass({ night: _ctx.isNightSelect }),
                  id: "name",
                  modelValue: _ctx.editingRow.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editingRow.name) = $event)),
                  onInput: _ctx.validateName
                }, null, 8, ["class", "modelValue", "onInput"])
              ]),
              _: 1
            }),
            (_ctx.editingRow.guideBookType === _ctx.PurchasingGuideBooks.MANUFACTURERS)
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  label: _ctx.SecondColumnNames.MANUFACTURERS,
                  prop: "productCategory"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.editingRow.productCategory,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editingRow.productCategory) = $event)),
                      class: _normalizeClass([{ night: _ctx.isNightSelect }, "m-2"]),
                      onChange: _ctx.manufacturerSelectChanged
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ingredientsCategorySelectOptions, (option) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: option.label,
                            label: option.label,
                            value: option.value
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "class", "onChange"])
                  ]),
                  _: 1
                }, 8, ["label"]))
              : _createCommentVNode("", true),
            (_ctx.editingRow.guideBookType === _ctx.PurchasingGuideBooks.UNITS)
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 1,
                  label: _ctx.SecondColumnNames.UNITS,
                  prop: "shortUnitName"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      class: _normalizeClass({ night: _ctx.isNightSelect }),
                      modelValue: _ctx.editingRow.shortUnitName,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editingRow.shortUnitName) = $event)),
                      onChange: _ctx.unitInputChanged,
                      onInput: _ctx.validateShortName
                    }, null, 8, ["class", "modelValue", "onChange", "onInput"])
                  ]),
                  _: 1
                }, 8, ["label"]))
              : _createCommentVNode("", true),
            (_ctx.editingRow.guideBookType === _ctx.PurchasingGuideBooks.PURCHASING_PLACES)
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 2,
                  label: _ctx.SecondColumnNames.PURCHASING_PLACES,
                  prop: "parentPlace"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      class: _normalizeClass([{ night: _ctx.isNightSelect }, "m-2"]),
                      modelValue: _ctx.editingRow.parentPlace,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editingRow.parentPlace) = $event)),
                      onChange: _ctx.purchasingPlacesSelectChanged
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parentPurchasingPlacesSelectOptions, (option) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: option.value,
                            label: option.label,
                            value: option.value
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["class", "modelValue", "onChange"])
                  ]),
                  _: 1
                }, 8, ["label"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["model", "rules", "size"]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_custom_button, {
            "type-button": "success",
            disabled: _ctx.isSaveButtonDisabled,
            onClick: _ctx.saveClicked
          }, {
            default: _withCtx(() => [
              _createTextVNode("СОХРАНИТЬ ")
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]),
          _createVNode(_component_custom_button, {
            "type-button": "reset",
            disabled: _ctx.isResetButtonDisabled,
            onClick: _ctx.resetForm
          }, {
            default: _withCtx(() => [
              _createTextVNode("СБРОСИТЬ ")
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["class", "width", "onOpen"]))
}