import {
  TableRow,
  SummaryMethodProps,
} from "@/modules/admin/orders-issuing/models/tableInterfaces"

//Summary method for total row. If all dishes in order are fully issued their amount will not take part in total summary
export const dishSummaryMethod = (param: SummaryMethodProps<TableRow>) => {
  const { columns, data } = param
  const dishSums: string[] = []
  columns.forEach((column, columnIndex) => {
    if (columnIndex === 0) dishSums[columnIndex] = "Итого:"
    else {
      let amountSum = 0
      let issueAmountSum = 0
      data.forEach((row) => {
        const keys = Object.keys(row)
        const values = Object.values(row)
        const rowItem: Array<string> = values[keys.indexOf(column.property)]
          ? values[keys.indexOf(column.property)].split(" / ")
          : []
        if (parseInt(rowItem[0])) amountSum += parseInt(rowItem[0])
        if (parseInt(rowItem[1])) issueAmountSum += parseInt(rowItem[1])
      })
      dishSums[columnIndex] = `${amountSum} / ${issueAmountSum}`
    }
  })
  return dishSums
}
