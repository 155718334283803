import {Roles} from "@/modules/order/models/data/enums";
import {MenuLink} from "@/modules/portal/models/interfaces/menu-links-interface";

interface hasUserPermission {
  adminPermissions: boolean
  userPermissions: boolean
}

export default function hasAnyPermissions(permissionsMenu: Map<string, Array<MenuLink>>):hasUserPermission {
  return {
    adminPermissions: !!permissionsMenu.get(Roles.ADMIN)!.length,
    userPermissions: !!permissionsMenu.get(Roles.USER)!.length
  } as hasUserPermission
}
