import DISHCATEGORY from "../enums/DishCategory";

const DishCategoryName: Record<DISHCATEGORY, string> = {
  [DISHCATEGORY.BREAKFAST]: 'Завтрак',
  [DISHCATEGORY.SOUP]: 'Суп',
  [DISHCATEGORY.GARNISH]: 'Гарнир',
  [DISHCATEGORY.SECONDCOURSE]: 'Второе блюдо',
  [DISHCATEGORY.SALAD]: 'Салат',
  [DISHCATEGORY.BAKERY]: 'Выпечка',
  [DISHCATEGORY.DRINK]: 'Напитки',
};
export default DishCategoryName