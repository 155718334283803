
import { computed, defineComponent, ref } from "vue"
import { Order, OrderedDish } from "@/utils/data/interfaces"
import { createYearOptions, monthOptions, } from "@/modules/order/models/filterOptions"
import { useStore } from "vuex"
import WeekdaysItem from "@/modules/order/components/order-history/WeekdaysItem.vue"
import WeekItem from "@/modules/order/components/order-history/WeekItem.vue"
import moment from "moment"

export default defineComponent({
  name: "OrderHistory",
  components: {
    WeekdaysItem,
    WeekItem
  },
  setup() {
    const store = useStore()
    const userId = store.getters.getUserId
    const currentDate = ref(moment())
    const monthValue = ref(currentDate.value.month() + 1)
    moment.locale("ru")
    const monthValueString = computed(() => {
      return moment.months()[monthValue.value - 1]
    })
    const yearValue = ref(currentDate.value.year())
    store.dispatch("downloadFirstUserOrder", userId)
    // date of first user's order ever. Needs for years options
    const firstOrderDate = computed(() => {
      return store.getters.getFirstOrderDate
    })
    const yearOptions = computed(() => {
      return createYearOptions(moment(firstOrderDate.value).year())
    })
    //Dates of mondays in chosen month in ISO format
    const mondaysDates = computed(() => {
      const mondaysDates: Array<string> = []
      for (let counter = 0; counter < 5; counter++) mondaysDates.push(moment({
        year: yearValue.value,
        month: monthValue.value - 1,
        day: 1,
        hour: 0
      }).day(1).add(counter, "weeks").toISOString())
      return mondaysDates
    })
    //Months shifting
    const leftButtonClicked = () => {
      monthValue.value = currentDate.value.subtract(1, "months").month() + 1
      yearValue.value = currentDate.value.year()
      downloadHistory()
    }
    const rightButtonClicked = () => {
      monthValue.value = currentDate.value.add(1, "months").month() + 1
      yearValue.value = currentDate.value.year()
      downloadHistory()
    }
    const selectChanged = () => {
      currentDate.value = moment({ year: yearValue.value, month: monthValue.value - 1, day: 1, hour: 0 })
      downloadHistory()
    }
    //Order history downloading
    const downloadHistory = () => {
      const dateFromMoment = moment({ year: yearValue.value, month: monthValue.value - 1, day: 1, hour: 0 })
      const dateFrom = dateFromMoment.toISOString()
      const dateTo = dateFromMoment.add(1, "months").toISOString()
      store.dispatch("downloadOrderHistory", { userId, dateFrom, dateTo })
    }
    downloadHistory()
    const orderHistory = computed(() => store.getters.getOrderHistory as Array<Order>)
    //Total values calculation
    const getOrderPrice = (dishes: Array<OrderedDish>): number => {
      let orderPrice = 0
      dishes.forEach((dish) => {
        orderPrice += dish.amount * dish.price
      })
      return orderPrice
    }
    const totalPerMonth = computed((() => {
      let totalPerMonth = 0
      orderHistory.value.forEach((order) => {
        totalPerMonth += getOrderPrice(order.dishes)
      })
      return totalPerMonth
    }))
    const gotPerMonth = computed(() => {
      let gotPerMonth = 0
      orderHistory.value.forEach((order) => {
        if (order.isIssued) gotPerMonth += getOrderPrice(order.dishes)
      })
      return gotPerMonth
    })
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    return {
      monthValue,
      monthValueString,
      yearValue,
      monthOptions,
      yearOptions,
      mondaysDates,
      leftButtonClicked,
      rightButtonClicked,
      selectChanged,
      getOrderPrice,
      orderHistory,
      totalPerMonth,
      gotPerMonth,
      isNightSelect,
    }
  },
})
