import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_download_button = _resolveComponent("download-button")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_icon_switcher = _resolveComponent("icon-switcher")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.buttonGroupClass)
  }, [
    (_ctx.isAddDisplayed)
      ? _withDirectives((_openBlock(), _createBlock(_component_custom_button, {
          key: 0,
          "type-button": "success",
          onClick: _ctx.addButtonClicked
        }, {
          default: _withCtx(() => [
            _createTextVNode("ДОБАВИТЬ")
          ]),
          _: 1
        }, 8, ["onClick"])), [
          [_vShow, _ctx.isFullSizeButtons]
        ])
      : _createCommentVNode("", true),
    (_ctx.isEditDisplayed)
      ? _withDirectives((_openBlock(), _createBlock(_component_custom_button, {
          key: 1,
          disabled: _ctx.editDisabled,
          onClick: _ctx.editButtonClicked
        }, {
          default: _withCtx(() => [
            _createTextVNode("РЕДАКТИРОВАТЬ")
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])), [
          [_vShow, _ctx.isFullSizeButtons]
        ])
      : _createCommentVNode("", true),
    (_ctx.isCopyDisplayed)
      ? _withDirectives((_openBlock(), _createBlock(_component_custom_button, {
          key: 2,
          disabled: _ctx.copyDisabled,
          onClick: _ctx.copyButtonClicked
        }, {
          default: _withCtx(() => [
            _createTextVNode("КОПИРОВАТЬ")
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])), [
          [_vShow, _ctx.isFullSizeButtons]
        ])
      : _createCommentVNode("", true),
    (_ctx.isJoinDisplayed)
      ? _withDirectives((_openBlock(), _createBlock(_component_custom_button, {
          key: 3,
          disabled: _ctx.joinDisabled,
          onClick: _ctx.joinButtonClicked
        }, {
          default: _withCtx(() => [
            _createTextVNode("ОБЪЕДИНИТЬ")
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])), [
          [_vShow, _ctx.isFullSizeButtons]
        ])
      : _createCommentVNode("", true),
    (_ctx.isDownloadDisplayed)
      ? _withDirectives((_openBlock(), _createBlock(_component_download_button, {
          key: 4,
          disabled: _ctx.downloadDisabled,
          "is-full-size": true,
          onDownloadClicked: _ctx.downloadButtonClicked
        }, {
          default: _withCtx(() => [
            _createTextVNode("ЗАГРУЗИТЬ ЧЕК")
          ]),
          _: 1
        }, 8, ["disabled", "onDownloadClicked"])), [
          [_vShow, _ctx.isFullSizeButtons]
        ])
      : _createCommentVNode("", true),
    (_ctx.isCancelDisplayed)
      ? _withDirectives((_openBlock(), _createBlock(_component_custom_button, {
          key: 5,
          disabled: _ctx.cancelDisabled,
          onClick: _ctx.cancelButtonClicked
        }, {
          default: _withCtx(() => [
            _createTextVNode("ОТМЕНИТЬ")
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])), [
          [_vShow, _ctx.isFullSizeButtons]
        ])
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_custom_button, {
      disabled: _ctx.deleteDisabled,
      onClick: _ctx.deleteButtonClicked
    }, {
      default: _withCtx(() => [
        _createTextVNode("УДАЛИТЬ")
      ]),
      _: 1
    }, 8, ["disabled", "onClick"]), [
      [_vShow, _ctx.isFullSizeButtons]
    ]),
    (_ctx.isAddDisplayed)
      ? _withDirectives((_openBlock(), _createBlock(_component_icon_button, {
          key: 6,
          type: "add",
          onClick: _ctx.addButtonClicked
        }, null, 8, ["onClick"])), [
          [_vShow, !_ctx.isFullSizeButtons]
        ])
      : _createCommentVNode("", true),
    (_ctx.isEditDisplayed)
      ? _withDirectives((_openBlock(), _createBlock(_component_icon_button, {
          key: 7,
          disabled: _ctx.editDisabled,
          type: "edit-list",
          onClick: _ctx.editButtonClicked
        }, null, 8, ["disabled", "onClick"])), [
          [_vShow, !_ctx.isFullSizeButtons]
        ])
      : _createCommentVNode("", true),
    (_ctx.isCopyDisplayed)
      ? _withDirectives((_openBlock(), _createBlock(_component_icon_button, {
          key: 8,
          disabled: _ctx.copyDisabled,
          type: "copy",
          onClick: _ctx.copyButtonClicked
        }, null, 8, ["disabled", "onClick"])), [
          [_vShow, !_ctx.isFullSizeButtons]
        ])
      : _createCommentVNode("", true),
    (_ctx.isJoinDisplayed)
      ? _withDirectives((_openBlock(), _createBlock(_component_icon_button, {
          key: 9,
          disabled: _ctx.joinDisabled,
          type: "join",
          onClick: _ctx.joinButtonClicked
        }, null, 8, ["disabled", "onClick"])), [
          [_vShow, !_ctx.isFullSizeButtons]
        ])
      : _createCommentVNode("", true),
    (_ctx.isDownloadDisplayed)
      ? _withDirectives((_openBlock(), _createBlock(_component_download_button, {
          key: 10,
          disabled: _ctx.downloadDisabled,
          onDownloadClicked: _ctx.downloadButtonClicked
        }, null, 8, ["disabled", "onDownloadClicked"])), [
          [_vShow, !_ctx.isFullSizeButtons]
        ])
      : _createCommentVNode("", true),
    (_ctx.isCancelDisplayed)
      ? _withDirectives((_openBlock(), _createBlock(_component_icon_button, {
          key: 11,
          disabled: _ctx.cancelDisabled,
          type: "cancel",
          onClick: _ctx.cancelButtonClicked
        }, null, 8, ["disabled", "onClick"])), [
          [_vShow, !_ctx.isFullSizeButtons]
        ])
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_icon_button, {
      disabled: _ctx.deleteDisabled,
      type: "reset",
      onClick: _ctx.deleteButtonClicked
    }, null, 8, ["disabled", "onClick"]), [
      [_vShow, !_ctx.isFullSizeButtons]
    ]),
    (_ctx.viewFullSizeButtons)
      ? (_openBlock(), _createBlock(_component_icon_switcher, {
          key: 12,
          type: "switch-buttons",
          onSwitched: _ctx.switchButtons
        }, null, 8, ["onSwitched"]))
      : _createCommentVNode("", true)
  ], 2))
}