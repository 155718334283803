
import { computed, defineComponent, PropType, ref } from "vue"
import { Order } from "@/utils/data/interfaces"
import { OrderViewType } from "@/utils/data/types"
import moment from "moment"
import { FULLDATE_FORMAT } from "@/utils/data/moment-formats"
import { useStore } from "vuex";

export default defineComponent({
  name: "OrderView",
  props: {
    //Order for viewing
    order: {
      type: Object as PropType<Order>,
      required: true
    },
    //Where this dialog will be used, "ordering" - for order confirmation(with OK and CANCEL buttons) or "history" - in order history with CLOSE button
    orderViewType: {
      type: String as PropType<OrderViewType>,
      default: "history"
    },
    //Uses in spare dishes for dishes identification
    showIdentification: {
      type: Boolean,
      default: false,
    },
    //Determines if is in plastic label will be displayed
    showIsInPlastic: {
      type: Boolean,
      default: true
    },
    //Determines if total price label will be displayed
    showTotal: {
      type: Boolean,
      default: true
    },
    dishesByTypes: {
      type: Array,
      default: []
    }
  },
  emits: ["closeOrderDescription", "okButtonClicked", "isInPlasticChanged", "saveDailyMenu"],
  setup(props, { emit }) {
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    //Determines displaying buttons
    const isHistoryType = () => props.orderViewType === "history"
    const isOrderingType = () => props.orderViewType === "ordering"

    //-there is a possibility to review daily menu before saving
    //-for this it is necessary to form daily menu as order and put it in prop
    const isMenuReviewType = () => props.orderViewType === "menuReview"

    const isInPlastic = computed({
      get: () => props.order.isInPlastic,
      set: (isInPlastic) => emit("isInPlasticChanged", isInPlastic)
    })

    const dialogTitle = computed(() => props.orderViewType === "menuReview" ? "Меню" : "Заказ")
    const scrollBarHeight = computed(() => `${store.getters.getCurrentClientHeight * 0.40}px`)
    const orderPrice = computed(() => {
      let totalPrice = 0
      props.order.dishes.forEach((dish) => totalPrice += dish.amount * dish.price)
      return totalPrice
    })
    const closeOrderDescription = () => emit("closeOrderDescription")

    const okButtonDisabled = ref(false)
    const dialogOpen = () => okButtonDisabled.value = false
    const okButtonClicked = () => {
      okButtonDisabled.value = true
      closeOrderDescription()
      emit("okButtonClicked")
    }

    const saveDailyMenu = () => {
      closeOrderDescription()
      emit("saveDailyMenu")
    }

    return {
      isNightSelect,
      isHistoryType,
      isOrderingType,
      isMenuReviewType,
      dialogTitle,
      scrollBarHeight,
      closeOrderDescription,
      okButtonDisabled,
      dialogOpen,
      okButtonClicked,
      saveDailyMenu,
      orderPrice,
      isInPlastic,
      moment,
      FULLDATE_FORMAT
    }

  }
})
