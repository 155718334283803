import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45293069"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_bold = _resolveComponent("close-bold")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_search = _resolveComponent("search")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_popover, { visible: _ctx.catherPopperVisible }, {
      reference: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _ctx.searchValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
          class: _normalizeClass({ night: _ctx.isNightSelect }),
          maxlength: "64",
          placeholder: "Выберите сотрудника",
          onClick: _ctx.openPopper,
          onInput: _ctx.handleInput
        }, {
          suffix: _withCtx(() => [
            _createVNode(_component_el_icon, {
              style: {"cursor":"pointer"},
              class: _normalizeClass(["el-input__icon close", { showed: _ctx.isAnythingInSearch }]),
              onClick: _ctx.clearInputSearch
            }, {
              default: _withCtx(() => [
                _createVNode(_component_close_bold)
              ]),
              _: 1
            }, 8, ["class", "onClick"]),
            _createVNode(_component_el_icon, { class: "el-input__icon" }, {
              default: _withCtx(() => [
                _createVNode(_component_search)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "class", "onClick", "onInput"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["content", { night: _ctx.isNightSelect }])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arrayUsers, (user) => {
            return (_openBlock(), _createElementBlock("span", {
              key: user.userId,
              class: "user",
              onClick: ($event: any) => (_ctx.setInputSearch(user))
            }, _toDisplayString(user.value), 9, _hoisted_2))
          }), 128))
        ], 2)
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}