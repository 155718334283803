export enum PurchasingGuideBooks {
  PURCHASING_PLACES = "Места закупки",
  MANUFACTURERS = "Производители",
  UNITS = "Единицы измерения",
}

export enum SecondColumnNames {
  PURCHASING_PLACES = "Родительское место закупки",
  MANUFACTURERS = "Группа продуктов",
  UNITS = "Краткое наименование",
}
export enum PeriodType {
  NOT_SELECTED = "Не выбрано",
  CREATED_AT = "Дата создания",
  COMPLETED_AT = "Дата завершения",
}

export enum ListStatus {
  NOT_SELECTED = "NOT_SELECTED",
  DRAFT = "DRAFT",
  NEW = "NEW",
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  DONE = "DONE",
}

export enum ListFormed {
  NOT_SELECTED = "NOT_SELECTED",
  AUTO = "AUTO",
  MANUAL = "MANUAL",
}

export enum ListActionType {
  ADD = "add",
  EDIT = "edit",
  COPY = "copy",
  VIEW = "view",
  DELETE = "delete",
  RESET = "reset",
  SEND = "send",
  SAVE = "save",
  COMPLETE = "complete",
  RETURN = "return",
  DELETE_ROW = "delete-row",
}

export enum IngredientGroupType {
  CATEGORY = "category",
  PLACE = "place",
  NO_PLACE = "Место не выбрано",
}

export enum ConfirmationMessages {
  SAVE_LIST = "Сохранить список закупки?",
  RESET = "Сбросить поля?",
  DELETE_LIST = "Удалить список закупки?",
  DELETE_ROWS = "Удалить строки?",
  DELETE_ROW = "Удалить строку?",
  DELETE_RECEIPT = "Удалить чек?",
  SEND = "Отправить список исполнителю?",
  COMPLETE = "Завершить список?",
  RETURN_LIST = "Вернуть список в работу?",
}

export enum SwitcherType {
  FILTER = "switch-filters",
  BUTTONS = "switch-buttons",
  INGREDIENT_GROUP = "switch-ingredients-group",
  VISIBLE = "switch-visible",
}
