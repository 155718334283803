import {Getter, Module} from "vuex"
import {DailyDishMenu, Dish} from "@/utils/data/interfaces"
import {Roles, SystemMessages, TypeMessages} from "@/modules/order/models/data/enums"
import {elMessage} from "@/modules/order/models/elMessage"
import {get, put} from "@/utils/fetchQueries"
import moment from "moment/moment";
import {FULLDATE_FORMAT} from "@/utils/data/moment-formats";
import DishMenuType from "@/modules/admin/models/enums/DishMenuType";
import {AdditionalRoles} from "@/modules/admin/AppSettings/models/rolesHelper";
import {updateOrdersAccordingToMenu} from "@/utils/helpers/updateOrdersAccordingToMenu";

const menuForSelectDate = (allMenus: Map<string, Map<DishMenuType, Map<number, Dish>>>, selectDate: string): Array<Dish> => {
  if (!allMenus.get(selectDate)) return []
  let allDishesForSelectDate = [] as Array<Dish>

  Object.values(DishMenuType).forEach(item => {
    if (!allMenus.get(selectDate)!.get(item)) {
      return
    }
    const tempDishesWithTypeMenu = [...allMenus.get(selectDate)!.get(item)!.values()] as Array<Dish>
    tempDishesWithTypeMenu.forEach(dish => allDishesForSelectDate.push(dish))
  })
  return allDishesForSelectDate
}
const idsDishesForParamsDate = (allMenus: Map<string, Map<DishMenuType, Map<number, Dish>>>, selectDate: string): Array<number> => {
  if (!allMenus.get(selectDate)) return []

  let allIdsDishesForSelectDate = [] as Array<number>

  Object.values(DishMenuType).forEach(menuType => {
    if (!allMenus.get(selectDate)!.get(menuType)) {
      return
    }
    const tempDishesWithTypeMenu = [...allMenus.get(selectDate)!.get(menuType)!.values()] as Array<Dish>
    tempDishesWithTypeMenu.forEach(dish => allIdsDishesForSelectDate.push(dish.dishId))
  })
  return allIdsDishesForSelectDate
}


export const creatingMenu: Module<any, any> = {
  state: {
    //selectors
    selectDate: '',
    selectTypeMenu: DishMenuType.COMMON,

    //dishes and menus
    allDishes: new Map<DishMenuType, Array<Dish>>(),
    allMenus: new Map<string, Map<DishMenuType, Map<number, Dish>>>(),
    menuForSelectDateBeforeEditing: [] as Array<Dish>,
    //dates
    datesWithMenu: new Set<string>(),
    datesWithUpdatedMenu: new Set<string>(),

  },
  getters: {
    getDishesForCreateMenu(state) {
      return state.allDishes.get(state.selectTypeMenu)?.map((dish: Dish) => {
        return {
          ...dish,
          dishInMenu: state.allMenus?.get(state.selectDate)?.get(state.selectTypeMenu)?.has(dish.dishId)
        }
      }) ?? []
    },

    getDishesForSelectDate(state) {
      if (state.allMenus.get(state.selectDate) && state.allMenus.get(state.selectDate).get(state.selectTypeMenu)) {
        return [...state.allMenus.get(state.selectDate).get(state.selectTypeMenu).values()]
      }
      return []
    },
    getAllDishesForSelectDate(state) {
      return menuForSelectDate(state.allMenus, state.selectDate)
    },

    getAllIdsDishesForSelectDate(state) {
      return idsDishesForParamsDate(state.allMenus, state.selectDate)
    },

    getIdsDishesForParamsDate: (state) => (date: string) => {
      return idsDishesForParamsDate(state.allMenus, date)
    },

    getDatesWithMenu(state) {
      return state.datesWithMenu
    },

    getDatesWithUpdatedMenu(state) {
      return [...state.datesWithUpdatedMenu.values()]
    },

    getHasNewDatesWithUpdatedMenu(state) {
      return !state.datesWithUpdatedMenu.size
    },

    getIsSelectMenuChanged(state) {
      const idsDishes = idsDishesForParamsDate(state.allMenus, state.selectDate).sort()
      const idsDishesBeforeEditing = state.menuForSelectDateBeforeEditing.map((dish: Dish) => dish.dishId).sort()
      return JSON.stringify(idsDishes) === JSON.stringify(idsDishesBeforeEditing)
    }
  },
  mutations: {
    changeSelectMenu(state, dish: Dish) {
      if (!state.allMenus.has(state.selectDate)) {
        state.allMenus.set(state.selectDate, new Map())
        state.allMenus.get(state.selectDate).set(state.selectTypeMenu, new Map())
        state.allMenus.get(state.selectDate).get(state.selectTypeMenu).set(dish.dishId, dish)
        return
      }
      if (!state.allMenus.get(state.selectDate).has(state.selectTypeMenu)) {
        state.allMenus.get(state.selectDate).set(state.selectTypeMenu, new Map())
        state.allMenus.get(state.selectDate).get(state.selectTypeMenu).set(dish.dishId, dish)
        return
      }
      if (state.allMenus.get(state.selectDate).get(state.selectTypeMenu).has(dish.dishId)) {
        state.allMenus.get(state.selectDate).get(state.selectTypeMenu).delete(dish.dishId)
        return
      }
      state.allMenus.get(state.selectDate).get(state.selectTypeMenu).set(dish.dishId, dish)

    },
    setAllMenus(state, data) {

      if (!state.allMenus.get(state.selectDate)) {
        data.forEach((dish: Dish) => {
          state.allMenus.set(moment(dish.date!).toISOString(), new Map())
        })
      }
      data.forEach((dish: Dish) => {
        state.allMenus.get(dish.date!)?.set(dish.menuType, new Map())
      })
      data.forEach((dish: Dish) => {
        if (dish.date === state.selectDate) {
          dish.dishInMenu = true
          state.allMenus.get(dish.date!)?.get(dish.menuType)?.set(dish.dishId, dish)
        } else state.allMenus.get(dish.date!)?.get(dish.menuType).set(dish.dishId, dish)
      })
      state.menuForSelectDateBeforeEditing = data
    },

    //DateWithMenu
    setDatesWithMenu(state, data) {
      state.datesWithMenu = new Set<string>()
      data.forEach((item: string) => {
        state.datesWithMenu.add(moment(item).format(FULLDATE_FORMAT))
      })
    },
    updateDatesWithMenu(state, date) {
      state.datesWithMenu.add(moment(date).format(FULLDATE_FORMAT))
      state.datesWithUpdatedMenu.add(date)
    },
    clearNewDatesWithMenu(state) {
      state.datesWithUpdatedMenu = new Set<string>()
    },
    //selected Date
    changeSelectDate(state, date) {
      state.selectDate = date
    },

    //selectTypeMenu
    changeSelectTypeMenu(state, typeMenu) {
      state.selectTypeMenu = typeMenu
    },

    //tableDishes
    setDishesForMenu(state, data) {
      state.allDishes.set(state.selectTypeMenu, data)
    }
  },
  actions: {
    addNewDateWithMenu({commit}, date) {
      elMessage(SystemMessages.MENU_SAVED, TypeMessages.SUCCESS)
      commit("updateDatesWithMenu", date)
    },
    setSelectDate({commit}, date) {
      commit("changeSelectDate", date)
    },
    addRemoveDishInMenu({commit}, dish) {
      commit("changeSelectMenu", dish)
    },
    async getDatesWithMenuOnSelectedPeriod({commit}, {mondayThisWeek, fridayThisWeek}) {

      const res = await get(
        `/api/daily-dish/dates?dateFrom=${mondayThisWeek}&dateTo=${fridayThisWeek}`
      )
      if (!res.ok) {
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
      } else {
        const data = (await res.json()).data
        commit("setDatesWithMenu", data.dates)
      }
    },
    async getDishesForMenu({commit}, activeMenuType) {
      commit("changeSelectTypeMenu", activeMenuType)

      if (this.getters.getDishesForCreateMenu.length !== 0) {
        return
      }

      const onlyNotDeletedDishes = true
      const res = await get(`/api/dish/?onlyNotDeleted=${onlyNotDeletedDishes}&menuType=${activeMenuType}`)
      if (!res.ok) {
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
      } else {
        const dishes: Dish[] = (await res.json()).data
        commit("setDishesForMenu", dishes)
      }
    },

    async getDailyDishMenu({commit}, {pickedDate}) {
      const forMenuCreating = true
      const res = await get(
        `/api/daily-dish?dateFrom=${pickedDate}&dateTo=${pickedDate}&forMenuCreating=${forMenuCreating}`
      )
      if (!res.ok) {
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
      } else {
        const data = (await res.json()).data as Array<Dish>
        commit("setAllMenus", data)
      }
    },

    async sendMenu({commit}, {userAdditionalRole, userRole}) {
      const menu = {
        menu: this.getters.getDatesWithUpdatedMenu.map((date: string) => {
          return {
            date: date,
            ids: this.getters.getIdsDishesForParamsDate(date)
          }
        }) as Array<DailyDishMenu>
      }

      const sortedDates = this.getters.getDatesWithUpdatedMenu.sort()
      const startPeriodDate = sortedDates[0]
      const endPeriodDate = sortedDates[sortedDates.length - 1];
      const isEditor: boolean = userAdditionalRole === AdditionalRoles.EDITOR || userRole === Roles.ADMIN

      const res = await put(`/api/daily-dish?dateFrom=${startPeriodDate}&dateTo=${endPeriodDate}&isEditor=${isEditor}`, menu)
      if (res.ok) {
        elMessage(SystemMessages.MENU_UPDATED, TypeMessages.SUCCESS)

        let menusWithIds= new Map<string, Array<number>>()
        for(let date of this.getters.getDatesWithUpdatedMenu) {
          menusWithIds.set(date, this.getters.getIdsDishesForParamsDate(date))
        }
        await updateOrdersAccordingToMenu(sortedDates, menusWithIds)

        commit("clearNewDatesWithMenu")
      } else
        res
          .json()
          .then((res) =>
            res.status && res.status === 405
              ? elMessage(SystemMessages.MENU_IN_USE, TypeMessages.ERROR)
              : elMessage(
                SystemMessages.SOMETHING_WENT_WRONG,
                TypeMessages.ERROR
              )
          )
    },
  },
}
export default creatingMenu
