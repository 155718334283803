import {Module} from "vuex"

import {Dish} from "@/utils/data/interfaces"

import {SystemMessages, TypeMessages} from "@/modules/order/models/data/enums"
import {elMessage} from "@/modules/order/models/elMessage"

import {get, put} from "@/utils/fetchQueries"
import filteringDishes from "@/store/modules/admin/utils/FilteringDishesForGuide";

import {SaveFiltering} from "@/utils/helpers/table/saveTableFiltering"
import {SaveSorting} from "@/utils/helpers/table/saveTableSorting"
import dishCategoryName from "@/modules/admin/models/records/DishCategoryName";
import dishMenuTypeName from "@/modules/admin/models/records/DishMenuTypeName";

const translateDishesForDisplay = (dishes: Array<Dish>) => {

  return dishes.map((dish: Dish) => {
    return {
      ...dish,
      category: dishCategoryName[dish.category],
      menuType: dishMenuTypeName[dish.menuType]
    }
  })
}

export const dishGuidebook: Module<any, any> = {
  state: {
    tableDishes: {
      dishes: [],
      deletedDishes: [],
      savedFiltering: {},
      savedSorting: null,
      savedSearch: ''
    },
  },
  getters: {
    getDishesForFind(state) {
      return state.tableDishes.dishes
    },
    getDeletedDishesForFind(state) {
      return state.tableDishes.deletedDishes
    },

    getDishesForGuide(state) {
      return translateDishesForDisplay(state.tableDishes.dishes)
    },
    getTableDeletedDishes(state) {
      return translateDishesForDisplay(state.tableDishes.deletedDishes)
    },
    getSavedFiltering(state) {
      return state.tableDishes.savedFiltering
    },
    getSavedSorting(state) {
      return state.tableDishes.savedSorting
    },
    getSavedSearch(state) {
      return state.tableDishes.savedSearch
    }
  },
  mutations: {
    setDishes(state, data) {
      state.tableDishes.dishes = data
    },
    setDeleteDishes(state, data) {
      state.tableDishes.deletedDishes = data
    },

    //mutation for post, put
    appendToTableDishes(state, data) {
      state.tableDishes.dishes.push(data)
    },
    deleteFromTableDishes(state, data) {
      state.tableDishes.dishes = filteringDishes(state.tableDishes.dishes, data)
    },

    appendToDeletedDishes(state, data) {
      state.tableDishes.deletedDishes.push(data)
    },
    deleteFromDeletedDishes(state, data) {
      state.tableDishes.deletedDishes = filteringDishes(state.tableDishes.deletedDishes, data)
    },

    saveFilter(state, data: SaveFiltering.FilterMap) {
      state.tableDishes.savedFiltering = {...state.tableDishes.savedFiltering, ...data}
    },

    saveSort(state, data: SaveSorting.CurrentSorting) {
      state.tableDishes.savedSorting = data
    },

    saveSearch(state, data: SaveSorting.CurrentSorting) {
      state.tableDishes.savedSearch = data
    }
  },
  actions: {
    async getDeletedDishes({commit}) {
      const res = await get(`/api/dish/?onlyDeleted=${true}&noIcons=${true}`)
      if (!res.ok) {
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
      } else {
        const dishesInArchive: Dish[] = (await res.json()).data // Template model of successful answer of backend
        commit("setDeleteDishes", dishesInArchive)
      }
    },
    async getDishes({commit}) {
      const res = await get(`/api/dish/?onlyNotDeleted=${true}&noIcons=${true}`)
      if (!res.ok) {
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
      } else {
        const dishes: Dish[] = (await res.json()).data // Template model of successful answer of backend
        commit("setDishes", dishes)
      }
    },

    async deleteDish({commit}, deletedDish) {
      const fullSizeImage = (await (await get(`/api/dish/image/${deletedDish.dishId}`)).json()).data.fullSizeImage
      const res = await put(`/api/dish/${+deletedDish.dishId!}`, {...deletedDish, fullSizeImage: fullSizeImage ? fullSizeImage : deletedDish.image })
      if (res.ok) {
        elMessage(SystemMessages.DISH_DELETED, TypeMessages.SUCCESS)
        await commit("deleteFromTableDishes", deletedDish)
        await commit("appendToDeletedDishes", deletedDish)
      } else {
        res.json().then(res => res.status && res.status === 405
          ? elMessage(SystemMessages.DISH_IN_USE, TypeMessages.ERROR)
          : elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
        )
      }
    },

    async restoreDish({commit}, restoredDish) {
      const fullSizeImage = (await (await get(`/api/dish/image/${restoredDish.dishId}`)).json()).data.fullSizeImage
      const res = await put(`/api/dish/${+restoredDish.dishId!}`, {...restoredDish,fullSizeImage: fullSizeImage ? fullSizeImage : restoredDish.image })
      if (res.ok) {
        elMessage(SystemMessages.DISH_RESTORED, TypeMessages.SUCCESS)
        await commit("appendToTableDishes", restoredDish)
        await commit("deleteFromDeletedDishes", restoredDish)
      } else elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
    },
  },
}
export default dishGuidebook
