import {Module} from "vuex"
import {User, UserForPayment} from "@/utils/data/interfaces";
import {get, put} from "@/utils/fetchQueries";
import {elMessage} from "@/modules/order/models/elMessage";
import {SystemMessages, TypeMessages} from "@/modules/order/models/data/enums";
import {getFullUserName} from "@/utils/helpers/getFullUserName";
import {getUserSurnameAndInitials} from "@/utils/helpers/getUserSurnameAndInitials";
import {downloadUsers} from "@/modules/admin/orders-issuing/models/fetch-modules/fetchModuleIssuing";

interface DatesForPayment {
  dateFrom: string,
  dateTo: string,
}

export const payment: Module<any, any> = {
  state: {
    allUsersForSelect: [] as Array<User>,
    selectedUsers: new Map<number, UserForPayment>(),
    sourceSelectedUsers: new Map<number, UserForPayment>(),
    idsUsersForRemind: new Set<number>(),
    selectedDates: {
      dateFrom: '',
      dateTo: '',
    } as DatesForPayment


  },
  getters: {
    getSelectedDates(state) {
      return state.selectedDates
    },
    getUsersForDisplaying: (state) => (filterValue: string) => {
      return state.allUsersForSelect.map((user: UserForPayment) => {
        {
          return {
            ...user,
            selectedForPayment: state.selectedUsers.has(user.userId)
          }
        }
      }).filter((user: UserForPayment) => (!filterValue || user.fullUserName?.toLowerCase().includes(filterValue.toLowerCase())))
        .sort((user1: UserForPayment, user2: UserForPayment) => (user1.fullUserName === user2.fullUserName) ? 0 : user1.fullUserName! > user2.fullUserName! ? 1 : -1)
    },

    getSelectedUsers: (state) => (filterValue?: string) => {
      return [...state.selectedUsers.values()]
        .filter((user: UserForPayment) => (!filterValue || user.fullUserName?.toLowerCase().includes(filterValue.toLowerCase())))
        .sort((item1, item2) => (item1.fullUserName === item2.fullUserName) ? 0 : item1.fullUserName > item2.fullUserName ? 1 : -1)
        .sort((item1, item2) => (item1.paid === item2.paid) ? 0 : item1.paid ? 1 : -1)
    },
    getNonPaidUsersForRemindCard: (state) => (filterValue?: string) => {
      return [...state.selectedUsers.values()].filter((user: UserForPayment) => !user.paid && (!filterValue || user.fullUserName?.toLowerCase().includes(filterValue.toLowerCase())))
    },

    getSelectedUsersForPaid(state) {
      return {
        bills: [...state.selectedUsers.values()].map((user: UserForPayment) => {
          return {
            userId: user.userId,
            paid: !!user.paid
          }
        })
      }
    },

    //for disable buttons
    getIdsUsersForRemind(state) {
      return [...state.idsUsersForRemind]
    },
    getHasIdsUsers(state) {
      return !![...state.idsUsersForRemind].length
    },
    getIsAllUsersPaid(state) {
      return !![...state.selectedUsers.values()].find((user) => !user.paid)
    },
    getIsListSelectedUsersChanged(state) {
      const selectedUsers = [...state.selectedUsers.values()]
      const sourceSelectedUsers = [...state.sourceSelectedUsers.values()]
      return JSON.stringify(sourceSelectedUsers) === JSON.stringify(selectedUsers) || sourceSelectedUsers.length > selectedUsers.length
    },
    getIsChangeSelectedUsers(state) {
      const selectedUsers = [...state.selectedUsers.values()].map((user: UserForPayment) => {
        return {
          userId: user.userId,
          monthlyAmount: user.monthlyAmount
        }
      })
      const sourceSelectedUsers = [...state.sourceSelectedUsers.values()].map((user: UserForPayment) => {
        return {
          userId: user.userId,
          monthlyAmount: user.monthlyAmount
        }
      })
      return JSON.stringify(sourceSelectedUsers) === JSON.stringify(selectedUsers)
    },
    getHasAnyUserSelectedEmptyMonthlyAmount(state) {
      return !![...state.selectedUsers.values()].find((user) => !user.monthlyAmount)
    },
    getHasAnyUsersInSourceSelectedUsers(state) {
      return !![...state.sourceSelectedUsers.values()].length
    }
  },
  mutations: {
    setAllUsersForSelect(state, {data, isMobile}) {
      state.allUsersForSelect = data.map((user: UserForPayment) => {
        user.fullUserName = isMobile ? getUserSurnameAndInitials(user) : getFullUserName(user)
        user.selectedForPayment = false
        return user
      })
    },
    setSelectedUsers(state, {data, isMobile}) {
      state.selectedUsers = new Map<number, UserForPayment>()
      state.sourceSelectedUsers = new Map<number, UserForPayment>()
      data.forEach((user: UserForPayment) => {
        state.selectedUsers.set(user.userId, {
          ...user,
          fullUserName: isMobile ? getUserSurnameAndInitials(user) : getFullUserName(user)
        })
        state.sourceSelectedUsers.set(user.userId, {
          ...user,
          fullUserName: isMobile ? getUserSurnameAndInitials(user) : getFullUserName(user)
        })
      })
    },
    updateSelectedUser(state, userId) {
      state.selectedUsers.get(userId).paid = !state.selectedUsers.get(userId).paid
    },
    changeSelectedUsers(state, user: User) {
      if (state.selectedUsers.has(user.userId)) {
        state.selectedUsers.delete(user.userId)
        return
      }
      state.selectedUsers.set(user.userId, user)
    },
    changeIdsUserForRemind(state, id) {
      if (state.idsUsersForRemind.has(id)) state.idsUsersForRemind.delete(id)
      else state.idsUsersForRemind.add(id)
    },
    clearIdsUserForRemind(state) {
      state.idsUsersForRemind = new Set()
    },
    setSelectedDates(state, dates: DatesForPayment) {
      state.selectedDates.dateTo = dates.dateTo
      state.selectedDates.dateFrom = dates.dateFrom
    }
  },
  actions: {
    async getAllUsersForPayment({commit}, isMobile: boolean) {
      const res = await downloadUsers()
      if (!res.ok) {
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
      } else {
        const data = (await res.json()).data
        commit("setAllUsersForSelect", {data, isMobile})
      }
    },

    async getSelectedUserForPayment({commit}, {dateFrom, dateTo, isMobile}) {
      commit("setSelectedDates", {dateFrom, dateTo})
      const res = await get(`/api/payment?dateFrom=${dateFrom}&dateTo=${dateTo}`)
      if (!res.ok) {
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
      } else {
        const data = (await res.json()).data
        commit("setSelectedUsers", {data, isMobile})
      }
    },

    async remindUsers({commit}) {
      const nonPaidUsers = this.getters.getIdsUsersForRemind
      const dates = this.getters.getSelectedDates as DatesForPayment
      const url = nonPaidUsers.length !== 0
        ? `/api/payment/remind?dateFrom=${dates.dateFrom}&dateTo=${dates.dateTo}&userIds=${nonPaidUsers.join(",")}`
        : `/api/payment/remind?dateFrom=${dates.dateFrom}&dateTo=${dates.dateTo}`
      const res = await put(url, nonPaidUsers)
      res.ok
        ? elMessage(SystemMessages.REMINDER_SENT, TypeMessages.SUCCESS)
        : elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
      commit("clearIdsUserForRemind")
    },

    async updateSelectedUserForPayment({commit}, {dateFrom, dateTo, isMobile}) {
      if (!this.getters.getSelectedUsersForPaid.bills.length && !this.getters.getHasAnyUsersInSourceSelectedUsers) {
        elMessage(SystemMessages.USERS_EMPTY, TypeMessages.ERROR)
        return
      }

      const res = await put(`/api/payment?dateFrom=${dateFrom}&dateTo=${dateTo}`, this.getters.getSelectedUsersForPaid)
      res.ok
        ? elMessage(SystemMessages.USERS_UPDATED, TypeMessages.SUCCESS)
        : elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
      this.dispatch("getSelectedUserForPayment", {dateFrom, dateTo, isMobile})
    },

    async userPaidMonth({commit}, userPaid) {
      commit("updateSelectedUser", userPaid.userId)
    },
    addRemoveSelectedUser({commit}, user) {
      commit("changeSelectedUsers", user)
    },
    addRemoveUserForRemind({commit}, user: User) {
      commit("changeIdsUserForRemind", user.userId)
    }
  }
}
export default payment
