import {deleteUrl, get, post, put} from "@/utils/fetchQueries"
import {Order} from "@/utils/data/interfaces"
import moment from "moment"
import {AdditionalRoles} from "@/modules/admin/AppSettings/models/rolesHelper";
import { Roles } from "../data/enums";

export const downloadDishMenu = (dateFrom: string, dateTo: string) =>
  get("/api/daily-dish", { dateFrom, dateTo })

export const downloadDaysWithFormedDishMenu = (
  dateFrom: string,
  dateTo: string
) => get("/api/daily-dish/dates", { dateFrom, dateTo })

export const saveUserOrder = (orderForDB: Order, userAdditionalRole?:AdditionalRoles, userRole?: Roles) =>
  post(`/api/order?fromAdmin=${userAdditionalRole === AdditionalRoles.COOK || userRole === Roles.ADMIN}`, orderForDB)

export const changeUserOrder = (orderForDB: Order, userAdditionalRole?:AdditionalRoles, userRole?: Roles) =>
  put(`/api/order?fromAdmin=${userAdditionalRole === AdditionalRoles.COOK || userRole === Roles.ADMIN}`, orderForDB)

export const deleteUserOrder = (userId: number, orderDate: string, userAdditionalRole?:AdditionalRoles, userRole?: Roles) => {
  const dateFrom = moment(orderDate)
    .hours(3)
    .minutes(0)
    .seconds(0)
    .milliseconds(0)
    .toISOString()
  const dateTo = moment(dateFrom).add(1, "days").toISOString()
  const fromAdmin = userAdditionalRole === AdditionalRoles.COOK || userRole === Roles.ADMIN;
  return deleteUrl(`/api/order/${userId}`, { fromAdmin, dateFrom, dateTo })
}

export const downloadUserOrder = (
  userId: number,
  dateFrom: string,
  dateTo: string
) => get(`/api/order/${userId}`, { dateFrom, dateTo })

export const downloadDishes = (dishIds: Array<number>) =>
  get(`/api/dish?dishIds=${dishIds.join(",")}`)

export const downloadFullSizeImage = (dishId: number) => {
  return get(`/api/dish/image/${dishId}`)
}