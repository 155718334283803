import {Module} from "vuex"
import {AdditionalRole, Permission, TimeSettingsInterface, User, UserForPayment} from "@/utils/data/interfaces"

import {SystemMessages, timeSettings, TypeMessages} from "@/modules/order/models/data/enums"
import {elMessage} from "@/modules/order/models/elMessage"

import {get, post} from "@/utils/fetchQueries"
import prepareUserPermissionFullList from "@/store/modules/admin/utils/PrepareUserPermissionFullList"
import SetFormatTimeSetting from "@/store/modules/admin/utils/SetFormatTimeSetting"
import {getUserSurnameAndInitials} from "@/utils/helpers/getUserSurnameAndInitials"
import {downloadAllRoles, setUserRole} from "@/modules/admin/AppSettings/models/fetchModules/processAdditionalRole"
import {AdditionalRoles} from "@/modules/admin/AppSettings/models/rolesHelper"
import prepareUserRoleFullList from "@/store/modules/admin/utils/PrepareUserRolesList";

export const appSettings: Module<any, any> = {
  state: {
    //unchanged data from the backend
    allRoles: [] as Array<AdditionalRole>,
    allUsers: [] as Array<User>,
    allPermissions: [] as Array<Permission>,

    userLocalRoles: [] as Array<AdditionalRole>,
    userLocalRolesBeforeEditing: [] as Array<AdditionalRole>,

    userLocalPermissions: [] as Array<Permission>,
    userLocalPermissionsBeforeEditing: [] as Array<Permission>,

    timeSettings: [] as Array<TimeSettingsInterface>,
  },

  getters: {
    getAllUsers(state) {
      return state.allUsers.sort((user1: UserForPayment, user2: UserForPayment) => (user1.fullUserName === user2.fullUserName) ? 0 : user1.fullUserName! > user2.fullUserName! ? 1 : -1)
    },
    getAllUsersForSearch: (state) => (filterValue: string) => {
      return state.allUsers
        .sort((user1: UserForPayment, user2: UserForPayment) => (user1.fullUserName === user2.fullUserName) ? 0 : user1.fullUserName! > user2.fullUserName! ? 1 : -1)
        .filter((user: UserForPayment) => !filterValue || user.fullUserName?.toLowerCase().includes(filterValue.toLowerCase()))
        .map((user: User) => {
          return {
            value: `${user.fullUserName} (${user.email})`,
            userId: user.userId,
          }
        })
    },

    getUserByID: (state) => (idUser: number) => {
      return state.allUsers.find((user: User) => user.userId === idUser)
    },
    getAllPermissions(state) {
      return state.allPermissions
    },
    getSelectedUserPermissions(state) {
      return state.userLocalPermissions
    },
    getTimeSetting(state) {
      return SetFormatTimeSetting(state.timeSettings)
    },
    getAllRoles(state) {
      return state.allRoles
    },
    getAllIdsRoles(state) {
      return state.allRoles.map((role: AdditionalRole) => role.id)
    },
    getSelectedUserRoles(state) {
      return state.userLocalRoles
    },
    getSelectedRoleId(state) {
      return state.userLocalRoles.find((role: AdditionalRole) => role.selected)?.id ?? 0
    },

    getUsersByRole: (state) => (roleName: AdditionalRoles) => {
      let userIds: Array<number> = []
      state.allRoles.forEach((role: AdditionalRole) => {
        if (role.name === roleName) userIds = role.userIds
      })
      return userIds
    },
    getIsChangeUserPermissions(state) {
      return JSON.stringify(state.userLocalPermissions.sort()) === JSON.stringify(state.userLocalPermissionsBeforeEditing.sort())
    },
    getIsChangeUserRoles(state) {
      return JSON.stringify(state.userLocalRoles.sort()) === JSON.stringify(state.userLocalRolesBeforeEditing.sort())
    }
  },
  mutations: {
    //Roles
    setAllRoles(state, data) {
      state.allRoles = data.map((role: AdditionalRole) => ({...role, selected: false}))
    },
    updateSelectedRole(state, changedRole: AdditionalRole) {
      state.userLocalRoles = state.userLocalRoles.map((role: AdditionalRole) => {
        return {
          ...role,
          selected: role.selected && role.name === changedRole.name ? false : role.name === changedRole.name
        }
      })
    },
    resetSelectedRole(state) {
      state.userLocalRoles.forEach((role: AdditionalRole) => role.selected = false)
    },
    //Users
    setAllUsers(state, data) {
      state.allUsers = data.map((user: User) => {
        user.fullUserName = getUserSurnameAndInitials(user)
        return user
      })
    },
    updateUserSelection(state, userId) {
      state.userLocalPermissions = prepareUserPermissionFullList(
        state.allUsers,
        userId,
        JSON.parse(JSON.stringify(state.allPermissions))
      )
      state.userLocalPermissionsBeforeEditing = JSON.parse(JSON.stringify(state.userLocalPermissions))

      state.userLocalRoles = prepareUserRoleFullList(
        state.allUsers,
        userId,
        JSON.parse(JSON.stringify(state.allRoles)))

      state.userLocalRolesBeforeEditing = JSON.parse(JSON.stringify(state.userLocalRoles))
    },

    setDefaultUserLocalRoles(state, data) {
      state.userLocalRoles = data.map((item: AdditionalRole) => {
        return {
          ...item,
          selected: false,

        }
      })
    },

    //Permissions
    setAllPermissions(state, data) {
      state.allPermissions = data
    },

    setDefaultUserLocalPermissions(state, data) {
      state.userLocalPermissions = data.map((item: Permission) => {
        item.selected = false
        return item
      })
    },

    changeUserPermission(state, permissionId) {
      state.userLocalPermissions.forEach((permission: Permission) => {
        if (permission.id === permissionId)
          permission.selected = !permission.selected
      })
    },

    clearUserLocalPermissions(state) {
      state.userLocalPermissions.forEach(
        (permission: Permission) => (permission.selected = false)
      )
    },

    //Time settings
    setTimeSetting(state, data) {
      state.timeSettings = data
    },

  },
  actions: {
    //Users
    updateSelectedUser({commit}, userId) {
      commit("updateUserSelection", userId)
    },
    async getAllUsers({commit}) {
      const res = await get(`/api/auth/users/full-info?excludeAdmins=${true}`)
      if (!res.ok) {
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
      } else {
        const data = (await res.json()).data
        commit("setAllUsers", data)
      }
    },
    //Permissions
    addRemovePermission({commit}, permissionsId) {
      commit("changeUserPermission", permissionsId)
    },
    clearUserPermission({commit}) {
      commit("clearUserLocalPermissions")
    },
    async getAllPermissions({commit}) {
      const res = await get(`/api/permission`)
      if (!res.ok) {
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
      } else {
        const data: Array<Permission> = (await res.json()).data
        commit("setAllPermissions", data)
        commit(
          "setDefaultUserLocalPermissions",
          JSON.parse(JSON.stringify(data))
        )
      }
    },


    async setUserSettings({commit}, user: User) {
      if (!this.getters.getIsChangeUserPermissions) {
        const responseByPermissions = await post(
          `/api/permission/${user.userId}`,
          {
            userId: user.userId,
            permissions: this.getters.getSelectedUserPermissions.filter((item: Permission) => item.selected)
          }
        )
        responseByPermissions.ok
          ? elMessage(SystemMessages.PERMISSIONS_SAVED, TypeMessages.SUCCESS)
          : elMessage(SystemMessages.PERMISSIONS_NOT_SAVED, TypeMessages.ERROR)
      }

      if (!this.getters.getIsChangeUserRoles) {
        const responseByRoles = await setUserRole(user.userId, this.getters.getSelectedRoleId)
        responseByRoles.ok
          ? elMessage(SystemMessages.ROLES_SAVED, TypeMessages.SUCCESS)
          : elMessage(SystemMessages.PROLES_NOT_SAVED, TypeMessages.ERROR)
      }
      this.dispatch("getAllUsers")
    },

    //Roles
    async getAllRoles({commit}) {
      const res = await downloadAllRoles()
      if (res.ok) {
        const roles = (await res.json()).data
        commit("setAllRoles", roles)
        commit("setDefaultUserLocalRoles", roles)
      }
    },

    //Time settings
    async getInstalledTimeSettings({commit}) {
      const res = await get(`/api/time-settings`)
      if (!res.ok) {
        elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
      } else {
        const data = (await res.json()).data
        //TODO: вернуть настройку уведомления о расформированном заказе, когда будет готов бэк
        commit("setTimeSetting", data.filter((item: TimeSettingsInterface) => item.type !== timeSettings.CANCELED_ORDER_NOTIFICATION))
      }
    },

    async setTimeSettings({commit}, timeSettings) {
      const setting = {settings: timeSettings}
      const res = await post(`/api/time-settings`, setting)
      this.dispatch("getInstalledTimeSettings")
      res.ok
        ? elMessage(SystemMessages.TIMESETTINGS_SAVE, TypeMessages.SUCCESS)
        : elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
    },
  },
}
export default appSettings
