import { ElMessage } from "element-plus"
import { EpPropMergeType } from "element-plus/es/utils/vue/props/types"

export const elMessage = (
  message: string,
  type:
    | EpPropMergeType<
      StringConstructor,
      "success" | "warning" | "info" | "error",
      unknown
    >
    | undefined
) => {
  ElMessage({
    grouping: true,
    duration: 1500,
    message: message,
    type: type,
  })
}
