import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55603f79"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "emoji-settings-wrapper" }
const _hoisted_2 = { class: "emoji-settings" }
const _hoisted_3 = { class: "emoji-row-wrapper" }
const _hoisted_4 = { class: "emoji-image-wrapper" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "buttons-horizontal-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_dialog_window = _resolveComponent("dialog-window")!
  const _component_emoji_handling = _resolveComponent("emoji-handling")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_table, {
        data: _ctx.emojiTableData,
        class: _normalizeClass({ night: _ctx.isNightSelect })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            "highlight-current-row": "",
            label: "Эмодзи",
            "min-width": "170px"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("img", {
                    src: scope.row.picture,
                    alt: "Эмодзи"
                  }, null, 8, _hoisted_5)
                ]),
                _createElementVNode("div", null, _toDisplayString(scope.row.name), 1)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            "min-width": "50px"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", {
                class: "edit-buttons-wrapper",
                onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createVNode(_component_icon_button, {
                  type: "edit",
                  onClick: ($event: any) => (_ctx.editEmoji(scope.row))
                }, null, 8, ["onClick"]),
                _createVNode(_component_icon_button, {
                  type: "delete",
                  onClick: ($event: any) => (_ctx.deleteEmoji(scope.row))
                }, null, 8, ["onClick"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data", "class"]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_custom_button, {
          "type-button": "success",
          onClick: _ctx.addEmoji
        }, {
          default: _withCtx(() => [
            _createTextVNode("ДОБАВИТЬ ЭМОДЗИ")
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _createVNode(_component_dialog_window, {
      "show-close": false,
      modelValue: _ctx.deleteDialogVisible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.deleteDialogVisible) = $event)),
      title: "Подтвердите действие",
      "main-text": _ctx.confirmMessage,
      onCancelClicked: _ctx.hideDeleteDialog,
      onOkClicked: _ctx.deletingConfirmed
    }, null, 8, ["modelValue", "main-text", "onCancelClicked", "onOkClicked"]),
    _createVNode(_component_emoji_handling, {
      modelValue: _ctx.emojiHandlingVisible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.emojiHandlingVisible) = $event)),
      emoji: _ctx.currentEmoji,
      action: _ctx.emojiActionType,
      onCloseDialog: _ctx.hideEmojiHandling
    }, null, 8, ["modelValue", "emoji", "action", "onCloseDialog"])
  ]))
}